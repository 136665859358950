import { Injectable } from '@angular/core';
import { CompanyStorage } from 'src/app/_core/company-storage';
import { TaxApiService } from './tax-api.service';
import { MonitoringService } from 'src/app/services/monitoring.service';

export interface ITaxConfig {
  tin: string;
  ipn: string;
  orgFullName: string;
  cashiers: Cashier[];
  store: TaxObject;

  active: boolean;
  taxRegister: TaxRegister;
  cashier: Cashier;
  defaultTaxLetter: string;
  useDefaultTax: boolean;

  loading?: boolean;
  ready?: boolean;
  hasFeature?: boolean;
  editSettings?: boolean;
  editTaxObject?: boolean;
  editTaxRegister?: boolean;
  taxObjects?: TaxObject[];
  taxObjectsLoading?: boolean;
  taxObjectsError: boolean;
  stores?: TaxObject[];
  isNewOrg: boolean;
  shiftView?: boolean;
  privateLoaded?: boolean;
}

class TaxObject {
  id: string;
  erpStoreId: number;
  erpStoreName: string;
  typeObjectName: string;
  name: string;
  toCode: number;
  address: string;
  stanObject: string;
  typeOfRights: string;
  accStart: string;
  distr: string;
  lastCh: string;
  territ: string;
  taxRegisters: TaxRegister[];
}

class TaxRegister {
  address: string;
  created: string;
  fiscalNumber: string;
  localNumber: number;
  name: string;
  objectName: string;
  status: string;
}

export class Cashier {
  id: string;
  'organizationId': string;
  'chiefCashier': boolean;
  'name': string;
  'registerNumber': string;
  'status': string;
  'subjectKeyId': string;
  'hasPrivateKey': boolean;
  privateKey: CashierPrivateKey;
}

export class CashierPrivateKey {
  id?: string;
  expirationDate?: string;
  cashierId?: string;
  keyFileBase64: string;
  keyFileName: string;
  tin?: string;
  providerAddress?: string;
  providerCmpAddress?: string;
  providerName: string;
  providerOcspAccessPointAddress?: string;
  providerOcspAccessPointPort?: string;
  providerTspAddress?: string;
  providerTspAddressPort?: string;
  password?: string;
}

const TAX_REGISTER_CONFIG = 'TAX_REGISTER_CONFIG';

@Injectable({
  providedIn: 'root',
})
export class TaxRegisterConfigService {
  private _config: ITaxConfig[] = [];

  constructor(
    private companyStorage: CompanyStorage,
    private taxApiService: TaxApiService,
    private monitoringService: MonitoringService
  ) {}

  async updateTaxConfigs(companyId: string, storeId: number) {
    return this.taxApiService
      .getStoreConfig(companyId, storeId)
      .toPromise()
      .then(async (res) => {
        await this.saveConfig(res);
      })
      .catch((err) => {
        this.monitoringService.logException(err, 1, {
          operation: 'updateTaxConfigs',
          storeId: storeId,
        });
      });
  }

  async saveConfig(newConfigs: ITaxConfig[]) {
    const storage = await this.companyStorage.repository<ITaxConfig>(
      TAX_REGISTER_CONFIG
    );
    const configsToSave: ITaxConfig[] = [];

    for (let ntc of newConfigs) {
      const storedConfig = await this.getStoreConfig(ntc.store.erpStoreId);
      if (storedConfig) {
        if (storedConfig.taxRegister) {
          const taxReg = ntc.store.taxRegisters.find(
            (tr) => tr.fiscalNumber == storedConfig.taxRegister.fiscalNumber
          );

          if (taxReg) {
            ntc.taxRegister = taxReg;
          }
        }

        ntc.cashier = storedConfig.cashier;
        ntc.active = storedConfig.active ? storedConfig.active : ntc.active;
      }

      configsToSave.push(ntc);
    }

    await storage.loadItems(configsToSave);
    this._config = configsToSave;
  }

  async enableConfig(orgCode: string, erpStoreId: number) {
    const orgConfig = await this.getOrgConfig(orgCode, erpStoreId);
    orgConfig.active = true;
    return this.addOrUpdateTaxConfig(orgConfig);
  }

  async disableConfig(orgCode: string, erpStoreId: number) {
    const orgConfig = await this.getOrgConfig(orgCode, erpStoreId);
    orgConfig.active = false;
    return this.addOrUpdateTaxConfig(orgConfig);
  }

  async getStoreConfig(storeId: number) {
    if (!this._config || !this._config.length) {
      const storage = await this.companyStorage.repository<ITaxConfig>(
        TAX_REGISTER_CONFIG
      );

      this._config = await storage.getItems();
    }

    return this._config.find((c) => c.store.erpStoreId == storeId && c.active);
  }

  async getRegisterConfig(taxRegisterFiscalNumber: string) {
    if (!this._config || !this._config.length) {
      const storage = await this.companyStorage.repository<ITaxConfig>(
        TAX_REGISTER_CONFIG
      );

      this._config = await storage.getItems();
    }

    return this._config.find(
      (c) => c.active && c.taxRegister?.fiscalNumber == taxRegisterFiscalNumber
    );
  }

  async getConfig() {
    if (this._config && this._config.length) {
      return this._config;
    }

    const storage = await this.companyStorage.repository<ITaxConfig>(
      TAX_REGISTER_CONFIG
    );

    this._config = await storage.getItems();
    return this._config;
  }

  async updateDefaultTax(
    orgCode: string,
    erpStoreId: number,
    taxLetter: string,
    isActive: boolean
  ) {
    const orgConfig = await this.getOrgConfig(orgCode, erpStoreId);
    orgConfig.defaultTaxLetter = taxLetter;
    orgConfig.useDefaultTax = isActive;
    return this.addOrUpdateTaxConfig(orgConfig);
  }

  async selectTaxRegister(
    orgCode: string,
    taxRegister: TaxRegister,
    erpStoreId: number
  ) {
    const orgConfig = await this.getOrgConfig(orgCode, erpStoreId);
    orgConfig.taxRegister = taxRegister;
    return this.addOrUpdateTaxConfig(orgConfig);
  }
  async clearTaxRegister(orgCode: string, erpStoreId: number) {
    const orgConfig = await this.getOrgConfig(orgCode, erpStoreId);
    orgConfig.taxRegister = null;
    return this.addOrUpdateTaxConfig(orgConfig);
  }

  async selectCashier(orgCode: string, cashier: any, erpStoreId: number) {
    const orgConfig = await this.getOrgConfig(orgCode, erpStoreId);
    orgConfig.cashier = cashier;
    return this.addOrUpdateTaxConfig(orgConfig);
  }
  async clearCashier(orgCode: string, erpStoreId: number) {
    const orgConfig = await this.getOrgConfig(orgCode, erpStoreId);
    orgConfig.cashier = null;
    return this.addOrUpdateTaxConfig(orgConfig);
  }

  async getOrgConfig(orgCode: string, erpStoreId: number): Promise<ITaxConfig> {
    const storage = await this.companyStorage.repository<ITaxConfig>(
      TAX_REGISTER_CONFIG
    );

    const configs = await storage.getItems();
    const orgConfig = configs.find(
      (c) =>
        c.store.erpStoreId == erpStoreId &&
        ((c.tin && c.tin == orgCode) || (c.ipn && c.ipn == orgCode))
    );
    if (!orgConfig) {
      throw `Налаштуванна ПРРО для організації ${orgCode} не знеайдено.`;
    }

    return orgConfig;
  }

  private async addOrUpdateTaxConfig(taxConfig: ITaxConfig) {
    const storage = await this.companyStorage.repository<ITaxConfig>(
      TAX_REGISTER_CONFIG
    );

    const configsToSave: ITaxConfig[] = [];
    const configs = await storage.getItems();
    if (!configs) {
      configsToSave.push(taxConfig);
    } else {
      for (let tc of configs) {
        if (
          tc.store.erpStoreId == taxConfig.store.erpStoreId &&
          ((tc.tin && tc.tin == taxConfig.tin) ||
            (tc.ipn && tc.ipn == taxConfig.ipn))
        ) {
          configsToSave.push(taxConfig);
        } else {
          configsToSave.push(tc);
        }
      }
    }

    this._config = configsToSave;
    return storage.loadItems(configsToSave);
  }
}
