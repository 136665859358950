import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import AsyncUtils from '../utility/async-utils';

export interface IBluetoothDevice {
  id: string;
  uuid: string;
  address: string;
  class: number;
  name: string;
  rssi: number;
}

@Injectable({ providedIn: 'root' })
export class BluetoothService {
  constructor(
    private _bluetoothSerial: BluetoothSerial,
    private _androidPermissions: AndroidPermissions
  ) {}

  async getConnectedDevices(): Promise<IBluetoothDevice[]> {
    await this._checkPermissions(
      this._androidPermissions.PERMISSION.BLUETOOTH_CONNECT
    );
    await this._turnOnBluetooth();
    return this._bluetoothSerial.list();
  }

  async sendDataToDevice(
    deviceAddress: string,
    data: string | ArrayBuffer | number[] | Uint8Array
  ): Promise<void> {
    await this._checkPermissions(
      this._androidPermissions.PERMISSION.BLUETOOTH_SCAN
    );

    await this._bluetoothSerial
      .isConnected()
      .then(() => this._bluetoothSerial.disconnect())
      .catch((err) => {});

    await this._turnOnBluetooth();
    const connection = await this._connect(deviceAddress);
    if (!connection) {
      throw 'Помилка підключення до принтера';
    }

    await this._bluetoothSerial.write(data);
    let count = 0;
    while (count < 10) {
      ++count;
      const isConnected = await this._bluetoothSerial
        .isConnected()
        .catch((err) => {
          return 'NO';
        });

      if (isConnected == 'NO') {
        return Promise.resolve();
      }

      await AsyncUtils.wait(700);
    }

    return this._bluetoothSerial.disconnect().catch((err) => {});
  }

  private async _turnOnBluetooth() {
    const isEnabled = await this._bluetoothSerial.isEnabled().catch((res) => {
      return this._bluetoothSerial
        .enable()
        .then((r) => {
          return 'OK';
        })
        .catch((err) => {
          return 'NO';
        });
    });

    if (isEnabled != 'OK') {
      throw 'Необхідно увімкнути Bluetooth.';
    }
  }

  private async _checkPermissions(permission: any) {
    const result = await this._androidPermissions
      .checkPermission(permission)
      .catch((err) => {
        return { hasPermission: false };
      });

    if (!result.hasPermission) {
      const requestResult = await this._androidPermissions
        .requestPermission(permission)
        .catch((err) => {
          return { hasPermission: false };
        });
    }
  }

  private async _connect(address: string): Promise<boolean> {
    return new Promise((res, rej) => {
      this._bluetoothSerial.connect(address).subscribe(
        (con) => {
          if (con == 'OK') {
            res(true);
          } else {
            res(false);
          }
        },
        (err) => rej(err)
      );
    });
  }
}
