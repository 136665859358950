import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { ITerminalData } from '../modules/company/company-data.service';
import { StorageService } from '../services/storage.service';
const POS_TERMINAL_SETTINGS = 'POS_TERMINAL_SETTINGS';

export class RepositoryItem {
  id?: number | string;
  [key: string]: any;
}

export class Repository<T> {
  private get table(): Promise<any> {
    if (!this._tableName) {
      throw 'Generic repository. key not provided';
    }

    return this._storage.get(this._tableName);
  }

  constructor(private _storage: Storage, private _tableName: string) {}

  async getItems(): Promise<T[]> {
    return this.table.then((res) => res || []);
  }

  async getItem(id: string | number, idKey: string): Promise<T> {
    const items: T[] = await this.table;
    if (items) {
      return items.find((i) => i[idKey] && i[idKey] == id) as T;
    }
  }

  async addItem(item: T, idKey?: string, allowDuplicate = false): Promise<any> {
    const items: T[] = await this.table;

    if (!items) {
      return this._storage.set(this._tableName, [item]);
    } else {
      if (idKey && !allowDuplicate) {
        const existingItem = items.find((i) => i[idKey] == item[idKey]);
        if (!existingItem) {
          items.push(item);
          return this._storage.set(this._tableName, items);
        }
      } else {
        items.push(item);
        return this._storage.set(this._tableName, items);
      }
    }
  }

  async updateItem(item: T, idKey: string): Promise<void> {
    const items: T[] = await this.table;
    if (!items || items.length == 0) {
      return null;
    }

    let newItems: T[] = [];
    for (let i of items) {
      if (i[idKey] && i[idKey] == item[idKey]) {
        newItems.push(item);
      } else {
        newItems.push(i);
      }
    }

    return this._storage.set(this._tableName, newItems);
  }

  async addOrupdateItem(
    item: T,
    idKey: string,
    position: 'initial' | 'start' | 'end' = 'initial'
  ): Promise<void> {
    const items: T[] = await this.table;
    if (!items) {
      return this._storage.set(this._tableName, [item]);
    } else {
      let exists = false;
      let newItems: T[] = [];
      for (let i of items) {
        if (i[idKey] && i[idKey] == item[idKey]) {
          if (position == 'initial') {
            newItems.push(item);
          }
          exists = true;
        } else {
          newItems.push(i);
        }
      }

      if (!exists && position == 'initial') {
        newItems.push(item);
      }

      if (position == 'end') {
        newItems.push(item);
      }

      if (position == 'start') {
        newItems.unshift(item);
      }

      return this._storage.set(this._tableName, newItems);
    }
  }

  async deleteItem(item: T, idKey: string): Promise<void> {
    const items: T[] = await this.table;
    if (!items || items.length == 0) {
      return null;
    }

    let toKeep: T[] = [];
    for (let i of items) {
      if (i[idKey] && i[idKey] == item[idKey]) {
        continue;
      }

      toKeep.push(i);
    }

    return this._storage.set(this._tableName, toKeep);
  }

  async deleteItemByKey(key: string, idKey: string): Promise<void> {
    const items: T[] = await this.table;
    if (!items || items.length == 0) {
      return null;
    }

    let toKeep: T[] = [];
    for (let i of items) {
      if (i[idKey] && i[idKey] == key) {
        continue;
      }

      toKeep.push(i);
    }

    return this._storage.set(this._tableName, toKeep);
  }

  async loadItems(items: RepositoryItem[]): Promise<void> {
    if (!this._tableName) {
      throw 'Generic repository. key not provided';
    }

    return this._storage.set(this._tableName, items);
  }
  async saveItem(item: RepositoryItem): Promise<void> {
    if (!this._tableName) {
      throw 'Generic repository. key not provided';
    }

    return this._storage.set(this._tableName, item);
  }

  async getSingleItem(): Promise<RepositoryItem> {
    if (!this._tableName) {
      throw 'Generic repository. key not provided';
    }

    return this._storage.get(this._tableName);
  }

  async deleteTable(): Promise<void> {
    if (!this._tableName) {
      throw 'Generic repository. key not provided';
    }

    return this._storage.remove(this._tableName);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CompanyStorage {
  private _companyData = new BehaviorSubject<ITerminalData>(null);

  constructor(private _baseStorageService: StorageService) {}

  async set(key: string, value: any) {
    const _storage = await this._getCompanyStorage();
    return _storage.set(key, value);
  }

  async get(key: string) {
    const _storage = await this._getCompanyStorage();
    return _storage.get(key);
  }

  async remove(key: string) {
    const _storage = await this._getCompanyStorage();
    return _storage.remove(key);
  }

  async keys() {
    const _storage = await this._getCompanyStorage();
    return _storage.keys();
  }

  async repository<T>(table: string) {
    const _storage = await this._getCompanyStorage();
    return new Repository<T>(_storage, table);
  }

  private async _getCompanyStorage(): Promise<Storage> {
    const companyId = await this._getCompanyId().catch((err) => {
      alert(err);
      throw err;
    });

    return this._baseStorageService.getNamedStorage(companyId).catch((err) => {
      alert(err);
      throw err;
    });
  }

  private async getCompanyData(): Promise<ITerminalData> {
    if (this._companyData.value) {
      return this._companyData.value;
    }

    return this._baseStorageService.get(POS_TERMINAL_SETTINGS).then((data) => {
      if (data) {
        this._companyData.next(data);
        return data;
      }
    });
  }

  clearCompanyData() {
    this._companyData.next(null);
  }

  private async _getCompanyId(): Promise<string> {
    const companyData = await this.getCompanyData();
    if (!companyData) {
      throw new Error('Company data not found.');
    }
    return companyData.store.company_id;
  }
}
