import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  ITaxConfig,
  TaxRegisterConfigService,
} from 'src/app/common/tax/services/tax-register-config.service';
import { TaxService } from 'src/app/common/tax/tax.service';

@Component({
  selector: 'order-fiscal-switch',
  templateUrl: './order-fiscal-switch.component.html',
  styleUrls: ['./order-fiscal-switch.component.scss'],
})
export class OrderFiscalSwitchComponent implements OnInit {
  @Input() storeId: number;

  orgName = '';
  registerLabel = '';
  registerAddress = '';
  taxRegisterConfigured = false;
  private _isFiscalCheck = true;
  get isFiscalCheck() {
    return this._isFiscalCheck && !!this._storeConfig;
  }
  fiscalOperationInProgress = false;
  private signerStateSub: Subscription;
  loading = false;
  @Input() type: 'button' | 'card' = 'card';
  notConfigured = false;
  private _storeConfig: ITaxConfig;

  constructor(
    private taxRegisterConfigService: TaxRegisterConfigService,
    private taxService: TaxService
  ) {}

  updateIsFiscal(value: boolean) {
    this._isFiscalCheck = value;
  }

  async ngOnInit() {
    this._storeConfig = await this.taxRegisterConfigService.getStoreConfig(
      this.storeId
    );
    if (this._storeConfig) {
      this.orgName = this._storeConfig.orgFullName;
      this.registerLabel = `${this._storeConfig.taxRegister?.fiscalNumber} ${this._storeConfig.taxRegister?.name}`;
      this.registerAddress = `${this._storeConfig.taxRegister?.address}`;
      this.taxService.isAgentSignerReady(this._storeConfig).then((res) => {
        if (res) {
          this.taxRegisterConfigured = true;
        }
      });

      const _signerStateSub = this.taxService.getSignerStateSub(
        this._storeConfig.cashier.subjectKeyId
      );

      if (_signerStateSub) {
        this.signerStateSub = _signerStateSub.subscribe((state) => {
          switch (state) {
            case 'off':
            case 'error':
              this.loading = false;
              this.taxRegisterConfigured = false;
              break;

            case 'pending':
              this.loading = true;
              break;
            case 'keyReady':
              this.loading = true;
              break;

            case 'ready':
              this.loading = false;
              this.taxRegisterConfigured = true;
              break;

            default:
              this.loading = false;
              this.taxRegisterConfigured = false;
              break;
          }
        });
      }
    } else {
      this.notConfigured = true;
    }
  }

  toggleFiscalOperation() {
    this._isFiscalCheck = !this._isFiscalCheck;
  }

  ngOnDestroy() {
    if (this.signerStateSub) {
      this.signerStateSub.unsubscribe();
    }
  }
}
