import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-info-base-modal',
  templateUrl: './info-base-modal.component.html',
  styleUrls: ['./info-base-modal.component.scss'],
})
export class InfoBaseModalComponent implements OnInit {
  dtaSub: Observable<any>;
  article: any;
  private _sub: Subscription;
  loading = false;
  errorMessage: string;

  constructor(private modalCtrl: ModalController) {}

  cancel() {
    this.modalCtrl.dismiss();
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  ngOnInit() {
    this.loading = true;
    this.errorMessage = undefined;
    this._sub = this.dtaSub.subscribe(
      (res) => {
        this.article = res;
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.errorMessage = 'Інструкцію не знайдено';
      }
    );
  }
}
