import {
  Component,
  Output,
  Input,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { INumpudConfig } from './numpad.service';

@Component({
  selector: 'app-numpad',
  templateUrl: './numpad.component.html',
  styleUrls: ['./numpad.component.scss'],
})
export class NumpadComponent implements OnInit, AfterViewInit {
  private _value: string;

  set value(input: string) {
    this._value = input;

    let val = input;
    if (this.numpudConfig.autoDecimal) {
      val = (+input / 100).toFixed(2);
    }

    this.change.emit(val);
  }
  get value() {
    return this._value;
  }

  submitBtn = false;
  width = 300;
  height = 300;
  displayOutput = false;
  @Output() change = new EventEmitter<string>();
  @Input() numpudConfig: INumpudConfig;

  preselectBtns: Array<number> = [];
  placeholder: string;
  @ViewChild('valueInput') valueInput: ElementRef<HTMLInputElement>;
  fixPayButtonWidth = '33%';
  divisible = false;
  fractionDigits?: 2 | 3;

  constructor(private modalController: ModalController) {}

  changeValue(ev: any) {
    ev.stopPropagation();
    ev.preventDefault();

    const val = ev.key;
    if (val == 'Backspace') {
      if (this.value.length > 0) {
        this.value = this.value.slice(0, -1);
      }

      return;
    }

    if (!(!isNaN(Number.parseFloat(val)) || val == '.' || val == ',')) {
      return;
    }

    if (this.value) {
      this.value += ev.key;
    } else {
      this.value = ev.key;
    }
  }

  ngOnInit() {
    this.submitBtn = this.numpudConfig.submitBtn;
    this.displayOutput = this.numpudConfig.displayOutput;

    if (this.numpudConfig.preselectBtns) {
      this.preselectBtns = this.numpudConfig.preselectBtns;
      const buttonWidth =
        Math.round((100 / this.preselectBtns.length) * 100) / 100;
      this.fixPayButtonWidth = `${buttonWidth}%`;
    }

    if (this.numpudConfig.value) {
      this.value = this.numpudConfig.value.toString();
    }

    if (this.numpudConfig.placeholder) {
      this.placeholder = this.numpudConfig.placeholder;
    } else {
      this.placeholder = 'Вага в грамах';
    }

    if (this.numpudConfig.height) {
      this.height = this.numpudConfig.height;
    }

    if (this.numpudConfig.width) {
      this.width = this.numpudConfig.width;
    }

    this.fractionDigits = this.numpudConfig.fractionDigits;
    this.divisible = this.numpudConfig.divisible && !this.fractionDigits;
  }

  ngAfterViewInit() {
    if (this.valueInput && this.valueInput.nativeElement) {
      // this.valueInput.nativeElement.focus();
    }
  }

  setValue(event: any) {
    const command = event.target.dataset.value;
    if (command === undefined) {
      return;
    }

    let input = this.value ? this.value.toString() : '';

    if (this.valueInput) {
      // this.valueInput.nativeElement.focus();
    }

    // if (!this.displayOutput) {
    //   this.value = command;
    //   return;
    // }

    switch (command) {
      case 'backspace':
        if (input.length > 0) {
          input = input.slice(0, -1);
        }

        this.value = this._moneyMask(input);
        return;

      case 'ok':
        this.modalController.dismiss(this._toNumber(this.value));
        return;

      case '.':
        if (!input) {
          return;
        }

        if (input.indexOf('.') > -1) {
          return;
        }
    }

    if (input) {
      input += command;
    } else {
      input = command;
    }

    this.value = this._moneyMask(input);
  }

  private _toNumber(value: string) {
    if (this.fractionKoeficient) {
      value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
      return parseFloat(value) / this.fractionKoeficient;
    } else {
      return parseFloat(value);
    }
  }

  private _moneyMask = (value: string) => {
    if (!this.fractionDigits) {
      return value;
    }

    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

    const options = { minimumFractionDigits: this.fractionDigits };
    const result = new Intl.NumberFormat('uk-UA', options).format(
      parseFloat(value) / this.fractionKoeficient
    );

    return result;
  };

  get fractionKoeficient() {
    if (!this.fractionDigits) {
      return 0;
    }

    if (this.fractionDigits == 2) {
      return 100;
    }
    if (this.fractionDigits == 3) {
      return 1000;
    }
  }

  setPreselectValue(value: string) {
    if (this.numpudConfig.autoDecimal) {
      this.value = `${value}00`;
    } else {
      this.value = value;
    }
  }

  reset() {
    this._value = null;
  }

  initialValue(value: string) {
    this._value = value;
  }
}
