import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderDTO } from 'src/app/private/terminal/repositories/orders.repository';
import { NumpadComponent } from 'src/app/shared/numpad/numpad.component';
import MoneyUtils from 'src/app/utility/money-utils';
import { OrderFiscalSwitchComponent } from '../order-fiscal-switch/order-fiscal-switch.component';

@Component({
  selector: 'app-order-pay',
  templateUrl: './order-pay.component.html',
  styleUrls: ['./order-pay.component.scss'],
})
export class OrderPayComponent implements OnInit {
  order: OrderDTO;
  isAdvance: boolean;
  numpadButtons = ['100', '200', '500'];
  orderAmountFocus = false;
  orderAdvanceFocus = false;
  @ViewChild('numpad') numpad: NumpadComponent;
  paymentProvided: string;
  paymentAdvance: string;
  advanceAmount: number;
  inputAmount: number;
  paymentChange: string;
  orderTotalAmount = '';
  @ViewChild('fiscalSwitch') fiscalSwitch: OrderFiscalSwitchComponent;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.orderTotalAmount = MoneyUtils.money(
      this.order.totalAmount - this.order.totalPaid
    );
    if (this.isAdvance) {
      this.orderAdvanceFocus = true;
    } else {
      this.orderAmountFocus = true;
    }
  }

  paymentMethod: 'cash' | 'card' = 'cash';
  setPaymentMethod(pm: 'cash' | 'card') {
    this.paymentMethod = pm;
  }

  close() {
    this.modalCtrl.dismiss();
  }

  pay() {
    if (!this.isValid) {
      return;
    }

    const amount = this.isAdvance
      ? this.advanceAmount
      : this.order.totalAmount - this.order.totalPaid;
    this.modalCtrl.dismiss({
      paymentMethod: this.paymentMethod,
      amount: amount,
      providedAmount: this.inputAmount,
      isFiscal: this.fiscalSwitch.isFiscalCheck,
    });
  }

  paymentProvidedClick() {
    this.orderAdvanceFocus = false;
    this.orderAmountFocus = true;
    this.numpad.initialValue(this.paymentProvided);
  }

  paymentAdvanceClick() {
    this.orderAdvanceFocus = true;
    this.orderAmountFocus = false;
    this.numpad.initialValue(this.paymentAdvance);
  }

  get isValid(): boolean {
    if (this.isAdvance) {
      if (!this.advanceAmount) {
        return false;
      }

      if (this.advanceAmount > this.order.totalAmount - this.order.totalPaid) {
        return false;
      }

      if (this.paymentMethod == 'card') {
        return true;
      }

      if (!this.inputAmount) {
        return false;
      }

      if (this.inputAmount < this.advanceAmount) {
        return false;
      }

      return true;
    } else {
      if (this.paymentMethod == 'card') {
        return true;
      } else {
        if (!this.inputAmount) {
          return false;
        }

        if (this.inputAmount < this.order.totalAmount - this.order.totalPaid) {
          return false;
        }

        return true;
      }
    }
  }

  numpadInput(value: any) {
    if (this.orderAmountFocus) {
      this.paymentProvided = value;
      this.inputAmount = +value;
    }

    if (this.orderAdvanceFocus) {
      this.paymentAdvance = value;
      this.advanceAmount = +value;
    }

    let paymentChange = 0;
    if (this.isAdvance) {
      paymentChange = value - this.advanceAmount;
    } else {
      const needToPay = +this.order.amount - this.order.totalPaid;
      paymentChange = value - needToPay;
      if (paymentChange > 0) {
        this.paymentChange = MoneyUtils.money(paymentChange);
      } else {
        this.paymentChange = '0,00';
      }
    }

    if (paymentChange > 0) {
      this.paymentChange = MoneyUtils.money(paymentChange);
    } else {
      this.paymentChange = '0,00';
    }
  }
}
