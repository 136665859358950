import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericRepository } from './generic.repository';

export interface IWorkAreaItem {
  id?: number;
  name?: string;
}
class WorkAreaItem implements IWorkAreaItem {
  id?: number;
  name?: string;
}

const TABLE_KEY = '__work_areas';

@Injectable({ providedIn: 'root' })
export class WorkAreasRepository {
  constructor(private _repository: GenericRepository) {}
  getWorkAreas(): Promise<WorkAreaItem[]> {
    return this._repository.getItems(TABLE_KEY);
  }

  loadWorkAreas(workAreas: WorkAreaItem[]): Promise<void> {
    return this._repository.loadItems(TABLE_KEY, workAreas);
  }

  clearStorage() {
    return this._repository.deleteTable(TABLE_KEY);
  }
}
