import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { ICheckTax } from 'src/app/common/tax/models/tax-order.model';
import MoneyUtils from 'src/app/utility/money-utils';
import { IReceipt, IReceiptProduct } from '../terminal.models';
import { GenericRepository, IGenericItem } from './generic.repository';

export class OrderItemDTO {
  uuid = UUID.UUID();
  productId: number;
  name: string;
  price: number;
  product?: any;
  quantity: number;
  weighted?: boolean = false;
  productTypeId: number;
  productType: string;
  unitId: number;
  amount: number;
  work_start?: Date;
  closed?: Date;
  closedByName: string;
  employeeName: string;
  employee_id: string;
  isService: boolean;
  workAreaId?: number;
  amount_str?: string;
  percentDiscount?: number;
  amountDiscount?: number;
  discountType?: 0 | 1;
  totalAmount?: number;
  total_str?: string;
  chips_cost?: number;
  chips_for_issue?: number;
  uktZedCode: string;
  displayDiscount?: boolean;
  producttaxid?: number;
  producttaxname?: string;
  producttaxpercent?: number;
  producttaxletter?: string;

  taxprice?: number;

  excisetaxid?: number;
  excisetaxname?: string;
  excisetaxpercent?: number;
  excisetaxletter?: string;

  exciseLabels?: string[];
  uktzedcode?: string;
}

export class OrderDTO implements IGenericItem {
  id = 0;
  uuid = UUID.UUID();
  saleOrderId?: number;
  assigneeId: string;
  partnerId?: number;
  acceptorId: string;
  employeeId: string;
  createdBy: string;
  employeeName?: string;
  customer: any;
  customerId: number;
  customer_id?: number;
  customerName?: string;
  deadline: Date;
  statusId: number;
  orderStateId: number;
  opened: string;
  created: string;
  createdName: string;
  note: string;
  publicNote: string;
  iscashless = false;
  cashless = false;
  amount: number;
  total_str: string;
  totalPaid: number;
  totalReturned: number;
  totalReturnedStr: string;
  total: number;
  totalStr: string;
  totalPaidStr: string;
  totalRemains: number;
  totalRemainsStr: string;
  storeName?: string;
  discount?: number;
  wish?: string;
  chipsTotal: number;
  totalAmount?: number;
  amountDiscount: number;
  percentDiscount: number;
  loyaltyProgramId: number;
  storeId: number;
  invoiceOrderId?: number;

  orderItems: OrderItemDTO[] = [];
  delivery: any;
  fiscalNumber: string;
  taxRegisterFiscalNumber: string;
  isFiscal: boolean;
  // productsDict?: any = {};
  loyaltyPrograms?: any[] = [];
  private _productsDiscounts: any = {};
  loyaltyProgram?: any;
  taxes: ICheckTax[];
  rroXmlReceipt?: string;
  companyName?: string;
  companyFiscalNumber?: string;
  cashier?: string;
  pointName?: string;
  pointAddress?: string;
  taxRegisterName?: string;
  rndSum?: number;
  noRndSum?: number;
  providedSum?: number;
  remainsSum?: number;
  paymentTypeId?: number;
  paymentType: 'card' | 'cash' | 'bonuses';
  orderChannelId?: number;
  testing?: boolean;
  isPaid?: boolean;
  payments?: any[];
  documentDate: string;
  orderNumber: string;
  invoices: any[] = [];
  offline: boolean;
  xmlCheck?: string;
  appVersion?: number;
  returnOrders?: OrderDTO[];

  constructor(order: OrderDTO | IReceipt = null) {
    if (!order) {
      return;
    }

    this.id = order.id;
    this.uuid = order.uuid;

    // orderDTO
    if ('orderItems' in order) {
      this.employeeId = (<any>order).createdBy;
      this.createdBy = (<any>order).createdBy;
      this.employeeName = order.employeeName;
      this.assigneeId = order.assigneeId;
      this.customerId = order.customerId;
      this.acceptorId = order.acceptorId;

      if (order.customer_id > 0) {
        this.customerId = order.customer_id;
      }

      this.opened = order.opened;

      if (typeof order.deadline == 'string') {
        this.deadline = new Date(order.deadline);
      } else {
        this.deadline = order.deadline;
      }

      this.createdName = order.createdName;
      this.statusId = order.statusId;
      this.orderItems =
        order.orderItems.map((item) => {
          return JSON.parse(JSON.stringify(item));
        }) || [];
      this.totalPaid = order.totalPaid;
      this.totalPaidStr = order.totalPaidStr;
      this.totalRemainsStr = order.totalRemainsStr;
      this.totalRemains = order.totalRemains;
      this.totalReturnedStr = order.totalReturnedStr;
      this.totalStr = order.totalStr;

      this.totalAmount = Number(order.totalAmount);
      this.cashless = order.cashless;
      this.iscashless = order.cashless;
      this.customerName = order.customerName;
      this.loyaltyProgramId = order.loyaltyProgramId;

      if (!isNaN(order.percentDiscount)) {
        this.percentDiscount = +order.percentDiscount;
      }

      if (!isNaN(order.amountDiscount)) {
        this.amountDiscount = +order.amountDiscount;
      }

      this.orderStateId = order.orderStateId;
      this.fiscalNumber = order.fiscalNumber;
      this.taxRegisterFiscalNumber = order.taxRegisterFiscalNumber;
      this.rroXmlReceipt = order.rroXmlReceipt;

      this.orderItems.forEach((item) => {
        item.isService = item.productType == 'products.service';
      });

      this.orderChannelId = order.orderChannelId;
      this.returnOrders = order.returnOrders;
    } else if ('date_open' in order || 'created' in order) {
      // IReceipt

      this.employeeId = (<any>order).employee;
      this.createdBy = (<any>order).employee;
      this.employeeName = order.employee_name;
      this.createdName = order.employee_name;
      this.customer = order.customer;

      if (order.customer_id > 0) {
        this.customerId = order.customer_id;
      }

      this.opened = order.date_open;
      let products: IReceiptProduct[];
      if (order.products && order.products.length) {
        products = order.products;
      }

      if (order.salesReceiptProducts && order.salesReceiptProducts.length) {
        products = order.salesReceiptProducts;
      }

      this.orderItems = (order.products || []).map((p: IReceiptProduct) => {
        const productUuid = p.uuid ? p.uuid : UUID.UUID();
        return <OrderItemDTO>{
          productTypeId: p.productTypeId,
          productId: p.productid,
          quantity: p.quantity,
          price: p.price,
          weighted: p.weighted,
          name: p.name,
          amount: p.amount,
          unitId: p.unitId,
          uuid: productUuid,
          closed: p.closed ? new Date(p.closed) : null,
          work_start: p.work_start ? new Date(p.work_start) : null,
          isService: p.productType == 'products.service',
          workAreaId: p.work_area_id,
          uktZedCode: p.uktzedcode,
          exciseLabels: p.exciseLabels,

          displayDiscount: p.displayDiscount,
          producttaxid: p.producttaxid,
          producttaxname: p.producttaxname,
          producttaxpercent: p.producttaxpercent,
          producttaxletter: p.producttaxletter,

          taxprice: p.taxprice,

          excisetaxid: p.excisetaxid,
          excisetaxname: p.excisetaxname,
          excisetaxpercent: p.excisetaxpercent,
          excisetaxletter: p.excisetaxletter,
        };
      });

      this.totalAmount = Number(order.total);

      if (order.store_name) {
        this.storeName = order.store_name;
      }

      this.percentDiscount = order.percentdiscount;
      this.amountDiscount = order.amountdiscount;
      this.iscashless = order.iscashless;
      this.loyaltyProgramId = (<any>order).loyalty_program_id;
      this.fiscalNumber = order.fiscalnumber;
      this.taxRegisterFiscalNumber = order.taxregisterfiscalnumber;
    }

    // this.payment_status = order.payment_status;
    // this.amountdiscount = order.amountdiscount;
    // this.percentdiscount = order.percentdiscount;
    // this.discount = order.discount;
    // this.amount = order.amount;
    // this.total = order.total;
    // this.returnreceipts = order.returnreceipts || [];
    // this.productsDict = order.productsDict;

    this.created = order.created;
    // this.date_close = order.date_close;

    this.amount = order.amount;
    this.note = order.note;
    this.publicNote = order.publicNote;
    this.discount = Number(order.discount);
    this.wish = order.wish;
    this.isFiscal = order.isFiscal;
    this.taxes = order.taxes;

    if (order.storeName) {
      this.storeName = order.storeName;
    }
    this.loyaltyPrograms = order.loyaltyPrograms;
    this.loyaltyProgram = order.loyaltyProgram;

    this.companyName = order.companyName;
    this.companyFiscalNumber = order.companyFiscalNumber;
    this.cashier = order.cashier;
    this.pointName = order.pointName;
    this.pointAddress = order.pointAddress;
    this.taxRegisterName = order.taxRegisterName;
    this.partnerId = order.partnerId;
    this.delivery = order.delivery;
    this.storeId = order.storeId;
    this.testing = order.testing;
    this.offline = order.offline;
    this.isPaid = order.isPaid;
    this.payments = order.payments;
    this.documentDate = order.documentDate;
    this.orderNumber = order.orderNumber;
    this.invoices = order.invoices;
    this.rndSum = order.rndSum;
    this.noRndSum = order.noRndSum;
    this.providedSum = order.providedSum;
    this.remainsSum = order.remainsSum;
    this.paymentType = order.paymentType;
    this.paymentTypeId = order.paymentTypeId;
    this.appVersion = order.appVersion;
    this.totalReturned = order.totalReturned;
    this.total = order.total;

    if (order.fiscalNumber) {
      this.fiscalNumber = order.fiscalNumber;
    }
    if (order.taxRegisterFiscalNumber) {
      this.taxRegisterFiscalNumber = order.taxRegisterFiscalNumber;
    }

    this._updateProductsDiscounts(this.loyaltyProgram);
    this._calculate();
  }

  getAdvanceTitle(currencyName: string): string {
    let msg = `Авансовий внесок за замовлення #${
      this.orderNumber
    }. СУМА ЗАМОВЛЕННЯ: ${
      this.total_str
    }${currencyName}, СПЛАЧЕНО: ${MoneyUtils.money(
      this.totalPaid
    )}${currencyName}, ДО СПЛАТИ:  ${MoneyUtils.money(
      this.totalAmount - this.totalPaid
    )}${currencyName}`;
    return msg;
  }

  addLoyaltyPrograms(programs: any[]) {
    if (!programs) {
      return;
    }

    this.loyaltyPrograms = programs;
  }

  private _updateProductsDiscounts(loyaltyProgram: any) {
    if (!loyaltyProgram) {
      this._productsDiscounts = {};
      return;
    }

    if (!this._productsDiscounts) {
      this._productsDiscounts = {};
    }

    let _percentageDiscount = 0;
    if (loyaltyProgram.percentagediscount > 0) {
      _percentageDiscount = loyaltyProgram.percentagediscount;
    } else if (loyaltyProgram.percentageDiscount > 0) {
      _percentageDiscount = loyaltyProgram.percentageDiscount;
    }

    if (!loyaltyProgram.products || loyaltyProgram.products.length == 0) {
      this.percentDiscount = _percentageDiscount;
    } else {
      this.percentDiscount = undefined;
      this._productsDiscounts = loyaltyProgram.products.reduce(
        (prevValue: any, currentValue: any) => {
          prevValue[currentValue.productid] = _percentageDiscount;
          return prevValue;
        },
        this._productsDiscounts
      );
    }
  }

  selectLoyaltyProgram(loyaltyProgram: any) {
    this._updateProductsDiscounts(loyaltyProgram);

    if (loyaltyProgram) {
      this.loyaltyProgramId = loyaltyProgram.id;
      this.loyaltyProgram = loyaltyProgram;
    } else {
      this.loyaltyProgramId = null;
      this.loyaltyProgram = null;
    }

    this._calculate();
  }

  addItem(orderItem: OrderItemDTO) {
    if (!this.orderItems) {
      this.orderItems = [orderItem];
    } else {
      const p = this.orderItems.find(
        (v: OrderItemDTO) =>
          v.productId === orderItem.productId && v.uuid == orderItem.uuid
      );

      if (!p) {
        this.orderItems.push(orderItem);

        // this.orderItems.push({
        //   uuid: UUID.UUID(),
        //   productId: orderItem.productId,
        //   //name: orderItem.name,
        //   //amount: orderItem.price,
        //   //price: orderItem.price,
        //   quantity: orderItem.quantity,
        //   //returnedquantity: 0,
        //   //weighted: orderItem.weighted,
        //   //chips_cost: orderItem.chips_cost,
        //   //chips_for_issue: orderItem.chips_for_issue,
        //   // productTypeId: orderItem.productTypeId,
        //   // unitId: orderItem.unitId,
        //   // work_area_id: orderItem.work_area_id,
        // });
      } else {
        if (orderItem.weighted) {
          p.quantity = parseFloat(orderItem.quantity.toString());
        } else {
          p.quantity =
            parseFloat(p.quantity.toString()) +
            parseFloat(orderItem.quantity.toString());
        }
      }

      // this.productsDict[orderItem.productId] = orderItem;
      if (p && p.quantity === 0) {
        // this.removeProduct(p.productId);
      }
    }

    this._calculate();
  }

  updateQuantity(productId: number, quantity: number): void {
    let index;
    const p = this.orderItems.find((v: OrderItemDTO, i: number) => {
      if (v.productId !== productId) return false;

      index = i;
      return true;
    });

    if (!p) {
      return;
    }

    p.quantity = quantity || 0;
    this._calculate();
  }

  updatePrice(productId: number, price: number): void {
    let index;
    const p = this.orderItems.find((v: OrderItemDTO, i: number) => {
      if (v.productId !== productId) return false;

      index = i;
      return true;
    });

    if (!p) {
      return;
    }

    p.price = price || 0;
    this._calculate();
  }

  public deleteProduct(index: number) {
    const p = this.orderItems[index];
    if (!p) {
      return;
    } else {
      this.orderItems.splice(index, 1);
      // this.productsDict[productId] = undefined;
    }

    this._calculate();
  }

  public setDiscount(percentDiscount: number, amountDiscount: number) {
    if (percentDiscount >= 0) {
      this.percentDiscount = +percentDiscount;
    }

    if (amountDiscount >= 0) {
      this.amountDiscount = +amountDiscount;
    }

    this._calculate();
  }

  // public deleteProduct(productId: number) {
  //   let index;
  //   const p = this.orderItems.find((v: OrderItemDTO, i: number) => {
  //     if (v.productId !== productId) return false;

  //     index = i;
  //     return true;
  //   });

  //   if (!p) {
  //     return;
  //   } else {
  //     this.orderItems.splice(index, 1);
  //     this.productsDict[productId] = undefined;
  //   }

  //   this._culculate();
  // }

  // public more(productId: number) {
  //   let index;
  //   const p = this.orderItems.find((v: OrderItemDTO, i: number) => {
  //     if (v.productId !== productId) return false;

  //     index = i;
  //     return true;
  //   });

  //   if (!p) return;
  //   else p.quantity += 1;

  //   this.culculate();
  // }

  private _calculate() {
    let _chipsTotal = 0;
    let _amountDiscount = 0;
    this.amount = 0;
    this.discount = 0;
    this.taxes = [];
    this.rndSum = null;
    this.noRndSum = null;

    // amount
    for (let product of this.orderItems) {
      product.amount = product.price * product.quantity;
      product.totalAmount = product.amount;
      product.amount_str = product.amount.toFixed(2);
      this.amount += parseFloat(product.amount.toFixed(2));

      // chips
      const chipsCost =
        (product.chips_cost - (product.chips_for_issue || 0)) *
        product.quantity;

      if (chipsCost) {
        _chipsTotal += parseFloat(chipsCost.toFixed(2));
      }

      // discount
      if (this.percentDiscount == 0) {
        product.percentDiscount = 0;
        product.amountDiscount = 0;
      }

      if (this.percentDiscount > 0) {
        product.percentDiscount = this.percentDiscount;
        product.displayDiscount = false;
      } else {
        product.percentDiscount = this._productsDiscounts[product.productId];
        if (product.percentDiscount > 0) {
          product.displayDiscount = true;
        }
      }

      if (product.percentDiscount > 0) {
        product.discountType = 1;
        product.amountDiscount =
          (product.amount * product.percentDiscount) / 100;
        _amountDiscount += product.amountDiscount;
        product.totalAmount = product.amount - product.amountDiscount;
        product.total_str = (product.amount - product.amountDiscount).toFixed(
          2
        );
      } else {
        product.total_str = product.amount.toFixed(2);
        product.amountDiscount = 0;
      }

      // taxes
      this._addProductTax(product);
    }

    this.chipsTotal = _chipsTotal;
    this.discount = 0;
    if (this.percentDiscount > 0) {
      this.discount = (this.amount * this.percentDiscount) / 100;
    } else if (_amountDiscount > 0) {
      this.discount = _amountDiscount;
    } else if (this.amountDiscount > 0) {
      this.discount = this.amountDiscount;
    }

    this.totalAmount = 0;
    if (this.amount >= this.discount) {
      this.totalAmount = this.amount - this.discount;
    } else {
      this.totalAmount = this.amount;
    }

    if (this.paymentType == 'cash') {
      this._roundTotal();
    } else {
      this.providedSum = null;
      this.remainsSum = null;
    }

    this.total_str = this.totalAmount.toFixed(2);
    this._roundTaxes();
  }

  private _roundTaxes() {
    for (let tax of this.taxes) {
      tax.TURNOVER = MoneyUtils.round(tax.TURNOVER);
      tax.SOURCESUM = MoneyUtils.round(tax.SOURCESUM);
      tax.SUM = MoneyUtils.round(tax.SUM);
    }
  }

  private _roundTotal() {
    const amountInCents = this.totalAmount * 100;
    const centsRoundTo = 10;
    const restCents = amountInCents % centsRoundTo;

    if (restCents == 0) {
      return;
    }

    this.noRndSum = this.totalAmount;
    if (restCents > 4) {
      // round top
      const _rndSum = (centsRoundTo - restCents) / 100;
      this.rndSum = _rndSum * -1;
      this.totalAmount += _rndSum;
    } else {
      // round down
      this.rndSum = restCents / 100;
      this.totalAmount -= this.rndSum;
    }
  }

  private _addProductTax(product: OrderItemDTO) {
    let _exciseAmount = 0;
    const _turnover = product.totalAmount;
    // excise
    (<any>product).letters = '';
    if (product.excisetaxid > 0 && product.excisetaxpercent > 0) {
      _exciseAmount = MoneyUtils.round(
        (_turnover / (100 + product.excisetaxpercent)) *
          product.excisetaxpercent
      );
      this._addTax(
        product.excisetaxletter,
        1,
        product.excisetaxpercent,
        product.excisetaxname,
        _exciseAmount,
        _turnover,
        _turnover
      );

      (<any>product).exciseTaxAmount = _exciseAmount;
      if ((<any>product).letters) {
        (<any>product).letters += product.excisetaxletter;
      } else {
        (<any>product).letters = product.excisetaxletter;
      }
    }

    if (product.producttaxid > 0 && product.producttaxpercent > 0) {
      const _sourceSum = MoneyUtils.round(_turnover - _exciseAmount);
      let _taxAmount = MoneyUtils.round(
        (_sourceSum / (product.producttaxpercent + 100)) *
          product.producttaxpercent
      );

      this._addTax(
        product.producttaxletter,
        0,
        product.producttaxpercent,
        product.producttaxname,
        _taxAmount,
        _sourceSum,
        _turnover
      );

      (<any>product).productTaxAmount = _taxAmount;

      if ((<any>product).letters) {
        (<any>product).letters += product.producttaxletter;
      } else {
        (<any>product).letters = product.producttaxletter;
      }
    }
  }

  private _addTax(
    letter: string,
    taxType: number,
    percent: number,
    name: string,
    sum: number,
    sourcesum: number,
    turnover: number
  ) {
    if (this.taxes == null) {
      this.taxes = [];
    }

    let _tax = this.taxes.find(
      (t) => t.LETTER.toLowerCase() == letter.toLowerCase()
    );

    if (_tax) {
      _tax.SUM += sum;

      if (turnover > 0) {
        _tax.TURNOVER += turnover;
      }

      if (sourcesum > 0) {
        _tax.SOURCESUM += sourcesum;
      }
    } else {
      _tax = {
        TYPE: taxType,
        NAME: name,
        LETTER: letter,
        PRC: percent,
        TURNOVER: turnover,
        SOURCESUM: sourcesum,
        SUM: sum,
      };

      this.taxes.push(_tax);
    }
  }
}

const TABLE_KEY = '__orders';

@Injectable({ providedIn: 'root' })
export class OrdersRepository {
  constructor(private _repository: GenericRepository) {}

  getOrders(): Promise<OrderDTO[]> {
    return this._repository.getItems<OrderDTO>(TABLE_KEY);
  }

  getOrder(orderUuid: string): Promise<OrderDTO> {
    return this._repository.getItem<OrderDTO>(TABLE_KEY, orderUuid);
  }

  updateOrder(order: OrderDTO): Promise<void> {
    return Promise.resolve();
    return this._repository.getItem(TABLE_KEY, order.uuid).then((item) => {
      if (item) {
        return this._repository.updateItem(TABLE_KEY, order, 'uuid');
      } else {
        return this._repository.addItem(TABLE_KEY, order);
      }
    });
  }

  deleteOrders(): Promise<void> {
    return this._repository.deleteTable(TABLE_KEY);
  }
}
