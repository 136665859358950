import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { EmployeeItem } from '../private/terminal/repositories/employee.repository';

export class TerminalSession {
  id: number;
  uuid: string;
  employee_id: string;
  start: Date;
  end: Date;
  changed?: boolean;
  store_id: number;
  employee: EmployeeItem;

  constructor(employeeId: string, storeId: number) {
    this.id = 0;
    this.store_id = storeId;
    this.uuid = UUID.UUID();
    this.employee_id = employeeId;
    this.start = new Date();
    this.end = null;
    this.changed = true;
  }
}

@Injectable({ providedIn: 'root' })
export class TerminalAuthService {
  private _terminalSession: TerminalSession;
  get currentSession(): TerminalSession {
    return this._terminalSession;
  }

  constructor() {}

  getTerminalSession(): TerminalSession {
    if (!this._terminalSession) {
      return null;
    }

    return this._terminalSession;
  }

  startTerminalSession(session: TerminalSession) {
    this._terminalSession = session;
  }

  endTerminalSession() {
    this._terminalSession = null;
  }

  hasPermissions(permissions: string[]): boolean {
    if (!this._terminalSession || !this._terminalSession.employee) {
      return false;
    }

    const employeePermissions =
      this._terminalSession.employee.permissions || [];

    const permissionsFound = permissions.filter((e) =>
      employeePermissions.includes(e)
    );
    return permissionsFound.length == permissions.length;
  }
}
