import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, from, BehaviorSubject } from 'rxjs';

import { map, mergeMap, switchMap } from 'rxjs/operators';
import { LanguagesService } from 'src/app/services/languages.service';
import { Platform } from '@ionic/angular';
import { SettingsService } from 'src/app/services/settings.service';
import { StorageService } from 'src/app/services/storage.service';
import { OnlineOrderContract } from 'src/app/models/online-order';

const POS_TERMINAL_SETTINGS = 'POS_TERMINAL_SETTINGS';

export interface ITerminalData {
  customers: any[];
  discounts: any[];
  employees: any[];
  locales: any;
  store: any;
  company: any;
  suppliers: any[];
  supply_products: any[];
  terminal: any;
  cashbox_shift: any;
  receipt_wishes: string[];
  token?: string;
  printer_settings: any;
  partners: any[];
  loyaltyprograms: any[];
  payment_methods: any[];
  halls: any[];
}

export class TerminalData {
  customers: any[];
  discounts: any[];
  employees: any[];
  locales: any;
  store: any;
  suppliers: any[];
  supply_products: any[];
  terminal: any;
  cashbox_shift: any;
  receipt_wishes: string[];
  printer_settings: any;

  constructor(conf: ITerminalData = null) {
    this.customers = conf?.customers || [];
    this.discounts = conf?.discounts || [];
    this.employees = conf?.employees || [];
    this.locales = conf?.locales || {};
    this.store = conf?.store || {};
    this.suppliers = conf?.suppliers || [];
    this.supply_products = conf?.supply_products || [];
    this.terminal = conf?.terminal || [];
    this.cashbox_shift = conf?.cashbox_shift || {};
    this.receipt_wishes = conf?.receipt_wishes || [];
  }
}

@Injectable({ providedIn: 'root' })
export class TerminalDataService {
  private _settingsSub = new BehaviorSubject<ITerminalData>(null);
  private _settings: Observable<ITerminalData>;
  private get terminalBaseUrl() {
    return `${this._settingsService.api_endpoint}/pos/terminal`;
  }

  constructor(
    private _http: HttpClient,
    private _settingsService: SettingsService,
    private _storage: StorageService,
    private _plt: Platform,
    private languagesService: LanguagesService
  ) {
    this._loadStoredData();
    this._settings.subscribe();
  }

  private _onTerminalData = new BehaviorSubject<ITerminalData>(null);

  getTerminalData(): Observable<ITerminalData> {
    return new Observable((observer) => {
      from(this._storage.get(POS_TERMINAL_SETTINGS)).subscribe((res) => {
        if (res) {
          observer.next(res);
        } else {
          this._onTerminalData.subscribe((event) => observer.next(event));
        }
      });
    });
  }

  terminalSettings(): ITerminalData {
    return this._settingsSub.value;
  }

  getTerminalSettings = (): Observable<ITerminalData> =>
    this._http.get<ITerminalData>(`${this.terminalBaseUrl}/settings`).pipe(
      mergeMap((data) => {
        this._settingsSub.next(data);
        this.languagesService.setLanguage(data.terminal.lang);
        return this._saveSettings(data).pipe(map(() => data));
      })
    );

  getProductPoints = (companyId: string): Observable<any[]> =>
    this._http.get<any[]>(`${this._settingsService.store_api}/Product/Coins`, {
      params: new HttpParams().set('responseFormater', 'none'),
      headers: new HttpHeaders().set('companyId', companyId),
    });

  getOnlineOrders = (
    companyId: string,
    storeId: number
  ): Observable<OnlineOrderContract[]> =>
    this._http.get<any[]>(
      `${this._settingsService.store_api}/Order/${companyId}/${storeId}`,
      {
        params: new HttpParams().set('responseFormater', 'none'),
        headers: new HttpHeaders().set('companyId', companyId),
      }
    );

  private _loadStoredData() {
    const platformObs = from(this._plt.ready());
    this._settings = platformObs.pipe(
      switchMap(() => {
        return from(this._storage.get(POS_TERMINAL_SETTINGS));
      }),
      map((settings) => {
        if (settings) {
          this.languagesService.setLanguage(settings.terminal.lang);
          this._settingsSub.next(settings);
          return settings;
        } else {
          return null;
        }
      })
    );
  }

  clearSettings() {
    this._storage.remove(POS_TERMINAL_SETTINGS);
    this._settingsSub.next(null);
  }

  private _saveSettings(settings: ITerminalData) {
    return from(this._storage.set(POS_TERMINAL_SETTINGS, settings)).pipe(
      map(() => this._settingsSub.next(settings))
    );
  }
}
