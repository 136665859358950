<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()" color="dark">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Довідка</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div *ngIf="article && !loading">
    <h3>{{ article.title }}</h3>
    <div [innerHTML]="article.bodyhtml">{{ article.bodyHtml }}</div>
  </div>

  <div *ngIf="errorMessage" class="ion-padding ion-text-center">
    <h2>{{ errorMessage }}</h2>
  </div>

  <ion-list *ngIf="loading">
    <ion-list-header>
      <ion-skeleton-text
        [animated]="true"
        style="width: 200px"
      ></ion-skeleton-text>
    </ion-list-header>
    <ion-item>
      <ion-thumbnail slot="start">
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </ion-thumbnail>
      <ion-label>
        <h3>
          <ion-skeleton-text
            [animated]="true"
            style="width: 80%"
          ></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text
            [animated]="true"
            style="width: 60%"
          ></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text
            [animated]="true"
            style="width: 30%"
          ></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
