<div class="info-tab" style="position: relative" *ngIf="type == 'card'">
  <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>
  <ion-icon
    *ngIf="!taxRegisterConfigured || !isFiscalCheck"
    style="position: absolute; right: 10px; top: 10px; font-size: 40px"
    name="warning-outline"
    color="danger"
  ></ion-icon>
  <ion-icon
    *ngIf="taxRegisterConfigured && isFiscalCheck"
    style="position: absolute; right: 10px; top: 10px; font-size: 40px"
    name="checkmark-circle-outline"
    color="success"
  ></ion-icon>
  <div>
    <p *ngIf="taxRegisterConfigured && isFiscalCheck">
      <strong>Фіскальна операція</strong>
    </p>
    <p *ngIf="taxRegisterConfigured && !isFiscalCheck">
      <strong style="font-size: 18px">Не фіскальна операція</strong>
    </p>

    <p *ngIf="notConfigured">
      <strong style="font-size: 18px">Програмний реєстратор вимкнено</strong>
    </p>
  </div>
  <ion-grid class="p-0">
    <ion-row>
      <ion-col>
        <div
          *ngIf="taxRegisterConfigured && isFiscalCheck; else warningTemlate"
        >
          <div style="line-height: 1" (click)="toggleFiscalOperation()">
            <table>
              <tr>
                <td colspan="2">
                  <small>ФОП {{ orgName }}</small>
                </td>
              </tr>
              <tr>
                <td><small>Каса:</small></td>
                <td>
                  <small>&nbsp;{{ registerLabel }}</small>
                </td>
              </tr>
              <tr>
                <td><small>Адреса:</small></td>
                <td>
                  <small>&nbsp;{{ registerAddress }}</small>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </ion-col>
      <ion-col size="12" size-md="auto" class="ion-text-end">
        <div
          class="button-wrapper"
          *ngIf="taxRegisterConfigured"
          (click)="toggleFiscalOperation()"
        >
          <div class="button-cover"></div>
          <ion-button
            fill="outline"
            [disabled]="fiscalOperationInProgress"
            color="{{ isFiscalCheck ? 'primary' : 'medium' }}"
          >
            <ion-spinner
              name="crescent"
              class="mr-3"
              *ngIf="fiscalOperationInProgress"
            ></ion-spinner>
            <span class="mr-3" *ngIf="isFiscalCheck">Увімкнено</span>
            <span class="mr-3" *ngIf="!isFiscalCheck">Вимкнено</span>
            <ion-toggle
              [enableOnOffLabels]="true"
              [checked]="isFiscalCheck"
            ></ion-toggle>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ng-template #warningTemlate>
    <div style="line-height: 1">
      <span class="text-danger">
        <small>
          Відповідно до Закону України №1017-ІХ від 01.12.2020 року застосування
          РРО чи ПРРО при розрахунках з покупцями стало обов’язковим.
        </small>
      </span>
    </div>
    <div style="line-height: 1">
      <span class="text-danger">
        <small>
          Провайдер програмного забезпечення не несе відповідальності за
          наслідки у разі відключення фіскалізації.
        </small>
      </span>
    </div>
  </ng-template>
</div>

<div *ngIf="taxRegisterConfigured && type == 'button'" class="button-wrapper">
  <div class="button-cover" (click)="toggleFiscalOperation()"></div>
  <ion-button
    fill="outline"
    [disabled]="fiscalOperationInProgress"
    color="{{ isFiscalCheck ? 'primary' : 'medium' }}"
  >
    <ion-spinner
      name="crescent"
      class="mr-3"
      *ngIf="fiscalOperationInProgress"
    ></ion-spinner>
    <span class="mr-3" *ngIf="isFiscalCheck">Увімкнено</span>
    <span class="mr-3" *ngIf="!isFiscalCheck">Вимкнено</span>
    <ion-toggle
      [enableOnOffLabels]="true"
      [checked]="isFiscalCheck"
    ></ion-toggle>
  </ion-button>
</div>
