export class ReceiptFormatterService {
  config: any = {
    currency: '$',
    width: 32,
    ruler: '=',
  };

  formatters: any = {};
  private EOL = '\r\n';

  constructor() {
    var formatters = new Formatters(this.config);
    this.addFormatters({
      empty: formatters.empty,
      ruler: formatters.ruler,
      text: formatters.text,
      properties: formatters.properties,
      table: formatters.table,
      table2: formatters.table2,
    });
  }

  create(chunks): string {
    return chunks
      .map((chunk) => {
        if (chunk.hasOwnProperty('type')) {
          return this.formatters[chunk.type](chunk, this.config);
        }

        return '';
      })
      .join(this.EOL);
  }

  addFormatter(name, handler) {
    if (!this.formatters.hasOwnProperty(name)) {
      this.formatters[name] = handler.bind(this);
    } else {
      throw new Error('Formatter named "' + name + '" already exists.');
    }
  }

  addFormatters(formatters) {
    for (let name in formatters) {
      this.addFormatter(name, formatters[name]);
    }
  }
}

class Formatters {
  formatters: any = {};
  config: any;
  private EOL = '\r\n';

  constructor(config: any) {
    this.config = config;
  }

  empty(chunk) {
    return FormatterUtils.pad(
      '',
      ' ',
      this.config.width,
      FormatterUtils.PAD_RIGHT
    );
  }

  ruler(chunk) {
    return FormatterUtils.pad(
      '',
      this.config.ruler,
      this.config.width,
      FormatterUtils.PAD_RIGHT
    );
  }

  text(chunk) {
    if (Array.isArray(chunk.value)) {
      // Expand array to multiple text calls with same formatting.
      return chunk.value
        .map((value) => {
          return this.formatters.text({
            type: chunk.type,
            value: value,
            align: chunk.align,
            padding: chunk.padding,
          });
        })
        .join(this.EOL);
    }

    let chars =
      this.config.width -
      (chunk.hasOwnProperty('padding')
        ? chunk.hasOwnProperty('align') && chunk.align === 'center'
          ? chunk.padding * 2
          : chunk.padding
        : 0);
    let words = chunk.value?.toString().split(/\s/g);
    let lines = [];
    let line = '';

    words.reverse();

    while (words.length > 0) {
      let word = words.pop();

      if (line.length + word.length > chars) {
        lines.push(line);
        line = '';
      }

      line += word + ' ';
    }

    lines.push(line);

    let alignTypes = {
      left: FormatterUtils.PAD_RIGHT,
      right: FormatterUtils.PAD_LEFT,
      center: FormatterUtils.PAD_BOTH,
    };

    if (lines) {
      return lines
        .map((line) => {
          line = line.replace(/\s+$|^\s+/, '');

          if (chunk.hasOwnProperty('align')) {
            if (alignTypes.hasOwnProperty(chunk.align)) {
              return FormatterUtils.pad(
                line,
                ' ',
                this.config.width,
                alignTypes[chunk.align]
              );
            }
          }

          return FormatterUtils.pad(
            line,
            ' ',
            this.config.width,
            FormatterUtils.PAD_RIGHT
          );
        })
        .join(this.EOL);
    }

    return '';
  }

  properties(chunk) {
    let widest = 0;

    for (let line of chunk.lines) {
      widest = Math.max(line.name.length, widest);
    }

    return chunk.lines
      .map(
        (line) =>
          FormatterUtils.pad(
            line.name + ':',
            ' ',
            this.config.width - line.value.length
          ) + line.value
      )
      .join(this.EOL);
  }
  table2(chunk) {
    let lines = [this.formatters.ruler('')];

    for (let line of chunk.lines) {
      if (line.item.length > this.config.width - 12) {
        lines.push(
          [
            FormatterUtils.pad('', ' ', 0, FormatterUtils.PAD_RIGHT),
            line.item,
          ].join('')
        );
        lines.push(
          [
            FormatterUtils.pad(
              ' ',
              ' ',
              this.config.width - 12,
              FormatterUtils.PAD_RIGHT
            ),
            FormatterUtils.pad(line.qty, ' ', 3, FormatterUtils.PAD_RIGHT),
          ].join('')
        );
      } else {
        lines.push(
          [
            FormatterUtils.pad(
              line.item.substr(0, this.config.width - 12),
              ' ',
              this.config.width - 12,
              FormatterUtils.PAD_RIGHT
            ),
            FormatterUtils.pad(line.qty, ' ', 3, FormatterUtils.PAD_LEFT),
          ].join('')
        );
      }

      if (line.hasOwnProperty('discount')) {
        let discountText = line.discount.hasOwnProperty('message')
          ? '  (' + line.discount.message + ')'
          : '  (Item Disc. -' +
            (line.discount.type === 'percentage'
              ? line.discount.value * 100 + '%'
              : this.config.currency +
                FormatterUtils.money(line.discount.value)) +
            ')';

        lines.push(
          [
            FormatterUtils.pad('', ' ', 6, FormatterUtils.PAD_RIGHT),
            discountText,
          ].join('')
        );
      }
    }

    lines.push(this.formatters.ruler(''));

    return lines.join(this.EOL);
  }
  table(chunk) {
    let lines = [this.formatters.ruler('')];

    // lines.push(
    //   [
    //     FormatterUtils.pad('Qty', ' ', 6, FormatterUtils.PAD_RIGHT),
    //     FormatterUtils.pad(
    //       'Product',
    //       ' ',
    //       this.config.width - 18,
    //       FormatterUtils.PAD_RIGHT
    //     ),
    //     FormatterUtils.pad('Total', ' ', 12, FormatterUtils.PAD_LEFT),
    //   ].join('')
    // );

    // lines.push(this.formatters.ruler(''));

    for (let line of chunk.lines) {
      // let total = line.qty * line.cost;

      // if (line.hasOwnProperty('discount')) {
      //   if (line.discount.type === 'percentage')
      //     total *= 1 - line.discount.value;
      //   else total -= line.discount.value;
      // }

      if (line.item.length > this.config.width - 18) {
        lines.push(
          [
            FormatterUtils.pad('', ' ', 0, FormatterUtils.PAD_RIGHT),
            line.item,
          ].join('')
        );

        const _line = [];

        _line.push(
          FormatterUtils.pad(
            ' ',
            ' ',
            this.config.width - 18,
            FormatterUtils.PAD_RIGHT
          )
        );
        _line.push(
          FormatterUtils.pad(line.qty, ' ', 3, FormatterUtils.PAD_LEFT)
        );

        if (line.cost != null) {
          _line.push(
            FormatterUtils.pad(
              FormatterUtils.money(line.cost),
              ' ',
              7,
              FormatterUtils.PAD_LEFT
            )
          );
        }

        if (line.total != null) {
          _line.push(
            FormatterUtils.pad(
              this.config.currency + FormatterUtils.money(line.total),
              ' ',
              7,
              FormatterUtils.PAD_LEFT
            )
          );
        }

        if (line.unit) {
          _line.push(
            FormatterUtils.pad(line.unit, ' ', 3, FormatterUtils.PAD_LEFT)
          );
        }

        lines.push(_line.join(''));
      } else {
        const _line = [];
        _line.push(
          FormatterUtils.pad(
            line.item.substr(0, this.config.width - 18),
            ' ',
            this.config.width - 18,
            FormatterUtils.PAD_RIGHT
          )
        );
        _line.push(
          FormatterUtils.pad(line.qty, ' ', 3, FormatterUtils.PAD_LEFT)
        );

        if (line.cost != null) {
          _line.push(
            FormatterUtils.pad(
              FormatterUtils.money(line.cost),
              ' ',
              7,
              FormatterUtils.PAD_LEFT
            )
          );
        }

        if (line.total != null) {
          _line.push(
            FormatterUtils.pad(
              this.config.currency + FormatterUtils.money(line.total),
              ' ',
              7,
              FormatterUtils.PAD_LEFT
            )
          );
        }

        if (line.unit) {
          _line.push(
            FormatterUtils.pad(line.unit, ' ', 3, FormatterUtils.PAD_LEFT)
          );
        }

        lines.push(_line.join(''));
      }

      if (line.hasOwnProperty('discount')) {
        let discountText = line.discount.hasOwnProperty('message')
          ? '  (' + line.discount.message + ')'
          : '  (Item Disc. -' +
            (line.discount.type === 'percentage'
              ? line.discount.value * 100 + '%'
              : this.config.currency +
                FormatterUtils.money(line.discount.value)) +
            ')';

        lines.push(
          [
            FormatterUtils.pad('', ' ', 6, FormatterUtils.PAD_RIGHT),
            discountText,
          ].join('')
        );
      }

      if (line.hasOwnProperty('code') && line.code) {
        lines.push(
          [
            FormatterUtils.pad('', ' ', 0, FormatterUtils.PAD_RIGHT),
            line.code,
          ].join('')
        );
      }

      if (line.hasOwnProperty('labels')) {
        if (line.labels && line.labels.length > 0) {
          for (let label of line.labels) {
            lines.push(
              [
                FormatterUtils.pad('', ' ', 0, FormatterUtils.PAD_RIGHT),
                label,
              ].join('')
            );
          }
        }
      }
    }

    lines.push(this.formatters.ruler(''));

    return lines.join(this.EOL);
  }
}

class FormatterUtils {
  static PAD_LEFT = 'left';
  static PAD_RIGHT = 'right';
  static PAD_BOTH = 'both';

  static pad(value: string, char: string, length: number, side = undefined) {
    if (value == null || value == undefined) {
      value = '';
    }

    side = typeof side === 'undefined' ? 'right' : side;

    let padding = '';
    let required = Math.floor(length) - value.toString().length;

    while (required > 0) {
      required -= 1;

      if (side === this.PAD_LEFT) value = char + value;
      if (side === this.PAD_RIGHT) value = value + char;

      if (side === this.PAD_BOTH) {
        if (required % 2 === 0) value = char + value;
        else value = value + char;
      }
    }

    return value;
  }

  static money(cents) {
    if (!cents || isNaN(cents)) {
      return '';
    }

    return cents.toFixed(2).replace('.', ',');
  }
}
