import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { EmployeeItem } from './repositories/employee.repository';
import { TerminalClientService } from './services/terminal-client.service';
import { TerminalDataService } from './services/terminal-data.service';

@Component({
  templateUrl: './terminal-session.component.html',
})
export class TerminalSessionComponent {
  start: Date;
  isCachboxEnabled: false;
  activeCashboxShift = null;
  activeEmloyeeSession = null;
  cashInBoxControl = new FormControl(null, Validators.required);
  errorMessage = '';
  employee: EmployeeItem;

  constructor(
    private _terminalClientService: TerminalClientService,
    private _terminalDataService: TerminalDataService,
    private _router: Router
  ) {
    this.start = new Date();
    this.isCachboxEnabled =
      this._terminalDataService.terminalSettings().terminal.use_cashbox_shifts;
    this.activeCashboxShift =
      this._terminalClientService.currentCashboxSession.value;
    this.employee = this._terminalClientService.getLoggedInEmployee();
    this._terminalClientService.currentEmployeeSession.subscribe(
      (res) => (this.activeEmloyeeSession = res)
    );
  }

  startSession() {
    this.errorMessage = '';
    const cashInBox = parseInt(this.cashInBoxControl.value);

    if (this.activeCashboxShift && this.activeEmloyeeSession) {
      this._router.navigate(['/terminal'], { replaceUrl: true });
      return;
    }

    if (!this.isCachboxEnabled && this.activeEmloyeeSession) {
      this._router.navigate(['/terminal'], { replaceUrl: true });
      return;
    }

    if (this.isCachboxEnabled && !this.activeCashboxShift && isNaN(cashInBox)) {
      this.errorMessage =
        'Необхідно вказати залишок готівки в касі на початок зміни';
      return;
    }

    this._startEmployeeSessionIfNotexist()
      .pipe(
        mergeMap((res) => {
          if (!this.isCachboxEnabled || this.activeCashboxShift) {
            return of(true);
          }

          return this._terminalClientService
            .startCashboxSession(cashInBox)
            .pipe(
              map((res) => {
                if (res) {
                  return true;
                } else {
                  return false;
                }
              })
            );
        })
      )
      .subscribe((res) => {
        this._router.navigate(['/terminal'], { replaceUrl: true });
      });
  }

  private _startEmployeeSessionIfNotexist(): Observable<boolean> {
    if (this.activeEmloyeeSession) {
      return of(true);
    } else {
      return from(
        this._terminalClientService.startEmployeeSession().then((session) => {
          if (session) {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  }

  cancel() {
    this._router.navigate(['/lock-screen'], { replaceUrl: true });
  }
}
