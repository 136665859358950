import { ShiftTotals } from './tax.model';

export interface ITaxOrder {
  documentType?: 0 | 100 | 101 | 102 | 103 | null;
  documentSubType?: number;
  // documentTypeName?:
  //   | 'check'
  //   | 'startShift'
  //   | 'stopShift'
  //   | 'report'
  //   | 'startOfflineShift'
  //   | 'stopOfflineShift';

  uid?: string;
  orderNumber: number;
  tin: string;
  ipn?: string;
  organizationName: string;
  pointName: string;
  pointAddress: string;
  cashDeskNum: number;
  cashRegisterNum: string;
  cashRegisterName: string;
  cashier: string;
  ORDERDATE?: string;
  ORDERTIME?: string;
  offline?: boolean;
  ORDERTAXNUM?: string;
  ORDERSTORNUM?: string;
  offlineLocalNum?: number;
  PREVDOCHASH?: string;
  offlineSessionId?: string;
  offlineSeed?: string;
  offlineNextLocalNum?: string;

  // operation?: 'check' | 'startShift' | 'stopShift' | 'report';
  ticketXml?: string;
  signedTicket?: string;
  // status?: 'new' | 'inProgress' | 'completed' | 'error';
  xmlDocument?: string;
  signedXmlDocument?: string;
  ticket?: TaxTicket;
  testing?: boolean;

  CHECKTOTAL?: number;
  PROVIDED?: number;
  REMAINS?: number;
  CHECKPAY?: ICheckPay[];
  CHECKTAX?: ICheckTax[];
  CHECKBODY?: ICheckProduct[];
  DISCOUNTSUM?: number;
  RNDSUM?: number;
  NORNDSUM?: number;

  ORDERRETNUM?: string;
  REVOKELASTONLINEDOC?: boolean;
  shiftTotals?: ShiftTotals;
  documentDate?: string;

  toString?: (width: number, currencyName?: string) => string;
  toHtml?: () => string;
}

export class TaxTicket {
  UID?: string;
  ORDERDATE?: string;
  ORDERTIME?: string;
  ORDERNUM: number;
  ORDERTAXNUM: string;
  OFFLINESESSIONID?: string;
  OFFLINESEED?: string;
  ERRORCODE?: number;
  VER?: number;

  taxRegisterFiscalNum: string;
}

// export interface ICheck extends ITaxOrder {
//   CHECKTOTAL?: number;
//   PROVIDED?: number;
//   REMAINS?: number;
//   CHECKPAY?: ICheckPay[];
//   CHECKTAX?: ICheckTax[];
//   CHECKBODY?: ICheckProduct[];
//   DISCOUNTSUM?: number;

//   ORDERRETNUM?: string;
//   REVOKELASTONLINEDOC?: boolean;
// }

export interface ICheckPay {
  // Код форми оплати (числовий):
  // 0–Готівка, 1–Банківська картка, 2-Попередня оплата, 3-Кредит, ...
  PAYFORMCD: number;
  // Найменування форми оплати (128 символів)
  PAYFORMNM: string;
  // Загальна сума (15.2 цифри)
  SUM: number;
  // Сума внесених коштів (15.2 цифри)
  PROVIDED?: number;
  // Решта (не зазначається, якщо решта відсутня) (15.2 цифри)
  REMAINS?: number;
  PAYSYS?: ICheckPaySys[];
}

export interface ICheckPaySys {
  NAME: string;
  ACQUIRETRANSID: string;
}

export interface ICheckTax {
  // Код виду податку/збору (числовий):
  // 0-ПДВ,1-Акциз,2-ПФ...
  TYPE: number;
  // Найменування виду податку/збору (64 символи)
  NAME: string;
  // Літерне позначення виду і ставки податку/збору (А,Б,В,Г,...) (1 символ)
  LETTER: string;
  // Відсоток податку/збору (15.2 цифри)
  PRC: number;
  // Ознака податку/збору, не включеного у вартість
  SIGN?: boolean;
  // Сума для розрахування податку/збору (15.2 цифри)
  TURNOVER: number;
  SOURCESUM: number;
  // Сума податку/збору (15.2 цифри)
  SUM: number;
}

export interface ICheckProduct {
  CODE: number;
  UKTZED?: string;
  NAME: string;
  UNITCD?: number;
  UNITNM?: string;
  AMOUNT: number;
  PRICE: number;
  LETTERS?: string;
  COST: number;
  DISCOUNTTYPE?: number;
  DISCOUNTPERCENT?: number;
  DISCOUNTSUM?: number;
  EXCISELABELS: string[];
  COMMENT?: string;
}

// export interface IZReport extends ITaxOrder {
//   shiftTotals: ShiftTotals;
// }

// export class ZReportModel implements IZReport {
//   uid: string;
//   documentTypeName: 'report';
//   orderNumber: number;
//   tin: string;
//   organizationName: string;
//   pointName: string;
//   pointAddress: string;
//   cashDeskNum: number;
//   cashRegisterNum: string;
//   cashRegisterName: string;
//   cashier: string;
//   ORDERDATE?: string;
//   ORDERTIME?: string;
//   offline?: boolean;
//   ORDERTAXNUM?: string;
//   offlineSessionId?: string;
//   offlineNextLocalNum?: string;
//   offlineSeed: string;

//   shiftTotals: ShiftTotals;

//   constructor(report: IZReport) {
//     if (!report) {
//       return;
//     }

//     this.uid = report.uid ? report.uid : UUID.UUID();
//     this.documentTypeName = 'report';
//     this.orderNumber = report.orderNumber;
//     this.tin = report.tin;
//     this.organizationName = report.organizationName;
//     this.pointName = report.pointName;
//     this.pointAddress = report.pointAddress;
//     this.cashDeskNum = report.cashDeskNum;
//     this.cashRegisterNum = report.cashRegisterNum;
//     this.cashRegisterName = report.cashRegisterName;
//     this.cashier = report.cashier;

//     const _date = new Date();
//     this.ORDERDATE = this._getOrderDate(_date);
//     this.ORDERTIME = this._getOrderTime(_date);
//     this.offline = report.offline;
//     this.offlineSeed = report.offlineSeed;
//     this.offlineSessionId = report.offlineSessionId;
//     this.offlineNextLocalNum = report.offlineNextLocalNum;

//     this.shiftTotals = report.shiftTotals;

//     if (this.offline) {
//       console.log('_checkSum');

//       const _checkSumString = this._getCheckSumString();
//       console.log(_checkSumString);

//       let _checkSum = CRC32.str(_checkSumString);
//       console.log('crc32');
//       console.log(_checkSum);

//       if (_checkSum == 0) {
//         _checkSum = 1;
//       } else {
//         _checkSum = Number(`0000${_checkSum}`.slice(-4));
//       }

//       console.log(_checkSum);

//       this.ORDERTAXNUM = `${this.offlineSessionId}.${this.offlineNextLocalNum}.${_checkSum}`;
//     }
//   }

//   private _getCheckSumString(): string {
//     let _checkControlString = `${this.offlineSeed},${this.ORDERDATE},${this.ORDERTIME},${this.orderNumber},${this.cashRegisterNum},${this.cashDeskNum}`;
//     return _checkControlString;
//   }

//   private _getOrderDate(date: Date) {
//     let _date = `0${date.getDate()}`;
//     _date = _date.substring(_date.length - 2);

//     let _month = `0${date.getMonth() + 1}`;
//     _month = _month.substring(_month.length - 2);

//     const _year = date.getFullYear();
//     return `${_date}${_month}${_year}`;
//   }

//   private _getOrderTime(date: Date) {
//     let _hours = `0${date.getHours()}`;
//     _hours = _hours.substring(_hours.length - 2);

//     let _minutes = `0${date.getMinutes()}`;
//     _minutes = _minutes.substring(_minutes.length - 2);

//     let _seconds = `0${date.getSeconds()}`;
//     _seconds = _seconds.substring(_seconds.length - 2);

//     return `${_hours}${_minutes}${_seconds}`;
//   }
// }

import { UUID } from 'angular2-uuid';
import MoneyUtils from 'src/app/utility/money-utils';
import { ReceiptFormatterService } from 'src/app/private/terminal/receipt-formatter.service';
import DateUtils from 'src/app/utility/date-utils';
import { parseISO } from 'date-fns';

export class TaxOrder implements ITaxOrder {
  documentType?: 0 | 100 | 101 | 102 | 103;
  documentTypeName?:
    | 'check'
    | 'startShift'
    | 'stopShift'
    | 'startOfflineShift'
    | 'stopOfflineShift';
  documentSubType?: number;
  uid?: string;
  orderNumber: number;
  tin: string;
  ipn?: string;
  organizationName: string;
  pointName: string;
  pointAddress: string;
  cashDeskNum: number;
  cashRegisterNum: string;
  cashRegisterName: string;
  cashier: string;
  offline?: boolean;
  offlineSessionId: string;
  offlineSeed: string;
  offlineNextLocalNum: string;

  ORDERDATE: string;
  ORDERTIME: string;

  CHECKTOTAL?: number;
  DISCOUNTSUM?: number;
  REMAINS?: number;
  PROVIDED?: number;
  CHECKPAY?: ICheckPay[];
  CHECKTAX?: ICheckTax[];
  CHECKBODY?: ICheckProduct[];

  ORDERTAXNUM?: string;
  ORDERRETNUM?: string;
  ORDERSTORNUM?: string;
  REVOKELASTONLINEDOC?: boolean;
  testing?: boolean;
  documentDate: string;
  shiftTotals: ShiftTotals;
  xmlDocument?: string;

  constructor(order?: ITaxOrder) {
    if (!order) {
      return;
    }

    const _date = order.documentDate || new Date().toISOString();
    this.documentDate = _date;
    this.ORDERDATE = this._getOrderDate(_date);
    this.ORDERTIME = this._getOrderTime(_date);
    this.uid = order.uid ? order.uid : UUID.UUID();

    this.documentType = order.documentType;

    // if (order.documentTypeName == 'check') {
    //   this.documentType = 0;
    // } else if (order.documentTypeName == 'startShift') {
    //   this.documentType = 100;
    // } else if (order.documentTypeName == 'stopShift') {
    //   this.documentType = 101;
    // } else if (order.documentTypeName == 'startOfflineShift') {
    //   this.documentType = 102;
    // } else if (order.documentTypeName == 'stopOfflineShift') {
    //   this.documentType = 103;
    // }

    this.documentSubType = order.documentSubType;
    this.orderNumber = order.orderNumber;
    this.tin = order.tin;
    this.ipn = order.ipn;
    this.organizationName = order.organizationName;
    this.pointName = order.pointName;
    this.pointAddress = order.pointAddress;
    this.cashDeskNum = order.cashDeskNum;
    this.cashRegisterNum = order.cashRegisterNum;
    this.cashRegisterName = order.cashRegisterName;
    this.cashier = order.cashier;

    this.CHECKTOTAL = order.CHECKTOTAL;
    this.CHECKPAY = order.CHECKPAY;
    this.CHECKTAX = order.CHECKTAX;
    this.CHECKBODY = order.CHECKBODY;

    this.ORDERTAXNUM = order.ORDERTAXNUM;
    this.ORDERRETNUM = order.ORDERRETNUM;
    this.REVOKELASTONLINEDOC = order.REVOKELASTONLINEDOC;

    this.offline = order.offline;
    this.offlineSeed = order.offlineSeed;
    this.offlineSessionId = order.offlineSessionId;
    this.offlineNextLocalNum = order.offlineNextLocalNum;
    this.DISCOUNTSUM = order.DISCOUNTSUM;
    this.shiftTotals = order.shiftTotals;

    if (this.PROVIDED > this.CHECKTOTAL) {
      this.REMAINS = MoneyUtils.round(this.PROVIDED - this.CHECKTOTAL);
    }
  }

  private _getOrderDate(date: string) {
    const dateObject = parseISO(date);
    let _date = `0${dateObject.getDate()}`;
    _date = _date.substring(_date.length - 2);

    let _month = `0${dateObject.getMonth() + 1}`;
    _month = _month.substring(_month.length - 2);

    const _year = dateObject.getFullYear();
    return `${_date}${_month}${_year}`;
  }

  private _getOrderTime(date: string) {
    const dateObject = parseISO(date);
    let _hours = `0${dateObject.getHours()}`;
    _hours = _hours.substring(_hours.length - 2);

    let _minutes = `0${dateObject.getMinutes()}`;
    _minutes = _minutes.substring(_minutes.length - 2);

    let _seconds = `0${dateObject.getSeconds()}`;
    _seconds = _seconds.substring(_seconds.length - 2);

    return `${_hours}${_minutes}${_seconds}`;
  }

  toString(width: number, currencyName?: string): string {
    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';
    _receiptFormatterService.config.width = width;

    const printLines = this._getTextLines(currencyName);
    const printText = _receiptFormatterService.create(printLines);
    return printText;
  }

  toHtml(): string {
    return `${this._getHeaderHtml()}${this._getBodyHtml()}${this._getReceiptSummary()}`;
  }

  private _getTextLines(currencyName?: string): any[] {
    const lines: any[] = [];

    if (this.organizationName) {
      lines.push({
        type: 'text',
        value: [`ФОП ${this.organizationName}`],
        align: 'center',
      });
    }

    if (this.pointName) {
      lines.push({
        type: 'text',
        value: [this.pointName],
        align: 'center',
      });
    }

    if (this.pointAddress) {
      lines.push({
        type: 'text',
        value: [this.pointAddress],
        align: 'center',
      });
    }

    const _companyNumber = this.tin || this.ipn;
    if (_companyNumber) {
      lines.push({
        type: 'text',
        value: `ІД ${_companyNumber}`,
        align: 'center',
      });
    }

    if (this.documentType == 0) {
      if (this.documentSubType == 4) {
        lines.push({
          type: 'text',
          value: 'Службова видача',
          align: 'center',
        });
      }

      if (this.documentSubType == 2) {
        lines.push({
          type: 'text',
          value: 'Службове внесення',
          align: 'center',
        });
      }

      lines.push({
        type: 'text',
        value: 'Касовий чек',
        align: 'center',
      });
    }

    if (this.cashRegisterNum) {
      lines.push({
        type: 'text',
        value: [`ФН ПРРО ${this.cashRegisterNum} ВН ${this.cashDeskNum}`],
      });
    }

    if (this.ORDERTAXNUM) {
      lines.push({
        type: 'text',
        value: [`ФН чека ${this.ORDERTAXNUM} ВН ${this.orderNumber}`],
      });
    }

    if (this.testing) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
      });
    }

    if (this.cashier) {
      lines.push({
        type: 'text',
        value: ['касир ' + this.cashier],
        align: 'center',
      });
    }

    const productsLine = this._getProductsPrintText();
    if (productsLine) {
      lines.push(productsLine);
    } else {
      lines.push({
        type: 'text',
        value: '--------------------------------',
        align: 'center',
      });
    }

    // summary
    const summary: any[] = [];
    if (this.CHECKTAX && this.CHECKTAX.length > 0) {
      this.CHECKTAX.forEach((tax) => {
        summary.push({
          name: tax.NAME + ' ' + tax.LETTER + ' ' + tax.PRC + '%',
          value: tax.SUM,
        });
      });
    }

    summary.push({
      name: 'СУМА',
      value: `${MoneyUtils.money(this.CHECKTOTAL)} ${currencyName}`,
    });

    if (this.DISCOUNTSUM) {
      summary.push({
        name: '   ЗНИЖКА',
        value: `${MoneyUtils.money(this.DISCOUNTSUM)} ${currencyName}`,
      });

      summary.push({
        name: 'РАЗОМ',
        value: `${MoneyUtils.money(this.CHECKTOTAL)} ${currencyName}`,
      });
    }

    // if (this.rndSum) {
    //   summary.push({
    //     name: 'ЗАОКРУГЛЕННЯ',
    //     value: MoneyUtils.money(this.rndSum),
    //   });
    // }

    summary.push({
      name: 'СУМА ДО СПЛАТИ',
      value: `${this.CHECKTOTAL} ${currencyName}`,
    });

    lines.push({ type: 'properties', lines: summary });
    lines.push({
      type: 'text',
      value: '--------------------------------',
      align: 'center',
    });

    const total: any[] = [];
    if (this.CHECKPAY && this.CHECKPAY.length > 0) {
      this.CHECKPAY.forEach((pay) => {
        total.push({
          name: pay.PAYFORMNM,
          value: `${pay.SUM} ${currencyName}`,
        });
      });
    }

    if (this.PROVIDED) {
      total.push({
        name: 'Сплачено',
        value: `${MoneyUtils.money(this.PROVIDED)} ${currencyName}`,
      });
    }
    if (this.REMAINS) {
      total.push({
        name: 'Решта',
        value: `${MoneyUtils.money(this.REMAINS)} ${currencyName}`,
      });
    }
    lines.push({ type: 'properties', lines: total });

    lines.push({
      type: 'text',
      value: DateUtils.display(this.documentDate),
      align: 'center',
    });

    if (this.testing) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ЧЕК',
      });
    } else {
      lines.push({
        type: 'text',
        value: ['ФІСКАЛЬНИЙ ЧЕК'],
        align: 'center',
      });
    }

    lines.push({
      type: 'text',
      value: 'онлайн',
      align: 'center',
    });

    return lines;
  }

  private _getProductsPrintText(): any {
    if (!this.CHECKBODY) {
      return null;
    }

    return {
      type: 'table',
      lines: this.CHECKBODY.map((p) => {
        return {
          item: p.NAME,
          qty: p.AMOUNT,
          cost: p.COST,
          total: p.PRICE,
        };
      }),
    };
  }

  private _getHeaderHtml(): string {
    let headerHtml = `<div style="width: 100%;font-size: 14px;">`;

    if (this.organizationName) {
      headerHtml += `<div style="text-align: center;">ФОП ${this.organizationName}</div>`;
    }

    if (this.pointName) {
      headerHtml += `<div style="text-align: center;">${this.pointName}</div>`;
    }

    if (this.pointAddress) {
      headerHtml += `<div style="text-align: center;">${this.pointAddress}</div>`;
    }

    const companyNumber = this.tin || this.ipn;
    if (companyNumber) {
      headerHtml += `<div">&nbsp;&nbsp;&nbsp;&nbsp;ІД ${companyNumber}</div>`;
    }

    if (this.documentType == 0) {
      if (this.documentSubType == 2) {
        headerHtml += `<div">&nbsp;&nbsp;&nbsp;&nbsp;Службове внесення</div>`;
      }

      if (this.documentSubType == 4) {
        headerHtml += `<div">&nbsp;&nbsp;&nbsp;&nbsp;Службова видача</div>`;
      }
      headerHtml += `<div">&nbsp;&nbsp;&nbsp;&nbsp;Касовий чек</div>`;
    }

    if (this.cashRegisterNum) {
      headerHtml += `<div>ФН ПРРО ${this.cashRegisterNum} ВН ${this.cashDeskNum}</div>`;
    }

    if (this.ORDERTAXNUM) {
      headerHtml += `<div>ФН чека ${this.ORDERTAXNUM} ВН ${this.orderNumber}</div>`;
    }

    if (this.testing) {
      headerHtml += `<div>ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
    }

    if (this.cashier) {
      headerHtml += `<div>касир ${this.cashier}</div>`;
    }

    headerHtml += `</div>`;
    return headerHtml;
  }

  private _getBodyHtml(): string {
    let tableHtml = `<table style="width:100%;border-top:1px solid black;border-bottom:1px solid black;margin-bottom:8px;margin-top:8px;"><tr style="font-size: 10px; font-weight: bold"><td>Назва</td><td>Кіл-ть</td><td>Ціна</td><td>Разом</td></tr>`;

    if (!this.CHECKBODY) {
      return tableHtml;
    }

    for (let p of this.CHECKBODY) {
      tableHtml += `<tr><td style="width: 100%;">${p.NAME}</td><td>${
        p.AMOUNT
      }</td><td style="padding:0 3px;text-align:right;">${MoneyUtils.money(
        p.PRICE
      )}</td><td style="padding:0 3px;text-align:right;">${MoneyUtils.money(
        p.COST
      )}</td></tr>`;

      if (p.UKTZED) {
        tableHtml += `<tr><td colspan="4" style="width: 100%;font-size:10px;padding-left: 20px;">УКТЗЕД КОД:${p.UKTZED}</td></tr>`;
      }

      if (p.EXCISELABELS && p.EXCISELABELS.length) {
        for (let el of p.EXCISELABELS) {
          tableHtml += `<tr><td colspan="4" style="width: 100%;font-size:10px;padding-left: 20px;">${el}</td></tr>`;
        }
      }
    }

    tableHtml += `</table>`;
    return tableHtml;
  }

  private _getReceiptSummary() {
    let summaryHtml = `<table style="width:100%">`;

    if (this.CHECKTAX && this.CHECKTAX.length > 0) {
      this.CHECKTAX.forEach((tax) => {
        summaryHtml += `<div style="text-align: center;">${tax.NAME} ${tax.LETTER} ${tax.PRC}%  ${tax.SUM}</div>`;
      });
    }

    // if (this.DISCOUNTSUM > 0) {
    //   summaryHtml += `<tr>
    //   <td style="width: 100%">ЗАГАЛЬНА СУМА:</td>
    //   <td style="text-align:right;">${MoneyUtils.money(this.CHECKTOTAL)}</td>
    //     </tr>`;

    //   summaryHtml += `<tr>
    //     <td style="width: 100%">РАЗОМ:</td>
    //     <td style="text-align:right;">${MoneyUtils.money(this.CH)}</td>
    //       </tr>`;
    // }

    // if (this.rndSum) {
    //   summaryHtml += `<tr>
    //   <td style="width: 100%">ЗАОКРУГЛЕННЯ:</td>
    //   <td style="text-align:right;">${MoneyUtils.money(this.rndSum)}</td>
    //     </tr>`;
    // }

    summaryHtml += `<tr>
    <td style="width: 100%">СУМА:</td>
    <td style="text-align:right;">${MoneyUtils.money(
      this.CHECKTOTAL
    )}</td></tr>`;

    // summaryHtml += `<tr><td style="width: 100%;" colspan="2">
    // <div style="padding:6px 10px"><div style="border-top:1px solid lightgrey"></div></div>
    // </td></tr>`;

    const payment = this.CHECKPAY[0];
    summaryHtml += `<tr>
      <td style="width: 100%">${payment?.PAYFORMNM}:</td>
      <td style="text-align:right;">${MoneyUtils.money(this.CHECKTOTAL)}</td>
        </tr>`;

    if (this.PROVIDED > 0) {
      summaryHtml += `<tr>
      <td style="width: 100%">Сплачено:</td>
      <td style="text-align:right;">${MoneyUtils.money(this.PROVIDED)}</td>
        </tr>`;
    }
    if (this.REMAINS > 0) {
      summaryHtml += `<tr>
      <td style="width: 100%">Решта:</td>
      <td style="text-align:right;">${MoneyUtils.money(this.REMAINS)}</td>
        </tr>`;
    }

    summaryHtml += `</table>`;
    summaryHtml += `<div>`;
    summaryHtml += `<div style="text-align: center;">${DateUtils.display(
      this.documentDate
    )}</div>`;

    if (this.testing) {
      summaryHtml += `<div>ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ЧЕК</div>`;
    } else {
      summaryHtml += `<div style="text-align: center;">ФІСКАЛЬНИЙ ЧЕК</div>`;
    }

    // if (this.cashRegisterNum) {
    //   summaryHtml += `<div style="text-align: center;">${this.cashRegisterNum}</div>`;
    // }

    summaryHtml += `</div>`;
    return summaryHtml;
  }
}
