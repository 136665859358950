import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

const POS_TERMINAL_SETTINGS = 'POS_TERMINAL_SETTINGS';

export interface ITerminalData {
  customers: any[];
  discounts: any[];
  employees: any[];
  locales: any;
  store: any;
  suppliers: any[];
  supply_products: any[];
  terminal: any;
  cashbox_shift: any;
  receipt_wishes: string[];
  token?: string;
  printer_settings: any;
}

@Injectable({ providedIn: 'root' })
export class CompanyDataService {
  private _companyData = new BehaviorSubject<ITerminalData>(null);

  constructor(private _storageService: StorageService) {}

  async getCompanyData(): Promise<ITerminalData> {
    if (this._companyData.value) {
      return this._companyData.value;
    }

    return this._storageService.get(POS_TERMINAL_SETTINGS).then((data) => {
      if (data) {
        this._companyData.next(data);
        return data;
      }
    });
  }

  clearCompanyData() {
    this._companyData.next(null);
  }

  async getCompanyId(): Promise<string> {
    const companyData = await this.getCompanyData();
    if (!companyData) {
      throw new Error('ERP company not found.');
    }
    return companyData.store.company_id;
  }

  get companyId(): string {
    const companyData = this._companyData.value;
    return companyData?.store?.company_id;
  }
}
