import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanyStorage } from 'src/app/_core/company-storage';
import { AppEndUserOwnerInfo } from '../models/end-user-owner-info.model';
import { IPrivateKeySettings } from './agent-signer.service';
import { CashierPrivateKey } from './tax-register-config.service';

export interface IPrivateKeyModel {
  tin: string;
  userId?: string;
  endUserInfo: AppEndUserOwnerInfo;
  privateKey: IPrivateKeySettings;
}
const PRIVATE_KEY_CONFIGS = 'PRIVATE_KEY_CONFIGS';

@Injectable({ providedIn: 'root' })
export class PrivateKeyService {
  cashiers = new BehaviorSubject<any>(null);

  constructor(private _companyStorage: CompanyStorage) {}

  async getPrivateKeyBySubjectId(
    subjectId: string
  ): Promise<CashierPrivateKey> {
    const configs = await this._getPrivteKeyConfigs();
    const config = configs.find(
      (c) =>
        c.endUserInfo.SubjectKeyId?.toLocaleLowerCase() ==
        subjectId.toLocaleLowerCase()
    );
    if (config) {
      return {
        keyFileBase64: config.privateKey.privateKeyBase64,
        keyFileName: config.privateKey.privateKeyFileName,
        password: config.privateKey.password,
        providerName: config.privateKey.providerName,
      };
    }

    throw new Error(
      'Налаштування ключа не знайдено. Ідентифікатор ключа ' + subjectId
    );
  }

  private async _getPrivteKeyConfigs(): Promise<IPrivateKeyModel[]> {
    var result = await this._companyStorage.get(PRIVATE_KEY_CONFIGS);
    if (!result) {
      return [];
    }

    return result;
  }
}
