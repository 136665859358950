import { Injectable } from '@angular/core';
import {
  BluetoothLE,
  ScanStatus,
  WriteCharacteristicParams,
} from '@awesome-cordova-plugins/bluetooth-le/ngx';

import { Platform } from '@ionic/angular';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, from, of, throwError } from 'rxjs';

export interface IBluetoothLeDevice {
  id: string;
  uuid: string;
  address: string;
  class: number;
  name: string;
  rssi: number;
  characteristic: string;
  service: string;
}

@Injectable({ providedIn: 'root' })
export class BluetoothLeService {
  constructor(private platform: Platform, private bluetoothLE: BluetoothLE) {}

  startScan(): Observable<IBluetoothLeDevice> {
    this.bluetoothLE.enable();

    return this.bluetoothLE.initialize().pipe(
      //   switchMap(() => {
      //     return from(this.bluetoothLE.requestPermissionBtScan()).pipe(
      //       switchMap((hasPerm) => {
      //         if (hasPerm) {
      //           return of(true);
      //         } else {
      //           return from(this.bluetoothLE.requestPermissionBtScan());
      //         }
      //       })
      //     );
      //   }),
      switchMap(() => {
        return this.bluetoothLE
          .startScan({
            allowDuplicates: true,
            scanMode: this.bluetoothLE.SCAN_MODE_LOW_LATENCY,
            matchMode: this.bluetoothLE.MATCH_MODE_AGGRESSIVE,
            matchNum: this.bluetoothLE.MATCH_NUM_MAX_ADVERTISEMENT,
            callbackType: this.bluetoothLE.CALLBACK_TYPE_ALL_MATCHES,
          })
          .pipe(
            map((res: ScanStatus) => {
              if (res.status != 'scanResult') {
                return null;
              }

              const advertisement: any = {};

              if (
                typeof res.advertisement == 'object' &&
                res.advertisement.serviceUuids &&
                res.advertisement.serviceUuids.length
              ) {
                advertisement.service = res.advertisement.serviceUuids[0];
              }

              console.log('SCAN RESULT');
              console.log(JSON.stringify(res));

              return <IBluetoothLeDevice>{
                uuid: res.address,
                address: res.address,
                name: res.name,
                rssi: res.rssi,
                service: advertisement.service,
                // characteristic: res.characteristic,
              };
            })
          );
      })
    );
  }

  stopScan(): Promise<any> {
    return this.bluetoothLE.stopScan();
  }

  async sendDataToDevice(device: any, data: Uint8Array): Promise<any> {
    console.log('sendDataToDevice');
    // const hasPermResp = await this.bluetoothLE.hasPermissionBtConnect();
    // if (!hasPermResp.hasPermission) {
    //   const requestPerm = await this.bluetoothLE.requestPermissionBtConnect();
    //   if (!requestPerm.requestPermission) {
    //     throw 'Bluetooth conection rejected.';
    //   }
    // }

    this.bluetoothLE.enable();

    console.log('device');
    console.log(JSON.stringify(device));

    return this.bluetoothLE
      .initialize()
      .pipe(
        switchMap(() => {
          return from(
            this.bluetoothLE
              .isConnected({
                address: device.address,
              })
              .catch(() => {
                return { isConnected: false };
              })
          ).pipe(
            switchMap((res) => {
              if (!res.isConnected) {
                return from(
                  this.bluetoothLE.connect({
                    address: device.address,
                  })
                );
              } else {
                return of({});
              }
            })
          );
        }),
        // switchMap(() => {
        //   return this.bluetoothLE.connect({
        //     address: device.address,
        //   });
        // }),
        switchMap(() =>
          from(
            this.bluetoothLE
              .services({
                address: device.address,
              })
              .catch((err) => {
                console.log('services err');
                console.log(JSON.stringify(err));
              })
          )
        ),
        switchMap((services: any) => {
          console.log('services');
          console.log(JSON.stringify(services));

          return from(
            this.bluetoothLE
              .characteristics({
                address: device.address,
                service: services.services[0],
              })
              .catch((err) => {
                console.log('characteristics err');
                console.log(JSON.stringify(err));
                return {};
              })
          );
        }),
        switchMap((characteristic: any) => {
          // if (res.status != 'connected') {
          //   return throwError('Device not connected');
          // }

          console.log('characteristic');
          console.log(JSON.stringify(characteristic));

          const testChar = characteristic.characteristics.find(
            (c) => c.properties.writeWithoutResponse || c.properties.write
          );

          // console.log(data);
          // var bytes = this.bluetoothLE.stringToBytes(data);
          // var encodedString = this.bluetoothLE.bytesToEncodedString(data);
          // if your code includes special characters you should use the encodeUnicode helper function
          // var encodedUnicodeString = bluetoothle.encodeUnicode(encodedString);

          var encodedUnicodeString =
            this.bluetoothLE.bytesToEncodedString(data);
          const params: WriteCharacteristicParams = {
            value: encodedUnicodeString,
            service: characteristic.service,
            characteristic: testChar.uuid,
            address: device.address,
            // type: 'noResponse',
          };

          console.log('params');
          console.log(JSON.stringify(params));

          return from(
            this.bluetoothLE
              .write(params)
              .catch((err) => {
                console.log('write error');
                console.log(JSON.stringify(err));
              })
              .finally(() =>
                this.bluetoothLE
                  .disconnect({
                    address: device.address,
                  })
                  .then(() =>
                    this.bluetoothLE.close({
                      address: device.address,
                    })
                  )
              )
          );
        }),

        catchError((err) => {
          this.bluetoothLE.disconnect({
            address: device.address,
          });

          return throwError(err);
        })
      )
      .toPromise();

    // .catch((err) => {
    //   console.log('write error');
    //   console.log(JSON.stringify(device));
    //   return null;
    // });
    // if (!res || res.status != 'written') {
    //   throw 'Write error';
    // }
  }
}
