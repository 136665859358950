<div class="p-1 pb-3">
  <table (click)="setValue($event)" [style.width.px]="width" class="numpad">
    <tr *ngIf="displayOutput">
      <td colspan="3">
        <div class="pb-1">
          <input
            #valueInput
            [ngModel]="value"
            (keydown)="changeValue($event)"
            class="text-center value-input w-100"
            [placeholder]="placeholder"
          />
        </div>
      </td>
    </tr>
    <tr *ngIf="preselectBtns && preselectBtns.length">
      <td colspan="3">
        <table class="w-100">
          <tr>
            <td
              *ngFor="let preBtn of preselectBtns"
              (click)="setPreselectValue(preBtn)"
              class="p-0"
            >
              <ion-button
                class="fixed-summ-button w-100 m-0"
                expand="block"
                fill="outline"
                color="dark"
              >
                {{ preBtn }}
              </ion-button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="7">
          7
        </button>
      </td>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="8">
          8
        </button>
      </td>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="9">
          9
        </button>
      </td>
    </tr>
    <tr>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="4">
          4
        </button>
      </td>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="5">
          5
        </button>
      </td>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="6">
          6
        </button>
      </td>
    </tr>
    <tr>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="1">
          1
        </button>
      </td>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="2">
          2
        </button>
      </td>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="3">
          3
        </button>
      </td>
    </tr>
    <tr>
      <td>
        <button
          *ngIf="divisible"
          type="button"
          class="btn btn-outline-secondary"
          data-value="."
        >
          .
        </button>
      </td>
      <td>
        <button type="button" class="btn btn-outline-secondary" data-value="0">
          0
        </button>
      </td>
      <td>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-value="backspace"
        >
          <
        </button>
      </td>
    </tr>
    <tr *ngIf="submitBtn">
      <td colspan="3">
        <div class="pt-2">
          <ion-button
            type="button"
            color="success"
            fill="solid"
            expand="block"
            class="color-white ok-btn"
            data-value="ok"
          >
            OK
          </ion-button>
        </div>
      </td>
    </tr>
  </table>
</div>
