import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

// TODO company specific db name
const DB_NAME = '__nt_soft_pos_terminal';

export class IGenericItem {
  id?: number;
  uuid?: string;
  type?: string;
  selected?: boolean;
  [key: string]: any;
}

@Injectable({ providedIn: 'root' })
export class GenericRepository {
  constructor(private _storageService: StorageService) {}

  async getItems<T>(key: string): Promise<T[]> {
    if (!key) {
      throw 'Generic repository. key not provided';
    }

    const _storage = await this._storageService.getDefaultStorage();
    return _storage.get(key).then((res) => res || []);
  }

  async getItem<T>(key: string, id?: string | number): Promise<T> {
    if (!key) {
      throw 'Generic repository. key not provided';
    }

    const _storage = await this._storageService.getDefaultStorage();
    const items: IGenericItem[] = await _storage.get(key);
    if (id && items) {
      return items.find(
        (i) => (i.id && i.id == id) || (i.uuid && i.uuid == id)
      ) as T;
    }
  }

  async addItem(key: string, item: IGenericItem): Promise<any> {
    if (!key) {
      throw 'Generic repository. key not provided';
    }

    const _storage = await this._storageService.getDefaultStorage();
    const items: IGenericItem[] = await _storage.get(key);
    if (items) {
      items.push(item);
      return _storage.set(key, items);
    } else {
      return _storage.set(key, [item]);
    }
  }

  async updateItem(
    key: string,
    item: IGenericItem,
    idKey: string = null
  ): Promise<void> {
    if (!key) {
      throw 'Generic repository. key not provided';
    }

    const _storage = await this._storageService.getDefaultStorage();
    const items: IGenericItem[] = await _storage.get(key);

    if (!items || items.length == 0) {
      return null;
    }

    let newItems: IGenericItem[] = [];
    for (let i of items) {
      if (
        (item.id && i.id == item.id) ||
        (item.uuid && i.uuid == item.uuid) ||
        (idKey && i[idKey] == item[idKey])
      ) {
        newItems.push(item);
      } else {
        newItems.push(i);
      }
    }

    return _storage.set(key, newItems);
  }

  async deleteItem(key: string, id?: string | number): Promise<void> {
    if (!key) {
      throw 'Generic repository. key not provided';
    }

    const _storage = await this._storageService.getDefaultStorage();
    const items: IGenericItem[] = await _storage.get(key);

    if (!items || items.length == 0) {
      return null;
    }

    let toKeep: IGenericItem[] = [];
    for (let i of items) {
      if ((i.id && i.id == id) || (i.uuid && i.uuid == id)) {
        continue;
      }

      toKeep.push(i);
    }
    return _storage.set(key, toKeep);
  }

  async loadItems(key: string, items: IGenericItem[]): Promise<void> {
    if (!key) {
      throw 'Generic repository. key not provided';
    }

    const _storage = await this._storageService.getDefaultStorage();
    return _storage.set(key, items);
  }

  async deleteTable(key: string): Promise<void> {
    if (!key) {
      throw 'Generic repository. key not provided';
    }

    const _storage = await this._storageService.getDefaultStorage();
    return _storage.remove(key);
  }
}
