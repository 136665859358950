import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { APP_CONFIG, SettingsService } from 'src/app/services/settings.service';
import EncodeUtils from 'src/app/utility/encode-utils';
import { xml2json } from 'src/app/utility/xml-utils';
import {
  DocumentResponse,
  LastShiftTotalsCommand,
  LastShiftTotalsResponse,
  ShiftDocumentsCommand,
  ShiftDocumentsResponse,
  ShiftsCommand,
  ShiftsResponse,
  TaxError,
  TransactionsRegistrarStateCommand,
  TransactionsRegistrarStateResponse,
} from '../models/tax.model';
import { AgentSigner } from './agent-signer.service';
import { SharedService } from 'src/app/services/shared.service';

export interface ITaxResponse {
  data?: any;
  error?: string;
  errorCode?: number;
  httpStatusCode?: number;
  success: boolean;
  status?: string;
}

@Injectable({ providedIn: 'root' })
export class TaxApiService {
  private _http: HttpClient;
  private _taxApiEndpoint: string;
  private get _testTaxApiRequestFailed() {
    const res = localStorage.getItem('_testTaxApiRequestFailed__');
    return res && res == 'true';
  }
  private set _testTaxApiRequestFailed(val: boolean) {
    localStorage.setItem('_testTaxApiRequestFailed__', val?.toString());
  }

  private _testTaxError: ITaxResponse = { success: false };
  private get _throwMockError() {
    const testTaxServer = this.settings.getValue(APP_CONFIG.TEST_TAX_SERVER);
    return testTaxServer && this._testTaxApiRequestFailed;
  }

  private get _testTaxServer() {
    const testTaxServer = this.settings.getValue(APP_CONFIG.TEST_TAX_SERVER);
    return testTaxServer;
  }

  constructor(
    private _httpBackend: HttpBackend,
    private _platform: Platform,
    private settings: SettingsService,
    private _nativeHttp: HTTP,
    private agentSigner: AgentSigner,
    private httpClient: HttpClient,
    private shared: SharedService
  ) {
    this._http = new HttpClient(this._httpBackend);
    this._taxApiEndpoint = this.settings.getValue(APP_CONFIG.TAX_API_URL);
    this.settings._getValue<string>(APP_CONFIG.TAX_API_URL).then((res) => {
      this._taxApiEndpoint = res;
    });
  }

  async getServerState() {
    if (this._throwMockError) {
      throw 'ServerState error.';
    }

    const command = '{"Command":"ServerState"}';
    const serverState = await this._sendCommandToTax<{
      UID: string;
      Timestamp: string;
    }>(btoa(command));

    if (!serverState || !serverState.Timestamp) {
      throw 'ServerState. Сервер податкової недоступний';
    }

    return serverState;
  }

  getStoreConfig = (companyId: string, storeId: number): Observable<any[]> =>
    this.httpClient.get<any[]>(
      `${this._taxApiEndpoint}/erpStoreConfig/${storeId}`,
      {
        params: new HttpParams().set('responseFormater', 'none'),
        headers: new HttpHeaders().set('companyId', companyId),
      }
    );

  async ctxGetLastShiftTotals(
    contextKey: string,
    taxRegisterFiscalNUmber: string
  ): Promise<LastShiftTotalsResponse> {
    const command = new LastShiftTotalsCommand(taxRegisterFiscalNUmber);
    const signedData = await this.agentSigner.ctxSignData(
      contextKey,
      command.toString()
    );
    return this.sendCommandToTax<LastShiftTotalsResponse>(signedData);
  }

  async ctxGetTaxRegisterState(
    contextKey: string,
    taxRegisterFiscalNumber: string,
    offlineSessionId: string = null,
    offlineSeed: string = null,
    includeTaxObject = true
  ): Promise<TransactionsRegistrarStateResponse> {
    const command = new TransactionsRegistrarStateCommand(
      taxRegisterFiscalNumber,
      offlineSessionId,
      offlineSeed,
      includeTaxObject
    );

    const signedData = await this.agentSigner.ctxSignData(
      contextKey,
      command.toString()
    );

    const state =
      await this.sendCommandToTax<TransactionsRegistrarStateResponse>(
        signedData
      );

    return state;
  }

  async ctxGetShifts(
    ctxKey: string,
    taxRegisterFiscalNumber: string,
    from: Date,
    to: Date
  ): Promise<ShiftsResponse> {
    const command = new ShiftsCommand(taxRegisterFiscalNumber, from, to);
    const signedData = await this.agentSigner.ctxSignData(
      ctxKey,
      command.toString()
    );
    const shifts = await this._sendCommandToTax<ShiftsResponse>(
      signedData
    ).catch((err) => {
      const taxError = new TaxError(err.error);
      this._displayError(taxError.text, 10000);
      return null;
    });

    return shifts;
  }

  async ctxGetZRepExt(
    ctxKey: string,
    taxRegisterFiscalNumber: string,
    documentFiscalNum: string,
    responseType: string
  ): Promise<string> {
    const command = {
      Command: 'ZRepExt',
      RegistrarNumFiscal: taxRegisterFiscalNumber,
      NumFiscal: documentFiscalNum,
      Type: responseType,
    };

    const signedData = await this.agentSigner.ctxSignData(
      ctxKey,
      JSON.stringify(command)
    );
    const zRepResponse = await this._sendCommandToTax<DocumentResponse>(
      signedData
    ).catch((err) => {
      const taxError = new TaxError(err.error);
      this._displayError(taxError.text, 10000);
      return null;
    });

    return EncodeUtils.base64DecodeUnicode(zRepResponse.Data);
  }

  async ctxGetZRepJson(
    ctxKey: string,
    taxRegisterFiscalNumber: string,
    documentFiscalNum: string
  ): Promise<any> {
    const command = {
      Command: 'ZRepExt',
      RegistrarNumFiscal: taxRegisterFiscalNumber,
      NumFiscal: documentFiscalNum,
      Type: 'OriginalXml',
    };

    const signedData = await this.agentSigner.ctxSignData(
      ctxKey,
      JSON.stringify(command)
    );
    const zRepResponse = await this._sendCommandToTax<DocumentResponse>(
      signedData
    ).catch((err) => {
      const taxError = new TaxError(err.error);
      this._displayError(taxError.text, 10000);
      return null;
    });

    const win1251decoder = new TextDecoder('windows-1251');
    const arrayBuffer = EncodeUtils.base64ToArrayBuffer(zRepResponse.Data);
    const unicodeString = win1251decoder.decode(arrayBuffer);
    // const xmlDOM = new DOMParser().parseFromString(unicodeString, 'text/xml');
    return xml2json(unicodeString); // this._xmlToJson(xmlDOM);
  }

  async ctxGetZRepXml(
    ctxKey: string,
    taxRegisterFiscalNumber: string,
    documentFiscalNum: string
  ): Promise<string> {
    const command = {
      Command: 'ZRepExt',
      RegistrarNumFiscal: taxRegisterFiscalNumber,
      NumFiscal: documentFiscalNum,
      Type: 'OriginalXml',
    };

    const signedData = await this.agentSigner.ctxSignData(
      ctxKey,
      JSON.stringify(command)
    );
    const zRepResponse = await this._sendCommandToTax<DocumentResponse>(
      signedData
    );

    return EncodeUtils.base64DecodeUnicode(atob(zRepResponse.Data));
  }

  async ctxGetShiftDocuments(
    ctxKey: string,
    taxRegisterFiscalNumber: string,
    shiftId: number,
    openShiftFiscalNum: number
  ): Promise<ShiftDocumentsResponse> {
    const command = new ShiftDocumentsCommand(
      taxRegisterFiscalNumber,
      shiftId,
      openShiftFiscalNum
    );

    const signedData = await this.agentSigner.ctxSignData(
      ctxKey,
      command.toString()
    );
    const shiftDocumntsResponse =
      await this._sendCommandToTax<ShiftDocumentsResponse>(signedData).catch(
        (err) => {
          const taxError = new TaxError(err.error);
          this._displayError(taxError.text, 10000);
          return null;
        }
      );

    return shiftDocumntsResponse;
  }

  async ctxGetCheckExt(
    ctxKey: string,
    taxRegisterFiscalNumber: string,
    documentFiscalNum: string,
    responseType: string
  ): Promise<string> {
    const command = {
      Command: 'CheckExt',
      RegistrarNumFiscal: taxRegisterFiscalNumber,
      NumFiscal: documentFiscalNum,
      Type: responseType,
      AcquireCabinetUrl: true,
    };

    const signedData = await this.agentSigner.ctxSignData(
      ctxKey,
      JSON.stringify(command)
    );
    const сheckExtResponse = await this._sendCommandToTax<DocumentResponse>(
      signedData
    ).catch((err) => {
      const taxError = new TaxError(err.error);
      this._displayError(taxError.text, 10000);
      return null;
    });

    // const response = JSON.parse(сheckExtResponse) as DocumentResponse;
    return EncodeUtils.base64DecodeUnicode(сheckExtResponse.Data);
  }

  async ctxGetCheckXml(
    ctxKey: string,
    taxRegisterFiscalNumber: string,
    documentFiscalNum: string
  ): Promise<string> {
    const command = {
      Command: 'CheckExt',
      RegistrarNumFiscal: taxRegisterFiscalNumber,
      NumFiscal: documentFiscalNum,
      Type: 'OriginalXml',
      AcquireCabinetUrl: true,
    };

    const signedData = await this.agentSigner.ctxSignData(
      ctxKey,
      JSON.stringify(command)
    );
    const сheckXmlResponse = await this._sendCommandToTax<DocumentResponse>(
      signedData
    );

    const data = EncodeUtils.base64ToArrayBuffer(сheckXmlResponse.Data);
    const win1251decoder = new TextDecoder('windows-1251');
    return win1251decoder.decode(data);
  }

  async sendCommandToTax<T>(signedDocument: string | Uint8Array): Promise<T> {
    return this._sendCommandToTax<T>(signedDocument);
  }

  private async _sendCommandToTax<T>(
    signedDocument: string | Uint8Array
  ): Promise<T> {
    // this._logService.info(this._serviceName, '_sendCommandToTax');
    const signedDocBytes = EncodeUtils.base64ToArrayBuffer(
      <string>signedDocument
    );
    const headers = {
      'Content-Type': 'application/octet-stream',
    };

    const apiTaxUrl = this.settings.getValue(APP_CONFIG.TAX_API_URL);
    const platform = await this._platform.ready();
    if (platform == 'dom') {
      return this._http
        .post<any>(`${apiTaxUrl}/tax/cmd`, {
          SignedData: signedDocument,
        })
        .toPromise()
        .then((res) => {
          if (res.success) {
            return <T>res.data;
          }

          if (res.error) {
            throw res.error;
          }

          throw res;
        });
    } else {
      const response = await this._sendRequest(
        this.settings.fs_tax_cmd,
        {
          method: 'post',
          data: signedDocBytes,
          headers: headers,
          responseType: 'json',
        },
        'raw'
      );

      return response.data;
    }
  }

  async sendDocumentToTax(signedDocument: string): Promise<ITaxResponse> {
    if (this._throwMockError) {
      return this._testTaxError;
    }

    const signedDocBytes = EncodeUtils.base64ToArrayBuffer(signedDocument);
    const headers = {
      'Content-Type': 'application/octet-stream',
    };

    const apiTaxUrl = this.settings.getValue(APP_CONFIG.TAX_API_URL);
    const platform = await this._platform.ready();
    if (platform == 'dom') {
      return this._http
        .post<ITaxResponse>(`${apiTaxUrl}/tax/doc`, {
          SignedData: signedDocument,
        })
        .toPromise()
        .then((res) => {
          if (this._testTaxServer) {
            this._testTaxApiRequestFailed = true;
            return this._testTaxError;
          }

          this._testTaxApiRequestFailed = null;
          return res;
        })
        .catch((err) => {
          return this._handleHttpError(err);
        });
    } else {
      this._nativeHttp.setDataSerializer('raw');
      const result = await this._nativeHttp
        .sendRequest(this.settings.fs_tax_doc, {
          method: 'post',
          data: signedDocBytes,
          headers: headers,
          responseType: 'arraybuffer',
        })
        .then((resp) => {
          if (this._testTaxServer) {
            this._testTaxApiRequestFailed = true;
            return this._testTaxError;
          }

          this._testTaxApiRequestFailed = null;
          const result: ITaxResponse = { success: false };
          result.data = EncodeUtils.arrayBufferToBase64(resp.data);
          result.success = true;

          return result;
        })
        .catch((err) => {
          return this._handleHttpError(err);
        });

      return result;
    }
  }

  async sendPackageToTax(signedPackege: string): Promise<ITaxResponse> {
    if (this._throwMockError) {
      return this._testTaxError;
    }

    const apiTaxUrl = this.settings.getValue(APP_CONFIG.TAX_API_URL);
    const headers = {
      'Content-Type': 'application/octet-stream',
    };

    const platform = await this._platform.ready();
    if (platform == 'dom') {
      return this._http
        .post<ITaxResponse>(`${apiTaxUrl}/tax/pck`, {
          SignedData: signedPackege,
        })
        .toPromise()
        .then((res) => {
          if (this._testTaxServer) {
            this._testTaxApiRequestFailed = true;
            return this._testTaxError;
          }

          this._testTaxApiRequestFailed = null;
          return res;
        })
        .catch((err) => {
          return this._handleHttpError(err);
        });
    } else {
      const signedDocBytes = EncodeUtils.base64ToArrayBuffer(signedPackege);
      this._nativeHttp.setDataSerializer('raw');
      const result = await this._nativeHttp
        .sendRequest(this.settings.fs_tax_pck, {
          method: 'post',
          data: signedDocBytes,
          headers: headers,
          responseType: 'arraybuffer',
        })
        .then((resp) => {
          if (this._testTaxServer) {
            this._testTaxApiRequestFailed = true;
            return this._testTaxError;
          }

          this._testTaxApiRequestFailed = null;
          const result: ITaxResponse = { success: false };
          result.data = EncodeUtils.arrayBufferToBase64(resp.data);
          result.success = true;

          return result;
        })
        .catch((err) => {
          return this._handleHttpError(err);
        });

      return result;
    }
  }

  private _handleHttpError(err: any) {
    const result: ITaxResponse = { success: false };
    if (err.status) {
      result.httpStatusCode = err.status;
      result.status = 'HttpRequestFailed';
      result.error = err.error;
      result.errorCode = 1002;
    } else {
      result.httpStatusCode = err.status;
      result.status = 'UnknownError';
      result.error = err.error;
      result.errorCode = 1003;
    }

    return result;
  }

  private _sendRequest(
    url: string,
    options: {
      method:
        | 'get'
        | 'post'
        | 'put'
        | 'patch'
        | 'head'
        | 'delete'
        | 'options'
        | 'upload'
        | 'download';
      data?: {
        [index: string]: any;
      };
      params?: {
        [index: string]: string | number;
      };
      serializer?: 'json' | 'urlencoded' | 'utf8' | 'multipart' | 'raw';
      timeout?: number;
      headers?: {
        [index: string]: string;
      };
      filePath?: string | string[];
      name?: string | string[];
      responseType?: 'text' | 'arraybuffer' | 'blob' | 'json';
    },
    serializer: 'urlencoded' | 'json' | 'utf8' | 'multipart' | 'raw'
  ): Promise<HTTPResponse> {
    this._nativeHttp.setDataSerializer(serializer);
    return this._nativeHttp
      .sendRequest(url, options)
      .finally(() => this._nativeHttp.setDataSerializer('json'));
  }

  private _displayError(message: string, duration = 3000) {
    if (!message) {
      return;
    }

    this.shared.showErrorToast(message, duration);
  }
}
