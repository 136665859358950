import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from './monitoring.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private monitoringService: MonitoringService) {
    super();
  }

  handleError(error: any): void {
    super.handleError(error);

    this.monitoringService.logException(error); // Manually log exception
  }
}
