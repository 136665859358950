import { Injectable } from '@angular/core';
import {
  DocumentItem,
  LastShiftTotalsResponse,
  ShiftTotals,
  TaxDocumentSubType,
  TaxDocumentType,
  TransactionsRegistrarStateResponse,
} from '../models/tax.model';
import { CompanyStorage } from 'src/app/_core/company-storage';
import { sha256 } from 'js-sha256';
import { xml2json } from 'src/app/utility/xml-utils';
import EncodeUtils from 'src/app/utility/encode-utils';
import { TerminalDataService } from 'src/app/private/terminal/services/terminal-data.service';

export interface ITaxDocument {
  CHECK?: {
    CHECKHEAD: CHECKHEAD;
    CHECKTOTAL: any;
    CHECKBODY: any;
    CHECKPAY: any;
    CHECKTAX: any;
  };
  ZREP?: {
    ZREPHEAD: CHECKHEAD;
  };
}

export interface CHECKHEAD {
  ORDERNUM: number;
  DOCTYPE: number;
  DOCSUBTYPE: number;
  ORDERTAXNUM: string;
  OFFLINE: string;
  REVOKELASTONLINEDOC: boolean;
  REVOKED: boolean;
  ORDERDATE: string;
  ORDERTIME: string;
}

enum DocumentTypeEnum {
  SHIFT_START,
  SHIFT_STOP,
  SHIFT_OFFLINE_START,
  SHIFT_OFFLE_STOP,
  Z_REP,
  CHECK,
  RETURN,
  SERVICEINPUT,
  SERVICEOUTPUT,
  STORN,
}

const TAX_REGISTER_KEY = '__TAX_REGISTER_KEY_';
const SHIFT_TOTALS_KEY = '__SHIFT_TOTALS_KEY_';
const TAX_REGISTER_ONLINE_DOCUMENTS_KEY =
  '__TAX_REGISTER_ONLINE_DOCUMENTS_KEY_';
const TAX_REGISTER_OFFLINE_DOCUMENTS_KEY =
  '__TAX_REGISTER_OFFLINE_DOCUMENTS_KEY_';

const DOCCLASS_CHECK = 0;
const DOCCLASS_ZREP = 1;

const DOCTYPE_SALE_GOODS = 0;
const DOCTYPE_TRANSFER_FUNDS = 1;
const DOCTYPE_CURRENCY_EXCHANGE = 2;
const DOCTYPE_CASH_WITHDRAWAL = 3;
const DOCTYPE_OPEN_SHIFT = 100;
const DOCTYPE_CLOSE_SHIFT = 101;
const DOCTYPE_OFFLINE_BEGIN = 102;
const DOCTYPE_OFFLINE_END = 103;

const DOCSUBTYPE_CHECK_GOODS = 0;
const DOCSUBTYPE_CHECK_RETURN = 0;
const DOCSUBTYPE_SERVICE_DEPOSIT = 0;
const DOCSUBTYPE_ADDITIONAL_DEPOSIT = 0;
const DOCSUBTYPE_SERVICE_ISSUE = 0;
const DOCSUBTYPE_CHECK_STORNO = 0;

export interface TransactionsRegistrarState
  extends TransactionsRegistrarStateResponse {
  OfflineSession: boolean;
  Offline: boolean;
}

@Injectable({ providedIn: 'root' })
export class TaxRegisterService {
  readonly TAX_REGISTER_SHIFT_DICTIONSRY = 'TAX_REGISTER_SHIFT_DICTIONSRY';
  public viewColumns = 6;
  private _taxRegistersState: {
    [key: string]: any;
  } = {};
  private _shiftTotals: {
    [key: string]: LastShiftTotalsResponse;
  } = {};
  private _offlineSessions = {};
  private _onlineSessions = {};

  constructor(
    private _companyStorage: CompanyStorage // private terminalData: TerminalDataService
  ) {}

  // getShift(taxRegisterFiscalNumber: number): Promise<IShiftModel> {
  //   return this._getShift(taxRegisterFiscalNumber);
  // }

  // async getLastDoc(taxRegisterFiscalNumber: number): Promise<any> {
  //   const shift = await this._getShift(taxRegisterFiscalNumber);
  //   if (!shift) {
  //     throw 'Shift not found';
  //   }

  //   shift.documents[shift.documents.length - 1];
  // }

  // async saveShift(taxRegisterFiscalNumber: number, shiftModel: IShiftModel) {
  //   let shifts = await this._companyStorage.get(
  //     this.TAX_REGISTER_SHIFT_DICTIONSRY
  //   );
  //   if (!shifts) {
  //     shifts = {};
  //   }

  //   let shift = shifts[taxRegisterFiscalNumber] || {};
  //   if (shiftModel.shift) {
  //     shift.shift = shiftModel.shift;
  //   }

  //   if (shiftModel.taxRegister) {
  //     shift.taxRegister = shiftModel.taxRegister;
  //   }

  //   if (shiftModel.lastOnlineDoc) {
  //     shift.lastOnlineDoc = shiftModel.lastOnlineDoc;
  //   }

  //   shifts[taxRegisterFiscalNumber] = shift;
  //   return this._companyStorage.set(this.TAX_REGISTER_SHIFT_DICTIONSRY, shifts);
  // }

  // async getNextLocalNum(taxRegisterFiscalNumber: number): Promise<number> {
  //   const _shift = await this._getShift(taxRegisterFiscalNumber);
  //   let _nextLocalNumber = _shift.taxRegister.NextLocalNum;

  //   return _nextLocalNumber;
  // }

  async getShiftTotals(
    taxRegisterFiscalNumber: string
  ): Promise<LastShiftTotalsResponse> {
    const result = new LastShiftTotalsResponse();
    result.Totals = new ShiftTotals();

    // const lastShiftDocs = await this._getLastShiftDocuments(
    //   taxRegisterFiscalNumber
    // );
    // for (var i = documents.length; i > 0; i--) {
    //   const key = i - 1;
    //   const _doc = documents[key];
    //   this._updateShift(result, _doc);
    // }

    return result;
  }

  async saveDocument(
    taxRegisterFiscalNumber: string,
    xmlDoc: string
  ): Promise<void> {
    const docObject = this._getDocumentObject(xmlDoc);
    const state = await this.getState(taxRegisterFiscalNumber);
    const offlineNextLocalNum = +state.OfflineNextLocalNum;
    const isOfflineSessionOpen =
      offlineNextLocalNum > 1 && state.OfflineSession;
    let _docType: number;
    let _docSubType: number;
    let _isOfflineDoc: boolean;

    if (docObject.CHECK) {
      _docType = docObject.CHECK.CHECKHEAD.DOCTYPE;
      _docSubType = docObject.CHECK.CHECKHEAD.DOCSUBTYPE;

      if (_docType == DOCTYPE_OFFLINE_BEGIN) {
        if (isOfflineSessionOpen) {
          throw 'Код помилки: 5 OfflineSessionOpened\r\nОфлайн сессію для ПРРО наразі відкрито';
        }

        _isOfflineDoc = true;
      } else if (_docType == DOCTYPE_OFFLINE_END) {
        if (!isOfflineSessionOpen) {
          throw 'Код помилки: 15 OfflineSessionNotOpened\r\nОфлайн сессію для ПРРО наразі не відкрито';
        }
        _isOfflineDoc = this._isDocOffline(docObject);
      } else {
        _isOfflineDoc = this._isDocOffline(docObject);
      }
    }

    if (docObject.ZREP) {
      _isOfflineDoc = this._isDocOffline(docObject);
    }

    if (_docType == DOCTYPE_SALE_GOODS) {
      if (state.ShiftState == 0) {
        throw 'Код помилки: 5 ShiftNotOpened\r\nЗміну для ПРРО наразі не відкрито';
      }

      if (state.ZRepPresent) {
        throw 'Код помилки: 8 ZRepAlreadyRegistered \r\nZ-звіт наразі зареєстрований для поточної зміни';
      }

      if (isOfflineSessionOpen && !_isOfflineDoc) {
        throw 'Код помилки: 13 OnlineDocumentNotAllowedInOfflineSession\r\nОфлайн сессія';
      }
    } else if (_docType == DOCTYPE_OFFLINE_BEGIN) {
      if (isOfflineSessionOpen) {
        throw 'Код помилки: 14 OfflineSessionAlreadyOpened\r\nОффлайн сессія вже відкрита';
      }
    } else if (_docType == DOCTYPE_OFFLINE_END) {
      if (!isOfflineSessionOpen) {
        throw 'Код помилки: 15 OfflineSessionNotOpened\r\nОффлайн сессія наразі не відкрита';
      }
    } else if (_docType == DOCTYPE_OPEN_SHIFT && offlineNextLocalNum != 2) {
      if (state.ShiftState == 1) {
        throw 'Код помилки: 4 ShiftAlreadyOpened \r\nЗміну для ПРРО наразі відкрито';
      }
    } else if (_docType == DOCTYPE_CLOSE_SHIFT) {
      if (state.ShiftState == 0) {
        throw 'Код помилки: 5 ShiftNotOpened\r\nЗміну для ПРРО наразі не відкрито';
      }
    }

    if (_isOfflineDoc) {
      await this._saveOfflineDoc(taxRegisterFiscalNumber, xmlDoc);
    } else {
      await this.saveOnlineDoc(taxRegisterFiscalNumber, xmlDoc);
    }

    // const settings = this.terminalData.terminalSettings();
    // await this.taxRegisterWorker.saveShiftDocument(
    //   xmlDoc,
    //   settings.store.company_id,
    //   settings.store.id,
    //   state.ShiftId
    // );
  }

  private _isDocOffline(docObject: any): boolean {
    if (docObject.CHECK) {
      return docObject.CHECK.CHECKHEAD.OFFLINE == 'true';
    }

    if (docObject.ZREP) {
      return docObject.ZREP.ZREPHEAD.OFFLINE == 'true';
    }
  }

  private _isDocTesting(docObject: any): boolean {
    if (docObject.CHECK) {
      return docObject.CHECK.CHECKHEAD.TESTING == 'true';
    }

    if (docObject.ZREP) {
      return docObject.ZREP.ZREPHEAD.TESTING == 'true';
    }
  }

  private _getDocType(docObject: ITaxDocument): number {
    if (docObject.CHECK) {
      return docObject.CHECK.CHECKHEAD.DOCTYPE;
    }

    if (docObject.ZREP) {
      return docObject.ZREP.ZREPHEAD.DOCTYPE;
    }
  }

  private _hasOrderNumber(xmlDoc: string) {
    const parts = xmlDoc.split('<ORDERTAXNUM>');
    return parts.length > 1;
  }

  getLastOnlineDoc(taxRegisterFiscalNumber: string) {
    const onlineDocs = this._onlineSessions[taxRegisterFiscalNumber] || [];
    if (!onlineDocs || !onlineDocs.length) {
      return null;
    }

    const lastOnlineDocObject = this._getDocumentObject(
      onlineDocs[onlineDocs.length - 1]
    );

    return lastOnlineDocObject;
  }

  async getShiftDocs(taxRegisterFiscalNumber: string) {
    return [
      ...(await this._getOnlineShiftDocs(taxRegisterFiscalNumber)),
      ...(await this._getOfflineShiftDocs(taxRegisterFiscalNumber)),
    ];
  }

  async getShiftDocsObj(taxRegisterFiscalNumber: string) {
    return [
      ...(await this._getOnlineShiftDocs(taxRegisterFiscalNumber)),
      ...(await this._getOfflineShiftDocs(taxRegisterFiscalNumber)),
    ].map((doc) => this._getDocumentObject(doc));
  }

  async getOfflineParts(taxRegisterFiscalNumber: string) {
    return [await this._getOfflineShiftDocs(taxRegisterFiscalNumber)];
  }

  async getOfflineDocs(
    taxRegisterFiscalNumber: string
  ): Promise<DocumentItem[]> {
    const docs = await this._getOfflineShiftDocs(taxRegisterFiscalNumber);
    const documentItems = docs.map((d) => {
      const docObject = this._getDocumentObject(d);
      const docHead = docObject.CHECK
        ? docObject.CHECK.CHECKHEAD
        : docObject.ZREP.ZREPHEAD;

      const documentOfflineItem = new DocumentItem();
      documentOfflineItem.NumFiscal = docHead.ORDERTAXNUM;
      documentOfflineItem.NumLocal = docHead.ORDERNUM;
      documentOfflineItem.DocDateTime = this._isCheckTimeISOStr(docHead);
      console.log(documentOfflineItem.DocDateTime);

      documentOfflineItem.DocClass = docObject.CHECK ? 'Check' : 'ZRep';
      documentOfflineItem.CheckDocType = this._getCheckDocTypeName(docHead);
      documentOfflineItem.CheckDocSubType =
        this._getCheckDocSubTypeName(docHead);
      documentOfflineItem.Revoked = this._isCheckRevoked(docHead);
      documentOfflineItem.Storned = this._isCheckStorned(docHead);

      return documentOfflineItem;
    });

    return documentItems;
  }

  private _getCheckDocTypeName(
    docHead: CHECKHEAD
  ):
    | 'OpenShift'
    | 'SaleGoods'
    | 'OfflineBegin'
    | 'OfflineEnd'
    | 'CloseShift'
    | '' {
    if (docHead.DOCTYPE == TaxDocumentType.SALE_GOODS) {
      return 'SaleGoods';
    }
    if (docHead.DOCTYPE == TaxDocumentType.OPEN_SHIFT) {
      return 'OpenShift';
    }
    if (docHead.DOCTYPE == TaxDocumentType.CLOSE_SHIFT) {
      return 'CloseShift';
    }
    if (docHead.DOCTYPE == TaxDocumentType.OOFFLINE_BEGIN) {
      return 'OfflineBegin';
    }
    if (docHead.DOCTYPE == TaxDocumentType.OFFLINE_END) {
      return 'OfflineEnd';
    }
    return '';
  }

  private _getCheckDocSubTypeName(
    docHead: CHECKHEAD
  ):
    | 'CheckGoods'
    | 'ServiceDeposit'
    | 'ServiceIssue'
    | 'AdditionalDeposit'
    | 'CheckReturn'
    | 'CheckStorno' {
    if (docHead.DOCSUBTYPE == TaxDocumentSubType.SERVICE_DEPOSIT) {
      return 'ServiceDeposit';
    }
    if (docHead.DOCSUBTYPE == TaxDocumentSubType.SERVICE_ISSUE) {
      return 'ServiceIssue';
    }
    if (docHead.DOCSUBTYPE == TaxDocumentSubType.ADDITIONAL_DEPOSIT) {
      return 'AdditionalDeposit';
    }
    if (docHead.DOCSUBTYPE == TaxDocumentSubType.CHECK_RETURN) {
      return 'CheckReturn';
    }
    if (docHead.DOCSUBTYPE == TaxDocumentSubType.CHECK_STORNO) {
      return 'CheckStorno';
    }

    return 'CheckGoods';
  }

  private _isCheckRevoked(docHead: CHECKHEAD): boolean {
    return docHead.REVOKED === true || <any>docHead.REVOKED === 'true';
  }
  private _isCheckStorned(docHead: CHECKHEAD): boolean {
    return (
      docHead.REVOKELASTONLINEDOC === true ||
      <any>docHead.REVOKELASTONLINEDOC === 'true'
    );
  }
  private _isCheckTimeISOStr(docHead: CHECKHEAD): string {
    // 2024-05-15T12:48:31
    const dateStr = docHead.ORDERDATE.toString(); // 19112023 19-11-2023
    const timeStr = docHead.ORDERTIME.toString(); // 13303 1:33:03

    const year = dateStr.substring(dateStr.length - 4, dateStr.length);
    const month = dateStr.substring(dateStr.length - 6, dateStr.length - 4);
    const day = dateStr.substring(0, dateStr.length - 6);
    const sec = timeStr.substring(timeStr.length - 2, timeStr.length);
    const mins = timeStr.substring(timeStr.length - 4, timeStr.length - 2);
    const hours = timeStr.substring(0, timeStr.length - 4);

    return `${year}-${month}-${day}T${hours}:${mins}:${sec}`;
  }

  updateTaxOrderNum(xmlDoc: string, taxOrderNum: string): string {
    const hasOrderNumber = this._hasOrderNumber(xmlDoc);
    if (hasOrderNumber) {
      return xmlDoc;
    }

    return xmlDoc.replace(
      '</VER>',
      `</VER><ORDERTAXNUM>${taxOrderNum}</ORDERTAXNUM>`
    );
  }

  async revokeLastOnline(
    taxRegisterFiscalNumber: string,
    nextLocalNum: number
  ) {
    const offlineDocs = await this._getOfflineShiftDocs(
      taxRegisterFiscalNumber
    );
    if (!offlineDocs || offlineDocs.length < 2) {
      return;
    }

    let firstDoc: string = offlineDocs[0];
    firstDoc = firstDoc.replace(
      '<REVOKELASTONLINEDOC>true</REVOKELASTONLINEDOC>',
      ''
    );

    const localNumbers = this.parseLocalNumbers(firstDoc);
    if (nextLocalNum != localNumbers.NextLocalNum - 1) {
      return;
    }

    const onlineDocs = await this._getOnlineShiftDocs(taxRegisterFiscalNumber);
    if (onlineDocs.length) {
      const lastOnlineDoc: string = onlineDocs[onlineDocs.length - 1];
      const hasOrderNumber = this._hasOrderNumber(lastOnlineDoc);
      if (hasOrderNumber) {
        return;
      }

      const lastOnlineDocObject = this._getDocumentObject(lastOnlineDoc);
      const lastOnlineDocType = this._getDocType(lastOnlineDocObject);
      if (
        lastOnlineDocType == DOCTYPE_OPEN_SHIFT ||
        lastOnlineDocType == DOCTYPE_CLOSE_SHIFT
      ) {
        return;
      }
    }

    const secondOfflintDoc = offlineDocs[1];
    const secondOfflintDocObject = this._getDocumentObject(secondOfflintDoc);
    const docType = this._getDocType(secondOfflintDocObject);
    if (docType != DOCTYPE_SALE_GOODS && docType != undefined) {
      return;
    }

    firstDoc = firstDoc.replace(
      '</CASHREGISTERNUM>',
      '</CASHREGISTERNUM><REVOKELASTONLINEDOC>true</REVOKELASTONLINEDOC>'
    );

    offlineDocs[0] = firstDoc;
    this._offlineSessions[taxRegisterFiscalNumber] = offlineDocs;
    const storageKey = this._getTaxRegisterOfflineDocsKey(
      taxRegisterFiscalNumber
    );
    await this._companyStorage.set(storageKey, offlineDocs);
  }

  private async _getOfflineShiftDocs(
    taxRegisterFiscalNumber: string
  ): Promise<string[]> {
    if (
      this._offlineSessions[taxRegisterFiscalNumber] &&
      this._offlineSessions[taxRegisterFiscalNumber].length
    ) {
      return this._offlineSessions[taxRegisterFiscalNumber];
    }

    const storageKey = this._getTaxRegisterOfflineDocsKey(
      taxRegisterFiscalNumber
    );
    const storedOfflineDocs = await this._companyStorage.get(storageKey);
    if (storedOfflineDocs && storedOfflineDocs.length) {
      this._offlineSessions[taxRegisterFiscalNumber] = storedOfflineDocs;
    }

    return storedOfflineDocs || [];
  }

  async _getOnlineShiftDocs(taxRegisterFiscalNumber: string) {
    if (
      this._onlineSessions[taxRegisterFiscalNumber] &&
      this._onlineSessions[taxRegisterFiscalNumber].length
    ) {
      return this._onlineSessions[taxRegisterFiscalNumber];
    }

    const storageKey = this._getTaxRegisterOnlineDocsKey(
      taxRegisterFiscalNumber
    );
    const storedOnlineDocs = await this._companyStorage.get(storageKey);
    if (storedOnlineDocs && storedOnlineDocs.length) {
      this._onlineSessions[taxRegisterFiscalNumber] = storedOnlineDocs;
    }

    return storedOnlineDocs || [];
  }

  async saveOnlineDoc(taxRegisterFiscalNumber: string, xmlDoc: string) {
    const storageKey = this._getTaxRegisterOnlineDocsKey(
      taxRegisterFiscalNumber
    );

    const onlineDocs = await this._getOnlineShiftDocs(taxRegisterFiscalNumber);
    onlineDocs.push(xmlDoc);
    this._onlineSessions[taxRegisterFiscalNumber] = onlineDocs;
    await this._companyStorage.set(storageKey, onlineDocs);
  }

  private async _saveOfflineDoc(
    taxRegisterFiscalNumber: string,
    xmlDoc: string
  ) {
    const storageKey = this._getTaxRegisterOfflineDocsKey(
      taxRegisterFiscalNumber
    );

    const onlineDocs = await this._getOfflineShiftDocs(taxRegisterFiscalNumber);
    onlineDocs.push(xmlDoc);
    this._offlineSessions[taxRegisterFiscalNumber] = onlineDocs;
    await this._companyStorage.set(storageKey, onlineDocs);
  }

  async getLastDocHash(taxRegisterFiscalNumber: string) {
    const doc = await this._getLastShiftDoc(taxRegisterFiscalNumber);
    if (!doc) {
      return null;
    }

    const win1251string = EncodeUtils.unicodeToWin1251(doc);
    const win1251array = EncodeUtils.stringToArrayBuffer(win1251string);

    const res = sha256.create();
    res.update(win1251array);
    const res3 = res.hex();
    return res3;
  }

  async finishOffline(
    taxRegisterFiscalNumber: string,
    data?: { OfflineSeed: number; OfflineSessionId: number }
  ) {
    if (!data) {
      return this._clearOfflineDocs(taxRegisterFiscalNumber);
    }

    if (!data.OfflineSeed || !data.OfflineSessionId) {
      throw 'OfflineSeed not provided. OfflineSessionId not provided.';
    }

    const state = await this.getState(taxRegisterFiscalNumber);

    state.OfflineSeed = data.OfflineSeed.toString();
    state.OfflineSessionId = data.OfflineSessionId.toString();
    state.OfflineNextLocalNum = '1';

    await this._clearOfflineDocs(taxRegisterFiscalNumber);
    const key = this._getTaxRegisterKey(taxRegisterFiscalNumber);
    await this._companyStorage.set(key, state);
  }

  convertNumArrayToHexString(list) {
    var result = [];
    for (var i = 0; i < list.length; i++) {
      result.push(list[i].toString(16));
    }
    return result.join('');
  }
  private async _getLastShiftDoc(
    taxRegisterFiscalNumber: string
  ): Promise<string> {
    const offlineDocs = await this._getOfflineShiftDocs(
      taxRegisterFiscalNumber
    );
    if (offlineDocs && offlineDocs.length) {
      return offlineDocs[offlineDocs.length - 1];
    }

    const onlineDocs = await this._getOnlineShiftDocs(taxRegisterFiscalNumber);
    if (onlineDocs && onlineDocs.length) {
      return onlineDocs[onlineDocs.length - 1];
    }

    return null;
  }

  private async _getLastShiftDocs(
    taxRegisterFiscalNumber: string,
    docsCount: number
  ): Promise<string[]> {
    const offlineDocs = await this._getOfflineShiftDocs(
      taxRegisterFiscalNumber
    );
    if (offlineDocs && offlineDocs.length) {
      const _docsCount =
        offlineDocs.length >= docsCount ? docsCount : offlineDocs.length;
      return offlineDocs.slice(
        offlineDocs.length - _docsCount,
        offlineDocs.length
      );
    }

    const onlineDocs = await this._getOnlineShiftDocs(taxRegisterFiscalNumber);
    if (onlineDocs && onlineDocs.length) {
      const _docsCount =
        onlineDocs.length >= docsCount ? docsCount : onlineDocs.length;
      return onlineDocs.slice(
        onlineDocs.length - _docsCount,
        onlineDocs.length
      );
    }

    return [];
  }

  private _getDocumentObject(xmlDoc: string): ITaxDocument {
    return xml2json(xmlDoc);
  }

  private async _updateShiftTotals(
    taxRegisterFiscalNumber: string,
    document: any
  ): Promise<void> {
    // this._syncTaxRegister(orgCode, taxRegisterFiscalNumber, taxRegister);
    // return this._updateTaxRegister(taxRegisterFiscalNumber, taxRegister);
  }

  private _updateShift(lastShift: LastShiftTotalsResponse, document: any) {
    const docType = this._getDocumentType(document);

    if (docType == DocumentTypeEnum.SHIFT_START) {
    }

    if (docType == DocumentTypeEnum.SHIFT_STOP) {
    }

    if (docType == DocumentTypeEnum.Z_REP) {
    }

    if (docType == DocumentTypeEnum.CHECK) {
      // add totals
    }

    if (docType == DocumentTypeEnum.RETURN) {
      // remove totals
    }

    if (docType == DocumentTypeEnum.SERVICEINPUT) {
      const doc = document as any;
      lastShift.Totals.ServiceInput =
        lastShift.Totals.ServiceInput + doc.CHECKBODY.SERVICEINPUT;
    }

    if (docType == DocumentTypeEnum.SERVICEOUTPUT) {
      const doc = document as any;
      lastShift.Totals.ServiceOutput =
        lastShift.Totals.ServiceInput + doc.CHECKBODY.SERVICEOUTPUT;
    }
  }

  // async saveLastOnlineDocument(
  //   taxRegisterFiscalNumber: string,
  //   taxDocument: any
  // ): Promise<void> {
  //   const key = this._getTaxRegisterLastOnlineDocumentKey(
  //     taxRegisterFiscalNumber
  //   );
  //   return this._companyStorage.set(key, taxDocument);
  // }

  async syncShiftTotals(
    taxRegisterFiscalNumber: string,
    shiftTotals: LastShiftTotalsResponse
  ): Promise<void> {
    if (!shiftTotals) {
      throw 'Shift totals not provided';
    }

    const key = this._getShiftTotalsKey(taxRegisterFiscalNumber);
    this._shiftTotals[taxRegisterFiscalNumber] = shiftTotals;
    await this._clearOnlineDocs(taxRegisterFiscalNumber);
    return this._companyStorage.set(key, shiftTotals);
  }

  async updateTaxRegister(
    taxRegisterFiscalNumber: string,
    taxRegister: TransactionsRegistrarStateResponse
  ): Promise<void> {
    // this._syncTaxRegister(orgCode, taxRegisterFiscalNumber, taxRegister);
    return this._updateTaxRegister(taxRegisterFiscalNumber, taxRegister);
  }

  async stopTaxRegister(taxRegisterFiscalNumber: string): Promise<void> {
    const key = this._getTaxRegisterKey(taxRegisterFiscalNumber);
    return this._companyStorage.remove(key);
  }

  async getState(
    taxRegisterFiscalNumber: string
  ): Promise<TransactionsRegistrarState> {
    const key = this._getTaxRegisterKey(taxRegisterFiscalNumber);
    let _state: TransactionsRegistrarState = this._taxRegistersState[key];
    if (!_state) {
      _state = await this._companyStorage.get(key);
      this._taxRegistersState[key] = _state;
      if (!_state) {
        throw {
          error:
            'Програмний реєстратор не знайдено. ФН ' + taxRegisterFiscalNumber,
        };
      }
    }

    const state: TransactionsRegistrarState = JSON.parse(
      JSON.stringify(_state)
    );
    let lastDocs = await this.getShiftDocs(taxRegisterFiscalNumber);
    if (!lastDocs.length) {
      state.OfflineSession = +state.OfflineNextLocalNum > 1;
      return state;
    }

    let lastDoc = lastDocs[lastDocs.length - 1];
    const localNumbers = this.parseLocalNumbers(lastDoc);
    state.NextLocalNum = localNumbers.NextLocalNum;
    if (localNumbers.OfflineNextLocalNum) {
      state.OfflineNextLocalNum = localNumbers.OfflineNextLocalNum;
    }

    const hasOrderNumber = this._hasOrderNumber(lastDoc);
    if (!hasOrderNumber) {
      if (lastDocs.length == 1) {
        return state;
      } else {
        lastDocs = lastDocs.splice(0, lastDocs.length - 1);
        lastDoc = lastDocs[lastDocs.length - 1];
      }
    }

    let lastDocObject = this._getDocumentObject(lastDoc);
    const _isOffline = this._isDocOffline(lastDocObject);
    state.OfflineSession = _isOffline;
    state.Offline = _isOffline;
    state.Testing = this._isDocTesting(lastDocObject);

    if (lastDocObject.ZREP) {
      state.ZRepPresent = true;
      state.ShiftState = 1;
    }

    if (lastDocObject.CHECK) {
      const _docType = lastDocObject.CHECK.CHECKHEAD.DOCTYPE;

      if (
        _docType == DOCTYPE_OFFLINE_BEGIN ||
        _docType == DOCTYPE_OFFLINE_END
      ) {
        if (lastDocs.length > 1) {
          let preLastDoc = lastDocs[lastDocs.length - 2];
          const hasOrderNumber = this._hasOrderNumber(preLastDoc);
          if (!hasOrderNumber) {
            if (lastDocs.length == 2) {
              return state;
            } else {
              preLastDoc = lastDocs[lastDocs.length - 3];
            }
          }

          let preLastDocObject = this._getDocumentObject(preLastDoc);
          if (preLastDocObject.ZREP) {
            state.ZRepPresent = true;
            state.ShiftState = 1;
          } else {
            const _preLastDocType = preLastDocObject.CHECK.CHECKHEAD.DOCTYPE;
            if (_preLastDocType == DOCTYPE_CLOSE_SHIFT) {
              state.ZRepPresent = true;
              state.ShiftState = 0;
            } else {
              state.ZRepPresent = false;
              state.ShiftState = 1;
            }
          }
        }

        if (_docType == DOCTYPE_OFFLINE_BEGIN) {
          state.OfflineSession = true;
          state.Offline = true;
        }

        if (_docType == DOCTYPE_OFFLINE_END) {
          state.OfflineSession = false;
          state.Offline = true;
          state.OfflineNextLocalNum = '1';
          state.Testing = this._isDocTesting(lastDocObject);
        }
      } else {
        if (_docType == DOCTYPE_CLOSE_SHIFT) {
          state.ZRepPresent = true;
          state.ShiftState = 0;
        } else {
          state.ZRepPresent = false;
          state.ShiftState = 1;
        }
      }
    }

    return state;
  }

  parseLocalNumbers(xmlDoc: string): {
    OfflineNextLocalNum: string;
    NextLocalNum: number;
  } {
    const docObject = this._getDocumentObject(xmlDoc);
    const result = {
      OfflineNextLocalNum: null,
      NextLocalNum: null,
    };
    let orderTaxNum = null;

    if (docObject.CHECK) {
      result.NextLocalNum = +docObject.CHECK.CHECKHEAD.ORDERNUM + 1;
      orderTaxNum = docObject.CHECK.CHECKHEAD.ORDERTAXNUM;
    }

    if (docObject.ZREP) {
      result.NextLocalNum = +docObject.ZREP.ZREPHEAD.ORDERNUM + 1;
      orderTaxNum = docObject.ZREP.ZREPHEAD.ORDERTAXNUM;
    }

    if (!orderTaxNum) {
      return result;
    }

    const offlineLocalNum = orderTaxNum.toString().split('.');
    if (offlineLocalNum.length && offlineLocalNum.length == 3) {
      result.OfflineNextLocalNum = (+offlineLocalNum[1] + 1).toString();
    }
    return result;
  }

  // async getNextLocalNum(taxRegisterFiscalNumber: string) {
  //   const lastDoc = await this._getLastShiftDoc(taxRegisterFiscalNumber);
  //   if (lastDoc) {
  //     const docObject = this._getDocumentObject(lastDoc);

  //     const result = {
  //       OfflineNextLocalNum: ,
  //       NextLocalNum: +docObject.CHECK.CHECKHEAD.ORDERNUM + 1,
  //     };

  //     const orderTaxNum = docObject.CHECK.CHECKHEAD.ORDERTAXNUM;
  //     const offlineLocalNum = orderTaxNum.split('.');
  //     if(offlineLocalNum.length){
  //       result.OfflineNextLocalNum = +offlineLocalNum[1] + 1
  //     }
  //     return result;
  //   } else {
  //     const state = await this.getState(taxRegisterFiscalNumber);
  //     return {
  //       OfflineNextLocalNum: state.OfflineNextLocalNum,
  //       NextLocalNum: state.NextLocalNum,
  //     };
  //   }
  // }

  // private async _getDocuments(
  //   taxRegisterFiscalNumber: string
  // ): Promise<any[] | any[]> {
  //   const key = this._getTaxRegisterDocumentsKey(taxRegisterFiscalNumber);
  //   const documents = await this._companyStorage.get(key);

  //   console.log(documents);

  //   if (!documents) {
  //     return [];
  //   }

  //   return documents;
  // }

  // private async _saveDocuments(
  //   taxRegisterFiscalNumber: string,
  //   documents: any[]
  // ): Promise<void> {
  //   if (!documents || !documents.length) {
  //     throw 'documents object invalid';
  //   }

  //   const key = this._getTaxRegisterDocumentsKey(taxRegisterFiscalNumber);
  //   return this._companyStorage.set(key, documents);
  // }

  private async _updateTaxRegister(
    taxRegisterFiscalNumber: string,
    taxRegister: TransactionsRegistrarStateResponse
  ): Promise<void> {
    if (!taxRegister) {
      throw 'TaxRegister state not provided';
    }

    const key = this._getTaxRegisterKey(taxRegisterFiscalNumber);
    this._taxRegistersState[key] = taxRegister;
    await this._clearOnlineDocs(taxRegisterFiscalNumber);
    return this._companyStorage.set(key, taxRegister);
  }

  private async _clearOnlineDocs(taxRegisterFiscalNumber: string) {
    this._onlineSessions[taxRegisterFiscalNumber] = [];
    const storageKey = this._getTaxRegisterOnlineDocsKey(
      taxRegisterFiscalNumber
    );

    await this._companyStorage.set(storageKey, []);
  }

  private async _clearOfflineDocs(taxRegisterFiscalNumber: string) {
    this._offlineSessions[taxRegisterFiscalNumber] = [];
    const storageKey = this._getTaxRegisterOfflineDocsKey(
      taxRegisterFiscalNumber
    );

    await this._companyStorage.set(storageKey, []);
  }

  // private async _syncTaxRegister(
  //   orgCode: string,
  //   taxRegisterFiscalNumber: number,
  //   taxRegister: TransactionsRegistrarStateResponse
  // ): Promise<void> {
  //   const terminalSettings = this.terminalDataService.terminalSettings();
  //   this.httpClient
  //     .put<void>(
  //       `${this._taxApiEndpoint}/TaxRegister/State/${taxRegisterFiscalNumber}`,
  //       taxRegister,
  //       {
  //         headers: new HttpHeaders()
  //           .set('companyId', terminalSettings.store.company_id)
  //           .set('orgCode', orgCode),
  //       }
  //     )
  //     .subscribe();
  // }

  private _getTaxRegisterKey(taxRegisterFiscalNumber: string) {
    return `${TAX_REGISTER_KEY}_${taxRegisterFiscalNumber}`;
  }

  private _getTaxRegisterOnlineDocsKey(taxRegisterFiscalNumber: string) {
    return `${TAX_REGISTER_ONLINE_DOCUMENTS_KEY}_${taxRegisterFiscalNumber}`;
  }

  private _getTaxRegisterOfflineDocsKey(taxRegisterFiscalNumber: string) {
    return `${TAX_REGISTER_OFFLINE_DOCUMENTS_KEY}_${taxRegisterFiscalNumber}`;
  }

  private _getShiftTotalsKey(taxRegisterFiscalNumber: string) {
    return `${SHIFT_TOTALS_KEY}_${taxRegisterFiscalNumber}`;
  }

  // private _getTaxRegisterLastOnlineDocumentKey(
  //   taxRegisterFiscalNumber: string
  // ) {
  //   return `${TAX_REGISTER_LAST_ONLINE_DOCUMENT_KEY}_${taxRegisterFiscalNumber}`;
  // }

  private _getDocumentType(document: any): DocumentTypeEnum {
    return DocumentTypeEnum.SHIFT_START;
  }
}
