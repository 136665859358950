import { Component } from '@angular/core';
import { TerminalService } from './services/terminal.service';
import { Observable } from 'rxjs';
import DateUtils from 'src/app/utility/date-utils';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './terminal-sessions-dialog.component.html',
})
export class TerminalSessionsComponent {
  loading = false;
  service: TerminalService;
  userSessions: any[] = [];
  hasActiveSession = false;
  lastSession: any;
  userSessionsSub: Observable<any[]>;
  openActive = false;

  constructor(private _modalCtrl: ModalController) {
    this._loadSessions();
  }

  private _loadSessions() {
    this.loading = true;
    this.userSessionsSub.subscribe(
      (res) => {
        let activeSession = null;
        this.hasActiveSession = res && res.length > 0 && !res[0].end;

        if (res && res.length > 0) this.lastSession = res[0];

        if (this.hasActiveSession) {
          activeSession = res[0];

          const sessionDuration = DateUtils.differenceInHours(
            activeSession.start,
            activeSession.end
          );

          if (sessionDuration < 10 && this.openActive) {
            console.log(activeSession);
            this._modalCtrl.dismiss(activeSession);
          }
        }

        this.userSessions = res;
        this.loading = false;
      },
      (err) => this._modalCtrl.dismiss()
    );
  }

  selectSession(session: any) {
    this._modalCtrl.dismiss(session);
  }

  closeSession = (sessionId: number) => {
    this.loading = true;
    // this._service.closeSession(sessionId).subscribe(
    //   () => {
    //     this.loading = false;
    //     this.dialogRef.close();
    //   },
    //   null,
    //   () => (this.loading = false)
    // );
  };

  startSession = () => {
    this.loading = true;
    // this._service.createSession().subscribe(
    //   (session) => {
    //     this.loading = false;
    //     this.dialogRef.close(session);
    //   },
    //   (err) => {
    //     this.loading = false;
    //     // this.dialogRef.close();
    //   }
    // );
  };

  openSession = (sessionId: number) => {
    this.loading = true;
    // this._service.openSession(sessionId).subscribe(
    //   (session) => {
    //     this.loading = false;
    //     this.dialogRef.close(session);
    //   },
    //   (err) => {
    //     this.loading = false;
    //     // this.dialogRef.close();
    //   }
    // );
  };

  // openWorkShiftReport(workAreaId: number) {
  //   this._reportsService.openworkShiftReportDialog(workAreaId).subscribe();
  // }

  close() {
    this._modalCtrl.dismiss();
  }
}
