import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import {
  BehaviorSubject,
  concat,
  from,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { ApiV1Service } from 'src/app/services/api-v1.service';
import { SettingsService } from 'src/app/services/settings.service';
import jwt_decode from 'jwt-decode';
import { AuthService } from 'src/app/services/auth.service';
import { Device } from '@ionic-native/device/ngx';
import { StorageService } from 'src/app/services/storage.service';

export interface ICompanyModel {}

const COMPANY_KEY = '__app_company_';
const NT_USER_SESSION_ID = 'NT_USER_SESSION_ID';
const CONNECTION_TOKEN = 'CONNECTION_TOKEN';
const CURRENT_DEVICE_TOKEN = 'CURRENT_DEVICE_TOKEN';
const DEMO_DEVICE_ID = '7c886001-04af-48df-b57b-393796969751';
const NT_POS_AUTHORIZED_USER = 'NT_POS_AUTHORIZED_USER';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  private get baseUrl() {
    return this._settingsService.api_endpoint;
  }

  private get baseDevUrl() {
    return `${this._settingsService.api_endpoint}/dev`;
  }

  public connectionToken: Observable<any>;
  private _connectionTokenData = new BehaviorSubject(null);

  private _deviceToken = new BehaviorSubject(null);
  public tenantName: Observable<string>;
  public deviceToken: Observable<string>;

  constructor(
    private _settingsService: SettingsService,
    private api: ApiV1Service,
    private _storage: StorageService,
    private plt: Platform,
    private _router: Router,
    private http: HttpClient,
    private _authService: AuthService,
    private device: Device
  ) {
    this._loadStoredData();
  }

  private _loadStoredData() {
    const platformObs = from(this.plt.ready());

    this.connectionToken = platformObs.pipe(
      switchMap(() => {
        return from(this._storage.get(CONNECTION_TOKEN));
      }),
      map((token) => {
        if (token) {
          this._connectionTokenData.next(token);
          return true;
        } else {
          return false;
        }
      })
    );

    this.tenantName = platformObs.pipe(
      switchMap(() => this.deviceToken),
      map((deviceToken) => {
        if (deviceToken) {
          const ticket = jwt_decode<any>(deviceToken);
          return ticket.TenantName;
        } else {
          return null;
        }
      })
    );

    this.deviceToken = platformObs.pipe(
      switchMap(() => {
        const deviceToken = this._deviceToken.value;
        if (deviceToken) {
          return of(deviceToken);
        } else {
          return from(this._storage.get(CURRENT_DEVICE_TOKEN));
        }
      }),
      map((deviceToken) => {
        if (deviceToken) {
          this._deviceToken.next(deviceToken);
          return deviceToken;
        } else {
          return null;
        }
      })
    );
  }

  getCompany(): Observable<ICompanyModel> {
    return from(this._storage.get(COMPANY_KEY));
  }

  saveCompany(comapny: ICompanyModel): Observable<void> {
    return from(this._storage.set(COMPANY_KEY, comapny));
  }

  removeCompany(): Observable<void> {
    return from(this._storage.remove(COMPANY_KEY));
  }

  selectCompany(tenantId: string): Observable<Object> {
    return this.api.selectTenants(tenantId).pipe(
      mergeMap((sessionId) => {
        if (sessionId) {
          this._settingsService.registerDevice(tenantId);
          this.loginDevice(tenantId).subscribe();

          return this._configureUserSession(sessionId);
        } else {
          return of(null);
        }
      })
    );
  }

  loginDevice(tenantId: string): Observable<any> {
    if (!this.device || !this.device.uuid) {
      console.log('ionic device is not provided');
    }

    let deviceUuid = this.device.uuid;
    if (this._authService.isDemoLogin) {
      deviceUuid = DEMO_DEVICE_ID;
    }

    const request = {
      mobileDevice: {
        uuid: deviceUuid,
        isVirtual: this.device.isVirtual,
        model: this.device.model,
        serial: this.device.serial,
      },
      tenantId: tenantId,
    };

    return this.http.post<any>(`${this.baseDevUrl}/login`, request).pipe(
      mergeMap((token: any) => {
        this._storage.set(CURRENT_DEVICE_TOKEN, token);
        return of(jwt_decode<any>(token));
      })
    );
  }

  trySelectCompany(): Observable<void> {
    return this.getCompanySessionId().pipe(
      mergeMap((sessionId) => {
        if (!sessionId || sessionId === 'null') {
          return this.api.getTenants().pipe(
            mergeMap((tenants) => {
              if (tenants.length === 0) {
                this._router.navigate(['not-found']);
              } else if (tenants.length === 1) {
                return this.selectCompany(tenants[0].clientid).pipe(
                  map(() => {
                    this._router.navigate(['company']);
                  })
                );
              } else {
                return this._clearTerminalSettings().pipe(
                  map(() => {
                    this._router.navigate(['companies']);
                  })
                );
              }
            })
          );
        } else
          return this._configureUserSession(sessionId).pipe(
            map((res: boolean) => {
              if (res) {
                this._router.navigate(['company']);
              } else {
                this._router.navigate(['not-found']);
              }
            })
          );
      })
    );
  }

  startSingleCompanySession(): Observable<boolean> {
    return this.api.getUserSession().pipe(
      mergeMap((sessionId) => {
        if (!sessionId || sessionId === 'null') {
          return of(false);
        }

        return this._configureUserSession(sessionId);
      })
    );
  }

  private _configureUserSession(sessionId: string): Observable<boolean> {
    if (!sessionId) {
      return of(false);
    }

    return from(this._storage.set(NT_USER_SESSION_ID, sessionId));
  }

  getAndStoreCompanyToken() {
    return this._getCopmanyToken().pipe(
      mergeMap((companyToken) => {
        console.log(
          companyToken ? 'Company token received' : 'Company token NOT received'
        );
        return this._storeCompanyToken(companyToken);
      })
    );
  }

  useConnectionToken(token: string): Observable<void> {
    if (!token) {
      return of(null);
    }

    return from(this._storage.set(CONNECTION_TOKEN, token));
  }

  clearCompanyToken(): Observable<void> {
    this._connectionTokenData.next(null);
    this._storage.remove(CURRENT_DEVICE_TOKEN);
    this._deviceToken.next(null);
    return from(this._storage.remove(CONNECTION_TOKEN));
  }

  getCompanyToken(): Observable<string> {
    return from(this._storage.get(CONNECTION_TOKEN));
  }

  getCompanySessionId(): Observable<string> {
    return from(this._storage.get(NT_USER_SESSION_ID)).pipe(
      map((res) => {
        return res;
      })
    );
  }

  companyLogout() {
    return concat(
      from(this._storage.remove(NT_USER_SESSION_ID)),
      this._clearCompanyToken(),
      this.clearCompanyToken(),
      from(this._storage.remove(NT_POS_AUTHORIZED_USER)),
      this.removeCompany()
    );
  }

  private _clearTerminalSettings() {
    return from(this._storage.remove('POS_TERMINAL_SETTINGS'));
  }

  private _storeCompanyToken(companyToken: string) {
    console.log('Store company token');
    if (!companyToken) {
      console.error('Company token not provided');
      return throwError({
        error: 'Company token store error',
        description: 'Company token not provided',
      });
    } else {
      console.log('Company token stored');
      return from(this._storage.set(CONNECTION_TOKEN, companyToken));
    }
  }

  private _clearCompanyToken() {
    this._connectionTokenData.next(null);
    return from(this._storage.remove(CONNECTION_TOKEN));
  }

  private _getCopmanyToken(): Observable<string> {
    console.log('Request company token');
    return this.http.get<string>(
      `${this.baseUrl}/structure/organization/token`
    );
  }
}
