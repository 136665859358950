/// <reference types="@types/signalr-no-jquery" />
import { Injectable, EventEmitter } from '@angular/core';
import { hubConnection, Proxy } from 'signalr-no-jquery';
import { interval } from 'rxjs';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import { SettingsService } from './settings.service';
import { SharedService } from './shared.service';

export class ChatMessage {
  constructor(userId: string, userName: string, message: string) {
    this.UserId = userId;
    this.UserName = userName;
    this.Message = message;
  }

  UserId: string;
  UserName: string;
  Message: string;
}

@Injectable({ providedIn: 'root' })
export class SignalRHubConnector {
  _subscription: any;
  _hubSubscription: any;
  timer = interval(30000);
  hubConnection: Proxy;
  onConnected = new EventEmitter<Proxy>();
  ordersHubConnection: HubConnection;

  constructor(
    private _settingsService: SettingsService,
    private shared: SharedService
  ) {}

  getHubConnector() {
    if (this.hubConnection) {
      return this.hubConnection;
    }
  }

  connectToOrderHub(queryString: any): Promise<HubConnection> {
    // return new Promise((resolve, reject) => {
    //   const connection = hubConnection('https://localhost:5001', {
    //     qs: queryString,
    //   });
    //   const hub = connection.createHubProxy('OrderHub');
    //   // hub.on('onPaymentProcess', (sdsd, ddfd): void => {
    //   //   console.log('onPaymentProcess: ' + sdsd + ', ' + ddfd);
    //   // });

    //   hub.connection
    //     .start()
    //     .done(() => {
    //       console.log('Now connected, connection ID=' + connection.id);
    //       resolve(hub);
    //     })
    //     .fail((err) => {
    //       console.log('Could not connect');
    //       reject(err);
    //     });
    // });
    return Promise.resolve({} as HubConnection);

    return new Promise((resolve, reject) => {
      this.ordersHubConnection = new HubConnectionBuilder()
        .withUrl(
          this._settingsService.signal_r_endpoint,
          HttpTransportType.WebSockets
        )
        .withAutomaticReconnect()
        .build();

      this.ordersHubConnection
        .start()
        .then(() => {
          resolve(this.ordersHubConnection);
          // console.log('Connection started!');
        })
        .catch((err) => {
          reject(err);
          // console.log('Error while establishing connection :(');
        });
    });
  }

  timeout: any;

  connectToHub(hubName: string, queryString: any): Promise<Proxy> {
    // TODO add settings to be able disable hub
    // disable hub
    // this.hubConnection = { on: () => {} } as any;
    // return Promise.resolve({} as Proxy);

    return new Promise((resolve, reject) => {
      const connection = hubConnection(
        this._settingsService.signal_r_endpoint,
        {
          qs: queryString,
        }
      );
      const hub = connection.createHubProxy(hubName);
      // hub.on('onPaymentProcess', (sdsd, ddfd): void => {
      //   console.log('onPaymentProcess: ' + sdsd + ', ' + ddfd);
      // });

      this.hubConnection = hub;
      hub.on('onMessage', (msg): void => {
        this.shared.showSuccessToast(msg, 5000);
      });
      hub.connection
        .start()
        .done(() => {
          // console.log('Now connected, connection ID=' + connection.id);
          this.timer.subscribe(() => this._pingHub());
          this.onConnected.emit(hub);
          resolve(hub);
        })
        .fail((err) => {
          // console.log('Could not connect');
          reject(err);
        });

      hub.connection.disconnected(() => {
        this._reconnect();
      });
    });
  }

  private _pingHub() {
    try {
      this.hubConnection.invoke('Ping');
    } catch {
      this._reconnect();
    }
  }

  private _reconnect() {
    return;
    // console.log('hub reconnection');
    // const connection = hubConnection(this.signalRConnection, {
    //   qs: queryString,
    // });
    // const hub = connection.createHubProxy(hubName);
    // this.hubConnection = hub;
    // hub.on('onPaymentProcess', (sdsd, ddfd): void => {
    //   console.log('onPaymentProcess: ' + sdsd + ', ' + ddfd);
    // });
    // hub.on('onMessage', (msg): void => {
    //   this._snackBar.open(msg, '', { duration: 5000 });
    // });
    // hub.connection.start().done(() => {
    //   console.log('Now reconnected, connection ID=' + connection.id);
    //   this.onConnected.emit(hub);
    // });

    this.hubConnection.connection
      .start()
      .done(() => {
        // console.log('reconneted success');
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
      })
      .fail((err) => {
        // console.log('reconneted fail');
      });
  }
}

// @NgModule({
//   imports: [CommonModule, MatSnackBarModule],
//   providers: [SignalRHubConnector],
// })
// export class SignalRHubModule {}
