import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SharedService } from './services/shared.service';
import { SettingsService } from './services/settings.service';
import { DeviceHubConnector } from './services/device-hub.connector';
import { Device } from '@ionic-native/device/ngx';
import { StorageService } from './services/storage.service';
import { CompanyStorage } from './_core/company-storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { InfoBaseService } from './common/info-base/info-base.service';
import { MonitoringService } from './services/monitoring.service';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private sharedService: SharedService,
    private _settingsService: SettingsService,
    private _deviceHubConnector: DeviceHubConnector,
    private _device: Device,
    private _storage: StorageService,
    private _companyStorage: CompanyStorage,
    private infoBase: InfoBaseService,
    private monitoring: MonitoringService,
    private navigationBar: NavigationBar
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    if (global === undefined) {
      var global = window;
    }
  }

  private async _startDeviceHub() {
    const connection = await this._deviceHubConnector.connectToHub();
    this._deviceHubConnector
      .onMethod('onHelp')
      .subscribe((helpArticle: string) => {
        if (helpArticle) {
          this.infoBase.openArticle(helpArticle);
        }
      });

    this._deviceHubConnector
      .onMethod('onHelpClose')
      .subscribe((helpArticle: string) => {
        if (helpArticle) {
          this.infoBase.openArticle(helpArticle);
        }
      });

    connection.on('onGetDeviceState', async (connectionId) => {
      const commonStorage = await this._storage
        .keys()
        .then((keys) =>
          Promise.all(
            keys.map((k) => this._storage.get(k).then((data) => [k, data]))
          )
        );

      const companyStorage = await this._companyStorage
        .keys()
        .then((keys) =>
          Promise.all(
            keys.map((k) =>
              this._companyStorage.get(k).then((data) => [k, data])
            )
          )
        );

      const localStorageJson = JSON.stringify(localStorage);

      connection.send('ReceiveDeviceState', {
        connectionId: connectionId,
        state: { commonStorage, companyStorage, localStorageJson },
      });
    });

    connection.on('onPing', (connectionId) => {
      connection.send('ReceivePong', connectionId);
    });
  }

  initializeApp() {
    this.platform.ready().then((pl) => {
      if (pl != 'dom') {
        (<any>window).screen.orientation.lock('landscape');
        this.statusBar.hide();
        this.splashScreen.hide();
        this.statusBar.styleDefault();
      }

      if (this._device.platform == 'Android') {
        this.navigationBar.setUp(true);
        this.sharedService.hideNavigationBar();
      }

      this._settingsService.registerDevice();
      this._settingsService.getSystemConfig().subscribe();
      this.monitoring.startMonitoring(this._device.uuid, this._device.platform);
      this._startDeviceHub();
    });

    this.platform.resume.subscribe(() => {
      this.sharedService.hideNavigationBar();
      this.statusBar.hide();
    });
  }
}
