<ion-header>
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" color="dark">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="ion-margin-bottom">
    <span>Не друкується на чеку</span>
    <textarea [(ngModel)]="note" class="w-100" style="resize: none"></textarea>
  </div>
  <div>
    <span>Друкується на чеку</span>
    <textarea
      [(ngModel)]="publicNote"
      class="w-100"
      style="resize: none"
    ></textarea>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="success" fill="solid" (click)="save()">
        Зберегти
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<style>
  ::ng-deep .note-modal {
    --height: 340px;
    align-items: baseline;
    padding-top: 10px;
  }
</style>
