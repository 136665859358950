<ion-header>
  <ion-toolbar>
    <ion-title>{{ transl['common_translate.print-receipt'] }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="p-3">
    <div class="row">
      <div class="col-md-6">
        <h3 class="mb-3">{{ transl['common_translate.printer'] }}</h3>
        <ion-button (click)="selectPrinter()" color="dark" fill="outline">
          <span *ngIf="printer">
            <span *ngIf="printer.name" class="mr-2">{{ printer.name }}</span>
            <span *ngIf="printer.ip">{{ printer.ip }}</span>
          </span>
          <h3 *ngIf="!printer">
            {{ transl['printers_component.printers.select-printer'] }}
          </h3>
        </ion-button>
      </div>
      <div class="col-md-6">
        <h3>{{ transl['printers_component.print-preview.doc-for-print'] }}</h3>
        <ion-card style="display: inline-block">
          <div [innerHTML]="printText"></div>
        </ion-card>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="testPrint()" class="mr-3">
        {{ transl['printers_component.settings.test-print'] }}
      </ion-button>
      <ion-button
        fill="solid"
        [disabled]="!printer"
        color="primary"
        (click)="print()"
      >
        {{ transl['common_translate.print'] }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<style>
  ::ng-deep ion-modal.print-preview-modal {
    --width: 90% !important;
  }
</style>
