import { Injectable } from '@angular/core';
import { IPrinterItem } from '../private/terminal/repositories/printers.repository';

@Injectable({ providedIn: 'root' })
export class UsbService {
  private _usbPrinter: any;
  private get usbPrinter(): any {
    if (!this._usbPrinter) {
      this._usbPrinter = (window as any).cordova.plugins.UsbPrinter;
    }
    return this._usbPrinter;
  }

  constructor() {}

  async getUsbPrinters(): Promise<IPrinterItem[]> {
    return new Promise((resolve, reject) => {
      this.usbPrinter.getConnectedPrinters(
        (result: IPrinterItem[]) => {
          if (result && result.length > 0) {
            const printers = result.map((p: any) => {
              return {
                name: p.printername,
                type: 'usb',
                selected: false,
              };
            });

            resolve(printers);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async send(printerName: string, command: any): Promise<any> {
    if (!command) {
      return;
    }

    const _connection = await this._connect(printerName);
    await this._sendCommand(printerName, command).catch((err) => {
      this._disconnect(printerName);
      throw err;
    });

    return this._disconnect(printerName);
  }

  private _sendCommand(printerName: string, command: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.usbPrinter.sendCommand(
        printerName,
        command,
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  private async _connect(printerName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.usbPrinter.connect(
        printerName,
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  private async _disconnect(printerName: string): Promise<void> {
    return new Promise((resolve) => {
      this.usbPrinter.disconnect(
        printerName,
        () => resolve(),
        () => resolve()
      );
    });
  }
}
