import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { InfoBaseModalComponent } from './info-base-modal/info-base-modal.component';

@Injectable({
  providedIn: 'root',
})
export class InfoBaseService {
  private _url = 'https://info-api.turbopos.net/api';
  private _modal: HTMLIonModalElement;

  constructor(
    private http: HttpClient,
    @Inject('APP_LANGUAGE') private APP_LANGUAGE: string,
    private modalCtrl: ModalController
  ) {}

  private _getArticle(url: string): Observable<any> {
    return this.http.get(`${this._url}/article/${url}`, {
      headers: new HttpHeaders().set('lang', this.APP_LANGUAGE || ''),
    });
  }

  async openArticle(url: string): Promise<void> {
    this._modal = await this.modalCtrl.create({
      component: InfoBaseModalComponent,
      componentProps: {
        dtaSub: this._getArticle(url),
      },
      cssClass: 'info-base-modal',
    });

    this._modal.present();
    this._modal.onDidDismiss().then(() => (this._modal = null));
  }

  async closeInfoModal(): Promise<void> {
    this._modal.dismiss();
    this._modal = null;
  }
}
