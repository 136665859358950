<div style="margin-left: auto; margin-right: auto">
  <div style="padding-top: 50px">
    <mat-card
      class="mat-elevation-z8"
      style="display: inline-block; width: 500px"
    >
      <mat-card-header>
        <mat-card-title>Початок зміни</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div class="py-3">
          <div class="row pb-3" style="font-size: 16px">
            <div class="col-md-4">Початок о</div>
            <div class="col-md-8">
              <span class="mr-3">{{ start | date: 'HH:mm:ss' }}</span>
              <span>{{ start | date: 'dd.MM.yyyy' }} </span>
            </div>
          </div>
          <div class="row pb-3" style="font-size: 16px">
            <div class="col-md-4">Співробітник</div>
            <div class="col-md-8">
              {{ employee.firstname }} {{ employee.lastname }}
            </div>
          </div>

          <div
            class="row pb-3"
            style="font-size: 16px"
            *ngIf="isCachboxEnabled && !activeCashboxShift"
          >
            <div class="col-md-4">Готівка в касі</div>
            <div class="col-md-6">
              <input
                type="number"
                [formControl]="cashInBoxControl"
                class="form-control touched-error"
              />
            </div>
          </div>
        </div>
        <div *ngIf="errorMessage">
          <h6>{{ errorMessage }}</h6>
        </div>
      </mat-card-content>

      <mat-card-actions class="flex-container">
        <button mat-button (click)="cancel()">Відмінити</button>
        <span class="flex-spacer"></span>
        <button (click)="startSession()" mat-button color="success">
          Почати зміну
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
