import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(private modalCtrl: ModalController) {}

  async open(externalDataStorage = false): Promise<any> {
    const importedModuleFile = await import('./config.module');

    const modal = await this.modalCtrl.create({
      component: importedModuleFile.ConfigModule.getConfigComponent(),
      cssClass: 'config-modal',
      componentProps: {
        externalDataStorage: externalDataStorage,
      },
    });
    modal.present();
    return modal.onWillDismiss();
  }

  saveError(error: string) {
    const savedLocalErrors = localStorage.getItem('localErrors');
    let errors = [];
    if (savedLocalErrors) {
      try {
        const _errors = JSON.parse(savedLocalErrors) as string[];
        _errors.unshift(error);
        if (_errors.length > 30) {
          _errors.pop();
        }

        errors = _errors;
      } catch (err) {
        errors = [error];
      }
    } else {
      errors = [error];
    }

    localStorage.setItem('localErrors', JSON.stringify(errors));
  }

  getErrors(): string[] {
    try {
      const _errors = localStorage.getItem('localErrors');
      return JSON.parse(_errors);
    } catch {
      return [];
    }
  }
}
