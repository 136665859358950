<ion-header>
  <ion-toolbar>
    <ion-title> Рабочі зміни</ion-title>
    <ion-buttons slot="end">
      <span *ngIf="loading" class="loaderd-bg"></span>
      <ion-button
        (click)="startSession()"
        *ngIf="!loading && !hasActiveSession"
        color="success"
        >Почати зміну</ion-button
      >
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <table class="table">
    <tr>
      <th>#</th>
      <th>Статус</th>
      <th>Початок</th>
      <th>Закриття</th>
      <th>Каса <span style="font-size: 12px">(гот/бн)</span></th>
      <th>Знижка</th>
      <th></th>
    </tr>
    <tr *ngFor="let session of userSessions; let i = index">
      <td>{{ i + 1 }}</td>
      <td>
        <span *ngIf="!session.end" class="badge badge-success">Активна</span>
        <span *ngIf="session.end" class="badge badge-secondary">Закрита</span>
      </td>
      <td>
        {{ session.start | date : 'dd.MM HH:mm' }}
      </td>
      <td>
        <div *ngIf="!session.end">
          <button
            class="btn btn-success btn-sm"
            (click)="selectSession(session)"
            style="font-size: 12px"
          >
            Продовжити
          </button>
        </div>

        {{ session.end | date : 'dd.MM HH:mm' }}
      </td>
      <td>{{ session.cash }} / {{ session.cashless }}</td>
      <td>{{ session.discount }}</td>
      <td>
        <div *ngIf="!session.end">
          <button
            class="btn btn-danger btn-sm"
            (click)="closeSession(session.id)"
            style="font-size: 12px"
          >
            Закрити
          </button>
        </div>
        <div *ngIf="session.end && i == 0">
          <button
            class="btn btn-warning btn-sm"
            (click)="openSession(session.id)"
            style="font-size: 12px"
          >
            Відкрити
          </button>
        </div>
      </td>
      <td>
        <!-- <a href="javascript:void(0)" (click)="openWorkShiftReport(session.id)">Звіт</a> -->
      </td>
    </tr>
  </table>
</ion-content>
