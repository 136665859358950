import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss'],
})
export class SendEmailDialogComponent {
  text: string;
  html: string;

  emailControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
  ]);
  submitted = false;
  loading = false;

  constructor(
    private modalCtrl: ModalController,
    private shared: SharedService
  ) {}

  send() {
    this.submitted = true;
    if (this.emailControl.invalid) {
      return;
    }

    this.loading = true;
    this.shared
      .sendEmail({
        email: this.emailControl.value,
        text: this.text,
        html: this.html,
      })
      .subscribe(
        () => {
          this.loading = false;
          this.shared.showSuccessToast('Повідомлення надіслано');
          this.close();
        },
        () => {
          this.loading = false;
          this.shared.showErrorToast('Помилка при відправці');
        }
      );
  }
  close() {
    this.modalCtrl.dismiss();
  }
}
