import { EventEmitter, Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AppEndUserOwnerInfo } from './models/end-user-owner-info.model';
import {
  CHECKHEAD,
  DocumentItem,
  LastShiftTotalsResponse,
  ShiftItem,
  TaxError,
  TaxErrorEnum,
  TransactionsRegistrarStateResponse,
} from './models/tax.model';
import { CompanyDataService } from 'src/app/modules/company/company-data.service';
import { TaxDocumentUtility } from './tax-document.utility';
import {
  AgentSigner,
  IPrivateKeySettings,
} from './services/agent-signer.service';
import XmlUtils, { xml2json } from 'src/app/utility/xml-utils';
import { ITaxOrder, TaxOrder, TaxTicket } from './models/tax-order.model';
import { PrivateKeyService } from './services/private-key.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Platform, ToastController } from '@ionic/angular';
import { TaxApiService } from './services/tax-api.service';
import { SharedService } from 'src/app/services/shared.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import {
  ITaxDocument,
  TaxRegisterService,
} from './services/tax-register.service';
import {
  ITaxConfig,
  TaxRegisterConfigService,
} from './services/tax-register-config.service';
import { UUID } from 'angular2-uuid';
import EncodeUtils from 'src/app/utility/encode-utils';
import { Buffer } from 'buffer';
import { IReceipt } from 'src/app/private/terminal/terminal.models';
import {
  ITaxRegisterLogger,
  MonitoringService,
} from 'src/app/services/monitoring.service';

const PAYMENT_TYPE_CARD = 'card';
const PAYMENT_TYPE_CASH = 'cash';
const PAYMENT_TYPE_BONUSES = 'bonuses';
const PAYMENT_TYPE_AFTER_PAYMENT = 'afterPayment';
interface IAgentSignerError {
  code: string;
  message: string;
}

interface ITaxDocumentProcessResult {
  success: boolean;
  ticket?: TaxTicket;
  response?: string;
  ticketXml?: string;
  signerError?: IAgentSignerError;
  taxError?: string;
  ticketError?: string;
  status?: string;
}

const LOGGER_NAGE = 'TaxRegister';

@Injectable({ providedIn: 'root' })
export class TaxService {
  private get _taxRegisterApi(): string {
    return this._settingsService.tax_register_api;
  }

  selectedStore = new BehaviorSubject<any>(null);
  selectedPrro = new BehaviorSubject<any>(null);
  onTaxRegisterReady = new EventEmitter<boolean>();
  shiftStateChanged = new BehaviorSubject<LastShiftTotalsResponse>(null);

  _checkTaxOrderInterval: Observable<number>;
  _checkTaxOrderSub: Subscription;
  OnConfigurePrivateKey = new EventEmitter<{
    privateKeyBase64: string;
    privateKeyName: string;
    password: string;
    providerAddress: string;
    providerCmpAddress: string;
    providerTspAddress: string;
    providerTspAddressPort: string;
  }>();

  _webHttp: HttpClient;
  private _signerStateSubs = {};
  private _taxLogger: ITaxRegisterLogger;

  constructor(
    private _companyDataService: CompanyDataService,
    private agentSigner: AgentSigner,
    private privateKeyService: PrivateKeyService,
    private _settingsService: SettingsService,
    private _taxApi: TaxApiService,
    private http: HTTP,
    private _sharedService: SharedService,
    private _httpBackEnd: HttpBackend,
    private taxRegisterService: TaxRegisterService,
    private toastCtrl: ToastController,
    private taxRegisterConfigService: TaxRegisterConfigService,
    private ms: MonitoringService
  ) {
    this._webHttp = new HttpClient(this._httpBackEnd);
    this._taxLogger = this.ms.getTaxLogger();
  }

  // =========================== new code ====================

  getSignerStateSub(
    cxtKey: string
  ): BehaviorSubject<
    'off' | 'pending' | 'ready' | 'keyReady' | 'privateKeySaved' | 'error'
  > {
    let cxtSub = this._signerStateSubs[cxtKey];
    if (cxtSub) {
      return cxtSub;
    }

    cxtSub = new BehaviorSubject<
      'off' | 'pending' | 'ready' | 'keyReady' | 'privateKeySaved' | 'error'
    >('off');

    this._signerStateSubs[cxtKey] = cxtSub;
    return cxtSub;
  }

  async getStoreSignerStateSub(
    storeId: number
  ): Promise<
    BehaviorSubject<
      'off' | 'pending' | 'ready' | 'keyReady' | 'privateKeySaved' | 'error'
    >
  > {
    const taxConfig = await this.taxRegisterConfigService.getStoreConfig(
      storeId
    );
    const cxtKey = taxConfig?.cashier?.subjectKeyId;
    return this.getSignerStateSub(cxtKey);
  }

  async launchTaxConfigsSigner() {
    this._taxLogger.logTrace('launchTaxConfigsSigner');
    const configs = await this.taxRegisterConfigService.getConfig();
    const activeConfigs = configs.filter((c) => c.active);

    if (!activeConfigs.length) {
      return;
    }

    this._sharedService.notify('Завантаження бібліотеки', 10);

    const isInitialized = await this.agentSigner.initialize().catch();
    if (!isInitialized) {
      this._sharedService.showErrorToast(
        'Помилка ініціалізації бібліотеки підписів',
        6
      );
      return;
    }

    this._sharedService.notify('Завантаження підписів', 4);
    for await (let conf of activeConfigs) {
      await this.startTaxRegister(conf).catch((err) => {
        this._sharedService.showErrorToast(err, 6);
      });
    }

    if (this._msgs.length) {
      this._sharedService.infoDialogMsg('Попередження', this._msgs).then(() => {
        this._msgs = [];
      });
    }

    this._sharedService.notify('Підписи налаштовано', 3);
    this._sharedService.closeNotify(5);
  }

  async startTaxRegister(config: ITaxConfig) {
    this._taxLogger.logTrace('_startTaxRegister');
    const contextKey = config.cashier.subjectKeyId;
    const signerState = this.getSignerStateSub(contextKey);
    signerState.next('pending');
    const userOwnerInfo = await this._launchPrivateKey(config).catch((err) => {
      signerState.next('off');
      throw err.message || err;
    });

    this._checkPrivateKeyEndDate(userOwnerInfo);

    signerState.next('keyReady');

    const taxRegisterState = await this.ctxGetTaxRegisterState(config);
    const lastShiftTotals = await this._taxApi.ctxGetLastShiftTotals(
      contextKey,
      config.taxRegister.fiscalNumber
    );

    if (taxRegisterState && taxRegisterState.ShiftState == 1) {
      signerState.next('ready');
    }
  }

  private async _launchPrivateKey(
    config: ITaxConfig
  ): Promise<AppEndUserOwnerInfo> {
    if (!config.cashier) {
      throw 'Cashier not selected';
    }

    const contextKey = config.cashier.subjectKeyId;
    let userOwnerInfo = await this.agentSigner
      .ctxGetUserOwnerInfo(contextKey)
      .catch(() => {
        return null;
      });
    if (userOwnerInfo) {
      return userOwnerInfo;
    }

    // legacy code
    if (!config.cashier.privateKey) {
      config.cashier.privateKey = await this.privateKeyService
        .getPrivateKeyBySubjectId(config.cashier.subjectKeyId)
        .catch(() => null);
    }
    // end legacy code

    await this.agentSigner.initialize();
    userOwnerInfo = await this.agentSigner
      .ctxReadPrivateKey(contextKey, {
        privateKeyBase64: config.cashier.privateKey.keyFileBase64,
        privateKeyFileName: config.cashier.privateKey.keyFileName,
        password: config.cashier.privateKey.password,
        providerName: config.cashier.privateKey.providerName,
      })
      .catch((err) => {
        const error = err.message || JSON.stringify(err);
        throw `Помилка зчитування ключа. ${error}`;
      });

    return userOwnerInfo;
  }

  private _msgs: string[] = [];
  private _checkPrivateKeyEndDate(userOwnerInfo: any) {
    if (userOwnerInfo.DaysToEnd < 30) {
      this._msgs.push(
        `Термін дії ключа касира <strong>${userOwnerInfo.SubjCN}</strong> спливає за <strong>${userOwnerInfo.DaysToEnd}</strong> днів.`
      );
    }
  }

  async ctxGetTaxRegisterState(
    config: ITaxConfig
  ): Promise<TransactionsRegistrarStateResponse> {
    const state = await this._taxApi.ctxGetTaxRegisterState(
      config.cashier.subjectKeyId,
      config.taxRegister.fiscalNumber
    );

    await this.taxRegisterService.updateTaxRegister(
      config.taxRegister.fiscalNumber,
      state
    );
    return state;
  }

  private _onShiftStateChanged(shiftState: LastShiftTotalsResponse) {
    this.shiftStateChanged.next(shiftState);
  }

  getEndUserOwnerInfo(taxConf: ITaxConfig): Promise<AppEndUserOwnerInfo> {
    return this.ctxGetUserOwnerInfo(taxConf.cashier.subjectKeyId);
  }

  isAgentSignerReady(taxConf: ITaxConfig): Promise<boolean> {
    return this.agentSigner
      .ctxGetUserOwnerInfo(taxConf.cashier.subjectKeyId)
      .then(() => true)
      .catch((err) => false);
  }

  async isShiftOpened(storeId: number): Promise<boolean> {
    const config = await this.taxRegisterConfigService.getStoreConfig(storeId);
    if (!config) {
      return false;
    }

    if (!config.taxRegister) {
      return false;
    }

    const taxRegisterState = await this.taxRegisterService
      .getState(config.taxRegister.fiscalNumber)
      .catch(() => {
        return null;
      });

    return taxRegisterState?.ShiftState == 1;
  }

  async getChecks(from: Date, to: Date): Promise<any[]> {
    const documents: any[] = [];

    // const response = await this.getShifts(
    //   this.taxRegisterFiscalNumber,
    //   from,
    //   to
    // ).catch((err: TaxError) => {
    //   return [];
    // });

    return documents;
  }

  async stornLastDocument(documentNumder: string, storeId: number) {
    const order = new TaxOrder();
    order.documentType = 0;
    order.documentSubType = 5;
    order.ORDERSTORNUM = documentNumder;

    const result = await this._executeOrderTask(order, storeId);
    return result;
  }

  async startShift(storeId: number, testing = false) {
    const taxOrder = new TaxOrder();
    taxOrder.documentType = 100;
    taxOrder.testing = testing;

    const result = await this._executeOrderTask(taxOrder, storeId);
    return result;
  }

  async stopShift(storeId: number) {
    const taxOrder = new TaxOrder();
    taxOrder.documentType = 101;

    const result = await this._executeOrderTask(taxOrder, storeId);
    return result;
  }

  async sendZRepo(storeId: number) {
    const conf = await this.taxRegisterConfigService.getStoreConfig(storeId);
    const taxOrder = new TaxOrder();

    let lastShiftTotals: LastShiftTotalsResponse;
    // if (_lastShift.isOfflineSessionOpened()) {
    //   lastShiftTotals = {
    //     UID: _lastShift.UID,
    //     ShiftState: _lastShift.ShiftState,
    //     ZRepPresent: _lastShift.ZRepPresent,
    //     Testing: _lastShift.Testing,
    //     Totals: _lastShift.getOfflineZReport(),
    //   };
    // } else {
    lastShiftTotals = await this._taxApi.ctxGetLastShiftTotals(
      conf.cashier.subjectKeyId,
      conf.taxRegister.fiscalNumber
    );
    // }

    taxOrder.shiftTotals = lastShiftTotals.Totals;
    return this._executeOrderTask(taxOrder, storeId);
  }

  async serviceInputNext(amount: number, storeId: number): Promise<ITaxOrder> {
    const taxOrder = new TaxOrder();
    const _serviceInputDocSubType = 2;
    taxOrder.documentType = 0;
    taxOrder.documentSubType = _serviceInputDocSubType;
    taxOrder.CHECKTOTAL = amount;

    const result = await this._executeOrderTask(taxOrder, storeId);
    this._taxLogger.logTrace('[serviceInputNext] _executeOrderTask');
    result.ticket.taxRegisterFiscalNum = taxOrder.cashRegisterNum;
    result.ORDERTAXNUM = taxOrder.ORDERTAXNUM;
    return result;
  }

  async serviceOutputNext(amount: number, storeId: number): Promise<ITaxOrder> {
    const taxOrder = new TaxOrder();
    const _serviceInputDocSubType = 4;
    taxOrder.documentType = 0;
    taxOrder.documentSubType = _serviceInputDocSubType;
    taxOrder.CHECKTOTAL = amount;

    const result = await this._executeOrderTask(taxOrder, storeId);
    result.ticket.taxRegisterFiscalNum = taxOrder.cashRegisterNum;
    result.ORDERTAXNUM = taxOrder.ORDERTAXNUM;
    return result;
  }

  async _startOfflineSession(taxRegisterFiscalNumber: string) {
    const checkHead = await this._getCheckHead(taxRegisterFiscalNumber, 102);
    const startOfflineShiftDoc = TaxDocumentUtility.getChekDoc(checkHead);
    return this.taxRegisterService.saveDocument(
      taxRegisterFiscalNumber,
      startOfflineShiftDoc
    );
  }

  async stopOfflineShift(ctxKey: string, taxRegisterFiscalNumber: string) {
    await this._taxApi.getServerState().catch((err) => {
      throw `Сервер податкової недоступний. ${JSON.stringify(err)}`;
    });

    const onlineState = await this._taxApi.ctxGetTaxRegisterState(
      ctxKey,
      taxRegisterFiscalNumber
    );

    await this.taxRegisterService.revokeLastOnline(
      taxRegisterFiscalNumber,
      onlineState.NextLocalNum
    );

    await this._stopOfflineSession(taxRegisterFiscalNumber).catch((err) => {
      const taxError = new TaxError(err);
      if (taxError.error == TaxErrorEnum.OfflineSessionNotOpened) {
        return;
      }

      throw err;
    });

    const data = await this._sendOfflinePackage(
      ctxKey,
      taxRegisterFiscalNumber
    );
    if (!data) {
      throw 'Помилка відправки офлайн пакета';
    }

    await this.taxRegisterService.finishOffline(taxRegisterFiscalNumber);
    await this.syncLastShiftTotals(ctxKey, taxRegisterFiscalNumber);
    await this.syncTaxRegisterState(ctxKey, taxRegisterFiscalNumber);
  }

  async syncLastShiftTotals(
    ctxKey: string,
    taxRegisterFiscalNumber: string
  ): Promise<void> {
    const totals = await this._taxApi.ctxGetLastShiftTotals(
      ctxKey,
      taxRegisterFiscalNumber
    );
    return this.taxRegisterService.syncShiftTotals(
      taxRegisterFiscalNumber,
      totals
    );
  }

  async syncTaxRegisterState(
    ctxKey: string,
    taxRegisterFiscalNumber: string
  ): Promise<TransactionsRegistrarStateResponse> {
    const state = await this._taxApi.ctxGetTaxRegisterState(
      ctxKey,
      taxRegisterFiscalNumber
    );

    await this.taxRegisterService.updateTaxRegister(
      taxRegisterFiscalNumber,
      state
    );
    if (+state.OfflineNextLocalNum > 1) {
      if (state.ZRepPresent && state.ShiftState == 1) {
        const lastZRep = await this._taxApi.ctxGetZRepXml(
          ctxKey,
          taxRegisterFiscalNumber,
          state.LastFiscalNum
        );
        await this.taxRegisterService.saveOnlineDoc(
          taxRegisterFiscalNumber,
          lastZRep
        );
      } else {
        const lastCheck = await this._taxApi.ctxGetCheckXml(
          ctxKey,
          taxRegisterFiscalNumber,
          state.LastFiscalNum
        );
        await this.taxRegisterService.saveOnlineDoc(
          taxRegisterFiscalNumber,
          lastCheck
        );
      }
    }
    return state;
  }

  private async _stopOfflineSession(taxRegisterFiscalNumber: string) {
    const state = await this.taxRegisterService.getState(
      taxRegisterFiscalNumber
    );

    const checkHead = await this._getCheckHead(taxRegisterFiscalNumber, 103);
    const stopOfflineShiftDoc = TaxDocumentUtility.getChekDoc(checkHead);
    return this.taxRegisterService.saveDocument(
      taxRegisterFiscalNumber,
      stopOfflineShiftDoc
    );
  }

  private async _sendOfflinePackage(
    ctxKey: string,
    taxRegisterFiscalNumber: string
  ): Promise<any> {
    const perts = await this.taxRegisterService.getOfflineParts(
      taxRegisterFiscalNumber
    );
    if (!perts || !perts.length) {
      throw 'Offline session is empty';
    }

    let _result: any;
    for (let pack of perts) {
      const result = await this._processPackage(ctxKey, pack).catch((err) => {
        this._sharedService.showErrorToast(
          `Помилка підклюсчення до інтернету.`,
          10000
        );

        this._taxLogger.logTrace('_processPackage error');
        this._taxLogger.logTrace(JSON.stringify(err));
        throw err;
      });

      if (!result.success) {
        throw result.error;
      }

      if (result.data) {
        _result = result.data;
        break;
      }
    }

    if (!_result) {
      throw 'Помилка відправки оффлайн пакета';
    }

    return _result;
  }

  private async _getHeaderTaxOrder(
    taxRegisterFiscalNumber: string,
    docType: number,
    subDocType?: number,
    checkTotal?: string
  ): Promise<ITaxOrder> {
    const docHead = await this._getCheckHead(
      taxRegisterFiscalNumber,
      docType,
      subDocType,
      checkTotal
    );

    const taxOrder = new TaxOrder();
    taxOrder.orderNumber = docHead.ORDERNUM;
    taxOrder.ORDERDATE = docHead.ORDERDATE;
    taxOrder.ORDERTIME = docHead.ORDERTIME;
    taxOrder.tin = docHead.TIN;
    taxOrder.ipn = docHead.IPN;
    taxOrder.organizationName = docHead.ORGNM;
    taxOrder.pointName = docHead.POINTNM;
    taxOrder.pointAddress = docHead.POINTADDR;
    taxOrder.cashDeskNum = docHead.CASHDESKNUM;
    taxOrder.cashRegisterNum = docHead.CASHREGISTERNUM;
    taxOrder.cashier = docHead.CASHIER;

    if (docHead.DOCTYPE == 100) {
      // start new shift
    } else {
      taxOrder.testing = docHead.TESTING;
    }

    return taxOrder;
  }

  private async _getCheckHead(
    taxRegisterFiscalNumber: string,
    docType: number,
    subDocType?: number,
    checkTotal?: string
  ) {
    const taxConfig = await this.taxRegisterConfigService.getRegisterConfig(
      taxRegisterFiscalNumber
    );
    const state = await this.taxRegisterService.getState(
      taxRegisterFiscalNumber
    );
    const userOwnerInfo = await this.ctxGetUserOwnerInfo(
      taxConfig.cashier.subjectKeyId
    );
    if (!userOwnerInfo) {
      throw 'private key error';
    }
    const uid = this._getUuid();
    const _date = new Date();
    const ORDERDATE = this._getOrderDate(_date);
    const ORDERTIME = this._getOrderTime(_date);

    const checkHead: CHECKHEAD = {
      DOCTYPE: docType,
      SUBDOCTYPE: subDocType,
      UID: uid,
      TIN: taxConfig.tin,
      IPN: taxConfig.ipn,
      ORGNM: taxConfig.orgFullName,
      POINTNM: taxConfig.taxRegister.objectName,
      POINTADDR: taxConfig.taxRegister.address,
      ORDERDATE: ORDERDATE,
      ORDERTIME: ORDERTIME,
      ORDERNUM: state.NextLocalNum,
      CASHDESKNUM: taxConfig.taxRegister.localNumber,
      CASHREGISTERNUM: taxConfig.taxRegister.fiscalNumber.toString(),
      CASHIER: userOwnerInfo.SubjFullName,
      VER: 1,
      ORDERTAXNUM: null,
      OFFLINE: false,
      PREVDOCHASH: null,
      TESTING: state.Testing,
    };

    const isOffline = state.OfflineSession;
    if (!isOffline && docType != 102) {
      return checkHead;
    }

    checkHead.OFFLINE = true;

    if (docType == 102 || docType == 103) {
      checkHead.TESTING = null;
    }

    if (+state.OfflineNextLocalNum > 2) {
      checkHead.PREVDOCHASH = await this.taxRegisterService.getLastDocHash(
        taxRegisterFiscalNumber
      );
    }

    const offlineTaxOrderNum = this._offlineTaxOrderNum(
      taxRegisterFiscalNumber,
      taxConfig.taxRegister.localNumber,
      ORDERDATE,
      ORDERTIME,
      state.OfflineSeed,
      state.NextLocalNum,
      state.OfflineSessionId,
      +state.OfflineNextLocalNum,
      checkHead.PREVDOCHASH,
      checkTotal
    );

    checkHead.ORDERTAXNUM = offlineTaxOrderNum;
    return checkHead;
  }

  private _offlineTaxOrderNum(
    taxRegisterFiscalNumber: string,
    cashDeskNum: number,
    ORDERDATE: string,
    ORDERTIME: string,
    offlineSeed: string,
    nextLocalNum: number,
    offlineSessionId: string,
    offlineNextLocalNum: number,
    PREVDOCHASH?: string,
    CHECKTOTAL?: string
  ): string {
    const checkSumString = this._getCheckSumString(
      offlineSeed,
      ORDERDATE,
      ORDERTIME,
      nextLocalNum,
      taxRegisterFiscalNumber,
      cashDeskNum,
      PREVDOCHASH,
      CHECKTOTAL
    );

    let _checkSum = EncodeUtils.formatToCRC32(checkSumString).toString(16);
    _checkSum = '00000000'.substring(0, 8 - _checkSum.length) + _checkSum;
    let checkSum =
      Buffer.from(_checkSum, 'hex').readUInt32LE(null) % 10000 || 1;
    checkSum = checkSum == 0 ? 1 : checkSum;

    this._taxLogger.logTrace('OFFLINE culculate checkSum');
    this._taxLogger.logTrace(checkSumString);
    this._taxLogger.logTrace(checkSum.toString());
    return `${offlineSessionId}.${offlineNextLocalNum}.${checkSum}`;
  }

  private _getCheckSumString(
    offlineSeed: string,
    ORDERDATE: string,
    ORDERTIME: string,
    orderNumber: number,
    cashRegisterNum: string,
    cashDeskNum: number,
    PREVDOCHASH?: string,
    CHECKTOTAL?: string
  ): string {
    let _checkControlString = `${offlineSeed},${ORDERDATE},${ORDERTIME},${orderNumber},${cashRegisterNum},${cashDeskNum}`;
    if (CHECKTOTAL) {
      _checkControlString += `,${CHECKTOTAL}`;
    }

    if (PREVDOCHASH) {
      _checkControlString += `,${PREVDOCHASH}`;
    }

    return _checkControlString;
  }

  private _getUuid() {
    return UUID.UUID();
  }

  private _getOrderDate(date: Date) {
    let _date = `0${date.getDate()}`;
    _date = _date.substring(_date.length - 2);

    let _month = `0${date.getMonth() + 1}`;
    _month = _month.substring(_month.length - 2);

    const _year = date.getFullYear();
    return `${_date}${_month}${_year}`;
  }

  private _getOrderTime(date: Date) {
    let _hours = `0${date.getHours()}`;
    _hours = _hours.substring(_hours.length - 2);

    let _minutes = `0${date.getMinutes()}`;
    _minutes = _minutes.substring(_minutes.length - 2);

    let _seconds = `0${date.getSeconds()}`;
    _seconds = _seconds.substring(_seconds.length - 2);

    return `${_hours}${_minutes}${_seconds}`;
  }

  async sendOrder(
    order: IReceipt,
    fiscalData: { key: string; value: string }[]
  ): Promise<ITaxOrder | null> {
    const _taxOrder = await this._getTaxObject(order, fiscalData);
    order.documentDate = new Date().toISOString();
    // check doogs documentType = 0
    _taxOrder.documentType = 0;
    const result = await this._executeOrderTask(_taxOrder, order.storeId);
    if (result.ticket) {
      result.ticket.taxRegisterFiscalNum = _taxOrder.cashRegisterNum;
      result.ORDERTAXNUM = result.ticket.ORDERTAXNUM;
      result.xmlDocument = this._getTaxObjectXml(result);
    }

    return result;
  }

  async getTaxObject(order: IReceipt) {
    return this._getTaxObject(order, []);
  }

  private async _getTaxObject(
    order: IReceipt,
    fiscalData: { key: string; value: string }[]
  ): Promise<ITaxOrder> {
    if (!order.taxRegisterFiscalNumber) {
      throw 'ІД каси не встановлено';
    }

    const _taxOrder = await this.getRegisterTaxOrderObject(
      order.taxRegisterFiscalNumber
    );
    _taxOrder.CHECKTOTAL = order.total;
    _taxOrder.DISCOUNTSUM = order.discount;
    _taxOrder.PROVIDED = order.providedSum;
    _taxOrder.REMAINS = order.remainsSum;
    _taxOrder.CHECKTAX = order.taxes;
    _taxOrder.RNDSUM = order.rndSum;
    _taxOrder.NORNDSUM = order.noRndSum;

    _taxOrder.CHECKBODY = order.products.map((p) => {
      return {
        NAME: p.name,
        CODE: p.productid,
        PRICE: p.price,
        AMOUNT: p.quantity,
        COST: p.amount,

        // unit: p.unitId,
        LETTERS: (<any>p).letters,
        EXCISELABELS: p.exciseLabels,
        UKTZED: p.uktzedcode,
        DISCOUNTTYPE: p.discountType,
        DISCOUNTPERCENT: p.percentDiscount,
        DISCOUNTSUM: p.amountDiscount,
      };
    });

    const payForm = this._getPaymentForm(order.paymentType);

    if (fiscalData) {
      const paySysName = fiscalData.find((fd) => fd.key == 'ЕКВАЙЕР');
      const RRN = fiscalData.find((fd) => fd.key == 'RRN');

      if (payForm) {
        payForm.PAYSYS = [
          { NAME: paySysName?.value, ACQUIRETRANSID: RRN?.value },
        ];
      }
    }
    _taxOrder.CHECKPAY = [
      {
        PAYFORMCD: payForm.PAYFORMCD,
        PAYFORMNM: payForm.PAYFORMNM,
        PAYSYS: payForm.PAYSYS,
        SUM: order.total,
      },
    ];

    _taxOrder.CHECKTAX = order.taxes || [];
    _taxOrder.uid = order.uuid;

    return _taxOrder;
  }

  private _getPaymentForm(
    paymentType: 'card' | 'cash' | 'bonuses' | 'afterPayment'
  ): {
    PAYFORMCD: number;
    PAYFORMNM: string;
    PAYSYS?: any[];
  } {
    if (paymentType == PAYMENT_TYPE_AFTER_PAYMENT) {
      return { PAYFORMCD: 3, PAYFORMNM: 'ВІДКЛАДЕНА ОПЛАТА' };
    }

    if (paymentType == PAYMENT_TYPE_CASH) {
      return { PAYFORMCD: 0, PAYFORMNM: 'ГОТІВКА' };
    }

    if (paymentType == PAYMENT_TYPE_CARD) {
      return { PAYFORMCD: 1, PAYFORMNM: 'БАНКІВСЬКА КАРТКА' };
    }

    if (paymentType == PAYMENT_TYPE_BONUSES) {
      return { PAYFORMCD: 3, PAYFORMNM: 'КРЕДИТ' };
    }
  }

  async sendSaleCheck(
    taxOrder: ITaxOrder,
    storeId: number
  ): Promise<ITaxOrder | null> {
    // check doogs documentType = 0
    taxOrder.documentType = 0;
    const result = await this._executeOrderTask(taxOrder, storeId);
    if (result.ticket) {
      result.ticket.taxRegisterFiscalNum = taxOrder.cashRegisterNum;
      result.ORDERTAXNUM = result.ticket.ORDERTAXNUM;
      result.xmlDocument = this._getTaxObjectXml(result);
    }

    return result;
  }

  async sendReturnCheck(
    taxOrder: ITaxOrder,
    storeId: number
  ): Promise<ITaxOrder | null> {
    taxOrder.documentType = 0;
    taxOrder.documentSubType = 1;

    const result = await this._executeOrderTask(taxOrder, storeId);
    result.ticket.taxRegisterFiscalNum = taxOrder.cashRegisterNum;
    return result;
  }

  async returnCheck(
    ctxKey: string,
    taxRegisterFiscalNum: string,
    checkFiscalNumber: string,
    operationDate?: Date
  ) {
    const taxOrder = new TaxOrder() as ITaxOrder;
    taxOrder.ORDERRETNUM = checkFiscalNumber;
    taxOrder.documentType = 0;
    taxOrder.documentSubType = 1;

    let docHead = await this._getCheckHead(
      taxRegisterFiscalNum,
      taxOrder.documentType,
      taxOrder.documentSubType,
      taxOrder.CHECKTOTAL?.toFixed(2)
    );
    taxOrder.uid = docHead.UID;
    taxOrder.orderNumber = docHead.ORDERNUM;
    taxOrder.ORDERDATE = docHead.ORDERDATE;
    taxOrder.ORDERTIME = docHead.ORDERTIME;
    taxOrder.tin = docHead.TIN;
    taxOrder.ipn = docHead.IPN;
    taxOrder.organizationName = docHead.ORGNM;
    taxOrder.pointName = docHead.POINTNM;
    taxOrder.pointAddress = docHead.POINTADDR;
    taxOrder.cashDeskNum = docHead.CASHDESKNUM;
    taxOrder.cashRegisterNum = docHead.CASHREGISTERNUM;
    taxOrder.cashier = docHead.CASHIER;
    if (docHead.DOCTYPE == 100) {
      // start new shift
    } else {
      taxOrder.testing = docHead.TESTING;
    }

    const saleCheckXml = await this._taxApi.ctxGetCheckXml(
      ctxKey,
      taxRegisterFiscalNum,
      checkFiscalNumber
    );

    const docObject = xml2json(saleCheckXml) as ITaxDocument;
    taxOrder.CHECKTOTAL = docObject.CHECK.CHECKTOTAL.SUM;
    taxOrder.RNDSUM = docObject.CHECK.CHECKTOTAL.RNDSUM;
    taxOrder.NORNDSUM = docObject.CHECK.CHECKTOTAL.NORNDSUM;
    taxOrder.DISCOUNTSUM = docObject.CHECK.CHECKTOTAL.DISCOUNTSUM;

    if (docObject.CHECK.CHECKBODY.ROW.length) {
      taxOrder.CHECKBODY = docObject.CHECK.CHECKBODY.ROW;
    } else {
      taxOrder.CHECKBODY = [docObject.CHECK.CHECKBODY.ROW];
    }

    if (docObject.CHECK.CHECKPAY.ROW.length) {
      taxOrder.CHECKPAY = docObject.CHECK.CHECKPAY.ROW;
    } else {
      taxOrder.CHECKPAY = [docObject.CHECK.CHECKPAY.ROW];
    }

    if (docObject.CHECK.CHECKTAX) {
      if (docObject.CHECK.CHECKTAX.ROW.length) {
        taxOrder.CHECKTAX = docObject.CHECK.CHECKTAX.ROW;
      } else {
        taxOrder.CHECKTAX = [docObject.CHECK.CHECKTAX.ROW];
      }
    }

    const checkXml = this._getTaxObjectXml(taxOrder);
    taxOrder.xmlDocument = checkXml;

    if (operationDate) {
      // start offline
      // save doc
      // finifhOffline
      // send pack
    } else {
      const result = await this._processTaxDocument(ctxKey, taxOrder);
      result.ticket.taxRegisterFiscalNum = taxOrder.cashRegisterNum;

      return result;
    }
  }

  async returnCheckXml(storeId: number, checkXml: string) {
    const taxOrder = new TaxOrder() as ITaxOrder;
    taxOrder.documentType = 0;
    taxOrder.documentSubType = 1;

    const docObject = xml2json(checkXml) as ITaxDocument;
    taxOrder.ORDERRETNUM = docObject.CHECK.CHECKHEAD.ORDERTAXNUM;
    taxOrder.CHECKTOTAL = docObject.CHECK.CHECKTOTAL.SUM;
    // taxOrder.RNDSUM = docObject.CHECK.CHECKTOTAL.RNDSUM;
    // taxOrder.NORNDSUM = docObject.CHECK.CHECKTOTAL.NORNDSUM;
    taxOrder.DISCOUNTSUM = docObject.CHECK.CHECKTOTAL.DISCOUNTSUM;

    if (docObject.CHECK.CHECKBODY.ROW.length) {
      taxOrder.CHECKBODY = docObject.CHECK.CHECKBODY.ROW;
    } else {
      taxOrder.CHECKBODY = [docObject.CHECK.CHECKBODY.ROW];
    }

    if (docObject.CHECK.CHECKPAY.ROW.length) {
      taxOrder.CHECKPAY = docObject.CHECK.CHECKPAY.ROW;
    } else {
      taxOrder.CHECKPAY = [docObject.CHECK.CHECKPAY.ROW];
    }

    if (docObject.CHECK.CHECKTAX) {
      if (docObject.CHECK.CHECKTAX.ROW.length) {
        taxOrder.CHECKTAX = docObject.CHECK.CHECKTAX.ROW;
      } else {
        taxOrder.CHECKTAX = [docObject.CHECK.CHECKTAX.ROW];
      }
    }

    const result = await this._executeOrderTask(taxOrder, storeId);
    result.ticket.taxRegisterFiscalNum = taxOrder.cashRegisterNum;

    return result;
  }

  private async _executeOrderTask(
    taxOrder: ITaxOrder,
    storeId: number
  ): Promise<ITaxOrder> {
    const cnf = await this.taxRegisterConfigService.getStoreConfig(storeId);
    // const isConfigValid = await this._validateConfig(cnf).catch((err) => {
    //   this._sharedService.showErrorToast(err);
    //   throw err;
    // });
    const ctxKey = cnf.cashier.subjectKeyId;
    const taxRegisterFiscalNum = cnf.taxRegister.fiscalNumber;

    let docHead = await this._getCheckHead(
      taxRegisterFiscalNum,
      taxOrder.documentType,
      taxOrder.documentSubType,
      taxOrder.CHECKTOTAL?.toFixed(2)
    );

    if (docHead.OFFLINE) {
      await this.stopOfflineShift(ctxKey, taxRegisterFiscalNum)
        .then(async () => {
          this._sharedService.notify(
            `Каса ${taxRegisterFiscalNum} перейшла в онлайн режим`
          );

          docHead = await this._getCheckHead(
            taxRegisterFiscalNum,
            taxOrder.documentType,
            taxOrder.documentSubType,
            taxOrder.CHECKTOTAL?.toFixed(2)
          );
        })
        .catch(async (err) => {
          this._taxLogger.logException(err, 1, {
            operation: 'stopOfflineShift',
            point: 'catch',
            taxRegister: taxRegisterFiscalNum,
          });
          const offlineDocs = await this.taxRegisterService.getOfflineParts(
            taxRegisterFiscalNum
          );

          this._taxLogger.logTrace(JSON.stringify(offlineDocs), {
            dataType: 'offlineDocs',
          });
          const _taxError = new TaxError(err);
          if (_taxError && _taxError.error) {
            throw _taxError.text;
          }

          throw err;
        })
        .catch(async (err) => {
          this._taxLogger.logException(err, 1, {
            operation: 'stopOfflineShift',
            point: 'catch2',
            taxRegister: taxRegisterFiscalNum,
          });

          throw err;
        });
    }

    taxOrder.uid = docHead.UID;
    taxOrder.orderNumber = docHead.ORDERNUM;
    taxOrder.ORDERDATE = docHead.ORDERDATE;
    taxOrder.ORDERTIME = docHead.ORDERTIME;
    taxOrder.tin = docHead.TIN;
    taxOrder.ipn = docHead.IPN;
    taxOrder.organizationName = docHead.ORGNM;
    taxOrder.pointName = docHead.POINTNM;
    taxOrder.pointAddress = docHead.POINTADDR;
    taxOrder.cashDeskNum = docHead.CASHDESKNUM;
    taxOrder.cashRegisterNum = docHead.CASHREGISTERNUM;
    taxOrder.cashier = docHead.CASHIER;

    if (docHead.DOCTYPE == 100) {
      // start new shift
    } else {
      taxOrder.testing = docHead.TESTING;
    }

    if (docHead.OFFLINE) {
      throw 'Сервер податкової не доступний';

      // TODO Offline mode
      return this._saveOfflineDoc(taxRegisterFiscalNum, taxOrder, docHead);
    }

    const checkXml = this._getTaxObjectXml(taxOrder);
    taxOrder.xmlDocument = checkXml;

    this._taxLogger.logTrace('xmlDocument', {
      taxRegister: taxRegisterFiscalNum,
    });
    this._taxLogger.logTrace(taxOrder.xmlDocument, {
      taxRegister: taxRegisterFiscalNum,
    });

    const result = await this._processTaxDocument(ctxKey, taxOrder).catch(
      (err) => {
        this._taxLogger.logException(err, 1, {
          operation: '_processTaxDocument',
          taxRegister: taxRegisterFiscalNum,
        });

        throw err;
      }
    );
    if (result.success) {
      taxOrder.ORDERTAXNUM = result.ticket.ORDERTAXNUM;
      taxOrder.ticket = result.ticket;
      taxOrder.ticket.taxRegisterFiscalNum = taxRegisterFiscalNum;

      const xml = this.taxRegisterService.updateTaxOrderNum(
        taxOrder.xmlDocument,
        result.ticket.ORDERTAXNUM
      );

      await this.taxRegisterService.saveDocument(taxOrder.cashRegisterNum, xml);
      return taxOrder;
    }

    if (!result.success) {
      this._taxLogger.logException(<any>result, 1, {
        operation: '_processTaxDocument',
        point: 'result',
        taxRegister: taxRegisterFiscalNum,
      });
    }

    if (result.taxError) {
      return this._handleTaxError(cnf, taxOrder, result.taxError).catch(
        (error) => {
          this._taxLogger.logException(error, 1, {
            operation: '_handleTaxError',
            point: 'result',
            taxRegister: taxRegisterFiscalNum,
          });

          throw error;
        }
      );
    }

    if (result.signerError) {
      throw result.signerError?.message || result.signerError;
    }

    if (!result.taxError) {
      await this.taxRegisterService.saveDocument(
        taxOrder.cashRegisterNum,
        taxOrder.xmlDocument
      );
      return this._saveStartOffline(taxRegisterFiscalNum, taxOrder);
    }
  }

  private async _handleTaxError(
    cnf: ITaxConfig,
    taxOrder: ITaxOrder,
    taxError: string
  ): Promise<any> {
    // process tax errors
    this._taxLogger.logTrace('_handleTaxError', {
      taxRegister: cnf.taxRegister.fiscalNumber,
    });

    const ctxKey = cnf.cashier.subjectKeyId;
    const taxRegisterFiscalNum = cnf.taxRegister.fiscalNumber;

    const _taxError = new TaxError(taxError);
    if (_taxError.error == TaxErrorEnum.CheckLocalNumberInvalid) {
      const nextOrderNumber = _taxError.getNextCheckLocalNumberFromError();
      if (nextOrderNumber > 0) {
        taxOrder.orderNumber = nextOrderNumber;
      } else {
        const taxRegister = await this.ctxGetTaxRegisterState(cnf);
        taxOrder.orderNumber = taxRegister.NextLocalNum; //  await this._getNextOrderNumber(true);
      }

      const updatedXml = this._getTaxObjectXml(taxOrder);
      taxOrder.xmlDocument = updatedXml;

      this._taxLogger.logTrace('updatedXmlDocument', {
        taxRegister: cnf.taxRegister.fiscalNumber,
      });
      this._taxLogger.logTrace(taxOrder.xmlDocument, {
        taxRegister: cnf.taxRegister.fiscalNumber,
      });

      const retryResult = await this._processTaxDocument(ctxKey, taxOrder);
      if (retryResult.success) {
        taxOrder.ORDERTAXNUM = retryResult.ticket.ORDERTAXNUM;
        taxOrder.ticket = retryResult.ticket;
        taxOrder.ticket.taxRegisterFiscalNum = taxRegisterFiscalNum;

        const xml = this.taxRegisterService.updateTaxOrderNum(
          taxOrder.xmlDocument,
          retryResult.ticket.ORDERTAXNUM
        );
        await this.taxRegisterService.saveDocument(
          taxOrder.cashRegisterNum,
          xml
        );
        return taxOrder;
      }

      this._taxLogger.logException(<any>retryResult, 1, {
        operation: '_handleTaxError',
        point: 'process',
        taxRegister: cnf.taxRegister.fiscalNumber,
      });

      if (retryResult.signerError) {
        throw retryResult.signerError?.message || retryResult.signerError;
      }

      if (!taxError) {
        return this._saveStartOffline(taxRegisterFiscalNum, taxOrder);
      }

      const _retryTaxError = new TaxError(retryResult.taxError);
      this._sharedService.showErrorToast(_retryTaxError.text);
      throw retryResult.taxError;
    } else if (_taxError.error == TaxErrorEnum.ShiftAlreadyOpened) {
      this._displayError(_taxError.text);
      throw taxError;
    } else if (_taxError.error == TaxErrorEnum.ZRepAlreadyRegistered) {
      this._displayError(_taxError.text);
      throw taxError;
    } else if (_taxError.error == TaxErrorEnum.DocumentValidationError) {
      this._displayError(_taxError.text);
      throw taxError;
    } else if (_taxError.error == TaxErrorEnum.ShiftNotOpened) {
      this._displayError(_taxError.text);
      throw _taxError;
    }

    throw _taxError.text || 'TaxRegister error';
  }

  private async _saveStartOffline(
    taxRegisterFiscalNum: string,
    taxOrder: ITaxOrder
  ) {
    const state = await this.taxRegisterService.getState(taxRegisterFiscalNum);
    if (!state.OfflineNextLocalNum) {
      throw 'Каса не підтримує оффлайн режим';
    }

    await this._startOfflineSession(taxRegisterFiscalNum);
    this._sharedService.notify(
      `Каса ${taxRegisterFiscalNum} перейшла в оффлайн режим.`
    );
    const docHead = await this._getCheckHead(
      taxRegisterFiscalNum,
      taxOrder.documentType,
      taxOrder.documentSubType,
      taxOrder.CHECKTOTAL?.toFixed(2)
    );

    return this._saveOfflineDoc(taxRegisterFiscalNum, taxOrder, docHead);
  }

  private async _saveOfflineDoc(
    taxRegisterFiscalNum: string,
    taxOrder: ITaxOrder,
    docHead: CHECKHEAD
  ) {
    const state = await this.taxRegisterService.getState(taxRegisterFiscalNum);
    if (!state.OfflineNextLocalNum) {
      throw 'Каса не підтримує оффлайн режим';
    }

    docHead = await this._getCheckHead(
      taxRegisterFiscalNum,
      taxOrder.documentType,
      taxOrder.documentSubType,
      taxOrder.CHECKTOTAL?.toFixed(2)
    );

    taxOrder.ORDERDATE = docHead.ORDERDATE;
    taxOrder.ORDERTIME = docHead.ORDERTIME;
    taxOrder.orderNumber = docHead.ORDERNUM;
    taxOrder.ORDERTAXNUM = docHead.ORDERTAXNUM;
    taxOrder.PREVDOCHASH = docHead.PREVDOCHASH;
    taxOrder.offline = docHead.OFFLINE;

    const checkXml = this._getTaxObjectXml(taxOrder);
    taxOrder.xmlDocument = checkXml;
    await this.taxRegisterService.saveDocument(taxRegisterFiscalNum, checkXml);

    taxOrder.ticket = {
      ORDERTAXNUM: docHead.ORDERTAXNUM,
      taxRegisterFiscalNum: taxRegisterFiscalNum,
      ORDERNUM: taxOrder.orderNumber,
    };

    return taxOrder;
  }

  private async _validateConfig(config: ITaxConfig): Promise<boolean> {
    if (!config) {
      throw 'Програмний реєстратор не налаштовано';
    }

    if (!config.store) {
      throw 'Необхідно налаштувати торгову точку';
    }
    if (!config.taxRegister) {
      throw 'Необхідно налаштувати касу';
    }

    if (!config.cashier) {
      throw 'Необхідно налаштувати касира';
    }

    return true;
  }

  private async _processTaxDocument(
    ctxKey: string,
    taxOrder: ITaxOrder
  ): Promise<ITaxDocumentProcessResult> {
    const result: ITaxDocumentProcessResult = {
      success: false,
    };

    const signedData = await this.agentSigner
      .ctxSignData(ctxKey, taxOrder.xmlDocument)
      .catch((err: IAgentSignerError) => {
        this._taxLogger.logException(err, 2, {
          taxRegister: taxOrder.cashRegisterNum,
          errorType: 'SignError',
        });
        result.signerError = err;
        return '';
      });

    if (result.signerError) {
      return result;
    }

    const documentResponse = await this._taxApi
      .sendDocumentToTax(signedData)
      .catch((err) => {
        this._taxLogger.logException(err, 2, {
          taxRegister: taxOrder.cashRegisterNum,
          errorType: 'SendDocumentError',
        });

        return { success: false, httpStatusCode: 0, error: err, data: null };
      });

    if (!documentResponse.success) {
      if (documentResponse.httpStatusCode > 0) {
        result.taxError = documentResponse.error;
      }
      return result;
    }

    result.response = documentResponse.data;
    let ticketXmlStr = this._tryParseTicket(documentResponse.data);
    if (ticketXmlStr == null) {
      ticketXmlStr = await this.agentSigner
        .verifyData(documentResponse.data as string)
        .catch(() => null);
    }

    if (ticketXmlStr == null) {
      result.ticketError = 'Помилка обробки відповіді серверу ДПС';
      return result;
    }

    const ticketObject = this._getTaxTicketObject(ticketXmlStr);
    result.ticketXml = ticketXmlStr;
    result.ticket = ticketObject;
    result.success = true;

    return result;
  }

  private _tryParseTicket(signedTicket: string) {
    const signedTicketDecoded = signedTicket;
    const xmlStartIndex = signedTicketDecoded.indexOf('<?xml');
    const xmlEndIndex = signedTicketDecoded.indexOf('</TICKET>');
    if (xmlEndIndex < xmlStartIndex) {
      return null;
    }

    const ticketXml = signedTicketDecoded.substring(xmlStartIndex, xmlEndIndex);
    if (!ticketXml) {
      return null;
    }

    return `${ticketXml}</TICKET>`;
  }

  private async _processPackage(ctxKey: string, docs: any[]): Promise<any> {
    const signedPac = await this.agentSigner.ctxSignPackage(ctxKey, docs);
    return await this._taxApi.sendPackageToTax(signedPac);
  }

  private _getTaxTicketObject(ticketXml: string): TaxTicket {
    if (!ticketXml) {
      return null;
    }

    const ticketObject = XmlUtils.parseXml(ticketXml).TICKET as TaxTicket;
    if (!ticketObject) {
      return null;
    }

    return ticketObject;
  }

  async readPrivateKey(
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo> {
    return this.agentSigner
      .readPrivateKey(privateKeyConfig)
      .then((userInfo) => {
        const signerState = this.getSignerStateSub(userInfo.SubjectKeyId);
        signerState.next('keyReady');
        return userInfo;
      })
      .catch((err) => {
        throw err;
      });
  }

  async ctxGetUserOwnerInfo(ctxKey: string): Promise<AppEndUserOwnerInfo> {
    return this.agentSigner.ctxGetUserOwnerInfo(ctxKey).catch((err) => {
      this._displayError(err);
      throw err;
    });
  }

  async getAdvancedTaxOrder(
    storeId: number,
    amount: number,
    paymentType: 'card' | 'cash' | 'bonuses',
    orderId: number,
    productName: string
  ): Promise<ITaxOrder> {
    const taxOrder = await this.getStoreTaxOrderObject(storeId);

    taxOrder.CHECKTOTAL = amount;
    taxOrder.PROVIDED = amount;

    taxOrder.CHECKBODY = [
      {
        NAME: productName,
        CODE: orderId,
        PRICE: amount,
        AMOUNT: 1,
        COST: amount,

        LETTERS: '',
        EXCISELABELS: [],
        UKTZED: '',
        DISCOUNTTYPE: null,
        DISCOUNTPERCENT: null,
        DISCOUNTSUM: null,
      },
    ];

    const payForm = this.getPaymentForm(paymentType);
    taxOrder.CHECKPAY = [
      {
        PAYFORMCD: payForm.PAYFORMCD,
        PAYFORMNM: payForm.PAYFORMNM,
        SUM: amount,
      },
    ];

    taxOrder.CHECKTAX = [];

    return taxOrder;
  }

  PAYMENT_TYPE_CARD = 'card';
  PAYMENT_TYPE_CASH = 'cash';
  PAYMENT_TYPE_BONUSES = 'bonuses';

  getPaymentForm(paymentType: 'card' | 'cash' | 'bonuses'): {
    PAYFORMCD: number;
    PAYFORMNM: string;
  } {
    if (paymentType.toLocaleLowerCase() == this.PAYMENT_TYPE_CASH) {
      return { PAYFORMCD: 0, PAYFORMNM: 'ГОТІВКА' };
    }

    if (paymentType.toLocaleLowerCase() == this.PAYMENT_TYPE_CARD) {
      return { PAYFORMCD: 1, PAYFORMNM: 'БАНКІВСЬКА КАРТКА' };
    }

    if (paymentType.toLocaleLowerCase() == this.PAYMENT_TYPE_BONUSES) {
      return { PAYFORMCD: 3, PAYFORMNM: 'КРЕДИТ' };
    }
  }

  async getStoreTaxOrderObject(storeId: number): Promise<ITaxOrder> {
    const conf = await this.taxRegisterConfigService.getStoreConfig(storeId);
    if (!conf) {
      throw 'Помилка. ПРРО не налаштовано.';
    }

    const userOwnerInfo = await this.ctxGetUserOwnerInfo(
      conf.cashier.subjectKeyId
    );
    if (!userOwnerInfo) {
      return null;
    }

    const taxOrder = new TaxOrder({
      orderNumber: 0,
      tin: conf.tin,
      ipn: conf.ipn || null,
      organizationName: conf.orgFullName,
      pointName: conf.taxRegister.objectName,
      pointAddress: conf.taxRegister.address,
      cashDeskNum: conf.taxRegister.localNumber,
      cashRegisterNum: conf.taxRegister.fiscalNumber,
      cashRegisterName: conf.taxRegister.name,
      cashier: userOwnerInfo.SubjFullName,
      // shiftTotals: lastShift.Totals,
    });

    return taxOrder;
  }

  async getRegisterTaxOrderObject(
    taxRegisterFiscalNumber: string
  ): Promise<ITaxOrder> {
    const conf = await this.taxRegisterConfigService.getRegisterConfig(
      taxRegisterFiscalNumber
    );
    const userOwnerInfo = await this.ctxGetUserOwnerInfo(
      conf.cashier.subjectKeyId
    );
    if (!userOwnerInfo) {
      return null;
    }

    const taxRegisterState = await this.taxRegisterService.getState(
      taxRegisterFiscalNumber
    );

    const orderNumber = taxRegisterState.NextLocalNum;
    const testing = taxRegisterState.Testing;

    const taxOrder = new TaxOrder({
      orderNumber: orderNumber,
      tin: conf.tin,
      ipn: conf.ipn || null,
      organizationName: conf.orgFullName,
      pointName: conf.taxRegister.objectName,
      pointAddress: conf.taxRegister.address,
      cashDeskNum: conf.taxRegister.localNumber,
      cashRegisterNum: conf.taxRegister.fiscalNumber,
      cashRegisterName: conf.taxRegister.name,
      cashier: userOwnerInfo.SubjFullName,
      testing: testing || false,
      // shiftTotals: lastShift.Totals,
    });

    return taxOrder;
  }

  private _getTaxObjectXml(taxObject: ITaxOrder): string {
    if (!taxObject) {
      return null;
    }

    if (!taxObject.documentType && taxObject.documentType != 0) {
      const reportXmlObject = TaxDocumentUtility.getZRepo(taxObject);
      return reportXmlObject.xml;
    } else {
      const checkXmlObject = TaxDocumentUtility.getCheck(taxObject);
      return checkXmlObject.xml;
    }
  }

  // ============= SERVER API ===============

  async saveShift(shift: ShiftItem) {
    const _url = `${this._taxRegisterApi}shift/document`;
    this.http.setDataSerializer('json');
    const companyId = await this._companyDataService.getCompanyId();
    if (!companyId) {
      this._displayError('companyId not found!');
      return;
    }

    const data = await this.http
      .patch(_url, shift, { CompanyId: companyId })
      .catch((error) => error);
  }

  async saveShiftDocument(
    base64XmlDocument: string,
    shiftId: number,
    openShiftFiscalNum: string
  ) {
    const _url = `${this._taxRegisterApi}cabinet`;

    this.http.setDataSerializer('json');
    const companyId = await this._companyDataService.getCompanyId();
    if (!companyId) {
      this._displayError('companyId not found!');
      return;
    }

    const model = {
      base64XmlDocument: base64XmlDocument,
      shiftId: shiftId,
      openShiftFiscalNum: openShiftFiscalNum,
    };

    await this._webHttp.post(_url, model).toPromise();
  }

  // ============= END SERVER API ===============

  // ============= TAX REGISER STATE===============

  async getZRepJson(
    taxRegisterFiscalNumber: string,
    zReportFiscalNumber: string
  ) {
    const config = await this.taxRegisterConfigService.getRegisterConfig(
      taxRegisterFiscalNumber
    );

    return this._taxApi.ctxGetZRepJson(
      config.cashier.subjectKeyId,
      taxRegisterFiscalNumber,
      zReportFiscalNumber
    );
  }

  async ctxGetZRepJson(
    cxtKey: string,
    taxRegisterFiscalNumber: string,
    zReportFiscalNumber: string
  ) {
    return this._taxApi.ctxGetZRepJson(
      cxtKey,
      taxRegisterFiscalNumber,
      zReportFiscalNumber
    );
  }

  async getDocument(
    taxRegisterFiscalNumber: string,
    document: DocumentItem,
    responseType:
      | 'Availability'
      | 'OriginalXml'
      | 'SignedByServerXml'
      | 'Visualization'
      | 'SignedBySenderXml'
      | 'SignedBySenderAndServerXml' = 'Visualization'
  ) {
    const config = await this.taxRegisterConfigService.getRegisterConfig(
      taxRegisterFiscalNumber
    );

    if (document.DocClass == 'Check') {
      return this._taxApi.ctxGetCheckExt(
        config.cashier.subjectKeyId,
        taxRegisterFiscalNumber,
        document.NumFiscal,
        responseType
      );
    }

    if (document.DocClass == 'ZRep') {
      return this._taxApi.ctxGetZRepExt(
        config.cashier.subjectKeyId,
        taxRegisterFiscalNumber,
        document.NumFiscal,
        responseType
      );
    }
  }

  // ============= END TAX REGISER STATE===============

  private _displayError(message: string, duration = 20000) {
    if (!message) {
      return;
    }

    this.toastCtrl
      .create({
        message: message,
        duration: duration,
        position: 'top',
        color: 'danger',
        buttons: ['OK'],
      })
      .then((toast) => toast.present());
  }
}
