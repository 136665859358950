import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StorageService } from './storage.service';

const NOTIFICATIONS = '__NOTIFICATIONS';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notifications: Observable<any[]>;
  notificationsData = new BehaviorSubject<any[]>(null);

  constructor(private storage: StorageService, private plt: Platform) {
    this._loadStoredNotifications();
    this.notifications.subscribe();
  }

  addNotification(notification: any): Observable<void> {
    if (!notification) {
      of();
    }

    let notifications = [];
    if (
      this.notificationsData.value &&
      this.notificationsData.value.length > 0
    ) {
      notifications = this.notificationsData.value;
    }

    const notificationByCode = notifications.find(
      (n) => n.code == notification.code
    );

    if (notificationByCode) {
      of();
    }

    notifications.unshift(notification);
    this.notificationsData.next(notifications);

    return from(this.storage.set(NOTIFICATIONS, notifications));
  }

  removeNotification() {
    this.notificationsData.next([]);
    return from(this.storage.set(NOTIFICATIONS, []));
  }

  private _loadStoredNotifications() {
    const platformObs = from(this.plt.ready());

    this.notifications = platformObs.pipe(
      switchMap(() => {
        return from(this.storage.get(NOTIFICATIONS));
      }),
      map((data) => {
        if (data) {
          this.notificationsData.next(data);
          return data;
        } else {
          return null;
        }
      })
    );
  }
}
