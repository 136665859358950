import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CurrencyService {
  constructor() {}

  get symbol() {
    return '₴';
  }

  get name() {
    return 'грн';
  }
}
