import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import EntityUtils from 'src/app/utility/entity-utils';

import EncodeUtils from 'src/app/utility/encode-utils';
import { AppEndUserOwnerInfo } from '../models/end-user-owner-info.model';
import { Device } from '@ionic-native/device/ngx';
import differenceInDays from 'date-fns/differenceInDays';
import { EndUserCertificate } from 'euscp/EndUserCertificate';
import { EndUserSignInfo } from 'euscp/EndUserSignInfo';
import { EndUserSettings } from 'euscp/EndUserSettings';
import { EndUserPrivateKeyContext } from 'euscp/EndUserContexts';

export interface IPrivateKeySettings {
  privateKeyFileName: string;
  privateKeyBase64: string;
  password: string;
  issuerAddress?: string;
  cmpAddress?: string;
  tspAddress?: string;
  tspAddressPort?: string;

  providerName: string;
  providerOcspAccessPointAddress?: string;
  providerOcspAccessPointPort?: string;
}

export interface IAgentSigner {
  initialize(): Promise<boolean>;
  isInitialized(): Promise<boolean>;
  ctxGetUserOwnerInfo(ctxKey: string): Promise<AppEndUserOwnerInfo>;
  readPrivateKey(
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo>;
  ctxReadPrivateKey(
    ctxKey: string,
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo>;
  ctxSignData(ctxKey: string, data: string): Promise<string>;
  verifyData(signedData: string): Promise<string>;
  finalize(): Promise<void>;
  ctxSignPackage(ctxKey: string, docs: string[]): Promise<string>;
}

@Injectable({ providedIn: 'root' })
export class AgentSigner {
  private privateKeyConfig = new BehaviorSubject<IPrivateKeySettings>(null);
  private userOwnerInfo = new BehaviorSubject<AppEndUserOwnerInfo>(null);

  private _agentSigner: IAgentSigner;
  private async _getAgentSigner(): Promise<IAgentSigner> {
    if (!this._agentSigner) {
      const platform = this.device.platform; //  'iOS' | 'web_browser' | 'Android'
      console.log(`[AgentSigner] device platform ${platform}`);

      if (platform == 'Android') {
        this._agentSigner = new CordovaAgentSigner();
      } else {
        const euscp = await import('euscp');
        this._agentSigner = new JsAgentSigner(euscp);
      }
    }

    return Promise.resolve(this._agentSigner);
  }

  constructor(private device: Device) {}

  async ctxSignData(ctxKey: string, data: string): Promise<string> {
    const signer = await this._getAgentSigner();
    return signer.ctxSignData(ctxKey, data);
  }
  async ctxSignPackage(ctxKey: string, data: string[]): Promise<string> {
    const signer = await this._getAgentSigner();
    return await signer.ctxSignPackage(ctxKey, data);
  }
  async ctxGetUserOwnerInfo(ctxKey: string): Promise<AppEndUserOwnerInfo> {
    const signer = await this._getAgentSigner();

    const ownerInfo = await signer.ctxGetUserOwnerInfo(ctxKey);
    return EntityUtils.toUpperKeys(ownerInfo) as AppEndUserOwnerInfo;
  }
  async readPrivateKey(
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo> {
    if (!privateKeyConfig) {
      throw new Error('Невірні налаштування ключа ЕЦП');
    }

    const signer = await this._getAgentSigner();
    await this.initialize();
    const userOwnerInfo = await signer.readPrivateKey(privateKeyConfig);
    this.privateKeyConfig.next(privateKeyConfig);
    this.userOwnerInfo.next(userOwnerInfo);

    return userOwnerInfo;
  }
  async ctxReadPrivateKey(
    ctxKey: string,
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo> {
    if (!privateKeyConfig) {
      throw new Error('Невірні налаштування ключа ЕЦП');
    }

    const signer = await this._getAgentSigner();
    await this.initialize();
    const userOwnerInfo = await signer.ctxReadPrivateKey(
      ctxKey,
      privateKeyConfig
    );
    return EntityUtils.toUpperKeys(userOwnerInfo) as AppEndUserOwnerInfo;
  }
  async initialize() {
    const signer = await this._getAgentSigner();
    const isInitialized = await signer.isInitialized();
    if (isInitialized) {
      return true;
    }

    const result = await signer.initialize();
    return result;
  }

  async verifyData(signedData: string): Promise<string> {
    const signer = await this._getAgentSigner();
    return signer.verifyData(signedData);
  }
  async finalizeAgetntSigner(): Promise<void> {
    const signer = await this._getAgentSigner();
    return signer.finalize();
  }
}
export class CordovaAgentSigner implements IAgentSigner {
  _agentSigner: any;
  constructor() {
    this._agentSigner = (window as any).cordova.plugins.TaxSigner;
  }
  ctxSignPackage(ctxKey: string, docs: string[]): Promise<string> {
    return new Promise((resolve, reject) => {
      this._agentSigner.ctxSignPackage(
        ctxKey,
        docs,
        (signedData) => resolve(signedData),
        (err) => reject(err)
      );
    });
  }
  ctxGetUserOwnerInfo(ctxKey: string): Promise<AppEndUserOwnerInfo> {
    return new Promise(async (resolve, reject) => {
      this._agentSigner.ctxGetUserOwnerInfo(
        ctxKey,
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  ctxSignData(ctxKey: string, data: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._agentSigner.ctxSignData(
        ctxKey,
        data,
        (signedData) => resolve(signedData),
        (err) => reject(err)
      );
    });
  }
  isInitialized(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this._agentSigner.isInitialized(
        (info: boolean) => resolve(info),
        (err) => reject(err)
      );
    });
  }
  ctxReadPrivateKey(
    ctxKey: string,
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo> {
    return new Promise(async (resolve, reject) => {
      this._agentSigner.ctxReadPrivateKey(
        ctxKey,
        privateKeyConfig.privateKeyBase64,
        privateKeyConfig.password,
        privateKeyConfig.cmpAddress,
        privateKeyConfig.tspAddress,
        privateKeyConfig.tspAddressPort,
        (ownerInfo) => resolve(ownerInfo),
        (err) => reject(err)
      );
    });
  }
  getOwnCertificates(): Promise<EndUserCertificate[]> {
    return new Promise(async (resolve, reject) => {
      this._agentSigner.getOwnCertificates(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  async readPrivateKey(
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo> {
    return new Promise(async (resolve, reject) => {
      this._agentSigner.readPrivateKey(
        privateKeyConfig.privateKeyBase64,
        privateKeyConfig.password,
        privateKeyConfig.cmpAddress,
        privateKeyConfig.tspAddress,
        privateKeyConfig.tspAddressPort,
        (ownerInfo) => resolve(ownerInfo),
        (err) => reject(err)
      );
    });
  }

  async initialize(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this._agentSigner.initialize(
        (info: boolean) => resolve(info),
        (err) => reject(err)
      );
    });
  }

  async verifyData(signedData: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._agentSigner.verifyData(
        signedData,
        (data) => resolve(data),
        (err) => reject(err)
      );
    });
  }
  async finalize(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this._agentSigner.finalize(
        () => resolve(),
        (err) => reject(err)
      );
    });
  }
}
export class JsAgentSigner implements IAgentSigner {
  private readonly EU_SIGN_INCLUDE_CONTENT_TIME_STAMP_PARAMETER =
    'SignIncludeContentTimeStamp';

  private _endUserLibraryType =
    this.euscp.EndUserConstants.EndUserLibraryType.JS;

  private euSign: any;
  private get httpProtocol(): string {
    if (location.protocol == 'ionic:') {
      return 'http:';
    }
    return location.protocol;
  }

  private euSettings: EndUserSettings = {
    language: 'uk',
    encoding: 'utf-8',
    httpProxyServiceURL:
      this.httpProtocol + '//' + 'tax-proxy.turbopos.net/ProxyHandler.php',
    directAccess: true,
    CAs: 'assets/data/CAs.json',
    CACertificates: 'assets/data/CACertificates.p7b',
    allowedKeyMediaTypes: [
      'е.ключ ІІТ Алмаз-1К',
      'е.ключ ІІТ Кристал-1',
      'ID-карта громадянина (БЕН)',
      'е.ключ ІІТ Алмаз-1К (PKCS#11)',
      'е.ключ ІІТ Кристал-1 (PKCS#11)',
    ],
  };

  private _endUserContexts: {
    [key: string]: {
      pkContext: EndUserPrivateKeyContext;
    };
  } = {};

  constructor(
    private euscp: {
      EndUser;
      EndUserCertificate;
      EndUserConstants;
      EndUserKeyMedia;
      EndUserPrivateKeyContext;
      EndUserSettings;
      EndUserSignInfo;
    }
  ) {}

  async ctxSignPackage(ctxKey: string, docs: string[]): Promise<string> {
    let offlinePackage = new Uint8Array(0);
    for (let xmlDoc of docs) {
      const signedXml = await this._ctxSignData2(ctxKey, xmlDoc);
      const docSize = EncodeUtils.toBytesInt32(signedXml.byteLength).reverse();
      offlinePackage = new Uint8Array([
        ...offlinePackage,
        ...docSize,
        ...signedXml,
      ]);
    }
    return this._ctxSignDataBytes(ctxKey, offlinePackage);
  }

  async isInitialized(): Promise<boolean> {
    if (!this.euSign) {
      return false;
    }

    return this.euSign.IsInitialized();
  }

  async ctxGetUserOwnerInfo(ctxKey: string): Promise<AppEndUserOwnerInfo> {
    const endUserContext = this._getContext(ctxKey);
    if (!endUserContext) {
      throw new Error('Ключ не зчитано');
    }

    return this._toAppEndUser(endUserContext.pkContext);
  }

  getOwnCertificates(): Promise<EndUserCertificate[]> {
    return this.euSign.GetOwnCertificates();
  }
  async ctxReadPrivateKey(
    ctxKey: string,
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo> {
    var caCN = privateKeyConfig.providerName;
    var pkCertificates = null;

    if (
      privateKeyConfig.privateKeyBase64 == null ||
      privateKeyConfig.privateKeyBase64 == ''
    ) {
      throw new Error('Не обрано файл з ос. ключем');
    }

    if (privateKeyConfig.password == null || privateKeyConfig.password == '') {
      throw new Error('Не вказано пароль до ос. ключа');
    }

    let euPrivateKeyContext: EndUserPrivateKeyContext;

    const context = this._getContext(ctxKey);
    if (context) {
      return this._toAppEndUser(context.pkContext);
    }

    const privateKeyData = EncodeUtils.base64ToArrayBuffer(
      privateKeyConfig.privateKeyBase64
    );
    if (privateKeyConfig.privateKeyFileName.endsWith('.jks')) {
      const jksKeys = await this.euSign.GetJKSPrivateKeys(privateKeyData);
      console.log('EndUser: jks keys got');
      euPrivateKeyContext = await this.euSign.CtxReadPrivateKeyBinary(
        null,
        jksKeys[0].privateKey,
        privateKeyConfig.password,
        pkCertificates,
        caCN
      );
    } else {
      euPrivateKeyContext = await this.euSign.CtxReadPrivateKeyBinary(
        null,
        privateKeyData,
        privateKeyConfig.password,
        pkCertificates,
        caCN
      );
    }

    this._setContext(ctxKey, euPrivateKeyContext);
    return this._toAppEndUser(euPrivateKeyContext);
  }

  private async _toAppEndUser(
    euPrivateKeyContext: EndUserPrivateKeyContext
  ): Promise<AppEndUserOwnerInfo> {
    var certificates = await this.euSign.CtxGetOwnCertificates(
      euPrivateKeyContext
    );

    const model: any = euPrivateKeyContext.ownerInfo;
    model.CertInfo = certificates[0].infoEx;
    if (model?.CertInfo?.publicKeyID) {
      model.SubjectKeyId = model.CertInfo.publicKeyID.replace(/ /g, '');
    }

    if (model?.CertInfo?.privKeyEndTime) {
      const _now = new Date();
      model.DaysToEnd = differenceInDays(model?.CertInfo?.privKeyEndTime, _now);
    }

    return EntityUtils.toUpperKeys(model) as AppEndUserOwnerInfo;
  }

  async readPrivateKey(
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo> {
    var useFile =
      this._endUserLibraryType ==
      this.euscp.EndUserConstants.EndUserLibraryType.JS;

    // var pkFileInput = useFile ? document.getElementById('pkFile') : null;
    // var passwordInput = document.getElementById(
    //   useFile ? 'pkFilePassword' : 'pkKeyMediaPassword'
    // );
    var selectedKM = null; // = useFile ? null : getSelectedKeyMedia();
    // var kmSelect = document.getElementById('pkKeyMediaSelect');
    /*
      Загальне ім'я ЦСК з списку CAs.json, який видав сертифікат для ос. ключа.
      Якщо null бібліотека намагається визначити ЦСК автоматично за 
      сервером CMP\сертифікатом. Встановлюється у випадках, коли ЦСК не 
      підтримує CMP, та для пришвидшення пошуку сертифіката ос. ключа
    */
    var caCN = null;
    /*
      Сертифікати, що відповідають ос. ключу (масив об'єктів типу Uint8Array). 
      Якщо null бібліотека намагається завантажити їх з ЦСК автоматично з сервера CMP.
      Встановлюється у випадках, коли ЦСК не підтримує CMP, та для пришвидшення 
      пошуку сертифіката ос. ключа
    */
    var pkCertificates = null;

    if (
      useFile &&
      (privateKeyConfig.privateKeyBase64 == null ||
        privateKeyConfig.privateKeyBase64 == '')
    ) {
      throw new Error('Не обрано файл з ос. ключем');
    }

    if (!useFile && !selectedKM) {
      throw new Error('Не обрано носій з ос. ключем');
    }

    if (privateKeyConfig.password == null || privateKeyConfig.password == '') {
      throw new Error('Не вказано пароль до ос. ключа');
    }

    let userOwnerInfo: any;
    if (useFile) {
      const privateKeyData = EncodeUtils.base64ToArrayBuffer(
        privateKeyConfig.privateKeyBase64
      );
      if (privateKeyConfig.privateKeyFileName.endsWith('.jks')) {
        const jksKeys = await this.euSign.GetJKSPrivateKeys(privateKeyData);
        console.log('EndUser: jks keys got');
        userOwnerInfo = await this.euSign.ReadPrivateKeyBinary(
          jksKeys[0].privateKey,
          privateKeyConfig.password,
          pkCertificates,
          caCN
        );
      } else {
        userOwnerInfo = await this.euSign.ReadPrivateKeyBinary(
          privateKeyData,
          privateKeyConfig.password,
          pkCertificates,
          caCN
        );
      }
    } else {
      var keyMedia = new this.euscp.EndUserKeyMedia(selectedKM);
      keyMedia.password = privateKeyConfig.password;
      userOwnerInfo = await this.euSign.ReadPrivateKey(
        keyMedia,
        pkCertificates,
        caCN
      );
    }

    var certificates = await this.getOwnCertificates();
    userOwnerInfo.CertInfo = certificates[0].infoEx;
    userOwnerInfo.SubjectKeyId = userOwnerInfo.CertInfo.publicKeyID.replace(
      / /g,
      ''
    );

    return EntityUtils.toUpperKeys(userOwnerInfo) as AppEndUserOwnerInfo;
  }

  async initialize(): Promise<boolean> {
    this.euSign = this._getLibraryByType(
      this.euscp.EndUserConstants.EndUserLibraryType.JS
    );
    this.euSign.SetRuntimeParameter(
      this.EU_SIGN_INCLUDE_CONTENT_TIME_STAMP_PARAMETER,
      false
    );
    this.euSign.SetRuntimeParameter(
      this.euscp.EndUserConstants.EU_SIGN_TYPE_PARAMETER,
      this.euscp.EndUserConstants.EndUserSignType.CAdES_T
    );

    if (
      this._endUserLibraryType ==
      this.euscp.EndUserConstants.EndUserLibraryType.JS
    ) {
      const isInitialized = await this.euSign.IsInitialized();
      if (isInitialized) {
        console.log('EndUser: already initialized');
      } else {
        console.log('EndUser: initializing...');
        await this.euSign.Initialize(this.euSettings);
        console.log('EndUser: initialized');
      }
    } else {
      // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
      const libraryInfo: any = await this.euSign.GetLibraryInfo();
      if (!libraryInfo.supported) {
        throw (
          'Бібліотека web-підпису не підтримується ' +
          'в вашому браузері або ОС'
        );
      }

      if (!libraryInfo.loaded) {
        // Бібліотека встановлена, але потребує оновлення
        if (libraryInfo.isNativeLibraryNeedUpdate) {
          throw (
            'Бібліотека web-підпису потребує оновлення. ' +
            'Будь ласка, встановіть оновлення за посиланням ' +
            libraryInfo.nativeLibraryInstallURL
          );
        }

        // Якщо браузер підтримує web-розширення рекомендується
        // додатково до нативних модулів встановлювати web-розширення
        // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
        if (
          libraryInfo.isWebExtensionSupported &&
          !libraryInfo.isWebExtensionInstalled
        ) {
          throw (
            'Бібліотека web-підпису потребує встановлення web-розширення. ' +
            'Будь ласка, встановіть web-розширення за посиланням ' +
            libraryInfo.webExtensionInstallURL +
            ' та оновіть сторінку'
          );
        }

        // Бібліотека (нативні модулі) не встановлені
        throw (
          'Бібліотека web-підпису потребує встановлення. ' +
          'Будь ласка, встановіть бібліотеку за посиланням ' +
          libraryInfo.nativeLibraryInstallURL +
          ' та оновіть сторінку'
        );
      }

      const isInitialized = await this.euSign.IsInitialized();
      if (isInitialized) {
        console.log('EndUser: already initialized');
      } else {
        console.log('EndUser: initializing...');
        await this.euSign.Initialize(this.euSettings);
        console.log('EndUser: initialized');
      }
    }

    return true;
  }

  async ctxSignData(ctxKey: string, data: string): Promise<string> {
    const win1251string = EncodeUtils.unicodeToWin1251(data);
    const win1251array = EncodeUtils.stringToArrayBuffer(win1251string);

    const privateKeyContext = this._endUserContexts[ctxKey.toLocaleLowerCase()];
    if (!privateKeyContext) {
      throw 'Підпис не налаштовано';
    }

    const result = await this.euSign.CtxSignData(
      privateKeyContext.pkContext,
      this.euscp.EndUserConstants.EndUserSignAlgo.DSTU4145WithGOST34311,
      win1251array,
      false,
      true,
      true
    );

    return result as string;
  }

  async _ctxSignData2(ctxKey: string, data: string): Promise<Uint8Array> {
    const win1251string = EncodeUtils.unicodeToWin1251(data);
    const win1251array = EncodeUtils.stringToArrayBuffer(win1251string);

    const privateKeyContext = this._endUserContexts[ctxKey.toLocaleLowerCase()];
    if (!privateKeyContext) {
      throw 'Підпис не налаштовано';
    }

    const result = await this.euSign.CtxSignData(
      privateKeyContext.pkContext,
      this.euscp.EndUserConstants.EndUserSignAlgo.DSTU4145WithGOST34311,
      win1251array,
      false,
      true,
      false
    );

    return result as Uint8Array;
  }

  async _ctxSignDataBytes(ctxKey: string, data: Uint8Array): Promise<string> {
    const privateKeyContext = this._endUserContexts[ctxKey.toLocaleLowerCase()];
    if (!privateKeyContext) {
      throw 'Підпис не налаштовано';
    }

    const result = await this.euSign.CtxSignData(
      privateKeyContext.pkContext,
      this.euscp.EndUserConstants.EndUserSignAlgo.DSTU4145WithGOST34311,
      data,
      false,
      true,
      true
    );

    return result as string;
  }

  async verifyData(signedData: string): Promise<string> {
    console.log('VerifyDataInternal start');
    const arrayBuffer = EncodeUtils.base64ToArrayBuffer(signedData);
    const signInfo = await this.euSign.VerifyDataInternal(arrayBuffer);
    console.log('VerifyDataInternal finish');
    console.log(signInfo);

    let data: Uint8Array;
    const arrayResponse = <EndUserSignInfo[]>signInfo;
    if (arrayResponse.length > 0) {
      data = arrayResponse[0].data;
    } else {
      data = (<EndUserSignInfo>signInfo).data;
    }

    const result = await EncodeUtils.arraybufferToBase64Async(data);
    console.log('verifyData');
    console.log(window.atob(result));
    return window.atob(result);
  }

  async finalize(): Promise<void> {}

  private _getLibraryByType(type): any {
    if (this.euSign) {
      return this.euSign;
    }

    if (!this._endUserLibraryType) {
      this._endUserLibraryType =
        this.euscp.EndUserConstants.EndUserLibraryType.JS;
    }

    switch (type) {
      case this.euscp.EndUserConstants.EndUserLibraryType.JS:
        // Бібліотека для роботи з файловими ключами, що не потребує
        // встановлення додатково ПЗ
        return new this.euscp.EndUser(
          null,
          this.euscp.EndUserConstants.EndUserLibraryType.JS
        );

      case this.euscp.EndUserConstants.EndUserLibraryType.SW:
        var keyMedias = [];
        return new this.euscp.EndUser(
          null,
          this.euscp.EndUserConstants.EndUserLibraryType.SW
        );
    }
  }

  private _getContext(ctxKey: string) {
    const _ctxKey = ctxKey?.toLocaleLowerCase();
    return this._endUserContexts[_ctxKey];
  }

  private _setContext(ctxKey: string, context: EndUserPrivateKeyContext) {
    const _ctxKey = ctxKey?.toLocaleLowerCase();
    this._endUserContexts[_ctxKey] = { pkContext: context };
  }
}

export class HttpSigner implements IAgentSigner {
  private _http: HttpClient;
  private _signerUrl = 'http://localhost:5000/api/sign';

  constructor(private _httpBackend: HttpBackend) {
    this._http = new HttpClient(this._httpBackend);
  }
  ctxSignPackage(ctxKey: string, docs: string[]): Promise<string> {
    throw new Error('Method not implemented.');
  }
  ctxSignData2(ctxKey: string, data: string): Promise<Uint8Array> {
    throw new Error('Method not implemented.');
  }
  ctxSignDataBytes(ctxKey: string, data: Uint8Array): Promise<string> {
    throw new Error('Method not implemented.');
  }
  ctxGetUserOwnerInfo(ctxKey: string): Promise<AppEndUserOwnerInfo> {
    throw new Error('Method not implemented.');
  }
  ctxSignData(ctxKey: string, data: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
  isInitialized(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  ctxGetOwnCertificates(ctxKey: string): Promise<EndUserCertificate[]> {
    throw new Error('Method not implemented.');
  }
  ctxReadPrivateKey(
    ctxKey: string,
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo> {
    throw new Error('Method not implemented.');
  }
  getOwnCertificates(): Promise<EndUserCertificate[]> {
    throw new Error('Method not implemented.');
  }
  async readPrivateKey(
    privateKeyConfig: IPrivateKeySettings
  ): Promise<AppEndUserOwnerInfo> {
    return this._http
      .post<AppEndUserOwnerInfo>(`${this._signerUrl}/readPrivateKey`, {
        PrivateKeyBase64: privateKeyConfig.privateKeyBase64,
        Password: privateKeyConfig.password,
      })
      .toPromise();
  }

  async initialize(): Promise<boolean> {
    return this._http
      .get<boolean>(`${this._signerUrl}/initialize`)
      .toPromise()
      .then(() => true);
  }

  verifyData(signedData: string): Promise<string> {
    throw new Error('Method verifyData not implemented.');
  }
  finalize(): Promise<void> {
    throw new Error('Method finalize not implemented.');
  }
}
