import { Injectable } from '@angular/core';
import { GenericRepository, IGenericItem } from './generic.repository';

export class EmployeeItem extends IGenericItem {
  firstname?: string;
  full_name?: string;
  id?: number;
  is_owner?: false;
  lastname?: string;
  phone?: string;
  roles?: string[];
  terminalcode?: string;
  uid?: string;
  userid?: string;
  lastActivity?: Date;
}

const TABLE_KEY = '__employees';
@Injectable({ providedIn: 'root' })
export class EmployeeRepository {
  constructor(private _repository: GenericRepository) {}

  getEmployeesMap(): Promise<Map<string, EmployeeItem>> {
    return this._repository
      .getItems(TABLE_KEY)
      .then(
        (employees: EmployeeItem[]) =>
          new Map(employees.map((employee) => [employee.userid, employee]))
      );
  }

  getEmployees(): Promise<EmployeeItem[]> {
    return this._repository.getItems<EmployeeItem>(TABLE_KEY);
  }

  getEmployee(employeeId: string): Promise<EmployeeItem> {
    return this.getEmployees().then((employees: EmployeeItem[]) =>
      employees.find((employee) => employee.userid == employeeId)
    );
  }

  loadEmployees(employees: EmployeeItem[]): Promise<void> {
    return this._repository.getItems(TABLE_KEY).then((res: EmployeeItem[]) => {
      employees.forEach((employee) => {
        const storedEmployee = res.find((e) => e.userid == employee.userid);
        if (storedEmployee != null) {
          employee.lastActivity = storedEmployee.lastActivity;
        }
      });

      return this._repository.loadItems(TABLE_KEY, employees);
    });
  }

  saveEmployee(employee: EmployeeItem): Promise<void> {
    return this._repository.updateItem(TABLE_KEY, employee, 'userid');
  }

  clearStorage() {
    return this._repository.deleteTable(TABLE_KEY);
  }
}
