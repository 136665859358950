import { Component, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import { format } from 'date-fns';

@Component({
  selector: 'app-touch-datepicker',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <span *ngIf="isRange"> Оберіть період </span>
          <span *ngIf="!isRange"> Оберіть дату </span>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button color="dark" (click)="dimissDatepicker()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-item [hidden]="!isRange" class="mb-3">
              <ion-label><h2>Початок періоду</h2></ion-label></ion-item
            >
            <ion-datetime
              class="tp-datepicker"
              #dateTime
              [presentation]="presentation"
              size="cover"
              (ionChange)="change($event)"
              locale="uk-UA"
              [firstDayOfWeek]="1"
              #fromDateTime
              mode="md"
              ><span slot="time-label">Час</span></ion-datetime
            >
          </ion-col>
          <ion-col [hidden]="!isRange">
            <ion-item class="mb-3">
              <ion-label><h2>Кінець періоду</h2></ion-label></ion-item
            >
            <ion-datetime
              class="tp-datepicker"
              [presentation]="presentation"
              size="cover"
              (ionChange)="changeToDate($event)"
              locale="uk-UA"
              [firstDayOfWeek]="1"
              #toDateTime
              mode="md"
            >
              <span slot="time-label">Час</span></ion-datetime
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-title *ngIf="isRange" slot="start">
          <span class="pl-3">
            <span class="date-label">{{ fromDate | date : 'dd.MM.yyyy' }}</span>
            <span class="px-3">-</span>
            <span class="date-label">{{ toDate | date : 'dd.MM.yyyy' }}</span>
          </span>
        </ion-title>
        <ion-buttons slot="end"
          ><ion-button
            color="primary"
            [disabled]="!isChanged"
            (click)="confirm()"
            >Вибрати</ion-button
          >
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  `,
  styleUrls: ['./touch-datepicker.component.scss'],
})
export class TouchDatepickerComponent implements OnInit {
  @ViewChild('fromDateTime') fromDatepicker: IonDatetime;
  @ViewChild('toDateTime') toDatepicker: IonDatetime;

  isChanged = false;
  isRange = false;
  type: 'range' | 'date' = 'date';
  toDate: Date;
  fromDate: Date;
  presentation:
    | 'date'
    | 'date-time'
    | 'month'
    | 'month-year'
    | 'time'
    | 'time-date'
    | 'year' = 'date';
  hasTime = false;
  constructor(private _modalCtrl: ModalController) {}

  ngAfterViewInit() {
    this.hasTime = this.presentation == 'date-time';
    this.isRange = this.type == 'range';

    setTimeout(() => {
      if (this.fromDate) {
        this.fromDatepicker.reset(this._toLocalDateString(this.fromDate));
      }

      if (this.toDate) {
        this.toDatepicker.reset(this._toLocalDateString(this.toDate));
      }
    }, 1000);
  }

  ngOnInit() {}

  change(event: any) {
    if (!event.detail) {
      return;
    }

    this.isChanged = true;
    this.fromDate = new Date(event.detail.value);
  }

  changeToDate(event: any) {
    if (!event.detail) {
      return;
    }

    this.isChanged = true;
    this.toDate = new Date(event.detail.value);
  }

  confirm() {
    if (this.isRange) {
      this._modalCtrl.dismiss(
        { fromDate: this.fromDate, toDate: this.toDate },
        'confirm'
      );
    } else {
      this._modalCtrl.dismiss(this.fromDate, 'confirm');
    }
  }

  dimissDatepicker() {
    this._modalCtrl.dismiss(null, 'dismiss');
  }

  private _toLocalDateString(date: Date) {
    return (
      format(date, 'yyyy-MM-dd') + 'T' + format(date, 'HH:mm:ss') + '+03:00'
    );
  }
}
