import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
const DEFAULT_DB_NAME = '__nt_soft_pos_terminal';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storages: {
    [key: string]: Storage;
  } = {};

  constructor(private _platform: Platform) {}

  async set(key: string, value: any) {
    const _storage = await this.getDefaultStorage();
    return _storage.set(key, value);
  }

  async get(key: string) {
    const _storage = await this.getDefaultStorage();
    return _storage.get(key);
  }

  async remove(key: string) {
    const _storage = await this.getDefaultStorage();
    return _storage.remove(key);
  }

  async keys() {
    const _storage = await this.getDefaultStorage();
    return _storage.keys();
  }

  async getNamedStorage(storageName: string): Promise<Storage> {
    if (!storageName) {
      return Promise.reject('Storage name is required.');
    }

    if (this._storages[storageName]) {
      return Promise.resolve(this._storages[storageName]);
    }

    return this._platform.ready().then((platform) => {
      const _storage = new Storage(
        {
          name: storageName,
          driverOrder: ['sqlite', 'indexeddb', 'websql', 'localstorage'],
        },
        platform
      );

      this._storages[storageName] = _storage;
      return _storage;
    });
  }

  async getDefaultStorage(): Promise<Storage> {
    return this.getNamedStorage(DEFAULT_DB_NAME);
  }
}
