<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>{{ header }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" color="dark">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list *ngIf="columns <= 1">
    <ion-item
      button
      detail="true"
      *ngFor="let item of items"
      (click)="selectItem(item)"
    >
      <ion-label>
        <h2>{{ item.label }}</h2>
        <p *ngIf="item.note">
          <small>{{ item.note }}</small>
        </p>
        <p *ngIf="item.subLabel">{{ item.subLabel }}</p>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-grid *ngIf="columns > 1">
    <ion-row *ngFor="let row of rows">
      <ion-col *ngFor="let item of row">
        <ion-item
          *ngIf="item.label"
          button
          detail="true"
          (click)="selectItem(item)"
        >
          <ion-label>
            <h2>{{ item.label }}</h2>
            <p *ngIf="item.note">
              <small>{{ item.note }}</small>
            </p>
            <p *ngIf="item.subLabel">{{ item.subLabel }}</p>
          </ion-label>
        </ion-item></ion-col
      >
    </ion-row>
  </ion-grid>
</ion-content>
