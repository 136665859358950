<ion-content class="ion-padding">
  <div class="ion-text-center">
    <h1>Функції терміналу обмежені</h1>
  </div>
  <ion-item lines="none">
    <ion-label>
      <h1>Необхідно оплатити підписку</h1>
    </ion-label>
  </ion-item>
  <ion-item lines="none">
    <ion-label>
      Термінал буде розблоковано через {{ diffSeconds }} секунд
    </ion-label>
  </ion-item>
</ion-content>
