import { EventEmitter, Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { APP_CONFIG, SettingsService } from './settings.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeviceHubConnector {
  private _deviceHubConnection: HubConnection;
  private _onConnectedTrigger: (value: HubConnection) => void;
  private _onConnected: Promise<HubConnection> = new Promise((resolve) => {
    this._onConnectedTrigger = resolve;
    if (
      this._deviceHubConnection &&
      this._deviceHubConnection.state == 'Connected'
    ) {
      this._onConnectedTrigger(this._deviceHubConnection);
    }
  });

  constructor(
    private _device: Device,
    private _platform: Platform,
    private settings: SettingsService
  ) {}

  async connectToHub(): Promise<HubConnection> {
    // return Promise.resolve({ on: () => {} } as any);
    await this._platform.ready();
    const device = this._device;

    if (!device || !device.uuid) {
      throw Error('Device not provided.');
    }

    if (this._deviceHubConnection) {
      return this._onConnected;
    }

    // 'https://localhost:7048'; //
    const deviceApiUrl = this.settings.getValue(APP_CONFIG.DEVICE_API_URL);
    const url = `${deviceApiUrl}/device?uuid=${device.uuid}&platform=${device.platform}&manufacturer=${device.manufacturer}&model=${device.model}&serial=${device.serial}`;
    this._deviceHubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => device.uuid,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([10000, 20000])
      .build();

    this._deviceHubConnection.onclose(() => {
      this._deviceHubConnection = null;
    });

    this._deviceHubConnection.onreconnected(() => {
      this._onConnectedTrigger(this._deviceHubConnection);
    });

    this._deviceHubConnection.onreconnecting(() => {});

    return new Promise((resolve, reject) => {
      this._deviceHubConnection
        .start()
        .then(() => {
          resolve(this._deviceHubConnection);
          if (this._onConnectedTrigger) {
            this._onConnectedTrigger(this._deviceHubConnection);
          }
        })
        .catch((err) => {
          console.log('Error while establishing connection :(');
          console.log(err);
          this._deviceHubConnection = null;

          reject(err);
        });
    });
  }

  onMethod(method: string) {
    return new Observable((observer) => {
      this._deviceHubConnection.on(method, (args) => {
        observer.next(args);
      });
    });
  }

  receiveMessage(method: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this._deviceHubConnection.on(method, (res) => resolve(res));
      } catch (err) {
        reject(err);
      }
    });
  }

  async receiveDeviceCompany(
    companyId: string,
    companyName: string,
    storeName: string
  ) {
    return;

    const connection = await this.connectToHub();
    const device = this._device;
    connection.send(
      'ReceiveDeviceCompany',
      companyId,
      null,
      storeName,
      companyName,
      null, // storeId
      device.uuid,
      device.isVirtual,
      device.manufacturer,
      device.model,
      device.platform,
      device.serial,
      device.version
    );
  }

  send(methodName: string, ...args: any[]) {}
}
