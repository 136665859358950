import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LanguagesService } from 'src/app/services/languages.service';

@Component({
  templateUrl: './work-shift-dialog.component.html',
})
export class WorkShiftDialogComponent implements OnInit {
  employeeSession: any;
  loading = false;
  confirmClose = false;
  transl: any = {};
  closeSessionAction: () => Promise<void>;
  getSessionAction: () => Promise<any>;
  getSessionReportAction: () => Promise<any>;

  constructor(
    private languagesService: LanguagesService,
    private _modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this._getEmployeeSession();

    this.languagesService
      .get([
        'common.employee',
        'common.start',
        'common.end',
        'common.cancel',
        'work-shift.title',
        'work-shift.orders',
        'work-shift.average-order',
        'work-shift.average-products',
        'work-shift.close-session',
        'work-shift.confirm-close-session',
      ])
      .subscribe((res) => {
        this.transl = res;
      });
  }

  closeSession = () => {
    this.confirmClose = true;
  };

  confirmCloseSession() {
    this.loading = true;
    this.closeSessionAction().then(() => this._modalCtrl.dismiss());
  }

  cancelCloseSession() {
    this.confirmClose = false;
  }

  private _getEmployeeSession() {
    this.loading = true;

    this.getSessionAction()
      .then((session) => {
        if (!session) {
          return null;
        } else {
          return this.getSessionReportAction();
        }
      })
      .then((res) => {
        if (!res) {
          this._modalCtrl.dismiss();
        } else {
          this.employeeSession = res;
          this.loading = false;
        }
      });
  }

  sessionReport() {}

  close() {
    this._modalCtrl.dismiss();
  }
}
