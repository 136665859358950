import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { differenceInSeconds } from 'date-fns';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-terminal-locked',
  templateUrl: './terminal-locked.component.html',
  styleUrls: ['./terminal-locked.component.scss'],
})
export class TerminalLockedComponent implements OnInit, OnDestroy {
  dismissAt: Date;
  dismissTimer = timer(1000, 1000);
  private _sub = new Subscription();
  diffSeconds: number;

  constructor(private modalCtrl: ModalController) {}
  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  ngOnInit() {
    this._sub = this.dismissTimer.subscribe(() => {
      if (!this.dismissAt) {
        this.modalCtrl.dismiss();
      } else {
        const _now = new Date();
        const diffSeconds = differenceInSeconds(this.dismissAt, _now);
        this.diffSeconds = diffSeconds;
        if (diffSeconds <= 0) {
          this.modalCtrl.dismiss();
        }
      }
    });
  }
}
