import { parseISO } from 'date-fns';
import { OrderModel } from '../orders/order.model';
import { ReceiptFormatterService } from '../private/terminal/receipt-formatter.service';
import { OrderDTO } from '../private/terminal/repositories/orders.repository';
import { IReceipt } from '../private/terminal/terminal.models';
import DateUtils from './date-utils';
import MoneyUtils from './money-utils';
import * as QRCode from 'qrcode';

export default class OrderUtils {
  static getTaxLink(
    taxCheckUrl: string,
    fiscalNumber: string,
    taxRegisterFiscalNumber: string,
    sm: number,
    dateStr: string
  ) {
    if (!fiscalNumber || !taxRegisterFiscalNumber) {
      return null;
    }

    let date: Date;
    if (!dateStr) {
      date = new Date();
    } else {
      date = parseISO(dateStr);
    }

    const _date = this.formatOrderDate(date);
    const _time = this.formatOrderTime(date);
    const _sm = sm.toFixed(2).replace(',', '.');
    return `${taxCheckUrl}?fn=${taxRegisterFiscalNumber}&id=${fiscalNumber}&date=${_date}&time=${_time}&sm=${_sm}`;
  }

  static async getTaxLinkQrCode(
    taxCheckUrl: string,
    fiscalNumber: string,
    taxRegisterFiscalNumber: string,
    sm: number,
    date: string
  ) {
    if (!fiscalNumber) {
      return '';
    }

    const _taxLink = this.getTaxLink(
      taxCheckUrl,
      fiscalNumber,
      taxRegisterFiscalNumber,
      sm,
      date
    );
    return QRCode.toDataURL(_taxLink, {
      type: 'terminal',
      width: '120',
    });
  }

  static formatOrderDate(date: Date) {
    let _date = `0${date.getDate()}`;
    _date = _date.substring(_date.length - 2);

    let _month = `0${date.getMonth() + 1}`;
    _month = _month.substring(_month.length - 2);

    const _year = date.getFullYear();
    return `${_year}${_month}${_date}`;
  }

  static formatOrderTime(date: Date) {
    let _hours = `0${date.getHours()}`;
    _hours = _hours.substring(_hours.length - 2);

    let _minutes = `0${date.getMinutes()}`;
    _minutes = _minutes.substring(_minutes.length - 2);

    let _seconds = `0${date.getSeconds()}`;
    _seconds = _seconds.substring(_seconds.length - 2);

    return `${_hours}${_minutes}${_seconds}`;
  }

  static formatOrderAsText(
    order: OrderModel,
    taxCheckUrl: string,
    printWidth: number = 32
  ): string {
    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    let taxLink = null;

    if (order.fiscalNumber && order.taxRegisterFiscalNumber) {
      taxLink = this.getTaxLink(
        taxCheckUrl,
        order.fiscalNumber,
        order.taxRegisterFiscalNumber,
        order.totalAmount,
        order.created
      );
    }
    _receiptFormatterService.config.width = printWidth;
    let printLines = this._outputOrderText(order, '');

    return _receiptFormatterService.create(printLines);
  }

  static formatAsText(
    receipt: OrderDTO | IReceipt,
    taxCheckUrl: string,
    header: string,
    footer: boolean,
    printWidth: number = 32
  ): string {
    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    const order = new OrderDTO(receipt);
    let taxLink = null;

    if (order.fiscalNumber && order.taxRegisterFiscalNumber) {
      taxLink = this.getTaxLink(
        taxCheckUrl,
        order.fiscalNumber,
        order.taxRegisterFiscalNumber,
        order.totalAmount,
        order.created
      );
    }
    _receiptFormatterService.config.width = printWidth;
    let printLines = this._outputText(order, header, footer);

    return _receiptFormatterService.create(printLines);
  }

  //   static formatAsHtml(value: string): string {
  //     return parseFloat(value);
  //   }

  private static _outputOrderText(
    order: OrderModel,
    header: string,
    isCopy = false
  ): any[] {
    const lines: any[] = [];

    if (isCopy) {
      lines.push({
        type: 'text',
        value: '############################',
        align: 'center',
      });

      lines.push({
        type: 'text',
        value: 'КОПІЯ',
        align: 'center',
      });
      lines.push({
        type: 'text',
        value: '############################',
        align: 'center',
      });
    }

    let head: any;
    if (order.taxDocument) {
      if (order.taxDocument.CHECK) {
        head = order.taxDocument.CHECK.CHECKHEAD;
        const body = order.taxDocument.CHECK.CHECKBODY;
        const pay = order.taxDocument.CHECK.CHECKPAY;
        const total = order.taxDocument.CHECK.CHECKTOTAL;

        const orgType = head.IPN ? 'ТОВ' : 'ФОП';
        const orgId = head.IPN || head.TIN;
        lines.push({
          type: 'text',
          value: [`${orgType} ${head.ORGNM}`],
          align: 'center',
        });

        lines.push({
          type: 'text',
          value: [head.POINTNM],
          align: 'center',
        });

        lines.push({
          type: 'text',
          value: [head.POINTADDR],
          align: 'center',
        });

        lines.push({
          type: 'text',
          value: ['ІД ' + orgId],
        });

        if (head.DOCSUBTYPE == 1) {
          lines.push({
            type: 'text',
            value: ['Видатковий чек (повернення)'],
          });
        }

        lines.push({
          type: 'text',
          value: ['Касовий чек'],
        });

        lines.push({
          type: 'text',
          value: [
            'ПРРО ФН ' + head.CASHREGISTERNUM + ` ВН ${head.CASHDESKNUM}`,
          ],
        });
        lines.push({
          type: 'text',
          value: [
            'ЧЕК ФН ' + order.fiscalNumber + ` ВН ${head.ORDERNUM} онлайн`,
          ],
        });

        if (head.TESTING == 'true') {
          lines.push({
            type: 'text',
            value: ['ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ'],
          });
        }

        lines.push({
          type: 'text',
          value: ['касир ' + head.CASHIER],
          align: 'center',
        });
      }
    } else {
      if (order.storeName) {
        lines.push({
          type: 'text',
          value: [order.storeName],
          align: 'center',
        });
      }
      if (order.storeAddress) {
        lines.push({
          type: 'text',
          value: [order.storeAddress],
          align: 'center',
        });
      }
    }

    if (header) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: [header],
        align: 'center',
        padding: 2,
      });
    }

    if (order.deadline) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Дата гот',
            value: order.deadline
              ? DateUtils.formatCustom(order.deadline, 'dd MMM yyyy HH:mm')
              : '',
          },
        ],
      });
    }

    // if (receipt.id > 0) {
    //   lines.push({
    //     type: 'properties',
    //     lines: [
    //       {
    //         name: 'Номер заказа',
    //         value: receipt.id,
    //       },
    //     ],
    //   });
    // }

    if (order.customer) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Клиент',
            value: order.customer,
          },
        ],
      });
    }

    const productsLine = this._getOrderProductsText(order);
    if (productsLine == null) {
      return null;
    }

    lines.push(productsLine);
    let paymentType = order.cashless ? 'Картка' : 'Готівка';

    // summary
    const summary: any[] = [];

    if (order.taxDocument) {
      if (order.taxDocument.TAXES) {
        if (order.taxDocument.TAXES && order.taxDocument.TAXES.length > 0) {
          order.taxDocument.TAXES.forEach((tax) => {
            summary.push({
              name: tax.NAME + ' ' + tax.LETTER + ' ' + tax.PRC + '%',
              value: tax.SUM,
            });
          });
        }
      }
    }

    summary.push({
      name: 'ЗАГАЛЬНА СУМА',
      value: MoneyUtils.money(order.amount),
    });

    if (order.discount > 0) {
      //   const _loyaltyName = order.loyaltyProgram
      //     ? '   ' + order.loyaltyProgram.name
      //     : '   ЗНИЖКА';
      //   summary.push({
      //     name: _loyaltyName,
      //     value: MoneyUtils.money(order.discount),
      //   });
      //   summary.push({
      //     name: 'РАЗОМ',
      //     value: MoneyUtils.money(order.noRndSum),
      //   });
    }

    // if (order.rndSum) {
    //   summary.push({
    //     name: 'ЗАОКРУГЛЕННЯ',
    //     value: MoneyUtils.money(order.rndSum),
    //   });
    // }

    // summary.push({
    //   name: 'СУМА ДО СПЛАТИ',
    //   value: order.total_str,
    // });

    lines.push({ type: 'properties', lines: summary });
    lines.push({
      type: 'text',
      value: '--------------------------------',
      align: 'center',
    });

    const total: any[] = [];
    // total.push({ name: paymentType, value: order.total_str });

    // if (order.providedSum > 0) {
    //   total.push({
    //     name: 'Сплачено',
    //     value: MoneyUtils.money(order.providedSum),
    //   });
    // }
    // if (order.remainsSum > 0) {
    //   total.push({
    //     name: 'Решта',
    //     value: MoneyUtils.money(order.remainsSum),
    //   });
    // }
    lines.push({ type: 'properties', lines: total });

    lines.push({
      type: 'text',
      value: [
        order.opened
          ? DateUtils.display(order.opened)
          : order.created
          ? DateUtils.display(order.created)
          : '',
      ],
      align: 'center',
    });

    if (order.fiscalNumber) {
      if (head && head.TESTING == 'true') {
        lines.push({
          type: 'text',
          value: ['ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ'],
        });
      } else {
        lines.push({
          type: 'text',
          value: ['ФІСКАЛЬНИЙ ЧЕК'],
          align: 'center',
        });
      }
    }

    // if (order.taxRegisterName) {
    //   lines.push({
    //     type: 'text',
    //     value: [order.taxRegisterName],
    //     align: 'center',
    //   });
    // }

    // delivery
    // if (order.delivery) {
    //   lines.push({ type: 'empty' });
    //   lines.push({
    //     type: 'text',
    //     value: ['Доставка'],
    //     align: 'center',
    //   });

    //   const _values: string[] = [];
    //   if (order.delivery.deliveryMethod) {
    //     _values.push(order.delivery.deliveryMethod.name);
    //   }

    //   let _address1 = '';

    //   if (order.delivery.street) {
    //     _address1 += `ул.${order.delivery.street}`;
    //   }

    //   if (receipt.delivery.houseNumber) {
    //     _address1 += ` д.${receipt.delivery.houseNumber}`;
    //   }

    //   if (receipt.delivery.houseCode) {
    //     _address1 += ` корп.${receipt.delivery.houseCode}`;
    //   }

    //   _values.push(_address1);

    //   let _address2 = '';
    //   if (receipt.delivery.flatNumber) {
    //     _address1 += `кв.${receipt.delivery.flatNumber}`;
    //   }
    //   if (receipt.delivery.floor) {
    //     _address1 += ` этаж ${receipt.delivery.floor}`;
    //   }

    //   _values.push(_address2);

    //   lines.push({
    //     type: 'text',
    //     value: _values,
    //     align: 'left',
    //   });

    //   if (receipt.delivery.deliveryMethod.price) {
    //     lines.push({
    //       type: 'properties',
    //       lines: [
    //         {
    //           name: 'вартість',
    //           value: MoneyUtils.money(receipt.delivery.deliveryMethod.price),
    //         },
    //       ],
    //     });
    //   }

    //   if (receipt.delivery.note) {
    //     lines.push({ type: 'empty' });
    //     lines.push({
    //       type: 'text',
    //       value: receipt.delivery.note,
    //       align: 'center',
    //     });
    //   }
    // }

    // if (receipt.publicNote) {
    //   lines.push({ type: 'empty' });
    //   lines.push({
    //     type: 'text',
    //     value: receipt.publicNote,
    //   });
    // }

    // if (footer) {
    //   lines.push({ type: 'empty' });
    //   lines.push({
    //     type: 'text',
    //     value: [footer],
    //     align: 'center',
    //   });
    // }

    // if (receipt.wish) {
    //   lines.push({ type: 'empty' });
    //   lines.push({
    //     type: 'text',
    //     value: receipt.wish,
    //   });
    // }

    return lines;
  }

  private static _outputText(
    receipt: OrderDTO,
    header: string,
    footer: boolean,
    isCopy = false
  ): any[] {
    const lines: any[] = [];

    if (isCopy) {
      lines.push({
        type: 'text',
        value: '############################',
        align: 'center',
      });

      lines.push({
        type: 'text',
        value: 'КОПІЯ',
        align: 'center',
      });
      lines.push({
        type: 'text',
        value: '############################',
        align: 'center',
      });
    }

    if (receipt.companyName) {
      lines.push({
        type: 'text',
        value: [`ФОП ${receipt.companyName}`],
        align: 'center',
      });
    }

    if (receipt.pointName) {
      lines.push({
        type: 'text',
        value: [receipt.pointName],
        align: 'center',
      });
    }

    if (receipt.pointAddress) {
      lines.push({
        type: 'text',
        value: [receipt.pointAddress],
        align: 'center',
      });
    }

    if (receipt.companyFiscalNumber) {
      lines.push({
        type: 'text',
        value: ['ІД ' + receipt.companyFiscalNumber],
        align: 'center',
      });
    }

    if (receipt.taxRegisterFiscalNumber) {
      lines.push({
        type: 'text',
        value: ['ФН ПРРО ' + receipt.taxRegisterFiscalNumber],
        align: 'center',
      });
    }

    if (receipt.fiscalNumber) {
      lines.push({
        type: 'text',
        value: ['ФН чека ' + receipt.fiscalNumber + ' онлайн'],
        align: 'center',
      });
    }

    if (receipt.cashier) {
      lines.push({
        type: 'text',
        value: ['касир ' + receipt.cashier],
        align: 'center',
      });
    }

    if (header) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: [header],
        align: 'center',
        padding: 2,
      });
    }

    if (receipt.deadline) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Дата гот',
            value: receipt.deadline
              ? DateUtils.formatCustom(receipt.deadline, 'dd MMM yyyy HH:mm')
              : '',
          },
        ],
      });
    }

    // if (receipt.id > 0) {
    //   lines.push({
    //     type: 'properties',
    //     lines: [
    //       {
    //         name: 'Номер заказа',
    //         value: receipt.id,
    //       },
    //     ],
    //   });
    // }

    if (receipt.customerName) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Клиент',
            value: receipt.customerName,
          },
        ],
      });
    }

    const productsLine = this._getProductsPrintText(receipt);
    if (productsLine == null) {
      return null;
    }

    lines.push(productsLine);
    let paymentType = receipt.iscashless ? 'Картка' : 'Готівка';

    // summary
    const summary: any[] = [];
    if (receipt.taxes && receipt.taxes.length > 0) {
      receipt.taxes.forEach((tax) => {
        summary.push({
          name: tax.NAME + ' ' + tax.LETTER + ' ' + tax.PRC + '%',
          value: tax.SUM,
        });
      });
    }

    summary.push({
      name: 'ЗАГАЛЬНА СУМА',
      value: MoneyUtils.money(receipt.amount),
    });

    if (receipt.discount > 0) {
      const _loyaltyName = receipt.loyaltyProgram
        ? '   ' + receipt.loyaltyProgram.name
        : '   ЗНИЖКА';

      summary.push({
        name: _loyaltyName,
        value: MoneyUtils.money(receipt.discount),
      });

      summary.push({
        name: 'РАЗОМ',
        value: MoneyUtils.money(receipt.noRndSum),
      });
    }

    if (receipt.rndSum) {
      summary.push({
        name: 'ЗАОКРУГЛЕННЯ',
        value: MoneyUtils.money(receipt.rndSum),
      });
    }

    summary.push({
      name: 'СУМА ДО СПЛАТИ',
      value: receipt.total_str,
    });

    lines.push({ type: 'properties', lines: summary });
    lines.push({
      type: 'text',
      value: '--------------------------------',
      align: 'center',
    });

    const total: any[] = [];
    total.push({ name: paymentType, value: receipt.total_str });

    if (receipt.providedSum > 0) {
      total.push({
        name: 'Сплачено',
        value: MoneyUtils.money(receipt.providedSum),
      });
    }
    if (receipt.remainsSum > 0) {
      total.push({
        name: 'Решта',
        value: MoneyUtils.money(receipt.remainsSum),
      });
    }
    lines.push({ type: 'properties', lines: total });

    lines.push({
      type: 'text',
      value: [
        receipt.opened
          ? DateUtils.display(receipt.opened)
          : receipt.created
          ? DateUtils.display(receipt.created)
          : '',
      ],
      align: 'center',
    });

    if (receipt.fiscalNumber) {
      lines.push({
        type: 'text',
        value: ['ФІСКАЛЬНИЙ ЧЕК'],
        align: 'center',
      });
    }

    if (receipt.taxRegisterName) {
      lines.push({
        type: 'text',
        value: [receipt.taxRegisterName],
        align: 'center',
      });
    }

    // delivery
    if (receipt.delivery) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: ['Доставка'],
        align: 'center',
      });

      const _values: string[] = [];
      if (receipt.delivery.deliveryMethod) {
        _values.push(receipt.delivery.deliveryMethod.name);
      }

      let _address1 = '';

      if (receipt.delivery.street) {
        _address1 += `ул.${receipt.delivery.street}`;
      }

      if (receipt.delivery.houseNumber) {
        _address1 += ` д.${receipt.delivery.houseNumber}`;
      }

      if (receipt.delivery.houseCode) {
        _address1 += ` корп.${receipt.delivery.houseCode}`;
      }

      _values.push(_address1);

      let _address2 = '';
      if (receipt.delivery.flatNumber) {
        _address1 += `кв.${receipt.delivery.flatNumber}`;
      }
      if (receipt.delivery.floor) {
        _address1 += ` этаж ${receipt.delivery.floor}`;
      }

      _values.push(_address2);

      lines.push({
        type: 'text',
        value: _values,
        align: 'left',
      });

      if (receipt.delivery.deliveryMethod.price) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'вартість',
              value: MoneyUtils.money(receipt.delivery.deliveryMethod.price),
            },
          ],
        });
      }

      if (receipt.delivery.note) {
        lines.push({ type: 'empty' });
        lines.push({
          type: 'text',
          value: receipt.delivery.note,
          align: 'center',
        });
      }
    }

    if (receipt.publicNote) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: receipt.publicNote,
      });
    }

    if (footer) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: [footer],
        align: 'center',
      });
    }

    if (receipt.wish) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: receipt.wish,
      });
    }

    return lines;
  }

  private static _getOrderProductsText(
    receipt: OrderModel,
    area_id: number = null
  ): any {
    if (!receipt.products) {
      return null;
    }

    if (!area_id) {
      return {
        type: 'table',
        lines: receipt.products.map((p) => {
          return {
            item: p.name,
            qty: p.quantity,
            cost: p.price,
            total: p.amount,
            code: p.uktZedCode,
            labels: p.exciseLabels,
          };
        }),
      };
    }

    const products = receipt.products.filter((p) => p.workAreaId == area_id);
    if (products.length == 0) {
      return null;
    }

    return {
      type: 'table2',
      lines: products.map((p) => {
        return {
          item: p.name,
          qty: p.quantity,
          cost: p.price,
          total: p.amount,
        };
      }),
    };
  }

  private static _getProductsPrintText(
    receipt: OrderDTO,
    area_id: number = null
  ): any {
    if (!receipt.orderItems) {
      return null;
    }

    if (!area_id) {
      return {
        type: 'table',
        lines: receipt.orderItems.map((p) => {
          return {
            item: p.name,
            qty: p.quantity,
            cost: p.price,
            total: p.amount,
            code: p.uktZedCode,
            labels: p.exciseLabels,
          };
        }),
      };
    }

    const products = receipt.orderItems.filter((p) => p.workAreaId == area_id);
    if (products.length == 0) {
      return null;
    }

    return {
      type: 'table2',
      lines: products.map((p) => {
        return {
          item: p.name,
          qty: p.quantity,
          cost: p.price,
          total: p.amount,
        };
      }),
    };
  }
}
