import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { forkJoin } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private _authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return forkJoin([
      this._authService.ticket,
      this._authService.hasConnectionToken,
    ]).pipe(
      take(1),
      map((res) => {
        if (res[0] || res[1]) {
          return true;
        } else {
          return this.router.parseUrl('/login');
        }
      })
    );
  }
}
