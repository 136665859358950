<ion-header>
  <ion-toolbar>
    <ion-title> {{ transl['work-shift.title'] }} </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div>
    <table
      *ngIf="employeeSession"
      class="table table-borderless table-sm w-100"
    >
      <tr>
        <td>{{ transl['common.employee'] }}</td>
        <td>
          {{ employeeSession.employeename }}
        </td>
      </tr>
      <tr>
        <td>{{ transl['common.start'] }}</td>
        <td>
          {{ employeeSession.startdate | date : 'dd MMMM HH:mm' }}
        </td>
      </tr>
      <tr *ngIf="employeeSession.enddate">
        <td>{{ transl['common.end'] }}</td>
        <td>
          {{ employeeSession.enddate | date : 'dd MMMM HH:mm' }}
        </td>
      </tr>
      <tr>
        <td>{{ transl['work-shift.orders'] }}</td>
        <td>
          {{ employeeSession.orderscount }}
        </td>
      </tr>
      <tr>
        <td>{{ transl['work-shift.average-order'] }}</td>
        <td>
          {{ employeeSession.averageorderamount }}
        </td>
      </tr>
      <tr>
        <td>{{ transl['work-shift.average-products'] }}</td>
        <td>
          {{ employeeSession.averageorderproducts }}
        </td>
      </tr>
    </table>
    <hr />
    <div *ngIf="!confirmClose" class="text-right">
      <button class="btn btn-sm btn-info" (click)="closeSession()">
        {{ transl['work-shift.close-session'] }}
      </button>
      <!-- <button class="btn btn-sm btn-info" (click)="sessionReport()">
        Звіт
      </button> -->
    </div>
    <div *ngIf="confirmClose" class="flex-container">
      <button class="btn btn-sm" (click)="cancelCloseSession()">
        {{ transl['common.cancel'] }}
      </button>
      <span class="flex-spacer"></span>
      <button class="btn btn-sm btn-info" (click)="confirmCloseSession()">
        {{ transl['work-shift.confirm-close-session'] }}
      </button>
    </div>
  </div>
  <span class="bg-loader" *ngIf="loading"></span>
</ion-content>

<style>
  ::ng-deep .work-shifts-modal {
    padding-right: 10px;
    padding-bottom: 64px;
    --width: 310px;
    --height: 320px;
    align-items: end;
    justify-content: end;
  }
</style>
