import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { TerminalSessionsComponent } from '../terminal-sessions-dialog.component';
import { IReceipt } from '../terminal.models';
import { map, mergeMap, share } from 'rxjs/operators';
import { PrinterService } from 'src/app/shared/printer/printer.service';
import { TerminalClientService } from './terminal-client.service';
import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ReceiptService } from 'src/app/private/terminal/receipt.service';
import { EmployeeService } from './employee.service';
import { ITerminalData, TerminalDataService } from './terminal-data.service';
import { SettingsService } from 'src/app/services/settings.service';
import DateUtils from 'src/app/utility/date-utils';
import { ModalController } from '@ionic/angular';
import { IOrderContract } from 'src/app/orders/order.model';
import { ApiV1Service } from 'src/app/services/api-v1.service';
import { TerminalLockedComponent } from '../terminal-locked/terminal-locked.component';
import { addSeconds } from 'date-fns';
import { NoteDialogComponent } from '../note-dialog.component';
import { OrderPayComponent } from 'src/app/orders/order-pay/order-pay.component';
import { FeatureService } from 'src/app/services/features.service';

@Injectable({ providedIn: 'root' })
export class TerminalService {
  private get orderManagerUrl(): string {
    return this._settingsService.order_queue;
  }
  private get receiptBaseUrl(): string {
    return `${this._settingsService.api_endpoint}/receipts`;
  }
  private get terminalBaseUrl(): string {
    return `${this._settingsService.api_endpoint}/pos/terminal`;
  }
  private get crmBaseUrl(): string {
    return `${this._settingsService.api_endpoint}/crm/customers`;
  }

  private get baseUrl(): string {
    return `${this._settingsService.api_endpoint}`;
  }

  private _terminalUiSettingsKey: string;
  private _terminalUiSettings: any;
  get terminalUiSettings(): any {
    if (this._terminalUiSettings) return this._terminalUiSettings;

    const settings = localStorage.getItem(this._terminalUiSettingsKey);
    if (settings) return JSON.parse(settings);

    return {
      sidenavWidth: 380,
      productButtonWidth: 140,
      productButtonHeight: 100,
    };
  }
  set terminalUiSettings(settings: any) {
    localStorage.setItem(this._terminalUiSettingsKey, JSON.stringify(settings));
    this._terminalUiSettings = settings;
  }

  payObservable: Observable<any>;
  onPaymentProcessEnd = new EventEmitter();

  private get terminalData(): ITerminalData {
    return this._terminalDataService.terminalSettings();
  }

  constructor(
    private http: HttpClient,
    private _settingsService: SettingsService,
    private _printerService: PrinterService,
    private _terminalStateService: TerminalClientService,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _overlay: Overlay,
    private _receiptService: ReceiptService,
    private _employeeService: EmployeeService,
    private _terminalDataService: TerminalDataService,
    private _modalCtrl: ModalController,
    private api: ApiV1Service,
    private featureService: FeatureService
  ) {
    this._terminalUiSettingsKey = '_terminal_settings';
  }

  closeEmployeeSession(sessionUuid: string) {
    return this._terminalStateService.closeEmployeeSession(sessionUuid);
  }

  getTranslate = (key: string) => {
    if (!key) {
      return of(this.terminalData.locales);
    } else {
      return of(this.terminalData.locales[key]);
    }
  };

  getSession = (sessionId: number = null) =>
    this.http.get<any>(
      `${this.terminalBaseUrl}/session/${sessionId ? sessionId : ''}`
    );

  getSessions = (employeeId: string) =>
    this.http.get<any>(`${this.terminalBaseUrl}/sessions`).pipe(
      map((res: any[]) => {
        this._employeeService.updateEmloyeeSessions(employeeId, res);
        return res;
      })
    );

  getEmployeeSessions = (employeeId: string) =>
    this.http
      .get<any>(`${this.terminalBaseUrl}/sessions/employee/${employeeId}`)
      .pipe(
        map((res: any[]) => {
          this._employeeService.updateEmloyeeSessions(employeeId, res);
          return res;
        })
      );
  currentUserCashbox = (): Observable<any> =>
    this.http.get<any>(`${this.terminalBaseUrl}/current-user-cashbox`, {
      params: { skip: 'true' },
    });

  storeCashbox = (): Observable<any> =>
    this.http.get<any>(`${this.terminalBaseUrl}/store-cashbox`, {
      params: { skip: 'true' },
    });

  async openTerminalSessionsDialog(openActive: boolean = false) {
    const modal = await this._modalCtrl.create({
      component: TerminalSessionsComponent,
      componentProps: {
        service: this,
        openActive,
        userSessionsSub: this._getEmployeeSessions(),
      },
      backdropDismiss: false,
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    return data;
  }

  setDiscount = (receiptId: number, percent?: number, amount?: number) =>
    this.http.patch<any>(
      `${this.receiptBaseUrl}/${receiptId}/discount`,
      {},
      {
        params: new HttpParams()
          .set('percent', percent || percent === 0 ? percent.toString() : '')
          .set('amount', amount || amount === 0 ? amount.toString() : ''),
      }
    );

  pay = (receipt: IReceipt, companyId: string) => {
    this.payObservable = this.http
      .post<any>(this.orderManagerUrl, receipt, {
        headers: new HttpHeaders().set('companyId', companyId),
      })
      .pipe(
        // mergeMap(() =>
        //   this.http.post<any>(
        //     'https://ord-api.turbopos.net/api/order?code=rAe2hLBTNO_Awv7ShvzD8Pb3ROVSjHglKqQR8gVaiGPszCMJEAzFufjlgWA==',
        //     receipt,
        //     {
        //       headers: new HttpHeaders().set('companyId', companyId),
        //     }
        //   )
        // ),
        map(() => {
          this.payObservable = null;
          // this.closeOrder(receipt.uuid);
          // this.updateCashBox();
        }),
        // catchError((err) => {
        //   console.log('catchError');
        //   return this.http
        //     .post<any>(
        //       'https://nt-order-manager.azurewebsites.net/api/create?code=rAe2hLBTNO_Awv7ShvzDPb3ROVSKqQR8gVaiGPszCMJEAzFufjlgWA%3D%3D',
        //       receipt,
        //       {
        //         headers: new HttpHeaders().set('companyId', companyId),
        //       }
        //     )
        //     .pipe(catchError((err) => throwError(err)));
        // }),
        share()
      );

    return this.payObservable;
    //}
  };

  private _getEmployeeSessions() {
    return this._terminalStateService
      .getLoggedInEmployee()
      .pipe(
        mergeMap((res) => this._employeeService.getEmployeeSessions(res.userid))
      );
  }

  // newTerminalSessionsDialog = (openActive: boolean = false) => {
  //   const dialog = this._dialog.open(TerminalSessionNewComponent, {
  //     data: {
  //       service: this,
  //       openActive,
  //       sessions: this._getEmployeeSessions(),
  //     },
  //     width: '70%',
  //     disableClose: true,
  //   });

  //   return dialog.afterClosed();
  // };

  terminalReport = (
    start?: Date,
    end?: Date,
    employees: string[] = []
  ): Observable<any[]> =>
    this.http.get<any>(`${this.terminalBaseUrl}/sales-report-2`, {
      params: new HttpParams()
        .set('start', start ? DateUtils.format(start) : DateUtils.format())
        .set('end', end ? DateUtils.format(end) : DateUtils.format())
        .set('stores', employees.join(',')),
    });

  terminalSalaryReport = (
    start?: Date,
    end?: Date,
    employeeId?: string
  ): Observable<any[]> =>
    this.http.get<any>(`${this.terminalBaseUrl}/report/services`, {
      params: new HttpParams()
        .set('start', start ? DateUtils.format(start) : DateUtils.format())
        .set('end', end ? DateUtils.format(end) : DateUtils.format())
        .set('employee', employeeId ? employeeId : ''),
    });

  receiptsReport = (
    start?: Date,
    end?: Date,
    stores: string[] = []
  ): Observable<any[]> =>
    this.http.get<any>(`${this.terminalBaseUrl}/receipts`, {
      params: new HttpParams()
        .set('start', start ? DateUtils.format(start) : DateUtils.format())
        .set('end', end ? DateUtils.format(end) : DateUtils.format())
        .set('stores', stores.join(',')),
    });

  deleteReceipt = (receiptId: number) =>
    this.http.delete(`${this.receiptBaseUrl}/${receiptId}`);

  getReceipt = (receiptId: number): Observable<any> =>
    this.http.get<any>(`${this.receiptBaseUrl}/${receiptId}`);

  getOrder = (orderId: number): Observable<IOrderContract> =>
    this.http.get<IOrderContract>(
      `${this.baseUrl}/pos/terminal/order/${orderId}`,
      {
        params: { responseFormater: 'camelCase' },
      }
    );

  saveReturnOrder(returnReceipt: any) {
    return this.http.patch<any>(`${this.receiptBaseUrl}/return`, returnReceipt);
  }

  saveInvoiceReturn(returnReceipt: any) {
    return this.http.patch<any>(
      `${this.receiptBaseUrl}/returnInvoice`,
      returnReceipt
    );
  }

  updatePaymentType = (receiptId: number, paymentTypeId: number) =>
    this.http.patch<any>(
      `${this.receiptBaseUrl}/${receiptId}/payment-type-id/${paymentTypeId}`,
      {}
    );
  createSession = (employeeId: string) =>
    this.http.post<any>(`${this.terminalBaseUrl}/session`, { employeeId });

  closeSession = (sessionId: number) =>
    this.http.delete(`${this.terminalBaseUrl}/sessions/${sessionId}`);
  openSession = (sessionId: number) =>
    this.http.patch(`${this.terminalBaseUrl}/sessions/${sessionId}`, {});

  getCustomers = () => this.http.get<any>(`${this.crmBaseUrl}`);

  async checkCompany() {
    const _now = new Date();
    const dismissAt = addSeconds(_now, 30);

    await this.featureService.refreshFeatures();
    const isLocked = await this.featureService.isCpmanyLocked();
    if (isLocked) {
      const lockModal = await this._modalCtrl.create({
        component: TerminalLockedComponent,
        backdropDismiss: false,
        cssClass: 'terminal-lock-modal',
        componentProps: {
          dismissAt: dismissAt,
        },
      });
      lockModal.present();
    }
  }

  async openNoteDialog(title: string, note: string, publicNote: string) {
    const modal = await this._modalCtrl.create({
      component: NoteDialogComponent,
      componentProps: {
        title: title,
        note: note,
        publicNote: publicNote,
      },
      cssClass: 'note-modal',
    });
    modal.present();

    var { role, data } = await modal.onWillDismiss();
    return data;
  }

  async openOrderPayDialog(
    order: any,
    isAdvance = false
  ): Promise<{
    paymentMethod: 'cash' | 'card';
    amount: number;
    providedAmount: number;
    isFiscal: boolean;
  }> {
    const modal = await this._modalCtrl.create({
      component: OrderPayComponent,
      componentProps: {
        order: order,
        isAdvance: isAdvance,
      },
      cssClass: 'order-pay-modal',
      backdropDismiss: false,
    });
    modal.present();

    var { role, data } = await modal.onWillDismiss();
    return data;
  }
}
