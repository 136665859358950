import { Injectable } from '@angular/core';
import { GenericRepository, IGenericItem } from './generic.repository';

export class CategoryItem extends IGenericItem {
  parentCategoryId?: number;
}

const CATEGORIES_TABLE_KEY = '__categories';

@Injectable({ providedIn: 'root' })
export class CategoriesRepository {
  constructor(private _repository: GenericRepository) {}

  getCategories(): Promise<CategoryItem[]> {
    return this._repository.getItems<CategoryItem>(CATEGORIES_TABLE_KEY);
  }

  loadCategories(categories: CategoryItem[]): Promise<void> {
    return this._repository.loadItems(CATEGORIES_TABLE_KEY, categories);
  }

  clearStorage() {
    return this._repository.deleteTable(CATEGORIES_TABLE_KEY);
  }
}
