import { NgModule } from '@angular/core';
import { AuthGuard } from './_guards/auth.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RootPage } from './private/root/root.page';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./public/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./public/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: RootPage,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./private/tenants/tenants.module').then(
            (m) => m.TenantsPageModule
          ),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: RootPage,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./private/terminal/terminal.module').then(
            (m) => m.TerminalPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
