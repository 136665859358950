import { EventEmitter, Injectable } from '@angular/core';
import { from, Observable, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import domtoimage from 'dom-to-image';

import { SocketService } from 'src/app/services/socket.service';
import { PrinterPreviewComponent } from './components/printer-preview.component';
import { format } from 'date-fns';
import * as QRCode from 'qrcode';
import { uk } from 'date-fns/locale';

import {
  IPrinterItem,
  PrinterRepository,
} from 'src/app/private/terminal/repositories/printers.repository';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { UsbService } from 'src/app/services/usb.service';
import { PrinterApi } from './printer.api';
import { CompanyDataService } from 'src/app/modules/company/company-data.service';
import { ModalController } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';
import { BluetoothLeService } from 'src/app/services/bluetooth-le.service';
import { MonitoringService } from 'src/app/services/monitoring.service';

export interface IPrintObject {
  printer: IPrinterItem;
  printText: string;
  imglogo?: string;
  isClientReceipt?: boolean;
  bottomLogo?: string;
  bottomQRCodeData?: string;
  bottomText?: string;
  extraData?: { type: 'text' | 'qrCode' | 'image' | 'space'; value?: string }[];
}

class PrintersConfig {
  terminalId: string;
  command: IPrinterCommand;
  workAreas: string;
  printers: any[];
  remoteDebug: boolean;
  printerId: string;
}

export interface IPrinterCommand {
  id: string;
  printerId: string;
  command: 'print' | 'usbSearch' | 'printTest';
  data: any;
  status: 'finished' | 'panding';
}

class PrintObject implements IPrintObject {
  printer: IPrinterItem;
  printText: string;
  imglogo?: string;
  isClientReceipt?: boolean;

  constructor(
    printer: IPrinterItem,
    text: string,
    isClientReceipt: boolean = false
  ) {
    this.printer = printer;
    this.printText = text;
    this.isClientReceipt = isClientReceipt;
  }
}

class IpGenerator {
  private _ports: number[];
  private _subnetParts = [];
  private _ipsRange: { ipAddress: string; port: number }[] = [];

  private readonly _localIpPrefix = '192.168';
  private _totalRange = 0;

  constructor(ports: number[] = [9100, 6001], subnetParts: number = 2) {
    for (let i = 1; i <= subnetParts; i++) {
      this._subnetParts.push(i);
    }

    this._ports = ports;
    this._generateIps();
    this._totalRange = this._ipsRange.length;
  }

  private _generateIps() {
    this._ipsRange = [];
    for (let subnet of this._subnetParts) {
      for (let lastPart = 1; lastPart <= 255; lastPart++) {
        for (let port of this._ports) {
          this._ipsRange.push({
            ipAddress: `${this._localIpPrefix}.${subnet}.${lastPart}`,
            port: port,
          });
        }
      }
    }
  }

  getNextIp(): { ip: { ipAddress: string; port: number }; process: number } {
    if (!this._ipsRange.length) {
      return null;
    }
    const ip = this._ipsRange.shift();
    const process = (1 - this._ipsRange.length / this._totalRange) * 100;

    return { ip, process: process };
  }
}

declare const window;
declare const chrome;
const PRINTER_CLASS = 1664;

@Injectable({ providedIn: 'root' })
export class PrinterService {
  private _printerEncoder: EscPosEncoderNew;
  private _printers: IPrinterItem[] = [];
  public showPrintPreview = false;
  onPrinterAdded = new EventEmitter<IPrinterItem[]>();
  onPrintEnd = new EventEmitter();

  isScanInProcess: number;
  scannedIp: string;
  get printers(): IPrinterItem[] {
    return this._printers;
  }
  private _scanIpInProcess = false;
  private _ipGenerator: IpGenerator;
  private _scanNetworkTimer = timer(500, 500);
  private _scanNetworkSub: Subscription;
  get hasPrinters() {
    return this.printers && this.printers.length;
  }

  get isPrinterConfigured() {
    return this.printers.find((p) => p.printCheck) != undefined;
  }

  get isDesktop() {
    return chrome.webview?.hostObjects?.platform == 'desktop';
  }
  onDesktopPrinters = new EventEmitter<any[]>();

  constructor(
    private _socketService: SocketService,
    private _printerRepo: PrinterRepository,
    private _btService: BluetoothService,
    private _usbService: UsbService,
    // private _printerApi: PrinterApi,
    private _companyData: CompanyDataService,
    private modalCtrl: ModalController,
    private shared: SharedService,
    private bluetoothLeService: BluetoothLeService,
    private monitor: MonitoringService
  ) {
    this._printerEncoder = new EscPosEncoderNew();
    this._printerRepo.getPrinters().then((res: IPrinterItem[]) => {
      if (res && res.length > 0) {
        this._printers = res;
      }
    });

    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.addEventListener('message', (event: any) => {
        const res = event.data;
        if (res.DataType == 'Printers') {
          this.onDesktopPrinters.emit(res.Message);
        }
      });
    }
  }

  getPrintObject(
    printer: IPrinterItem,
    text: string,
    isClientReceipt: boolean = false
  ): IPrintObject {
    return new PrintObject(printer, text, isClientReceipt);
  }

  async printScope(printObjects: IPrintObject[]) {
    if (this.showPrintPreview) {
      this.openPrintPreview(printObjects);
    } else {
      const printers: { [key: string]: IPrintObject[] } = {};
      printObjects.forEach((pObj) => {
        const key = `${pObj.printer.address}`;
        if (!printers[key]) {
          printers[key] = [];
        }

        printers[key].push(pObj);
      });

      const addresses = Object.keys(printers);
      for await (const printer of addresses) {
        await this._sendToPrinter(printers[printer]);
      }

      this.onPrintEnd.emit();
    }
  }

  private _sendToPrinter(prints: IPrintObject[]): Promise<any> {
    const printerType = prints[0].printer.type;
    prints.forEach((p) => {
      p.printText = this._replaceCyrillicChars(p.printText);
    });

    if (printerType == 'bt') {
      return this._sendToBlueToothPrinter(prints);
    } else if (printerType == 'btle') {
      return this._sendToBtlePrinter(prints);
    } else if (printerType == 'net') {
      return this._sendToLanPrinter(prints);
    } else if (printerType == 'usb') {
      return this._sendToUsbPrinter(prints);
    } else if (printerType == 'web') {
      return this._sendToWebPrinter(prints);
    }
  }

  async scanPrinters(fullScan: boolean = false) {
    // _addTestPrinter();

    if (this.isDesktop) {
      window.chrome.webview.postMessage('getPrinters');

      alert('isDesktop - true');

      return;
    }

    if (this.isScanInProcess > 0 && this.isScanInProcess < 100) {
      return;
    }

    try {
      this.bluetoothLeService.startScan().subscribe((res) => {
        if (res) {
          if (
            res.name &&
            (res.name.toLowerCase().includes('printer') ||
              res.name.toLowerCase().includes('mpt') ||
              res.name.toLowerCase() == 'PT2200B'.toLowerCase() ||
              res.class == PRINTER_CLASS)
          ) {
            const printer = this._printerRepo.createPrinter(
              res.name,
              res.address,
              null,
              'btle',
              'cp866'
            );

            if (res.name.toLowerCase() == 'PT2200B'.toLowerCase()) {
              printer.codePageNumber = 6;
              printer.codePage = 'windows1251';
            }

            this._addPrinter(printer);
          }
        }
      });

      setTimeout(() => this.bluetoothLeService.stopScan(), 10000);
    } catch {}

    const btPrinters = await this._btService
      .getConnectedDevices()

      .catch((err) => {
        this.shared.showErrorToast(err);
        return [];
      });

    btPrinters.forEach((bp) => {
      if (
        bp.name &&
        (bp.name.toLowerCase().includes('printer') ||
          bp.name.toLowerCase().includes('mpt') ||
          bp.name.toLowerCase() == 'PT2200B'.toLowerCase() ||
          bp.class == PRINTER_CLASS)
      ) {
        const printer = this._printerRepo.createPrinter(
          bp.name,
          bp.address,
          null,
          'bt',
          'cp866'
        );

        this._addPrinter(printer);
      }
    });

    try {
      const usbPrinters = await this._usbService
        .getUsbPrinters()
        .catch(() => []);

      usbPrinters.forEach((p) => {
        const printer = this._printerRepo.createPrinter(
          'USB Printer',
          null,
          null,
          'usb',
          'cp866'
        );

        printer.name = p.name;
        this._addPrinter(printer);
      });
    } catch {}

    this._startScanNetwork(fullScan);
  }

  async createWebPrinter() {
    const printers = await this._printerRepo.getPrinters();
    const printerweb = printers.find((p) => p.type === 'web');
    if (printerweb) {
      return;
    } else {
      const webprinter = this._printerRepo.createPrinter(
        'WEB Printer',
        '0.0.0.0',
        null,
        'web'
      );

      this._addPrinter(webprinter);
    }
  }

  private _startScanNetwork(fullScan = false) {
    let subnetParts = 2;
    if (fullScan) {
      subnetParts = 255;
    }

    this._ipGenerator = new IpGenerator([9100, 6001], subnetParts);
    this._scanNetworkSub = this._scanNetworkTimer.subscribe(() =>
      this._scanNetwork()
    );
  }

  stopScanNetwork() {
    if (this._scanNetworkSub) {
      this._scanNetworkSub.unsubscribe();
    }
    this.isScanInProcess = 0;
  }

  findPrinterOnIp(ip: string, port: number): Observable<boolean> {
    return from(this._checkConnection(ip, port)).pipe(
      map((res) => {
        if (res) {
          this._addLanPrinter(ip, port);
          return true;
        }

        return false;
      })
    );
  }

  private _scanNetwork() {
    if (!this._ipGenerator) {
      return;
    }

    if (this._scanIpInProcess) {
      return;
    }

    this._scanIpInProcess = true;

    const nextIp = this._ipGenerator.getNextIp();
    if (nextIp == null) {
      this.stopScanNetwork();
    }

    this.isScanInProcess = nextIp.process;
    const ipAddress = nextIp.ip.ipAddress;
    const port = nextIp.ip.port;
    this.scannedIp = ipAddress;

    this._checkConnection(ipAddress, port)
      .then((res) => {
        if (res) {
          this._addLanPrinter(ipAddress, port);
          if (this.showPrintPreview) {
            throw 'ЗНАЙДЕНО ПРОСТРІЙ. IP: ' + ipAddress;
          }
        } else {
          if (this.showPrintPreview) {
            throw 'Помилка підключення. IP: ' + ipAddress;
          }
        }
        this._scanIpInProcess = false;
      })
      .catch(() => {
        this._scanIpInProcess = false;
      });
  }

  private _checkConnection(ip: string, port: number): Promise<boolean> {
    return this._socketService.checkConnection(ip, port).catch(() => {
      return false;
    });
  }

  private _addLanPrinter(ip: string, port: number) {
    const printer = this._printerRepo.createPrinter(
      'LAN Printer',
      ip,
      port,
      'net',
      'windows1251',
      'cp936'
    );
    printer.address = ip;

    this._addPrinter(printer);
  }

  // selectPrinter(p: IPrinterItem) {
  //   const printer = this._findPrinter(p);
  //   if (!printer) {
  //     return;
  //   }

  //   this._printers.forEach((p) => (p.selected = false));
  //   printer.selected = true;

  //   this._printerRepo
  //     .savePrinters(this._printers)
  //     .subscribe(() => (this._printers = this._printers));
  // }

  showPreview(showPrintPreview: boolean) {
    this.showPrintPreview = showPrintPreview;
  }

  async getPrinterData(terminalId?: string): Promise<PrintersConfig> {
    const printers = await this._getDevices();
    const terminal = new PrintersConfig();
    terminal.terminalId = terminalId;
    terminal.printers = printers;
    return terminal;

    // return terminal;

    // const companyId = await this._companyData.getCompanyId();
    // console.log(terminalId);
    // console.log(companyId);

    // return forkJoin([
    //   this._printerApi
    //     .getTerminal(companyId, terminalId)
    //     .pipe(catchError(() => of(null))),
    //   this._getDevices(),
    // ])
    //   .pipe(
    //     mergeMap((result) => {
    //       const config: PrintersConfig = result[0];
    //       const localPrinters = result[1];

    //       if (!config) {
    //         return this._printerApi
    //           .updateTerminal(companyId, terminalId, localPrinters)
    //           .pipe(
    //             map(() => {
    //               return {
    //                 terminalId: terminalId,
    //                 printers: localPrinters,
    //               };
    //             })
    //           );
    //       } else if (localPrinters.length == 0 || config.remoteDebug) {
    //         return this._printerRepo
    //           .savePrinters(config.printers)
    //           .pipe(map(() => config));
    //       } else {
    //         config.printers = localPrinters;
    //         return this._printerApi
    //           .updateTerminal(companyId, terminalId, localPrinters)
    //           .pipe(
    //             catchError(() => of(null)),
    //             map(() => config)
    //           );
    //       }
    //     }),
    //     map((terminal: PrintersConfig) => {
    //       this._printers = terminal.printers;
    //       return terminal;
    //     })
    //   )
    //   .toPromise();
  }

  private async _getDevices() {
    const printers = await this._printerRepo.getPrinters();
    if (printers && printers.length > 0) {
      return printers;
    } else {
      return [];
    }
  }

  async getDevices2(terminalId?: string) {
    // console.log('======getTerminal======');

    // const companyId = await this._companyData.getCompanyId();
    // console.log(terminalId);
    // console.log(companyId);

    const printers = await this._printerRepo.getPrinters();
    return printers;

    // this._printerApi
    //   .updateTerminal(companyId, terminalId, printers)
    //   .subscribe((res) => {
    //     console.log('======getTerminal======');
    //     console.log(res);
    //   });

    // return firstValueFrom(
    //   this._printerApi.getTerminal(companyId, terminalId).pipe(
    //     map((terminal: any) => {
    //       return terminal.printers;
    //     })
    //   )
    // );
  }

  async printTest(printer: IPrinterItem) {
    const date = format(new Date(), 'dd MMM yyyy HH:mm', {
      locale: uk,
    });

    let text = `\n\nTurboPOS ${date}\nТестовий друк\n\nЄІЇєії`;

    const printObject = this.getPrintObject(printer, text);
    printObject.bottomQRCodeData = 'https://turbopos.net';
    return this._sendToPrinter([printObject]);
  }

  async testQrCodePrint(printer: IPrinterItem) {
    const printObject = this.getPrintObject(printer, '');
    printObject.bottomQRCodeData = 'https://turbopos.net';
    return this._sendToPrinter([printObject]);
  }

  async testLogoPrint(printer: IPrinterItem) {
    const printObject = this.getPrintObject(printer, '');
    printObject.bottomQRCodeData = 'https://turbopos.net';
    return this._sendToPrinter([printObject]);
  }

  private async _dataUrlToImage(dataUrl: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.src = dataUrl;
    });
  }

  textToPng(
    text: string,
    htmlWidth = '60mm',
    imageWidth = 240,
    fontSize = 20
  ): Promise<HTMLImageElement> {
    const container = document.createElement('div');
    container.style.width = htmlWidth ? htmlWidth : '60mm';
    container.style.color = 'black';

    const textElement = document.createElement('pre');
    textElement.style.backgroundColor = 'white';
    textElement.style.alignItems = 'center';
    textElement.style.fontWeight = 'bold';
    textElement.style.fontSize = fontSize + 'px';
    textElement.style.whiteSpace = 'pre-wrap';
    textElement.innerHTML = text;

    container.appendChild(textElement);
    document.body.appendChild(container);

    // 440 for web print
    return new Promise((resolve) => {
      domtoimage
        .toPng(container, { width: imageWidth, bgcolor: 'white' })
        .then((dataUrl) => {
          let img = new Image();
          img.onload = () => {
            document.body.removeChild(container);
            resolve(img);
          };
          img.src = dataUrl;
        })
        .catch((err) => {
          resolve(null);
        });
    });
  }

  finishCommand(commandId: string, data?: string, error?: string) {
    // return this._printerApi.finishCommand(commandId, data, error);
  }

  async stopRemoteDebug(terminalId: string) {
    const companyId = await this._companyData.getCompanyId();
    // return this._printerApi.stopRemoteDebug(companyId, terminalId).toPromise();
  }

  printText3(printerId: string, text: string): Promise<any> {
    return this._getDevices().then((res) => {
      const printer = res.find(
        (p) => printerId && p.uuid.toLowerCase() == printerId.toLowerCase()
      );

      if (!printer) {
        throw new Error('Printer not found.');
      }

      if (!text) {
        throw new Error('Text not provided.');
      }

      const printObj: IPrintObject = new PrintObject(printer, text);
      return this._sendToPrinter([printObj]);
    });
  }

  printText2(text: string, printerId?: string) {
    this._getDevices().then((res) => {
      const defaultPrinter = res.find(
        (p) =>
          (printerId && p.uuid.toLowerCase() == printerId.toLowerCase()) ||
          p.printCheck
      );
      if (defaultPrinter) {
        const printObj: IPrintObject = new PrintObject(defaultPrinter, text);

        if (this.showPrintPreview) {
          this.openPrintPreview([printObj]);
        } else {
          this._sendToPrinter([printObj]);
        }
      }
    });
  }

  private async _getImagePrintData(
    image: string,
    width: number,
    height: number,
    algorithm: string,
    threshold: number
  ): Promise<Uint8Array> {
    const _imageData = await this._base64ToImage(image);
    if (!_imageData) {
      return;
    }

    const _width = width ? width - (width % 8) : 200;
    const _height = height ? height - (height % 8) : 200;

    this._printerEncoder
      .align('center')
      .image(_imageData, _width, _height, algorithm, threshold);
  }

  private async _getQrCodePrintData(
    data: string,
    useImg: boolean,
    model: number = 1,
    size: number = 6,
    errorLevel: string = 'm'
  ): Promise<void> {
    if (useImg) {
      const opts = {
        errorCorrectionLevel: 'L',
        type: 'image/jpeg',
        quality: 0.3,
        margin: 1,
        version: 6,
        width: '120',
      };

      const _qrCodeDataUrl: string = await QRCode.toDataURL(data, opts);
      const _qrCodeDataString: string = await QRCode.toString(data, opts);
      const qrCodeImg = await this._dataUrlToImage(_qrCodeDataUrl);
      this._printerEncoder
        .newline()
        .align('center')
        .image(qrCodeImg, 200, 200, 'atkinson', 128);
    } else {
      this._printerEncoder
        .newline()
        .align('center')
        .qrcode(data, model, size, errorLevel);
    }
  }

  private async _getGraphicPrintData(
    printText: string,
    graphicPrintHtmlWidth: string,
    graphicPrintImageWidth: number,
    graphicPrinFontSize: number
  ): Promise<void> {
    const textImage = await this.textToPng(
      printText,
      graphicPrintHtmlWidth,
      graphicPrintImageWidth,
      graphicPrinFontSize
    );
    let height = textImage.height; // * 1.8;
    height = height - (height % 8);

    let width = textImage.width; // * 1.8;
    width = width - (width % 8);

    this._printerEncoder.image(textImage, width, height, 'atkinson', 128);
  }

  private async _sendToBlueToothPrinter(data: IPrintObject[]): Promise<any> {
    this._printerEncoder.initialize();
    const printer = data[0].printer;
    for (const p of data) {
      await this._getImagePrintData(
        p.imglogo,
        printer.logoWidth,
        printer.logoHeight,
        printer.logoAlgorithm,
        printer.logoThreshold
      );
      if (p.printer.graphicPrint) {
        await this._getGraphicPrintData(
          p.printText,
          p.printer.graphicPrintHtmlWidth,
          p.printer.graphicPrintImageWidth,
          p.printer.graphicPrinFontSize
        );
      } else if (p.printText) {
        this.encodeText(p.printText, printer.textSize, p.printer.codePage);
      }

      if (p.bottomQRCodeData) {
        await this._getQrCodePrintData(
          p.bottomQRCodeData,
          printer.qrCodeUseImg,
          +printer.qrCodeModel,
          +printer.qrCodeSize,
          printer.qrCodeErrorLevel
        );
      }

      if (p.bottomText) {
        this.encodeText(p.bottomText, printer.textSize, p.printer.codePage);
      }

      if (p.extraData && p.extraData.length) {
        for (let ed of p.extraData) {
          if (ed.type == 'text') {
            const text = this._replaceCyrillicChars(ed.value);
            this.encodeText(text, printer.textSize, p.printer.codePage);
          }

          if (ed.type == 'qrCode') {
            await this._getQrCodePrintData(
              ed.value,
              printer.qrCodeUseImg,
              +printer.qrCodeModel,
              +printer.qrCodeSize,
              printer.qrCodeErrorLevel
            );
          }

          if (ed.type == 'space') {
            this._bottomLines(1);
          }
        }
      }

      if (!isNaN(+printer.bottomRows)) {
        this._bottomLines(+printer.bottomRows);
      }
      this._printerEncoder.cut('partial');
    }

    this.shared.notify('Друк...', 60);
    return this._btService
      .sendDataToDevice(printer.address, this._printerEncoder.encode().buffer)
      .catch((err) => this.shared.showErrorToast(err))
      .finally(() => this.shared.closeNotify());
  }

  private async _sendToBtlePrinter(data: IPrintObject[]) {
    this._printerEncoder.initialize();
    const printer = data[0].printer;
    for (const p of data) {
      await this._getImagePrintData(
        p.imglogo,
        printer.logoWidth,
        printer.logoHeight,
        printer.logoAlgorithm,
        printer.logoThreshold
      );
      if (p.printer.graphicPrint) {
        await this._getGraphicPrintData(
          p.printText,
          p.printer.graphicPrintHtmlWidth,
          p.printer.graphicPrintImageWidth,
          p.printer.graphicPrinFontSize
        );
      } else if (p.printText) {
        this.encodeText(
          p.printText,
          printer.textSize,
          p.printer.codePage,
          null,
          p.printer.codePageNumber
        );
      }

      if (p.bottomQRCodeData) {
        await this._getQrCodePrintData(
          p.bottomQRCodeData,
          printer.qrCodeUseImg,
          +printer.qrCodeModel,
          +printer.qrCodeSize,
          printer.qrCodeErrorLevel
        );
      }

      if (p.bottomText) {
        this.encodeText(
          p.bottomText,
          printer.textSize,
          p.printer.codePage,
          null,
          p.printer.codePageNumber
        );
      }

      if (!isNaN(+printer.bottomRows)) {
        this._bottomLines(+printer.bottomRows);
      }
      this._printerEncoder.cut('partial');
    }

    this.shared.notify('Друк...', 10);
    return this.bluetoothLeService
      .sendDataToDevice(printer, this._printerEncoder.encode())
      .catch((err) => this.shared.showErrorToast(err))
      .finally(() => this.shared.closeNotify());
  }

  private async _sendToLanPrinter(data: IPrintObject[]): Promise<any> {
    const printer = data[0].printer;
    this._printerEncoder.initialize();
    for (const p of data) {
      await this._getImagePrintData(
        p.imglogo,
        printer.logoWidth,
        printer.logoHeight,
        printer.logoAlgorithm,
        printer.logoThreshold
      );

      if (p.printer.graphicPrint) {
        this._getGraphicPrintData(
          p.printText,
          p.printer.graphicPrintHtmlWidth,
          p.printer.graphicPrintImageWidth,
          p.printer.graphicPrinFontSize
        );
      } else {
        this.encodeText(
          p.printText,
          printer.textSize,
          printer.codePage,
          printer.codePageStrange
        );
      }

      if (p.bottomQRCodeData) {
        await this._getQrCodePrintData(
          p.bottomQRCodeData,
          printer.qrCodeUseImg,
          +printer.qrCodeModel,
          +printer.qrCodeSize,
          printer.qrCodeErrorLevel
        );
      }

      if (p.bottomText) {
        this.encodeText(
          p.bottomText,
          printer.textSize,
          printer.codePage,
          printer.codePageStrange
        );
      }

      if (p.extraData && p.extraData.length) {
        for (let ed of p.extraData) {
          if (ed.type == 'text') {
            const text = this._replaceCyrillicChars(ed.value);
            this.encodeText(text, printer.textSize, p.printer.codePage);
          }

          if (ed.type == 'qrCode') {
            await this._getQrCodePrintData(
              ed.value,
              printer.qrCodeUseImg,
              +printer.qrCodeModel,
              +printer.qrCodeSize,
              printer.qrCodeErrorLevel
            );
          }

          if (ed.type == 'space') {
            this._bottomLines(1);
          }
        }
      }

      if (!isNaN(+printer.bottomRows)) {
        this._bottomLines(+printer.bottomRows);
      }
      this._printerEncoder.cut('partial');
    }

    return this._socketService.sendData(
      printer.ip,
      printer.port,
      this._printerEncoder.encode().buffer
    );
  }

  private async _sendToUsbPrinter(data: IPrintObject[]): Promise<any> {
    const printer = data[0].printer;
    this._printerEncoder.initialize();
    for (const p of data) {
      await this._getImagePrintData(
        p.imglogo,
        printer.logoWidth,
        printer.logoHeight,
        printer.logoAlgorithm,
        printer.logoThreshold
      );

      if (p.printer.graphicPrint) {
        await this._getGraphicPrintData(
          p.printText,
          p.printer.graphicPrintHtmlWidth,
          p.printer.graphicPrintImageWidth,
          p.printer.graphicPrinFontSize
        );
      } else {
        this.encodeText(p.printText, printer.textSize, printer.codePage);
      }

      if (p.bottomQRCodeData) {
        await this._getQrCodePrintData(
          p.bottomQRCodeData,
          printer.qrCodeUseImg,
          +printer.qrCodeModel,
          +printer.qrCodeSize,
          printer.qrCodeErrorLevel
        );
      }

      if (p.bottomText) {
        this.encodeText(p.bottomText, printer.textSize, printer.codePage);
      }

      if (!isNaN(+printer.bottomRows)) {
        this._bottomLines(+printer.bottomRows);
      }
      this._printerEncoder.cut('partial');
    }

    return this._usbService
      .send(printer.name, this._printerEncoder.encode().buffer)
      .catch((err) => {
        this.monitor.logException(err, 1, {
          service: 'PrinterService',
          point: 'USBPrint',
        });
      });
  }

  private async _sendToWebPrinter(data: IPrintObject[]): Promise<any> {
    const print = data[0];

    const logoImage = await this._base64ToImage(print.imglogo);

    let _qrCodeImg;
    if (print.bottomQRCodeData) {
      const _qrCodedataUrl: string = await QRCode.toDataURL(
        print.bottomQRCodeData,
        {
          type: 'terminal',
          width: '100',
        }
      );

      _qrCodeImg = await this._dataUrlToImage(_qrCodedataUrl);
    }

    const width = print.printer.printWidth + 'mm';
    if (print.printer.graphicPrint) {
      const textImage = await this.textToPng(
        print.printText,
        print.printer.graphicPrintHtmlWidth,
        print.printer.graphicPrintImageWidth,
        print.printer.graphicPrinFontSize
      );

      return await this._webPrint(
        null,
        width,
        logoImage,
        _qrCodeImg,
        textImage,
        null,
        print.bottomText
      );
    } else {
      return await this._webPrint(
        print.printText,
        width,
        logoImage,
        _qrCodeImg,
        null,
        print.printer.logoWidth,
        print.bottomText
      );
    }
  }

  private _bottomLines(bottomLines: number) {
    for (let i = 0; i < bottomLines; i++) {
      this._printerEncoder.newline();
    }
  }

  private encodeText(
    text: string,
    textSize: 'small' | 'normal',
    codePage: string,
    codePageStrange?: string,
    codePageNumber?: number
  ) {
    if (codePageStrange) {
      this._printerEncoder
        .codepage(codePageStrange, codePageNumber)
        .text('', 0)
        .codepage(codePage, codePageNumber)
        .size(textSize)
        .text(text, 0)
        .newline();
    } else {
      this._printerEncoder
        .codepage(codePage, codePageNumber)
        .size(textSize)
        .text(text, 0)
        .newline();
    }
  }

  private async _base64ToImage(imageData: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      if (!imageData) {
        resolve(null);
      } else {
        const image = new Image();
        image.onload = () => {
          resolve(image);
        };
        image.src = 'data:' + imageData;
      }
    });
  }

  private _webPrint(
    text: string,
    width: string,
    logoImage?: HTMLImageElement,
    qrCode?: HTMLImageElement,
    imageData?: HTMLImageElement,
    logoWidth = 150,
    bottomText?: string
  ): Promise<any> {
    return new Promise((resolve) => {
      var frame1: any = document.createElement('iframe');
      frame1.name = 'frame1';
      frame1.style.position = 'absolute';
      frame1.style.top = '-1000000px';
      document.body.appendChild(frame1);
      var frameDoc = frame1.contentWindow
        ? frame1.contentWindow
        : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;

      frameDoc.document.open();
      frameDoc.document.write(
        '<html><head><title>Print receipt</title><style>table td{padding;0} html,body{margin:0;padding;0;font-size:14px;font-family:sans-serif,Helvetica Neue,Lucida Grande,Arial}</style>'
      );

      frameDoc.document.write('</head><body>');
      if (logoImage) {
        frameDoc.document.write(
          `<div style="text-align: center"><span><img src="${logoImage.src}" width="${logoWidth}"></span></div>`
        );
      }

      if (text) {
        frameDoc.document.write(`<div>${text}</div>`);
      }

      if (imageData) {
        frameDoc.document.write(`<img src="${imageData.src}" >`);
      }

      if (qrCode) {
        frameDoc.document.write(
          `<div style="text-align: center"><span><img src="${qrCode.src}"></span></div>`
        );
      }

      if (bottomText) {
        frameDoc.document.write(
          `<div style="text-align: center">${bottomText}</div>`
        );
      }

      frameDoc.document.write(`<div>&nbsp;</div><div>&nbsp;</div>`);
      frameDoc.document.write('</body></html>');
      frameDoc.document.close();

      setTimeout(function () {
        window.frames['frame1'].focus();
        window.frames['frame1'].print();
        document.body.removeChild(frame1);

        resolve(null);
      }, 500);
    });
  }

  getPrinter(ipAddress?: string, uuid?: string): Observable<IPrinterItem> {
    return from(this._getDevices()).pipe(
      map((res) => {
        return res.find(
          (printer) =>
            (ipAddress && printer.ip == ipAddress) ||
            (uuid && printer.uuid == uuid)
        );
      })
    );
  }

  async updatePrinter(printer: IPrinterItem) {
    const copy = JSON.parse(JSON.stringify(printer));
    if (printer.work_areas)
      copy.work_areas = JSON.stringify(printer.work_areas);

    // this._printerApi.updatePrinter(copy).subscribe();

    await this._printerRepo.updatePrinter(printer);
    this._getDevices().then((res) => (this._printers = res));
  }

  private async _addPrinter(newPrinter: IPrinterItem) {
    // var companyId = await this._companyData.getCompanyId();
    // const copy = JSON.parse(JSON.stringify(newPrinter));
    // copy.work_areas = JSON.stringify(newPrinter.work_areas);

    // this._printerApi
    //   .createPrinter(companyId, this._device.uuid, copy)
    //   .subscribe();

    const printers = await this._getDevices();
    const printer = printers.find(
      (p) =>
        (p.ip === newPrinter.ip && p.port === newPrinter.port) ||
        p.uuid === newPrinter.uuid ||
        p.address === newPrinter.address
    );

    if (!printer) {
      if (!newPrinter.name) {
        newPrinter.name = 'Printer';
      }

      newPrinter.printWidth = '58';
      newPrinter.printCheck = true;

      printers.push(newPrinter);
      await this._printerRepo.savePrinter(newPrinter);
      this._printers = printers;
      this.onPrinterAdded.emit(printers);
    }
  }

  private _findPrinter(printer: IPrinterItem) {
    let index = -1;
    const p = this._printers.find((p, i) => {
      if (
        (p.ip && p.ip === printer.ip) ||
        (p.uuid && p.uuid === printer.uuid) ||
        (p.address && p.address === printer.address) ||
        (p.name && p.name === printer.name)
      ) {
        index = i;
        return true;
      }
    });
    if (!p) {
      return null;
    }

    // p.index = index;
    return p;
  }

  async removePrinter(p: IPrinterItem): Promise<void> {
    await this._printerRepo.deletePrinter(p);
    this._printers = await this._getDevices();
  }

  async openPrintPreview(printObjects: any[]): Promise<any> {
    const modal = await this.modalCtrl.create({
      component: PrinterPreviewComponent,
      cssClass: 'print-preview-modal',
      backdropDismiss: false,
      componentProps: {
        printObjects: printObjects,
        printTestAction: (printer: IPrinterItem) => this.printTest(printer),
        printAction: (res) => this._sendToPrinter([res]),
      },
    });
    modal.present();
    return modal.onWillDismiss();
  }

  async open(): Promise<any> {
    const importedModuleFile = await import('./printer.module');

    const modal = await this.modalCtrl.create({
      component: importedModuleFile.PrinterModule.getPrintersComponent(),
      cssClass: 'printers-modal',
      backdropDismiss: false,
      componentProps: {
        printerService: this,
      },
    });
    modal.present();
    return modal.onWillDismiss();
  }

  private _replaceCyrillicChars(text: string): string {
    let result: string[] = [];
    for (let letter of text) {
      switch (letter.charCodeAt(0)) {
        case 8211: // –
        case 8212: // —
          result.push(String.fromCharCode(45)); // -
          break;

        case 1110: // і
          result.push(String.fromCharCode(105)); // i
          break;

        case 1030: // І
          result.push(String.fromCharCode(73)); // i
          break;

        case 171: // «
        case 187: // »
          result.push(String.fromCharCode(34)); // "
          break;

        case 8217: // ’
          result.push(String.fromCharCode(39)); // "
          break;

        case 1169: // ґ
          result.push(String.fromCharCode(1075)); // г
          break;

        default:
          result.push(letter);
      }
    }

    return result.join('');
  }
}

declare var require: any;
const iconv = require('iconv-lite');
const linewrap = require('linewrap');
const { createCanvas } = require('canvas');
const Dither = require('canvas-dither');
const Flatten = require('canvas-flatten');

/**
 * Create a byte stream based on commands for ESC/POS printers
 */
class EscPosEncoderNew {
  private _buffer = [];
  private _codepage: string;
  private _state: any;
  /**
   * Create a new object
   *
   */
  constructor() {
    this._reset();
  }

  /**
   * Reset the state of the object
   *
   */
  _reset() {
    this._buffer = [];
    this._codepage = 'ascii';

    this._state = {
      bold: false,
      italic: false,
      underline: false,
      hanzi: false,
    };
  }

  /**
   * Encode a string with the current code page
   *
   * @param  {string}   value  String to encode
   * @return {object}          Encoded string as a ArrayBuffer
   *
   */
  _encode(value) {
    return iconv.encode(value, this._codepage);
  }

  /**
   * Add commands to the buffer
   *
   * @param  {array}   value  And array of numbers, arrays, buffers or Uint8Arrays to add to the buffer
   *
   */
  _queue(value) {
    value.forEach((item) => this._buffer.push(item));
  }

  /**
   * Initialize the printer
   *
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  initialize() {
    this._queue([0x1b, 0x40]);

    return this;
  }

  /**
   * Change the code page
   *
   * @param  {string}   value  The codepage that we set the printer to
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  codepage(value, pageNumber: number) {
    const codepages = {
      cp437: [0x00, false],
      cp737: [0x40, false],
      cp850: [0x02, false],
      cp775: [0x5f, false],
      cp852: [0x12, false],
      cp855: [0x3c, false],
      cp857: [0x3d, false],
      cp858: [0x13, false],
      cp860: [0x03, false],
      cp861: [0x38, false],
      cp862: [0x3e, false],
      cp863: [0x04, false],
      cp864: [0x1c, false],
      cp865: [0x05, false],
      cp866: [0x11, false],
      cp869: [0x42, false],
      cp936: [0xff, true],
      cp949: [0xfd, true],
      cp950: [0xfe, true],
      cp1252: [0x10, false],
      iso88596: [0x16, false],
      shiftjis: [0xfc, true],
      windows1250: [0x48, false],
      windows1251: [0x49, false],
      windows1252: [0x47, false],
      windows1253: [0x5a, false],
      windows1254: [0x5b, false],
      windows1255: [0x20, false],
      windows1256: [0x5c, false],
      windows1257: [0x19, false],
      windows1258: [0x5e, false],
    };

    let codepage;

    if (!iconv.encodingExists(value)) {
      throw new Error('Unknown codepage');
    }

    if (value in iconv.encodings) {
      if (typeof iconv.encodings[value] === 'string') {
        codepage = iconv.encodings[value];
      } else {
        codepage = value;
      }
    } else {
      throw new Error('Unknown codepage');
    }

    if (typeof codepages[codepage] !== 'undefined') {
      this._codepage = codepage;
      this._state.hanzi = codepages[codepage][1];

      const codePageNumber =
        pageNumber > 0 ? pageNumber : codepages[codepage][0];
      this._queue([0x1b, 0x74, codePageNumber]);
    } else {
      throw new Error('Codepage not supported by printer');
    }

    return this;
  }

  /**
   * Print text
   *
   * @param  {string}   value  Text that needs to be printed
   * @param  {number}   wrap   Wrap text after this many positions
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  text(value, wrap) {
    if (wrap) {
      let w = linewrap(wrap, { lineBreak: '\r\n' });
      value = w(value);
    }

    let bytes = this._encode(value);

    if (this._state.hanzi) {
      this._queue([0x1c, 0x26, bytes, 0x1c, 0x2e]);
    } else {
      this._queue([bytes]);
    }

    return this;
  }

  /**
   * Print a newline
   *
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  newline() {
    this._queue([0x0a, 0x0d]);

    return this;
  }

  /**
   * Print text, followed by a newline
   *
   * @param  {string}   value  Text that needs to be printed
   * @param  {number}   wrap   Wrap text after this many positions
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  line(value, wrap) {
    this.text(value, wrap);
    this.newline();

    return this;
  }

  /**
   * Underline text
   *
   * @param  {boolean|number}   value  true to turn on underline, false to turn off, or 2 for double underline
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  underline(value) {
    if (typeof value === 'undefined') {
      value = !this._state.underline;
    }

    this._state.underline = value;

    this._queue([0x1b, 0x2d, Number(value)]);

    return this;
  }

  /**
   * Italic text
   *
   * @param  {boolean}          value  true to turn on italic, false to turn off
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  italic(value) {
    if (typeof value === 'undefined') {
      value = !this._state.italic;
    }

    this._state.italic = value;

    this._queue([0x1b, 0x34, Number(value)]);

    return this;
  }

  /**
   * Bold text
   *
   * @param  {boolean}          value  true to turn on bold, false to turn off, or 2 for double underline
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  bold(value) {
    if (typeof value === 'undefined') {
      value = !this._state.bold;
    }

    this._state.bold = value;

    this._queue([0x1b, 0x45, Number(value)]);

    return this;
  }

  /**
   * Change text size
   *
   * @param  {string}          value   small or normal
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  size(value) {
    if (value === 'small') {
      value = 0x01;
    } else {
      value = 0x00;
    }

    this._queue([0x1b, 0x4d, value]);

    return this;
  }

  /**
   * Change text alignment
   *
   * @param  {string}          value   left, center or right
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  align(value) {
    const alignments = {
      left: 0x00,
      center: 0x01,
      right: 0x02,
    };

    if (value in alignments) {
      this._queue([0x1b, 0x61, alignments[value]]);
    } else {
      throw new Error('Unknown alignment');
    }

    return this;
  }

  /**
   * Barcode
   *
   * @param  {string}           value  the value of the barcode
   * @param  {string}           symbology  the type of the barcode
   * @param  {number}           height  height of the barcode
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  barcode(value, symbology, height) {
    const symbologies = {
      upca: 0x00,
      upce: 0x01,
      ean13: 0x02,
      ean8: 0x03,
      coda39: 0x04,
      itf: 0x05,
      codabar: 0x06,
    };

    if (symbology in symbologies) {
      let bytes = iconv.encode(value, 'ascii');

      this._queue([
        0x1d,
        0x68,
        height,
        0x1d,
        0x77,
        symbology === 'code39' ? 0x02 : 0x03,
        0x1d,
        0x6b,
        symbologies[symbology],
        bytes,
        0x00,
      ]);
    } else {
      throw new Error('Symbology not supported by printer');
    }

    return this;
  }

  /**
   * QR code
   *
   * @param  {string}           value  the value of the qr code
   * @param  {number}           model  model of the qrcode, either 1 or 2
   * @param  {number}           size   size of the qrcode, a value between 1 and 8
   * @param  {string}           errorlevel  the amount of error correction used, either 'l', 'm', 'q', 'h'
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  qrcode(value, model, size, errorlevel) {
    /* Force printing the print buffer and moving to a new line */

    this._queue([0x0a]);

    /* Model */

    const models = {
      1: 0x31,
      2: 0x32,
    };

    if (typeof model === 'undefined') {
      model = 2;
    }

    if (model in models) {
      this._queue([
        0x1d,
        0x28,
        0x6b,
        0x04,
        0x00,
        0x31,
        0x41,
        models[model],
        0x00,
      ]);
    } else {
      throw new Error('Model must be 1 or 2');
    }

    /* Size */

    if (typeof size === 'undefined') {
      size = 6;
    }

    if (typeof size !== 'number') {
      throw new Error('Size must be a number');
    }

    if (size < 1 || size > 8) {
      throw new Error('Size must be between 1 and 8');
    }

    this._queue([0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, size]);

    /* Error level */

    const errorlevels = {
      l: 0x30,
      m: 0x31,
      q: 0x32,
      h: 0x33,
    };

    if (typeof errorlevel === 'undefined') {
      errorlevel = 'm';
    }

    if (errorlevel in errorlevels) {
      this._queue([
        0x1d,
        0x28,
        0x6b,
        0x03,
        0x00,
        0x31,
        0x45,
        errorlevels[errorlevel],
      ]);
    } else {
      throw new Error('Error level must be l, m, q or h');
    }

    /* Data */

    let bytes = iconv.encode(value, 'iso88591');
    let length = bytes.length + 3;

    this._queue([
      0x1d,
      0x28,
      0x6b,
      length % 0xff,
      length / 0xff,
      0x31,
      0x50,
      0x30,
      bytes,
    ]);

    /* Print QR code */

    this._queue([0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x51, 0x30]);

    return this;
  }

  /**
   * Image
   *
   * @param  {object}         element  an element, like a canvas or image that needs to be printed
   * @param  {number}         width  width of the image on the printer
   * @param  {number}         height  height of the image on the printer
   * @param  {string}         algorithm  the dithering algorithm for making the image black and white
   * @param  {number}         threshold  threshold for the dithering algorithm
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  image(element, width, height, algorithm, threshold) {
    if (width % 8 !== 0) {
      throw new Error('Width must be a multiple of 8');
    }

    if (height % 8 !== 0) {
      throw new Error('Height must be a multiple of 8');
    }

    if (typeof algorithm === 'undefined') {
      algorithm = 'threshold';
    }

    if (typeof threshold === 'undefined') {
      threshold = 128;
    }

    let canvas = createCanvas(width, height);
    let context = canvas.getContext('2d');
    context.drawImage(element, 0, 0, width, height);
    let image = context.getImageData(0, 0, width, height);

    image = Flatten.flatten(image, [0xff, 0xff, 0xff]);

    switch (algorithm) {
      case 'threshold':
        image = Dither.threshold(image, threshold);
        break;
      case 'bayer':
        image = Dither.bayer(image, threshold);
        break;
      case 'floydsteinberg':
        image = Dither.floydsteinberg(image);
        break;
      case 'atkinson':
        image = Dither.atkinson(image);
        break;
    }

    let getPixel = (x, y) => (image.data[(width * y + x) * 4] > 0 ? 0 : 1);

    let bytes = new Uint8Array((width * height) >> 3);

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x = x + 8) {
        let i = y * (width >> 3) + (x >> 3);
        bytes[i] =
          (getPixel(x + 0, y) << 7) |
          (getPixel(x + 1, y) << 6) |
          (getPixel(x + 2, y) << 5) |
          (getPixel(x + 3, y) << 4) |
          (getPixel(x + 4, y) << 3) |
          (getPixel(x + 5, y) << 2) |
          (getPixel(x + 6, y) << 1) |
          getPixel(x + 7, y);
      }
    }

    this._queue([
      0x1d,
      0x76,
      0x30,
      0x00,
      (width >> 3) & 0xff,
      ((width >> 3) >> 8) & 0xff,
      height & 0xff,
      (height >> 8) & 0xff,
      bytes,
    ]);

    return this;
  }

  /**
   * Cut paper
   *
   * @param  {string}          value   full or partial. When not specified a full cut will be assumed
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  cut(value) {
    let data = 0x00;

    if (value == 'partial') {
      data = 0x01;
    }

    this._queue([0x1d, 0x56, data]);

    return this;
  }

  /**
   * Beeper sound functionality
   *
   * @return {object}                  Return the object, for easy chaining commands
   */
  beeper() {
    this._queue([0x1b, 0x42, 0x05, 0x01]);

    return this;
  }

  /**
   * Open cash drawer
   *
   * @return {object}                  Return the object, for easy chaining commands
   */
  openCashDrawer() {
    this._queue([0x1b, 0x70, 0x00]);

    return this;
  }

  /**
   * Add raw printer commands
   *
   * @param  {array}           data   raw bytes to be included
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  raw(data) {
    this._queue(data);

    return this;
  }

  /**
   * Encode all previous commands
   *
   * @return {Uint8Array}         Return the encoded bytes
   *
   */
  encode() {
    let length = 0;

    this._buffer.forEach((item) => {
      if (typeof item === 'number') {
        length++;
      } else {
        length += item.length;
      }
    });

    let result = new Uint8Array(length);

    let index = 0;

    this._buffer.forEach((item) => {
      if (typeof item === 'number') {
        result[index] = item;
        index++;
      } else {
        result.set(item, index);
        index += item.length;
      }
    });

    this._reset();

    return result;
  }
}
