import { Component } from '@angular/core';
import { IPrinterItem } from 'src/app/private/terminal/repositories/printers.repository';
import { LanguagesService } from 'src/app/services/languages.service';
import { IPrintObject } from '../printer.service';
import { AlertController, ModalController } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  templateUrl: './printer-preview.component.html',
})
export class PrinterPreviewComponent {
  printer: IPrinterItem;
  printText: string;
  printers: IPrinterItem[] = [];
  printObjects: IPrintObject[] = [];
  transl: any = {};
  printTestAction: (printer: IPrinterItem) => {};
  printAction: (printer: IPrintObject) => {};

  constructor(
    private languagesService: LanguagesService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private shared: SharedService
  ) {
    this.languagesService
      .get([
        'printers_component.printers.select-printer',
        'printers_component.preview.doc-for-print',
        'printers_component.settings.test-print',
        'common_translate.printer',
        'common_translate.print',
        'common_translate.print-receipt',
        'common_translate.cancel',
      ])
      .subscribe((res) => {
        this.transl = res;
      });
  }

  print() {
    if (!this.printer) {
      this.alertCtrl
        .create({ message: 'Оберіть принтер', buttons: ['Close'] })
        .then((alert) => alert.present());
      return;
    }

    this.printAction({
      printer: this.printer,
      printText: this.printText,
    });
  }

  async selectPrinter() {
    const selectItems = this.printObjects.map((po) => {
      return {
        label: `${po.printer.name} ${po.printer.ip}`,
        value: po,
      };
    });
    const res = await this.shared.selectModal(selectItems, 'Оберіть принтерВ');
    if (!res) {
      return;
    }

    this.printText = res.printText;
    this.printer = res.printer;
  }

  testPrint() {
    if (!this.printer) {
      this.alertCtrl
        .create({ message: 'Оберіть принтер', buttons: ['Close'] })
        .then((alert) => alert.present());
      return;
    }

    this.printTestAction(this.printer);
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
