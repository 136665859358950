import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericRepository, IGenericItem } from './generic.repository';

export class ProductItem extends IGenericItem {
  parentproductid?: number;
  category_id?: number;
}

const PRODUCTS_TABLE_KEY = '__products';

@Injectable({ providedIn: 'root' })
export class ProductsRepository {
  constructor(private _repository: GenericRepository) {}

  getProducts(): Promise<ProductItem[]> {
    return this._repository.getItems<ProductItem>(PRODUCTS_TABLE_KEY);
  }

  loadProducts(products: ProductItem[]): Promise<void> {
    return this._repository.loadItems(PRODUCTS_TABLE_KEY, products);
  }

  clearStorage() {
    return this._repository.deleteTable(PRODUCTS_TABLE_KEY);
  }
}
