import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['./select-modal.component.scss'],
})
export class SelectModalComponent implements OnInit {
  items: { label: string; subLabel?: string; note?: string; value: any }[];
  header: string;
  columns = 1;
  rows: any[] = [];

  constructor(private modlCtrl: ModalController) {}

  ngOnInit() {
    if (this.columns <= 1) {
      return;
    }

    const remains = this.items.length % this.columns;
    const lastItems = this.columns - remains;
    for (let i = 0; i < lastItems; i++) {
      this.items.push({ label: '', value: null });
    }

    this.rows = this.splitarray(this.items, this.columns);
  }

  private splitarray(array: any[], spacing: number) {
    var output = [];

    for (var i = 0; i < array.length; i += spacing) {
      output[output.length] = array.slice(i, i + spacing);
    }

    return output;
  }

  selectItem(value: any) {
    this.modlCtrl.dismiss(value.value);
  }

  close() {
    this.modlCtrl.dismiss();
  }
}
