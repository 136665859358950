import { EventEmitter, Injectable } from '@angular/core';
import { GenericRepository } from '../repositories/generic.repository';
import { CompanyStorage } from 'src/app/_core/company-storage';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

export class CustomerItem {
  id: number;
  birthday: Date;
  firstname: string;
  genderid: string;
  lastname: string;
  patronymic: string;
  phone: string;
  islastrequested?: boolean = false;
  bonusesaccount?: number = 0;
  percentdiscount?: number = 0;
  loyaltydiscount?: number = 0;
  loyaltytypeid?: number = 0;
  loyaltyname?: string;
  loyaltyprograms: any[];
  loyaltyprogramid?: number;
  customecode?: number;

  get fullName() {
    return `${this.firstname} ${this.firstname}`;
  }

  constructor(customer: any) {
    if (!customer) {
      return;
    }

    console.log(customer);
    this.id = customer.id;
    if (customer.firstName) {
      this.firstname = customer.firstName;
    }

    if (customer.firstname) {
      this.firstname = customer.firstname;
    }

    if (customer.lastName) {
      this.lastname = customer.lastName;
    }

    if (customer.lastname) {
      this.lastname = customer.lastname;
    }

    if (customer.genderId) {
      this.genderid = customer.genderId;
    }

    if (customer.genderid) {
      this.genderid = customer.genderid;
    }

    this.patronymic = customer.patronymic;
    this.phone = customer.phone;
    this.islastrequested = customer.islastrequested;
    this.bonusesaccount = customer.bonusesaccount;
    this.percentdiscount = customer.percentdiscount;
    this.loyaltydiscount = customer.loyaltydiscount;
    this.loyaltytypeid = customer.loyaltytypeid;
    this.loyaltyname = customer.loyaltyname;
    this.loyaltyprograms = customer.loyaltyprograms;
    this.loyaltyprogramid = customer.loyaltyprogramid;
    this.customecode = customer.customecode;

    if (customer.birthday && typeof customer.birthday == 'object') {
      this.birthday = customer.birthday;
    } else if (customer.birthday) {
      var parts = customer.birthday.split('.');
      if (parts.length == 3) {
        this.birthday = parse(customer.birthday, 'dd.MM.yyyy', new Date());
      } else {
        this.birthday = parseISO(customer.birthday);
      }
    }
  }
}

const TABLE_KEY = '__customers';
const CUSTOMERS_TABLE_KEY = '__customers';

@Injectable({ providedIn: 'root' })
export class CustomersRepository {
  onCustomersUpdate = new EventEmitter<CustomerItem[]>();

  constructor(
    private _repository: GenericRepository,
    private _companyStorage: CompanyStorage
  ) {
    this._syncLegacyOrders();
  }

  // legacy support
  private async _syncLegacyOrders() {
    const customers = await this._getCustomersLegacy();
    try {
      if (customers && customers.length) {
        for (let c of customers) {
          await this.saveCustomer(c);
        }
      }
    } catch {}

    await this.clearLegacyStorage();
  }
  // legacy support

  async saveCustomer(customer: CustomerItem): Promise<void> {
    const repo = await this._companyStorage.repository<CustomerItem>(
      CUSTOMERS_TABLE_KEY
    );

    await repo.addOrupdateItem(customer, 'id', 'start');
    const customers = await this.getCustomers();
    this.onCustomersUpdate.emit(customers);
  }

  async getCustomers(): Promise<CustomerItem[]> {
    const repo = await this._companyStorage.repository<CustomerItem>(
      CUSTOMERS_TABLE_KEY
    );

    return repo.getItems();
  }

  private _getCustomersLegacy(): Promise<CustomerItem[]> {
    return this._repository.getItems<CustomerItem>(TABLE_KEY);
  }

  private clearLegacyStorage() {
    return this._repository.deleteTable(TABLE_KEY);
  }

  async loadCustomers(customers: CustomerItem[]) {
    const repo = await this._companyStorage.repository<CustomerItem>(
      CUSTOMERS_TABLE_KEY
    );

    repo.loadItems(customers);
  }

  async clearStorage() {
    const repo = await this._companyStorage.repository<CustomerItem>(
      CUSTOMERS_TABLE_KEY
    );

    repo.deleteTable();
  }
}
