<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <span class="h3" *ngIf="isAdvance">Внесення авансу</span>
        <span class="h3" *ngIf="!isAdvance">Оплата замовлення</span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-grid>
          <ion-row>
            <ion-col class="h5 mb-0 pb-0">Сума замовлення</ion-col>
            <ion-col class="ion-text-end h5 mb-0 pb-0">{{
              order.amount.toFixed(2)
            }}</ion-col>
          </ion-row>
          <ion-row *ngIf="order.discount" class="text-success">
            <ion-col class="h6 mb-0 ml-4 pb-0">Знижка</ion-col>
            <ion-col class="ion-text-end h mb-0 pb-0">{{
              order.discount.toFixed(2)
            }}</ion-col>
          </ion-row>
          <ion-row *ngIf="order.totalPaid" class="text-success">
            <ion-col class="h6 mb-0 ml-4 pb-0">Сплачено</ion-col>
            <ion-col class="ion-text-end h mb-0 pb-0">{{
              order.totalPaid.toFixed(2)
            }}</ion-col>
          </ion-row>
          <ion-row *ngIf="order.totalPaid || order.discount">
            <ion-col class="h4 mb-0 pb-0">До сплати</ion-col>
            <ion-col class="ion-text-end h4 mb-0 pb-0">{{
              orderTotalAmount
            }}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="py-2">
              <ion-segment [value]="paymentMethod">
                <ion-segment-button
                  value="cash"
                  (click)="setPaymentMethod('cash')"
                >
                  <ion-label>Готівка</ion-label>
                </ion-segment-button>
                <ion-segment-button
                  value="card"
                  (click)="setPaymentMethod('card')"
                >
                  <ion-label>Карта</ion-label>
                </ion-segment-button>
              </ion-segment>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="isAdvance">
            <ion-col>
              <div
                class="fake-control amount-group"
                [class.focus]="orderAdvanceFocus"
                (click)="paymentAdvanceClick()"
              >
                Сума авансу |
                <span class="amount">{{ paymentAdvance }}</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="paymentMethod == 'card'">
            <ion-col>
              <div class="fake-control amount-group" style="border: none">
                <span>Карта</span>
                <span> | </span>
                <span class="amount">{{
                  isAdvance ? paymentAdvance : orderTotalAmount
                }}</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="paymentMethod == 'cash'">
            <ion-col>
              <div
                class="fake-control amount-group"
                [class.focus]="orderAmountFocus"
                (click)="paymentProvidedClick()"
              >
                <span>Готівка</span>
                <span> | </span>
                <span class="amount">{{ paymentProvided }}</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="paymentMethod == 'cash'">
            <ion-col>
              <div class="fake-control" style="border: none">
                Решта |
                <span>{{ paymentChange }}</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <order-fiscal-switch
                #fiscalSwitch
                [storeId]="order.storeId"
              ></order-fiscal-switch>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
      <ion-col size="auto">
        <!-- <div *ngIf="selectedOrder">
          <app-check [order]="selectedOrder"> </app-check>
        </div> -->
        <div>
          <app-numpad
            #numpad
            [numpudConfig]="{
              height: 60,
              width: 300,
              divisible: false,
              autoDecimal: true,
              preselectBtns: numpadButtons
            }"
            (change)="numpadInput($event)"
          ></app-numpad>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button color="dark" fill="outline" (click)="close()">
          Закрити
        </ion-button>
      </ion-col>
      <ion-col class="ion-text-end">
        <ion-button color="success" [disabled]="!isValid" (click)="pay()">
          Оплатити
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
