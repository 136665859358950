import { ITaxOrder } from './tax-order.model';
import * as sha256 from 'sha256';
import DateUtils from 'src/app/utility/date-utils';

export class TaxDocumentType {
  static SALE_GOODS = 0;
  static OPEN_SHIFT = 100;
  static CLOSE_SHIFT = 101;
  static OOFFLINE_BEGIN = 102;
  static OFFLINE_END = 103;
}

export class TaxDocumentSubType {
  static CHECK_RETURN = 1;
  static SERVICE_DEPOSIT = 2;
  static ADDITIONAL_DEPOSIT = 3;
  static SERVICE_ISSUE = 4;
  static CHECK_STORNO = 5;
}

export class TransactionsRegistrarStateCommand {
  private _command = 'TransactionsRegistrarState';
  get Command(): string {
    return this._command;
  }

  private _numFiscal: string; // <Фіскальний номер ПРРО>
  get NumFiscal(): string {
    return this._numFiscal;
  }

  private _offlineSessionId: string; // <Ідентифікатор офлайн сесії, для якої будуть надіслані пакети документів (не обов’язковий)>
  get OfflineSessionId(): string {
    return this._offlineSessionId;
  }

  private _offlineSeed: string; // <"Секретне число" для обчислення фіскального номера офлайн документа офлайн сесії, для якої будуть надіслані пакети документів (не обов’язковий)>
  get OfflineSeed(): string {
    return this._offlineSeed;
  }

  private _includeTaxObject: boolean; // <Ознака запиту відомостей об’єкту оподаткування (false/true) (не обов’язковий)>
  get IncludeTaxObject(): boolean {
    return this._includeTaxObject;
  }

  constructor(
    private numFiscal: string,
    private offlineSessionId?: string,
    private offlineSeed?: string,
    private includeTaxObject?: boolean
  ) {
    this._numFiscal = this.numFiscal;
    this._offlineSessionId = this.offlineSessionId;
    this._offlineSeed = this.offlineSeed;
    this._includeTaxObject = this.includeTaxObject;
  }

  toString(): string {
    const commandJson = {
      Command: this.Command,
      NumFiscal: this.NumFiscal,
      OfflineSessionId: this.OfflineSessionId,
      OfflineSeed: this.OfflineSeed,
      IncludeTaxObject: this.IncludeTaxObject,
    };
    return JSON.stringify(commandJson);
  }
}

export interface TransactionsRegistrarStateResponse {
  UID: string; // <Унікальний ідентифікатор відповіді>,
  Timestamp: string;
  ShiftState: number; // <0-зміну не відкрито, 1-зміну відкрито>,
  ShiftId: number; // = <Ідентифікатор зміни>,
  OpenShiftFiscalNum: string; // = <Фіскальний номер документа “Відкриття зміни”>,
  ZRepPresent: boolean; // = <Ознака присутності Z-звіту (false/true)>,
  Testing: boolean;
  Name: string; // = <П.І.Б. оператора, що відкрив зміну>,
  SubjectKeyId: string; // = <Ідентифікатор ключа суб’єкта сертифікату оператора, що відкрив зміну>,
  FirstLocalNum: number; // = <Перший внутрішній номер документа у зміні>,
  NextLocalNum: number; // = <Наступний внутрішній номер документа>,
  LastFiscalNum: string; // = <Останній фіскальний номер документа>,

  OfflineSupported: boolean; // = <Для ПРРО може використовуватись режим офлайн>,
  ChiefCashier: boolean; // = <Користувач є старшим касиром>,

  OfflineSessionId: string; // = <Ідентифікатор офлайн сесії (null – режим офлайн заборонений для ПРРО)>,
  OfflineSeed: string; // = <"Секретне число" для обчислення фіскального номера офлайн документа (null – режим офлайн заборонений для ПРРО)>,
  OfflineNextLocalNum: string; // = <Наступний локальний номер документа в офлайн сесії (null – режим офлайн заборонений для ПРРО)>,
  OfflineSessionDuration: string; // = <Тривалість офлайн сесії (хвилин) (null – режим офлайн заборонений для ПРРО)>,
  OfflineSessionsMonthlyDuration: string; // = <Сумарна тривалість офлайн сесій за поточний місяць (хвилин) (null – режим офлайн заборонений для ПРРО)>,
  OfflineSessionRolledBack: boolean;
  OfflineSessionRollbackCmdUID: any;

  Closed: boolean; // = <Ознака скасованої реєстрації ПРРО, на якому наразі не закрито зміну>,

  TaxObject?: TaxObject; // = <Відомості об’єкту оподаткування (TaxObjectItem)>
}

export class TaxObject {}

export class LastShiftTotalsCommand {
  private _command = 'LastShiftTotals';
  get Command(): string {
    return this._command;
  }

  private _numFiscal: string; // <Фіскальний номер ПРРО>
  get NumFiscal(): string {
    return this._numFiscal;
  }

  constructor(private numFiscal: string) {
    this._numFiscal = this.numFiscal;
  }

  toString(): string {
    const commandJson = {
      Command: this.Command,
      NumFiscal: this.NumFiscal,
    };
    return JSON.stringify(commandJson);
  }
}

export class ShiftsCommand {
  private _command = 'Shifts';
  get Command(): string {
    return this._command;
  }

  private _numFiscal: string; // <Фіскальний номер ПРРО>
  get NumFiscal(): string {
    return this._numFiscal;
  }

  private _from: Date; // <Фіскальний номер ПРРО>
  get From(): Date {
    return new Date(
      Date.UTC(
        this._from.getFullYear(),
        this._from.getMonth(),
        this._from.getDate()
      )
    );
  }

  private _to: Date; // <Фіскальний номер ПРРО>
  get To(): Date {
    return new Date(
      Date.UTC(
        this._to.getFullYear(),
        this._to.getMonth(),
        this._to.getDate(),
        23,
        59,
        59
      )
    );
  }

  constructor(private numFiscal: string, private from: Date, private to: Date) {
    this._numFiscal = this.numFiscal;
    this._from = this.from;
    this._to = this.to;
  }

  toString(): string {
    const _from = this.From.getTime();
    const _to = this.To.getTime();

    const commandJson = {
      Command: this.Command,
      NumFiscal: this.NumFiscal,
      From: `/Date(${_from})/`,
      To: `/Date(${_to})/`,
    };
    return JSON.stringify(commandJson);
  }
}

export class ShiftDocumentsCommand {
  private _command = 'Documents';
  get Command(): string {
    return this._command;
  }

  private _numFiscal: string; // <Фіскальний номер ПРРО>
  get NumFiscal(): string {
    return this._numFiscal;
  }

  private _shiftId: string; // <Ідентифікатор зміни>
  get ShiftId(): string {
    return this._shiftId;
  }

  private _openShiftFiscalNum: string; // <Фіскальний номер документа “Відкриття зміни>
  get OpenShiftFiscalNum(): string {
    return this._openShiftFiscalNum;
  }

  constructor(
    private numFiscal: string,
    private shiftId: number,
    private openShiftFiscalNum: number
  ) {
    this._numFiscal = this.numFiscal.toString();
    this._shiftId = this.shiftId.toString();
    this._openShiftFiscalNum = this.openShiftFiscalNum.toString();
  }

  toString(): string {
    const commandJson = {
      Command: this.Command,
      NumFiscal: this.NumFiscal,
      ShiftId: this.ShiftId,
      OpenShiftFiscalNum: this.OpenShiftFiscalNum,
    };
    return JSON.stringify(commandJson);
  }
}

export class DocumentResponse {
  UID: string;
  Data: string; // Base64
  ResultCode: number;
  ResultText: string;
}

export class ShiftDocumentsResponse {
  UID: string;
  Documents: DocumentItem[];
}

export class DocumentItem {
  NumFiscal: string; // <Фіскальний номер документа>
  NumLocal: number; // <Локальний номер документа>
  DocDateTime: string; // <Дата і час операції, зафіксованої документом>
  DocClass: 'Check' | 'ZRep'; // <Клас документа (“Check”, “ZRep”)>
  CheckDocType: string; // <Тип чека (“SaleGoods”, …)>
  CheckDocSubType: string; // <Розширений тип чека (“CheckGoods”, …)>
  Revoked: boolean; // <Ознака відкликаного документа>
  Storned: boolean; // <Ознака сторнованого документа>
}

export class ShiftsResponse {
  UID: string;
  Shifts: ShiftItem[];
}

export class ShiftItem {
  ShiftId: number;
  OpenShiftFiscalNum: string;
  CloseShiftFiscalNum: string;
  Testing: boolean;
  Opened: string;
  OpenName: string;
  OpenSubjectKeyId: string;
  Closed: string;
  CloseName: string;
  CloseSubjectKeyId: string;
  ZRepFiscalNum: string;
}

export class LastShiftTotalsResponse {
  UID: string; // Унікальний ідентифікатор відповіді,
  ShiftState: 0 | 1; // 0-зміну не відкрито, 1-зміну відкрито,
  ZRepPresent: boolean; // = <Ознака присутності Z-звіту (false/true),
  Testing: boolean; // Ознака зміни, що містить тестові документи (false/true)
  Totals: ShiftTotals; // Підсумки зміни (якщо зміну відкрито)
  Opened: string; // Дата і час відкриття зміни
  OpenName: string; // П.І.Б. оператора, що відкрив зміну
  OpenSubjectKeyId: string; // Ідентифікатор ключа суб’єкта сертифікату оператора
  Closed: string; // Дата і час закриття зміни
  CloseName: string; // П.І.Б. оператора, що закрив зміну
  CloseSubjectKeyId: string; // Ідентифікатор ключа суб’єкта сертифікату оператора
  ZRepFiscalNum: string; // Фіскальний номер Z-звіту
}

// Підсумки по зміні
export class ShiftTotals {
  Real: ShiftTotalsOrderType; // Підсумки реалізації
  Ret: ShiftTotalsOrderType; // Підсумки повернення
  Cash?: ShiftTotalsCash; // Підсумки видачі готівки
  Currency?: ShiftTotalsCurrency; // Підсумки операцій з іноземною валютою
  ServiceInput: number; // Службове внесення/Отримання авансу/Отримання підкріплення
  ServiceOutput: number; // Службова видача/Інкасація
}

// Підсумки по типу чека
export class ShiftTotalsOrderType {
  Sum: number; // Загальна сума
  PwnSumIssued: number; // Загальна сума коштів, виданих клієнту ломбарда
  PwnSumReceived: number; // Загальна сума коштів, одержаних від клієнта ломбарда
  RndSum: number; // Заокруглення (наприклад, 0.71)
  NoRndSum: number; // Загальна сума без заокруглення (наприклад, 1000.71)
  TotalCurrencySum: number; // Загальна сума переказів коштів
  TotalCurrencyCommission: number; // Загальна сума комісії від переказів коштів
  OrdersCount: number; // Кількість чеків
  TotalCurrencyCost: number; // Кількість операції переказу коштів

  PayForm: ShiftTotalsPayForm[]; // Підсумки по формам оплати
  Tax: ShiftTotalsTax[]; // Податки/збори
}

// Підсумки по формам оплати
export class ShiftTotalsPayForm {
  PayFormCode: number;
  PayFormName: string;
  Sum: number;
}

// Податки/збори
export class ShiftTotalsTax {
  Type: number; // Код виду податку/збору
  Name: string; // Найменування виду податку/збору
  Letter: string; // Літерне позначення виду і ставки податку/збору
  Prc: number; // Відсоток податку/збору// Відсоток податку/збору
  Sign: boolean; // Ознака податку/збору, не включеного у вартість
  Turnover: number; // Сума обсягів для розрахування податку/збору
  SourceSum: number; // Вихідна сума для розрахування податку/збору
  Sum: number; // Сума податку/збору
}

// Підсумки видачі готівки
export class ShiftTotalsCash {
  Sum: number; // Загальна сума
  Commission: number; // Загальна сума комісії
  OrdersCount: number; // Кількість чеків
}

// Підсумки операцій з іноземною валютою
export class ShiftTotalsCurrency {
  TotalInAdvance: number; // Отримано авансів національною валютою
  TotalInAttach: number; // Отримано підкріплень національною валютою
  TotalSurrCollection: number; // Здано по інкасації національною валютою
  Commission: number; // Отримано комісії конвертації
  CalcDocsCnt: number; // Кількість розрахункових документів за зміну
  AcceptedN: number; // Прийнято національної валюти для переказу
  IssuedN: number; // Видано національної валюти при виплаті переказу
  CommissionN: number; // Отримано комісії в національній валюті при здійсненні переказів
  TransfersCnt: number; // Кількість операцій (документів) переказів або виплат переказів
  Details: TotalsCurrencyDetails[]; // Підсумки по видам іноземної валюти
}

// Підсумки по виду іноземної валюти
export class TotalsCurrencyDetails {
  ValCd: number; // Код валюти
  ValSymCd: string; // Символьний код валюти
  BuyValI: number; // Загальна сума придбаної іноземної валюти
  SellValI: number; // Загальна сума проданої іноземної валюти
  BuyValN: number; // Загальна сума придбаної національної валюти
  SellValN: number; // Загальна сума проданої національної валюти
  StorBuyValI: number; // Загальна сума поверненої клієнтами іноземної валюти за операціями «сторно»
  StorSellValI: number; // Загальна сума виданої клієнтам національної валюти за операціями «сторно»
  StorBuyValN: number; // Загальна сума поверненої клієнтами національної валюти за операціями «сторно»
  StorSellValN: number; // Загальна сума виданої клієнтам національної валюти за операціями «сторно»
  CInValI: number; // Загальна сума прийнятої іноземної валюти за операціями конвертації
  COutValI: number; // Загальна сума виданої іноземної валюти за операціями конвертації
  Commission: number; // Загальна сума комісії за операціями конвертації
  InAdvance: number; // Отримано авансів
  InAttach: number; // Отримано підкріплень
  SurrCollection: number; // Здано по інкасації
  StorCInValI: number; // Видано іноземної валюти по сторно конвертації
  StorCOutValI: number; // Повернуто іноземної валюти по сторно конвертації
  StorCommission: number; // Повернуто суму комісії з сторно конвертації
}

export class ResponseOperators {
  UID: string;
  Tin: string;
  Operators: OperatorItem[];

  constructor() {
    this.Operators = [];
  }
}

export class OperatorItem {
  SubjectKeyId: string;
  RegNum: string;
  ChiefCashier: boolean;
}

export class TaxDataGroup {
  idGroup: number;
  title: string;
  type: number;
  headers: any;
  values?: any;
  listValues?: any[];
}

export interface ICabinetInfo {
  taxDataJson?: string;
  identity?: any[];
  stores: any[];
  prroDevices: any[];
  cashier?: string;
  register: any[];
}

export class TaxError {
  constructor(originalError: string) {
    this._parseTaxError(originalError);
  }

  originalError: string;
  error: TaxErrorEnum;
  text: string;
  errorCode: string;
  errorNumberCode: number;

  private _parseTaxError(taxError: string) {
    if (!taxError || taxError.length == 0) {
      return null;
    }

    const lines = taxError.split(/\r?\n/);
    if (lines.length == 0) {
      return;
    }

    if (lines.length < 2) {
      this.originalError = taxError;
      this.error = TaxErrorEnum[TaxErrorEnum[500]];
      this.text = taxError;
      this.errorCode = '500';
      this.errorNumberCode = 500;

      return;
    }

    const headerValues = lines[0].split(/(\s+)/).reverse();
    const messageParts: string[] = [];
    for (let i = 0; i < lines.length; i++) {
      if (i == 0) {
        continue;
      }

      messageParts.push(lines[i]);
    }

    this.originalError = taxError;
    this.error = TaxErrorEnum[TaxErrorEnum[+headerValues[2]]];
    this.text = messageParts.join('\n\r');
    this.errorCode = headerValues[2];
    this.errorNumberCode = +headerValues[2];
  }

  getNextCheckLocalNumberFromError() {
    console.log('getNextCheckLocalNumberFromError');
    let nextCheckLocalNumber: number;
    if (this.error == TaxErrorEnum.CheckLocalNumberInvalid) {
      const errorTextParts = this.text.split(' ');
      const _nextCheckLocalNumber = +errorTextParts.pop();
      if (!isNaN(_nextCheckLocalNumber)) {
        nextCheckLocalNumber = _nextCheckLocalNumber;
      }

      console.log(_nextCheckLocalNumber);
    }

    return nextCheckLocalNumber;
  }
}

export enum TaxErrorEnum {
  OperatorAccessToTransactionsRegistrarNotGranted = 2,
  ShiftAlreadyOpened = 4,
  ShiftNotOpened = 5,
  LastDocumentMustBeZRep = 6,
  CheckLocalNumberInvalid = 7,
  ZRepAlreadyRegistered = 8,
  DocumentValidationError = 9,
  OfflineSessionNotOpened = 15,
  ServerError = 500,
}

export class OfflineSession {
  startedAt: string;
  finishedAt: string;
  offlineSessionId: number;

  taxOrders: ITaxOrder[];

  constructor(offlineSession: OfflineSession) {
    if (!offlineSession) {
      this.startedAt = DateUtils.format();
    } else {
      this.startedAt = offlineSession.startedAt;
      this.finishedAt = offlineSession.finishedAt;
      this.offlineSessionId = offlineSession.offlineSessionId;

      this.taxOrders = offlineSession.taxOrders;
    }
  }

  getNextLocalNmber() {
    let _lastOfflineDocument = null;
    if (this.taxOrders && this.taxOrders.length > 0) {
      _lastOfflineDocument = this.taxOrders[this.taxOrders.length - 1];
    }
  }

  getNextOfflineNmber() {}
}

export class ShiftObject implements TransactionsRegistrarStateResponse {
  UID: string;
  Timestamp: string;
  ShiftState: number;
  ShiftId: number;
  OpenShiftFiscalNum: string;
  ZRepPresent: boolean;
  Testing: boolean;
  Name: string;
  SubjectKeyId: string;
  FirstLocalNum: number;
  NextLocalNum: number;
  LastFiscalNum: string;
  OfflineSupported: boolean;
  ChiefCashier: boolean;

  OfflineSessionId: string;
  OfflineSeed: string;
  OfflineNextLocalNum: string;
  OfflineSessionDuration: string;
  OfflineSessionsMonthlyDuration: string;
  OfflineSessionRolledBack: boolean;
  OfflineSessionRollbackCmdUID: any;

  Closed: boolean;

  TaxObject?: TaxObject;
  OfflineDocuments: ITaxOrder[];
  OnlineDocuments: ITaxOrder[];

  constructor(model: ShiftObject | TransactionsRegistrarStateResponse) {
    if (!model) {
      this.OfflineDocuments = [];
      this.OnlineDocuments = [];
    }

    this.UID = model.UID;
    this.ShiftState = model.ShiftState;
    this.ShiftId = model.ShiftId;
    this.OpenShiftFiscalNum = model.OpenShiftFiscalNum;
    this.ZRepPresent = model.ZRepPresent;
    this.Testing = model.Testing;
    this.Name = model.Name;
    this.SubjectKeyId = model.SubjectKeyId;
    this.FirstLocalNum = model.FirstLocalNum;
    this.NextLocalNum = model.NextLocalNum;
    this.LastFiscalNum = model.LastFiscalNum;
    this.OfflineSupported = model.OfflineSupported;
    this.ChiefCashier = model.ChiefCashier;
    this.OfflineSessionId = model.OfflineSessionId;

    this.OfflineSessionId = model.OfflineSessionId;
    this.OfflineSeed = model.OfflineSeed;
    this.OfflineNextLocalNum = model.OfflineNextLocalNum;
    this.OfflineSessionDuration = model.OfflineSessionDuration;
    this.OfflineSessionsMonthlyDuration = model.OfflineSessionsMonthlyDuration;
    this.Closed = model.Closed;

    this.TaxObject = model.TaxObject;

    // if ('OfflineDocuments' in model && model.OfflineDocuments.length > 0) {
    //   this.OfflineDocuments = model.OfflineDocuments;
    // } else {
    //   this.OfflineDocuments = [];
    // }

    // if ('OnlineDocuments' in model && model.OnlineDocuments.length > 0) {
    //   this.OnlineDocuments = model.OnlineDocuments;
    // } else {
    //   this.OnlineDocuments = [];
    // }
  }

  updateState(model: TransactionsRegistrarStateResponse) {
    this.ShiftState = model.ShiftState;
    this.ShiftId = model.ShiftId;
    this.OpenShiftFiscalNum = model.OpenShiftFiscalNum;
    this.ZRepPresent = model.ZRepPresent;
    this.Testing = model.Testing;
    this.Name = model.Name;
    this.SubjectKeyId = model.SubjectKeyId;
    this.FirstLocalNum = model.FirstLocalNum;
    this.NextLocalNum = +model.NextLocalNum;
    this.LastFiscalNum = model.LastFiscalNum;
    this.OfflineSupported = model.OfflineSupported;
    this.ChiefCashier = model.ChiefCashier;
    this.OfflineSessionId = model.OfflineSessionId;

    this.OfflineSessionId = model.OfflineSessionId;
    this.OfflineSeed = model.OfflineSeed;
    this.OfflineSessionDuration = model.OfflineSessionDuration;
    this.OfflineSessionsMonthlyDuration = model.OfflineSessionsMonthlyDuration;
    this.Closed = model.Closed;
  }

  getOfflinePackage(): string[] {
    if (!this.OfflineDocuments || this.OfflineDocuments.length == 0) {
      return null;
    }

    let pack = [];
    for (let i = 0; i < this.OfflineDocuments.length; i++) {
      pack.push(this.OfflineDocuments[i].signedXmlDocument);
    }

    return pack;
  }

  isOfflineSessionOpened() {
    if (!this.OfflineDocuments || this.OfflineDocuments.length == 0) {
      return false;
    }

    const _lastOfflineDocument =
      this.OfflineDocuments[this.OfflineDocuments.length - 1];

    if (_lastOfflineDocument.documentType == 103) {
      return false;
    }

    return true;
  }

  getOfflineZReport(): ShiftTotals {
    console.log('getOfflineZReport');
    if (!this.OfflineDocuments || this.OfflineDocuments.length == 0) {
      return null;
    }

    let _serviceInput = 0;
    let _serviceOutput = 0;
    let _realSum = 0;
    let _realOrdersCount = 0;
    const _realPayForms: ShiftTotalsPayForm[] = [];

    let _retSum = 0;
    let _retOrdersCount = 0;
    const _retPayForms: ShiftTotalsPayForm[] = [];

    const _offlineDocuments = this.OfflineDocuments.reverse();
    for (let doc of _offlineDocuments) {
      if (
        doc.documentType == 102 ||
        doc.documentType == 103 ||
        doc.documentType == 100 ||
        doc.documentType == 101
      ) {
        break;
      }

      // check
      if (doc.documentType == 0) {
        if (!doc.documentSubType || doc.documentSubType == null) {
          // sale
          const _check = <ITaxOrder>doc;
          for (let checkpay of _check.CHECKPAY) {
            let _index = -1;
            const _checkpay = _realPayForms.find((pf, i) => {
              if (pf.PayFormCode == checkpay.PAYFORMCD) {
                _index = i;
                return true;
              }

              return false;
            });

            if (_checkpay) {
              _checkpay.Sum += checkpay.SUM;
              _realPayForms[_index] = _checkpay;
            } else {
              _realPayForms.push({
                PayFormCode: checkpay.PAYFORMCD,
                PayFormName: checkpay.PAYFORMNM,
                Sum: checkpay.SUM,
              });
            }
          }

          _realSum += _check.CHECKTOTAL;
          _realOrdersCount++;
        } else if (doc.documentSubType == 1) {
          // return check
          const _check = <ITaxOrder>doc;
          for (let checkpay of _check.CHECKPAY) {
            let _index = -1;
            const _checkpay = _retPayForms.find((pf, i) => {
              if (pf.PayFormCode == checkpay.PAYFORMCD) {
                _index = i;
                return true;
              }

              return false;
            });

            if (_checkpay) {
              _checkpay.Sum += checkpay.SUM;
              _retPayForms[_index] = _checkpay;
            } else {
              _retPayForms.push({
                PayFormCode: checkpay.PAYFORMCD,
                PayFormName: checkpay.PAYFORMNM,
                Sum: checkpay.SUM,
              });
            }
          }

          _retSum += _check.CHECKTOTAL;
          _retOrdersCount++;
        } else if (doc.documentSubType == 2) {
          // _serviceInput
          _serviceInput += (<ITaxOrder>doc).CHECKTOTAL;
        } else if (doc.documentSubType == 4) {
          // serviceOutput
          _serviceOutput += (<ITaxOrder>doc).CHECKTOTAL;
        }
      }
    }

    if (
      _realSum == 0 &&
      _realSum == 0 &&
      _serviceInput == 0 &&
      _serviceOutput == 0
    ) {
      return null;
    }

    const _shiftTotals: ShiftTotals = {
      Real: {
        Sum: _realSum,
        PwnSumIssued: 0,
        PwnSumReceived: 0,
        RndSum: 0,
        NoRndSum: 0,
        TotalCurrencySum: 0,
        TotalCurrencyCommission: 0,
        OrdersCount: _realOrdersCount,
        TotalCurrencyCost: 0,

        PayForm: _realPayForms,
        Tax: [],
      },
      Ret: {
        Sum: _retSum,
        PwnSumIssued: 0,
        PwnSumReceived: 0,
        RndSum: 0,
        NoRndSum: 0,
        TotalCurrencySum: 0,
        TotalCurrencyCommission: 0,
        OrdersCount: _retOrdersCount,
        TotalCurrencyCost: 0,

        PayForm: _retPayForms,
        Tax: [],
      },
      Cash: null,
      Currency: null,
      ServiceInput: _serviceInput,
      ServiceOutput: _serviceOutput,
    };

    console.log(JSON.stringify(_shiftTotals));
    return _shiftTotals;
  }

  getPrevDocHash() {
    const _lastOfflineDocument = this._getLastOfflineDocument();
    if (
      !_lastOfflineDocument ||
      _lastOfflineDocument.documentType == 102 ||
      _lastOfflineDocument.documentType == 103
    ) {
      return null;
    } else {
      return sha256(_lastOfflineDocument.signedXmlDocument);
    }
  }

  private _addOnlineDocument(onlineDocument: ITaxOrder) {
    if (!this.OnlineDocuments) {
      this.OnlineDocuments = [onlineDocument];
    } else {
      this.OnlineDocuments.push(onlineDocument);
    }
  }

  private _addOfflineDocument(offlineDocument: ITaxOrder) {
    if (!this.OfflineDocuments) {
      this.OfflineDocuments = [offlineDocument];
    } else {
      this.OfflineDocuments.push(offlineDocument);
    }
  }

  private _getLastOfflineDocument(): ITaxOrder {
    if (this.OfflineDocuments && this.OfflineDocuments.length > 0) {
      return this.OfflineDocuments[this.OfflineDocuments.length - 1];
    } else {
      return null;
    }
  }
}

export enum TAX_ORDER_ERROR {
  SIGN_DOCUMENT_ERROR = 'signDocumentError',
}

export class CHECKHEAD {
  DOCTYPE: number; // 0-Чек реалізації товарів/послуг, 1-Чек переказу коштів, 2–Чек операції обміну валюти, 3-Чек видачі готівки, 100-Відкриття зміни, 101-Закриття зміни, 102-Початок офлайн сесії, 103-Завершення офлайн сесії
  SUBDOCTYPE?: number;
  UID: string;
  TIN?: string;
  IPN?: string;
  ORGNM: string;
  POINTNM: string;
  POINTADDR: string;
  ORDERDATE: string;
  ORDERTIME: string;
  ORDERNUM: number;
  CASHDESKNUM: number;
  CASHREGISTERNUM: string;
  REVOKELASTONLINEDOC?: boolean;
  CASHIER: string;
  VER: number;
  ORDERTAXNUM: string;
  OFFLINE: boolean;
  TESTING: boolean;
  PREVDOCHASH?: string;
}

export class ZREP {
  // ZREPHEAD: ZREPHEAD;
  // ZREPREALIZ: ZREPREALIZ;
  // ZREPRETURN: ZREPRETURN;
  // ZREPBODY: ZREPBODY;
}

export class CHECK {
  CHECKHEAD: CHECKHEAD;
}
