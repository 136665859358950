// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.8.52',
  appInsights: {
    instrumentationKey: '4cea2d1b-bfcc-4dfe-9c7f-7d3cc04bdc32',
    taxRegisterKey: '28e5bdbd-5c34-4b07-8420-876b05d5fb9b',
  },
};
