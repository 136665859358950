import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Tenant } from '../models/tenant';
import { Observable } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class ApiV1Service {
  private get baseUrl() {
    return this.settings.api_endpoint;
  }

  private get posApiUrl() {
    return this.settings.pos_api_endpoint;
  }

  constructor(private http: HttpClient, private settings: SettingsService) {}

  getTenants(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${this.baseUrl}/app-client/user-clients`);
  }

  getUserSession(): Observable<string> {
    return this.http.get<string>(
      `${this.baseUrl}/app-client/company-user-session`
    );
  }

  selectTenants(tenantId: string): Observable<string> {
    return this.http.post<string>(
      `${this.baseUrl}/app-client/select-client/${tenantId}`,
      {}
    );
  }

  getCompanyFeatures(storeId: number): Observable<any> {
    return this.http.get<boolean>(`${this.posApiUrl}/Billing/features`, {
      params: new HttpParams()
        .set('responseFormater', 'none')
        .set('storeId', storeId),
    });
  }

  sendEmail(sendDate: {
    email: string;
    text?: string;
    html?: string;
  }): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrl}/pos/terminal/send-email`,
      sendDate
    );
  }

  getStoreSettings(companyId: string) {
    return this.http.get<any>(
      `${this.settings.store_api}/Company/${companyId}`
    );
  }

  approveOnlineOrder(companyId: string, orderId: string) {
    return this.http.put<any>(`${this.settings.store_api}/Order/approve`, {
      companyId: companyId,
      orderId: orderId,
    });
  }

  getProduct(productId: number) {
    return this.http.get<boolean>(
      `${this.baseUrl}/pos/terminal/product/${productId}`,
      {
        params: new HttpParams().set('responseFormater', 'none'),
      }
    );
  }

  invoiceAddTaxRegister(fiscalNumber: string) {
    return this.http.patch(`${this.posApiUrl}/Billing/invoiceAddTaxRegister`, {
      fiscalNumber: fiscalNumber,
    });
  }

  invoiceAddStore(storeId: number) {
    return this.http.patch(`${this.posApiUrl}/Billing/invoiceAddStore`, {
      storeId: storeId,
    });
  }

  invoiceAddExpirenza() {
    return this.http.patch(`${this.posApiUrl}/Billing/invoiceAddExpirenza`, {});
  }

  invoiceAddTablesMap(storeId: number) {
    return this.http.patch(`${this.posApiUrl}/Billing/invoiceAddTablesMap`, {
      storeId: storeId,
    });
  }
}
