import { Injectable } from '@angular/core';
import { ApiV1Service } from 'src/app/services/api-v1.service';
import { StorageService } from './storage.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';

interface IFeaturesModel {
  store?: string;
  taxRegisters: any[];
  expirenza: string;
  tablesMap: string;
  terminals: any[];
  allFeatures: boolean;
}

@Injectable({ providedIn: 'root' })
export class FeatureService {
  private _companyFeatures: IFeaturesModel;
  constructor(
    private _api: ApiV1Service,
    private storage: StorageService,
    private auth: AuthService
  ) {}

  private async getFeatures(): Promise<IFeaturesModel> {
    if (this._companyFeatures) {
      return this._companyFeatures;
    }

    const features = await this._getCached();
    if (features) {
      this._companyFeatures = features;
      return features;
    }

    const tenant = await this.auth.getTenant();
    return this._getFeatures(tenant?.store.id).toPromise();
  }

  async isCpmanyLocked() {
    const features = await this.getFeatures();
    const isLocked = !features.store && !features.allFeatures;
    if (isLocked) {
      console.error('CpmanyLocked', {
        service: 'FeatureService',
      });

      const tenant = await this.auth.getTenant();
      this._api.invoiceAddStore(tenant?.store.id).subscribe();
    }

    return isLocked;
  }

  checkExpirenzaFeature() {
    if (!this._companyFeatures || this._companyFeatures.allFeatures) {
      return;
    }

    if (!this._companyFeatures.expirenza) {
      this._api.invoiceAddExpirenza().subscribe();
    }
  }

  async checkTablesMapFeature() {
    if (!this._companyFeatures || this._companyFeatures.allFeatures) {
      return;
    }

    if (!this._companyFeatures.tablesMap) {
      const tenant = await this.auth.getTenant();
      this._api.invoiceAddTablesMap(tenant?.store.id).subscribe();
    }
  }

  private _getFeatures(storeId: number) {
    return this._api.getCompanyFeatures(storeId).pipe(
      catchError((err) => {
        console.error(err, {
          service: 'FeatureService',
        });
        return throwError(() => err);
      }),
      map((res) => {
        this._setCached(res);
        return res;
      })
    );
  }

  async refreshFeatures() {
    const tenant = await this.auth.getTenant();
    return this._getFeatures(tenant?.store.id).toPromise();
  }

  hasTaxRegister(fiscalNumber: string): boolean {
    if (!this._companyFeatures) {
      console.error('TaxRegister subscription error. No features.', {
        service: 'FeatureService',
      });
      return false;
    }

    if (this._companyFeatures.allFeatures) {
      return true;
    }

    if (
      !this._companyFeatures.taxRegisters ||
      !this._companyFeatures.taxRegisters.length
    ) {
      console.error('TaxRegister subscription error. No subscriptions.', {
        service: 'FeatureService',
      });
      this._api.invoiceAddTaxRegister(fiscalNumber).subscribe();
      return false;
    }

    const taxRegister = this._companyFeatures.taxRegisters.find(
      (tr) => tr.entityId == fiscalNumber
    );

    if (!taxRegister) {
      console.error('TaxRegister subscription error. Subscription not found.', {
        service: 'FeatureService',
      });
    }

    return true;
  }

  private async _getCached(): Promise<any> {
    return this.storage.get('__company_features');
  }

  private async _setCached(features: any) {
    if (features) {
      this._companyFeatures = features;
      this.storage.set('__company_features', features);
    }
  }
}
