export default class MoneyUtils {
  static toNumber(value: string) {
    if (value === '' || value === null || value === undefined) {
      return null;
    }

    value = value.toString();
    if (value && value.length == 0) {
      return null;
    }

    // remove currency symbol
    value = value.replace(/[^0-9.,-]+/g, '');

    // replace local decimal separator
    if (value.toString().indexOf(',') !== -1) {
      value = value.toString().replace(',', '.');
    }

    if (Number.isNaN(Number(value))) {
      return null;
    }

    return parseFloat(value);
  }

  static round(value: number) {
    const rounded_1 = Math.round((value + Number.EPSILON) * 1000);
    return Math.round(rounded_1 / 10) / 100;
  }

  private _localDecimalSeparator() {
    const testNumber = 1.1;
    const numberSeparator = testNumber.toLocaleString().substring(1, 2);
    return numberSeparator;
  }

  static money(cents) {
    if (!cents || isNaN(cents)) {
      return (0).toFixed(2);
    }

    return (+cents).toFixed(2).replace('.', ',');
  }
}
