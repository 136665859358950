import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GenericRepository } from './generic.repository';

const TABLE_KEY = '__ordersBackup';

@Injectable({ providedIn: 'root' })
export class OrdersBackupRepository {
  constructor(private _repository: GenericRepository) {}

  updateOrder(order: any): Promise<void> {
    return this._repository.addItem(TABLE_KEY, order);
  }

  getOrders(): Promise<any[]> {
    return this._repository.getItems(TABLE_KEY);
  }

  deleteOrder(orderUuid: string): Promise<void> {
    return this._repository.deleteItem(orderUuid);
  }

  removeOldOrdersFromBackup(): Promise<void> {
    return this._repository.deleteTable(TABLE_KEY);
  }
}
